define("crm/router", ["exports", "crm/config/environment", "universe-gui/router"], function (_exports, _environment, _router) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    (0, _router.default)(this);
    this.route('invited', {
      path: "i/:invite_code"
    });
    this.route('emailValidated', {
      path: "v/:email_validation_code"
    });
    this.route('questionnaire-invites', function () {
      this.route('questionnaire-invite');
    });
    this.route('questionnaire-reply', {
      path: "q/:questionnaire_code"
    });
    this.route('organizations', function () {
      this.route('new');
      this.route('campanhas');
      this.route('organization', {
        path: ":organization_id"
      }, function () {
        this.route('edit'); // features

        this.route('features', function () {
          this.route('new');
          this.route('feature', {
            path: ":feature_id"
          }, function () {
            this.route('edit');
          });
        }); // plans

        this.route('plans', function () {
          this.route('new');
          this.route('plan', {
            path: ":plan_id"
          }, function () {
            this.route('edit');
          });
        }); // workers

        this.route('workers', function () {
          this.route('worker', {
            path: ":worker_id"
          }, function () {
            this.route('edit');
          });
          this.route('new');
        }); //patients

        this.route('patients', function () {
          this.route('patient', {
            path: ":patient_id"
          }, function () {
            this.route('items', function () {});
          });
        }); // customers

        this.route('customers', function () {
          this.route('customer', {
            path: ":customer_id"
          }, function () {
            this.route('edit');
            this.route('documents', function () {});
            this.route('open-items', function () {});
            this.route('items', function () {});
          });
        }); // appointments

        this.route('appointments', function () {
          this.route('appointment', {
            path: ":appointment_id"
          }, function () {
            this.route('edit');
          });
          this.route('week');
          this.route('group');
          this.route('day');
          this.route('day2');
          this.route('custom');
          this.route('loading');
        }); // consultations

        this.route('consultations', function () {
          this.route('consultation', {
            path: ":consultation_id"
          }, function () {
            this.route('edit');
            this.route('details');
          });
        }); // waitingRooms

        this.route('waitingRooms', function () {});
        this.route('suppliers', function () {
          this.route('new');
          this.route('supplier', {
            path: ":supplier_id"
          }, function () {
            this.route('edit');
            this.route('current-account');
          });
        }); // laboratories

        this.route('laboratories'); // configurations

        this.route('configurations', function () {
          this.route('general');
          this.route('item-types');
          this.route('avatar');
        });
        this.route('tasks', function () {
          this.route('archive');
        });
        this.route('invoices', function () {
          this.route('invoice', {
            path: ":invoice_id"
          });
        });
        this.route('documents', function () {}); //sheltereds

        this.route('sheltereds', function () {
          this.route('sheltered', {
            path: ":sheltered_id"
          }, function () {});
        }); //items

        this.route('items', function () {
          this.route('types');
          this.route('item', {
            path: ":item_id"
          }, function () {
            this.route('edit', function () {});
            this.route('movements');
            this.route('contacts');
            this.route('stock');
            this.route('documents');
            this.route('procurement');
          });
        }); //warehouses

        this.route('warehouses', function () {
          this.route('warehouse', {
            path: ":warehouse_id"
          }, function () {
            this.route('edit');
          });
        }); //exams

        this.route('exams', function () {
          this.route('exam', function () {});
        });
        this.route('pointOfSales');
        /*
         * Stats
         */

        this.route('stats', function () {
          this.route('waitingRoom');
          this.route('patients', function () {});
          this.route('sales', function () {
            this.route('items', function () {});
            this.route('overview', function () {
              this.route('itemTypes', function () {
                this.route('all');
              });
              this.route('sales');
            });
            this.route('documents', function () {
              this.route('invoices');
            });
            this.route('itemTypes', function () {
              this.route('itemType');
            });
            this.route('totals');
          });
          this.route('financialAccounts', function () {
            this.route('financialAccount', {
              path: ":financial_account_id"
            });
          });
          this.route('items');
          this.route('sales-table-days');
          this.route('schedule-wait');
          this.route('checkin-wait');
          this.route('visit-wait');
          this.route('checkout-wait');
          this.route('consultations', function () {});
          this.route('hr');
          this.route('reception', function () {
            this.route('map');
            this.route('waiting-room');
          });
          this.route('clinical', function () {
            this.route('scheduled');
            this.route('unscheduled');
            this.route('totals');
            this.route('species');
            this.route('types');
            this.route('workers');
          });
          this.route('crm', function () {
            this.route('patients', function () {});
          });
          this.route('internments', function () {
            this.route('daily');
          });
          this.route('referrals');
          this.route('procurement', function () {
            this.route('itemTypes');
          });
        });
        this.route('jasmin');
        this.route('purchases', function () {});
        this.route('periodics', function () {});
        this.route('inventory', function () {
          this.route('shortage');
          this.route('excess');
          this.route('movements');
        });
        this.route('campaigns', function () {
          this.route('campaign', {
            path: ":campaign_id"
          }, function () {});
          this.route('automatic');
        });
        this.route('sms', function () {});
        this.route('shifts', function () {}); //hotel

        this.route('hotel', function () {
          this.route('boxes', function () {});
          this.route('stays', function () {});
        });
        this.route('purchases', function () {});
        this.route('absences', function () {
          this.route('approval', function () {});
        });
        this.route('how-to', function () {});
        this.route('purchase-invoices', function () {
          this.route('new');
          this.route('upload');
          this.route('purchase-invoice', {
            path: ":purchase_invoice_id"
          }, function () {
            this.route('edit');
          });
        });
        this.route('financialAccounts', function () {
          this.route('financialAccount', {
            path: ":financial_account_id"
          }, function () {
            this.route('archive');
            this.route('financialAccountOpens', function () {
              this.route('financialAccountOpen', {
                path: ":financial_account_open_id"
              });
            });
          });
        });
        this.route('current-accounts', function () {});
        this.route('saft', function () {});
        this.route('managment');
        this.route('group', function () {
          this.route('sales');
          this.route('waitingRoom');
          this.route('financialAccounts', function () {
            this.route('financialAccount', {
              path: ":financial_account_id"
            });
          });
          this.route('calendar');
          this.route('salesTableDays');
          this.route('items');
          this.route('documents');
          this.route('patients', function () {
            this.route('new');
            this.route('active');
          });
          this.route('customers', function () {
            this.route('new');
            this.route('active');
          });
          this.route('financial-accounts', function () {});
          this.route('schedule-wait');
          this.route('checkin-wait');
          this.route('visit-wait');
          this.route('checkout-wait');
          this.route('consultations', function () {});
          this.route('hr');
          this.route('overview-sales');
          this.route('purchase-invoices', function () {});
          this.route('purchase-orders', function () {
            this.route('new');
          });
          this.route('purchase-requests', function () {});
          this.route('suppliers', function () {
            this.route('supplier', {
              path: ":supplier_id"
            }, function () {
              this.route('current-account');
            });
          });
          this.route('suppliers-current-accounts', function () {});
        });
        this.route('internments', function () {
          this.route('internment', {
            path: ":internment_id"
          }, function () {
            this.route('todo-chores', function () {
              this.route('todo-chore', {
                path: ":todo_chore_id"
              });
            });
            this.route('todo-drugs', function () {
              this.route('todo-drug', {
                path: ":todo_drug_id"
              });
            });
            this.route('todo-exams', function () {
              this.route('todo-exam', {
                path: ":todo_exam_id"
              });
            });
            this.route('todo-monitorings', function () {
              this.route('todo-monitoring', {
                path: ":todo_monitoring_id"
              });
            });
            this.route('todo-serums', function () {
              this.route('todo-serum', {
                path: ":todo_serum_id"
              });
            });
            this.route('shoppingCarts', function () {
              this.route('shoppingCart');
            });
            this.route('diagnosis');
            this.route('reports', function () {});
            this.route('clinical-report');
            this.route('financial-report');
          });
          this.route('overview');
          this.route('welcome');
          this.route('overview-shopping');
          this.route('simple-view');
        });
        this.route('liaisons', function () {});
        this.route('purchase-invoices-documents', function () {});
        this.route('events', function () {});
        this.route('management-stats');
        this.route('quotations', function () {
          this.route('quotation', {
            path: ":quotation_id"
          });
        });
        this.route('debts', function () {
          this.route('debt', {
            path: ":debt_id"
          });
        });
        this.route('purchase-orders', function () {
          this.route('new');
          this.route('purchase-order', {
            path: ":purchase_order_id"
          }, function () {
            this.route('edit');
          });
        });
        this.route('shopping-cart', {
          path: ":shopping_cart_id"
        });
        this.route('item-adjustments', function () {
          this.route('new');
          this.route('upload');
        });
        this.route('open-purchase-invoices', function () {});
        this.route('transfer-stock', function () {
          this.route('new');
        });
        this.route('manufacturers', function () {
          this.route('new');
          this.route('manufacturer', {
            path: ":manufacturer_id"
          }, function () {
            this.route('edit');
          });
        });
        this.route('equipments', function () {
          this.route('new');
          this.route('equipment', {
            path: ":equipment_id"
          }, function () {
            this.route('edit');
          });
        });
        this.route('equipment-parameters', function () {
          this.route('new');
          this.route('equipment-parameter', {
            path: ":equipment_parameter_id"
          }, function () {
            this.route('edit');
          });
        });
        this.route('equipment-types', function () {
          this.route('new');
          this.route('equipment-type', {
            path: ":equipment_type_id"
          }, function () {
            this.route('edit');
          });
        });
        this.route('equipment-parameter-types', function () {
          this.route('new');
          this.route('equipment-parameter-type', {
            path: ":equipment_parameter_type_id"
          }, function () {
            this.route('edit');
          });
        });
        this.route('shopping-carts', function () {});
        this.route('invites', function () {
          this.route('new');
        });
        this.route('templates', function () {
          this.route('new');
          this.route('edit', {
            path: ":template_id"
          });
        });
        this.route('clinicalWaitingRooms', function () {});
        this.route('update-items');
        this.route('schedule', function () {});
        this.route('listings', function () {
          this.route('sales', function () {
            this.route('all');
            this.route('receipts');
            this.route('receivables');
            this.route('taxes');
            this.route('inflow');
            this.route('lines');
            this.route('pending');
            this.route('shopping-carts');
            this.route('financial-accounts', function () {
              this.route('financial-account', {
                path: ":financial_account_id"
              }, function () {
                this.route('financial-account-opens', function () {
                  this.route('financial-account-open', {
                    path: ":financial_account_open_id"
                  });
                });
              });
            });
            this.route('items');
            this.route('vat-stats');
            this.route('items-new');
            this.route('users', function () {
              this.route('user', {
                path: ":user_id"
              });
            });
          });
          this.route('procurement', function () {
            this.route('items');
            this.route('pending');
            this.route('expired');
          });
          this.route('clinical', function () {
            this.route('periodics');
          });
          this.route('crm', function () {
            this.route('customers', function () {
              this.route('all');
              this.route('new');
              this.route('active');
            });
            this.route('contacts');
            this.route('patients', function () {
              this.route('new');
              this.route('active');
              this.route('all');
            });
          });
        });
        this.route('suppliers-current-accounts');
        this.route('procurement', function () {
          this.route('documents', function () {});
        });
        this.route('purchase-memos', function () {
          this.route('purchase-memo', {
            path: ":purchase_memo_id"
          }, function () {});
          this.route('new');
        });
        this.route('charges', function () {
          this.route('settings');
        });
        this.route('purchase-requests', function () {
          this.route('new');
          this.route('purchase-request', {
            path: ":purchase_request_id"
          }, function () {
            this.route('edit');
          });
        });
      });
    });
    this.route('settings');
    this.route('internal', function () {});
  });
  var _default = Router;
  _exports.default = _default;
});