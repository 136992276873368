define("crm/routes/organizations/organization/workers/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var organization = this.modelFor('organizations.organization');
      return Ember.RSVP.hash({
        users: organization.get("company.employees.length") > 1 ? null : this.store.query("user", {
          company: organization.get("company.id")
        }),
        employees: organization.get("company.employees.length") > 1 ? organization.get("company.employees") : this.store.query("employee", {
          company: organization.get("company.id")
        }),
        workers: organization.get("workers.length") > 1 ? organization.get("workers") : this.store.query("worker", {
          organization: organization.get("id")
        }),
        organization: organization
      }).then(function (r) {
        return r;
      });
    },
    actions: {
      created: function created() {
        this.model();
        this.transitionTo('organizations.organization.workers');
      },
      canceled: function canceled() {
        this.transitionTo('organizations.organization.workers');
      }
    }
  });

  _exports.default = _default;
});