define("crm/components/appointment-edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    es: Ember.inject.service(),
    selectedItemType: null,
    worker: null,
    visitTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      if (!Ember.isEmpty(this.get("itemTypes"))) {
        return this.get("itemTypes").filter(function (type) {
          return type.get("isVisit") && Ember.isEmpty(type.get("parent.id"));
        });
      }
    }),
    selectedCategoryObserver: Ember.observer("selectedCategory.id", function () {
      if (this.get("selectedCategory.id")) {
        var self = this;
        this.get("store").query("item", {
          company: this.get("organization.company.id"),
          type: this.get("selectedCategory.id"),
          sellable: true
        }).then(function (items) {
          self.set("filteredItems", items);

          if (items != null && items.get("length") == 1) {
            self.set("model.type", items.get("firstObject"));
          }
        });
      }
    }),
    canEditPatientAndCustomer: Ember.computed("model.status", function () {
      return [1, 2, 3].includes(this.get("model.status"));
    }),
    hideIcon: Ember.computed("model.category.id", "model.patient.id", function () {
      return !(this.get("model.category.id") && this.get("model.patient.id"));
    }),
    disabledAppointmentEdit: Ember.computed("model.category.id", "model.patient.id", function () {
      return !(this.get("model.category.id") && this.get("model.patient.id"));
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      var self = this;

      if (this.get("model")) {
        this.get("store").findRecord("appointment", this.get("model.id")).then(function (appointment) {
          self.set("model.type", appointment.get("type"));
          self.set("selectedItemType", appointment.get("type"));
          self.set("worker", appointment.get("worker"));
          var start = (0, _moment.default)(self.get("model.start"));
          self.set("startDay", start.format("YYYY-MM-DD"));
          self.set("startTime", start.format("HH:mm"));
          var end = (0, _moment.default)(self.get("model.end"));
          self.set("endTime", end.format("HH:mm"));
          self.set("model.sendNotification", !self.get("model.skipNotification"));
          self.set("patient", appointment.get("patient") ? appointment.get("patient") : null);
        });
      }

      if (this.get("model.category.id")) {
        this.set("selectedCategory", this.get("model.category"));
      }

      if (this.get("model.patient.id")) {
        this.set("patientWeights", this.get("store").query("weight", {
          patient: this.get("model.patient.id")
        }));
      } // if hasSMS


      if (this.get("model.organization.campaignsFeature")) {
        this.set("model.sendNotification", !["244", "245", "93", "94", "95"].includes(this.get("model.organization.id")));
      }
    },
    duration: Ember.computed("startDay", "startTime", "endTime", function () {
      if (this.get("startTime"), this.get("endTime")) {
        var startDate = (0, _moment.default)(this.get("startDay") + "T" + this.get("startTime"), "YYYY-MM-DDTHH:mm");
        var endDate = (0, _moment.default)(this.get("startDay") + "T" + this.get("endTime"), "YYYY-MM-DDTHH:mm");
        return endDate.diff(startDate, "minutes");
      } else {
        return "";
      }
    }),
    close: function close() {
      Ember.$("#modal-appointment-edit").modal("hide");
    },
    actions: {
      setSelectedCategory: function setSelectedCategory(value) {
        this.set("selectedCategory", value);
        this.set("model.category", value);
        this.set("model.type", null);
      },
      selectPatient: function selectPatient() {
        this.sendAction("openModal", {
          entity: "patient",
          action: "select"
        });
      },
      unselectPatientAndCustomer: function unselectPatientAndCustomer() {
        this.set("patient", null);
      },
      canceled: function canceled() {
        this.close();
      },
      create: function create() {
        var self = this; // Skip Notifications

        if (this.get("model.organization.campaignsFeature")) {
          this.set("model.skipNotification", !this.get("model.sendNotification"));
        }

        if (this.get("startDay") && this.get("startTime")) {
          this.set("model.start", (0, _moment.default)(this.get("startDay") + "T" + this.get("startTime"), "YYYY-MM-DDTHH:mm").format());
        } // END


        if (this.get("endTime")) {
          this.set("model.end", (0, _moment.default)(this.get("startDay") + "T" + this.get("endTime"), "YYYY-MM-DDTHH:mm").format());
        }

        if (this.get("worker")) {
          this.set("model.worker", this.get("worker"));
        }

        if (this.get("patient")) {
          this.set("model.patient", this.get("patient"));
          this.set("model.customer", this.get("patient.owner"));
        }

        this.get("model").save().then(function () {
          self.sendAction("reloadModel");
          self.close();
        }).catch(function (result) {
          self.get("es").errorReturned(result);
        });
      },
      reset: function reset() {
        this.set("selectedPatient", null);
        this.get("model") && this.get("model").rollbackAttributes();
        this.set("selectedItems", []);
        Ember.$("select").prop("selectedIndex", 0);
        this.set("selectedItemType", null);
      },
      selectedWorker: function selectedWorker(worker) {
        this.set("worker", worker);
      },
      openModalAssociatePatient: function openModalAssociatePatient() {
        Ember.$("#openAssociatePatientOnAppointment").modal();
      },
      openEditPatientModal: function openEditPatientModal(model) {
        this.sendAction("openEditPatientModal", model);
      },
      delete: function _delete(id) {
        this.sendAction("delete", id);
      },
      setEnabled: function setEnabled(model) {
        this.sendAction("setEnabled", model);
      },
      makeSale: function makeSale(model) {
        this.sendAction("makeSale", model);
      },
      openCreateAppointmentModal: function openCreateAppointmentModal(model) {
        Ember.$("#modal-appointment-create").modal("hide");
        this.sendAction("openCreateAppointmentModal", model);
      },
      openUpdateCustomerModal: function openUpdateCustomerModal(record) {
        this.sendAction("openUpdateCustomerModal", record);
      }
    }
  });

  _exports.default = _default;
});