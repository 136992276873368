define("crm/templates/components/tasks-list-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JlcEfTri",
    "block": "{\"symbols\":[\"task\"],\"statements\":[[6,\"ul\"],[9,\"class\",\"todo-list\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"filteredModel\"]]],null,{\"statements\":[[0,\"        \"],[6,\"li\"],[10,\"class\",[26,[\"ellipsis \",[25,\"if\",[[19,1,[\"isDue\"]],\"due\"],null]]]],[7],[0,\"\\n\"],[4,\"unless\",[[20,[\"hideDoneAction\"]]],null,{\"statements\":[[0,\"                \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"actions.doTask\"],null],null],[3,\"action\",[[19,0,[]],\"done\",[19,1,[]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-square-o text-\",[19,1,[\"priorityColor\"]]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[19,1,[\"isDue\"]]],null,{\"statements\":[[0,\"                \"],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-clock-o pull-right\"],[10,\"title\",[25,\"t\",[\"tasks.status.inDelay\"],null],null],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[6,\"span\"],[10,\"class\",[26,[\"m-l-xs \",[25,\"if\",[[19,1,[\"doneTaskDate\"]],\"todo-completed\"],null]]]],[7],[0,\"\\n                \"],[6,\"a\"],[10,\"title\",[19,1,[\"name\"]],null],[3,\"action\",[[19,0,[]],\"details\",[19,1,[]]]],[7],[0,\"\\n                    \"],[4,\"if\",[[19,1,[\"type\",\"code\"]]],null,{\"statements\":[[6,\"i\"],[10,\"class\",[26,[\"fa fa-xs fa-\",[25,\"t\",[[25,\"concat\",[\"icons.\",[19,1,[\"type\",\"code\"]]],null]],null]]]],[7],[8]],\"parameters\":[]},null],[0,\"\\n                    \"],[1,[19,1,[\"name\"]],false],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/tasks-list-simple.hbs"
    }
  });

  _exports.default = _default;
});