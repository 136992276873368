define("crm/templates/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oB3RUgS0",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-lg-10 col-lg-offset-1\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"title\",\"titleIcon\",\"hideTools\"],[[25,\"t\",[\"app.name\"],null],\"paw\",true]],{\"statements\":[[0,\"            \"],[1,[25,\"t\",[\"app.description\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[9,\"class\",\"col-lg-10 col-lg-offset-1\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"title\",\"titleIcon\",\"hideTools\"],[[25,\"t\",[\"about.contacts\"],null],\"phone\",true]],{\"statements\":[[0,\"            \"],[6,\"div\"],[9,\"class\",\"list-group\"],[7],[0,\"\\n                \"],[6,\"span\"],[9,\"class\",\"list-group-item\"],[7],[0,\"\\n                    \"],[6,\"i\"],[9,\"class\",\"fa fa-envelope-o fa-fw\"],[7],[8],[6,\"strong\"],[7],[0,\" \"],[1,[25,\"t\",[\"models.person.email\"],null],false],[8],[0,\": \"],[1,[25,\"t\",[\"app.email\"],null],false],[0,\"\\n                \"],[8],[0,\"\\n                \"],[6,\"span\"],[9,\"class\",\"list-group-item\"],[7],[0,\"\\n                    \"],[6,\"i\"],[9,\"class\",\"fa fa-mobile fa-fw\"],[7],[8],[6,\"strong\"],[7],[0,\" \"],[1,[25,\"t\",[\"models.person.mobilePhone\"],null],false],[8],[0,\": \"],[1,[25,\"t\",[\"app.phone\"],null],false],[0,\"\\n                \"],[8],[0,\"\\n                \"],[6,\"span\"],[9,\"class\",\"list-group-item\"],[7],[0,\"\\n                    \"],[6,\"i\"],[9,\"class\",\"fa fa-globe fa-fw\"],[7],[8],[0,\" \"],[6,\"strong\"],[7],[1,[25,\"t\",[\"models.person.country\"],null],false],[8],[0,\": \"],[1,[25,\"t\",[\"app.website\"],null],false],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/about.hbs"
    }
  });

  _exports.default = _default;
});