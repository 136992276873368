define("crm/templates/components/show-appointment-symptoms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hN24UQJu",
    "block": "{\"symbols\":[\"symptom\"],\"statements\":[[4,\"if\",[[20,[\"record\",\"symptomsDescription\"]]],null,{\"statements\":[[0,\"    \"],[6,\"i\"],[9,\"class\",\"fa fa-comment-o\"],[10,\"title\",[20,[\"record\",\"symptomsDescription\"]],null],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"     \\n\"]],\"parameters\":[]}],[4,\"each\",[[20,[\"record\",\"symptoms\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"badge badge-default\"],[10,\"title\",[19,1,[\"name\"]],null],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-appointment-symptoms.hbs"
    }
  });

  _exports.default = _default;
});