define("crm/templates/custom/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I8XHmjgL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"organizations.organization.stocks.stockMovements.stockMovement.edit\",[20,[\"record\"]]],null,{\"statements\":[[6,\"i\"],[9,\"class\",\"fa fa-pencil\"],[7],[8]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/custom/edit.hbs"
    }
  });

  _exports.default = _default;
});