define("crm/templates/organizations/organization/suppliers/supplier/current-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "waNMfT/z",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"suppliers.supplier.title\"],null],[25,\"t\",[\"icons.supplier\"],null]]]],false],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"titleIcon\",\"class\",\"title\",\"contentHeading\"],[\"icons.supplier\",\"module-procurement\",[25,\"t\",[\"suppliers.supplier.title\"],null],[20,[\"model\",\"name\"]]]],{\"statements\":[[0,\"        \"],[6,\"ul\"],[9,\"class\",\"list-group\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"vat\"]]],null,{\"statements\":[[0,\"                \"],[6,\"li\"],[9,\"class\",\"list-group-item\"],[7],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[1,[20,[\"model\",\"vat\"]],false],[8],[6,\"strong\"],[7],[1,[25,\"t\",[\"customers.customer.nif\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[20,[\"model\",\"currentAccount\"]]],null,{\"statements\":[[0,\"                \"],[6,\"li\"],[10,\"class\",[26,[\"list-group-item \",[25,\"if\",[[25,\"gt\",[[20,[\"model\",\"currentAccount\"]],0],null],\"text-danger\"],null]]]],[7],[0,\"\\n                    \"],[1,[25,\"price-display\",null,[[\"price\"],[[20,[\"model\",\"currentAccount\"]]]]],false],[0,\"\\n                \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"ibox-panel\",null,[[\"title\",\"titleIcon\",\"titleCount\",\"class\"],[[25,\"t\",[\"documents.title\"],null],\"copy\",[20,[\"filteredModels\",\"length\"]],\"module-procurement\"]],{\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"filteredModels\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.supplierItems\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/suppliers/supplier/current-account.hbs"
    }
  });

  _exports.default = _default;
});