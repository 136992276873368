define("crm/templates/organizations/organization/internments/welcome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UgmOBYXs",
    "block": "{\"symbols\":[],\"statements\":[[6,\"section\"],[9,\"class\",\"page-section\"],[7],[0,\"\\n    \"],[1,[25,\"u-second-nav\",null,[[\"class\",\"title\",\"titleIcon\"],[\"module-internments\",[25,\"t\",[\"pages.internments\"],null],[25,\"t\",[\"icons.internment\"],null]]]],false],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row text-center m-t-lg\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-6\"],[7],[0,\"\\n            \"],[6,\"a\"],[9,\"href\",\"https://hopi.petuniversal.com\"],[9,\"target\",\"_blank\"],[9,\"class\",\"ibox-content animated fadeIn m-b-lg\"],[7],[0,\"\\n                \"],[6,\"i\"],[9,\"class\",\"fa fa-3x fa-external-link text-grey m-t-md\"],[7],[8],[0,\"\\n                \"],[6,\"h2\"],[9,\"class\",\"m-t-md\"],[7],[0,\"Hopi\"],[8],[0,\"\\n                \"],[6,\"p\"],[7],[1,[25,\"t\",[\"internments.welcome.hopi\"],null],false],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-6\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"organizations.organization.internments.overview\"],[[\"tagName\",\"class\"],[\"article\",\"clickable ibox-content animated fadeIn m-b-lg\"]],{\"statements\":[[0,\"                \"],[6,\"i\"],[9,\"class\",\"fa fa-3x fa-gift text-grey m-t-md\"],[7],[8],[6,\"span\"],[9,\"class\",\"label label-info float-right\"],[7],[1,[25,\"t\",[\"menu.new\"],null],false],[8],[0,\"\\n                \"],[6,\"h2\"],[9,\"class\",\"m-t-md\"],[7],[1,[25,\"t\",[\"internments.welcome.trial\"],null],false],[8],[0,\"\\n                \"],[6,\"p\"],[7],[1,[25,\"t\",[\"internments.welcome.trialMsg\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/internments/welcome.hbs"
    }
  });

  _exports.default = _default;
});