define("crm/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "about": {
      "contacts": "Contacts"
    },
    "absences": {
      "approve": {
        "title": "Approve Absence Requests"
      },
      "edit": {
        "title": "Edit Absence"
      },
      "new": {
        "title": "New Absence"
      },
      "title": "Absences",
      "type": {
        "funeral": "Funeral",
        "holidays": "Holidays",
        "marriage": "Marriage",
        "parental": "Parental",
        "sick": "Sick",
        "title": "Absence type"
      }
    },
    "account": {
      "emailUs": "email",
      "freeVersion": "Free version!",
      "getStarted": {
        "addData": "Add the details of your hospital or clinic.",
        "clickCreate": "Click <Create>.",
        "clickPlus": "Click “+ Register Hospital”.",
        "guide": "See the get started guide",
        "seeDetails": "After registration of the animal you can proceed and hospitalize it.",
        "step1": "Step 1",
        "step1Done": "Hospital successfully registered.",
        "step2": "Step 2",
        "step2Done": "Already have registered patients.",
        "step3": "Step 3",
        "title1": "Register Hospital / Clinic",
        "title2": "Register your first patient",
        "title3": "New hospitalization chart"
      },
      "limitedUse": "Some functionality may be limited. To unlock send us an",
      "personEditSubtitle": "Update your personal data.",
      "securityEditSubtitle": "Change your login information.",
      "settingsSubtitle": "Customize the system as you like.",
      "softwareUpdated": "There is an update available - {module} version: {version}.<br><b>Click here to update!</b>",
      "title": "Account"
    },
    "actions": {
      "addLine": "Add Line",
      "advanceOneMonth": "Advance one month",
      "approve": "Approve",
      "bill": "Bill",
      "checkIn": "Check In",
      "checkOut": "Check Out",
      "checkOutTitle": "Move to checkout but keep chart open",
      "checkedOut": "Checked Out",
      "checkoutWithoutPaying": "Checkout without paying",
      "clear": "Clear Filters",
      "clearCart": "Clear",
      "close": "Close",
      "continue": "Continue",
      "create": {
        "lastAction": "Last action"
      },
      "currentHour": "Current Date and hour",
      "delete": "Delete",
      "details": "Details",
      "disable": "Disable",
      "doTask": "Do Task",
      "download": "Download",
      "edit": "Edit",
      "editProfile": "Edit Profile",
      "enable": "Enable",
      "filters": "Filters",
      "finalConsumer": "Final Consumer",
      "giveNext": "Give The Next One",
      "giveUp": "Give Up",
      "goBackOneMonth": "Go back one month",
      "goToConsultation": "Go To Visit",
      "makeSale": "Sale",
      "markAsPaid": "Mark as paid",
      "memos": "Generate Credit Notes",
      "missed": "Missed",
      "moveStock": "Move Stock",
      "newPurchaseInvoiceLine": "New purchase line",
      "newScheduling": "New Scheduling",
      "openConsultation": "See visit",
      "openShoppingCartDetails": "See shopping cart",
      "openShoppingCartEdit": "Edit shopping cart",
      "paid": "Paid",
      "pay": "Pay",
      "print": "Print",
      "receive": "Receive",
      "recordResults": "Record Results",
      "reject": "Reject",
      "removeAppointmentConsultation": "Are you sure you want to delete this appointment?",
      "reschedule": "Reschedule",
      "rescheduled": "Rescheduled",
      "roundPrices": "Round",
      "schedule": "Schedule",
      "scheduled": "Scheduled",
      "search": "Customer or Patient",
      "searchItems": "Item",
      "selectCustomer": "Select customer",
      "selectItem": "Select item",
      "selectPatient": "Select patient",
      "send": "Send",
      "showInvoice": "Generate Invoice",
      "title": "Actions",
      "transferStock": "Transfer Stock",
      "undoTask": "Undo Task",
      "view": "View",
      "viewItem": "View Item",
      "viewProfile": "View Profile",
      "viewTask": "View Task"
    },
    "adjustmentReasons": {
      "adjustmentReason": {
        "title": "Reason"
      }
    },
    "alerts": {
      "adjustmentWithItemStock": "This reason is only used for items that never had an initial stock",
      "appointmentCheckout": "Visit has ended",
      "appointmentGivenUp": "Client has given up",
      "appointmentMissed": "Client didn't show up",
      "appointmentRescheduled": "Appointment was rescheduled",
      "changesSaved": "Changes saved",
      "creatingItem": "Here you're creating a new item",
      "customerWithoutNif": "Can't pay with the selected billing data being Customer if the chosen customer doesn't have a NIF assigned",
      "done": "Are you sure you want to mark this task as done?",
      "inProgress": "In Progress...",
      "inactiveCustomer": "This customer is inactive",
      "inactivePatient": "This patient is inactive",
      "invalidItems": "There are invalid items",
      "leave": "If you leave all changes will be lost",
      "missingOwner": "There's no owner assigned",
      "missingQuotation": "This client doesn't have any quotation",
      "missingWeight": "There's no weight registed",
      "mustExistPrice": "A line must have a price",
      "negativeStock": "Quantity cannot be less than 0",
      "noDiagnostic": "You haven't yet added any diagnostic",
      "noDrugs": "You haven't yet added any drugs",
      "noMcd": "Without Complementary Means",
      "noSpaces": "This field doesn't accept spaces or accents",
      "noSymptoms": "You haven't yet added any symptoms",
      "noTreatmentPlan": "You haven't yet added any treatment plans",
      "noWarehouseItems": "This warehouse doesn't have any items",
      "referralCustomer": "This customer is a referral",
      "referralPatient": "This patient is a referral",
      "roomAllreadyWithAppointment": "This room already has an associated appointment in this period",
      "sendEmail": "Are you sure you want to send this email?",
      "simplifiedInvoiceWarning": "A simplified invoice can't have a total over 100",
      "transferDatesMessage": "The loading date must be before the unloading date",
      "weightUpdated": "The weight is not updated, please update it to continue",
      "workerAllreadyWithAppointment": "This worker allready has an appointment scheduled for this period"
    },
    "application": {
      "loading": "Loading"
    },
    "appointments": {
      "appointment": {
        "appointmentDate": "Appointment Date",
        "appointmentType": "Visit Type",
        "arrivalTime": "Arrival Time",
        "calls": "Calls",
        "dateHasPassed": "Can not schedule an appointment for a past date",
        "end": "End",
        "endConsultation": "End Visit",
        "exitTime": "Exit Time",
        "giveUpDate": "Give Up Date",
        "giveUpReason": "Give Up Reason",
        "googleAppointmentId": "Google Appointment ID",
        "madeBy": "Made by:",
        "missedDate": "Missed Date",
        "missedReason": "Missed Reason",
        "note": "Note",
        "notice": "Notice",
        "noticeDate": "Notice Date",
        "observation": "Observation",
        "periodicInfo": "Periodic Info",
        "receptionNotes": "Reception Notes",
        "recurrenceInfo": "Recurrence Info",
        "resource": "Resource",
        "scheduledBy": "Scheduled by:",
        "scheduledFor": "Scheduled for:",
        "start": "Start",
        "status": "Status",
        "subject": "Subject",
        "timeConsultation": "Time Visit",
        "title": "Appointment",
        "type": "Type",
        "weight": "Weight"
      },
      "checkin": "Checkin",
      "edit": {
        "title": "Edit Appointment"
      },
      "filters": {
        "noFilters": "You are not seeing any columns because the filters are all inactive, please select what you wish to see in the filters",
        "title": "Filters"
      },
      "messages": {
        "checkOut": "Successfully checked out",
        "deleted": "Appointment deleted",
        "deletedConsultation": "Consultation deleted",
        "reschedule": "Please choose another date for: "
      },
      "new": {
        "associatePatient": "Associate Patient",
        "changeCustomer": "Change customer",
        "changePatientAndCustomer": "Change patient and customer",
        "changeSupplier": "Change supplier",
        "completeRecord": "Complete Record",
        "expectedDuration": "Expected Duration",
        "fast": {
          "title": "New Fast Appointment"
        },
        "noDataToShow": "No Data To Show",
        "reason": "Reason",
        "repetition": "Repetition",
        "title": "New Appointment",
        "to": "to",
        "worker": "Select Worker"
      },
      "noWorkerAssigned": "Without an employee assigned",
      "status": {
        "checkinWithScheduling": "Checkin With Scheduling",
        "checkinWithoutscheduling": "Checkin Without Scheduling",
        "checkout": "Checkout",
        "finished": "Finished",
        "getOut": "Get Out",
        "givenUp": "Given Up",
        "inCheckout": "In Checkout",
        "inConsultation": "In Visit",
        "inProgress": "In Progress",
        "inVisit": "In Visit",
        "missed": "Missed",
        "paused": "Paused",
        "rescheduledPhrase": "Rescheduled",
        "scheduled": "Scheduled"
      },
      "title": "Appointments"
    },
    "approvals": {
      "approve": {
        "title": "Approve"
      },
      "messages": {
        "approve": "Are you sure you want to approve this absence?",
        "reject": "Are you sure you want to reject this absence?"
      },
      "reject": {
        "title": "Reject"
      },
      "status": {
        "approved": "Approved",
        "pending": "Pending",
        "rejected": "Rejected",
        "title": "Status"
      },
      "title": "Absences Approval"
    },
    "avatar": {
      "accessories": {
        "kurt": "Kurt",
        "prescription1": "Prescription 1",
        "prescription2": "Prescription 2",
        "round": "Round",
        "sunglasses": "Sunglasses",
        "title": "Accessories",
        "wayfarers": "Wayfarers"
      },
      "clothes": {
        "blazerShirt": "Blazer Shirt",
        "blazerSweater": "Blazer Sweater",
        "collarSweater": "Collar Sweater",
        "graphicShirt": "Graphic Shirt",
        "hoodie": "Hoodie",
        "overall": "Overall",
        "shirtCrewNeck": "Shirt Crew Neck",
        "shirtScoopNeck": "Shirt Scoop Neck",
        "shirtVNeck": "Shirt V Neck",
        "title": "Clothes"
      },
      "colorFabric": {
        "black": "Black",
        "blue1": "Blue 1",
        "blue2": "Blue 2",
        "blue3": "Blue 3",
        "gray1": "Gray 1",
        "gray2": "Gray 2",
        "heather": "Heather",
        "pastelBlue": "PastelBlue",
        "pastelGreen": "PastelGreen",
        "pastelOrange": "PastelOrange",
        "pastelRed": "PastelRed",
        "pastelYellow": "PastelYellow",
        "pink": "Pink",
        "red": "Red",
        "title": "Color Fabric",
        "white": "White"
      },
      "eyebrow": {
        "angry": "Angry",
        "angryNatural": "Angry Natural",
        "default": "Default",
        "defaultNatural": "Default Natural",
        "flatNatural": "Flat Natural",
        "raisedExcited": "Raised Excited",
        "raisedExcitedNatural": "Raised Excited Natural",
        "sadConcerned": "Sad Concerned",
        "sadConcernedNatural": "Sad Concerned Natural",
        "title": "Eyebrow",
        "unibrowNatural": "Unibrow Natural",
        "upDown": "Up Down",
        "upDownNatural": "Up Down Natural"
      },
      "eyes": {
        "close": "Close",
        "cry": "Cry",
        "default": "Default",
        "dizzy": "Dizzy",
        "eyeRoll": "Eye Roll",
        "happy": "Happy",
        "hearts": "Hearts",
        "side": "Side",
        "squint": "Squint",
        "surprised": "Surprised",
        "title": "Eyes",
        "wink": "Wink",
        "winkWacky": "Wink Wacky"
      },
      "hatColor": {
        "blue1": "Blue 1",
        "blue2": "Blue 2",
        "blue3": "Blue 3",
        "gray1": "Gray 1",
        "gray2": "Gray 2",
        "heather": "Heather",
        "pastelBlue": "PastelBlue",
        "pastelGreen": "PastelGreen",
        "pastelOrange": "PastelOrange",
        "pastelRed": "PastelRed",
        "pastelYellow": "PastelYellow",
        "pink": "Pink",
        "red": "Red",
        "title": "Hat Color",
        "white": "White"
      },
      "mouth": {
        "concerned": "Concerned",
        "default": "Default",
        "disbelief": "Disbelief",
        "eating": "Eating",
        "grimace": "Grimace",
        "sad": "Sad",
        "screamOpen": "Scream Open",
        "serious": "Serious",
        "smile": "Smile",
        "title": "Mouth",
        "tongue": "Tongue",
        "twinkle": "Twinkle",
        "vomit": "Vomit"
      },
      "skin": {
        "black": "Black",
        "brown": "Brown",
        "darkBrown": "Dark Brown",
        "light": "Light",
        "pale": "Pale",
        "tanned": "Tanned",
        "title": "Skin",
        "yellow": "Yellow"
      },
      "top": {
        "ShortHairShortFlat": "Short Hair Short Flat",
        "eyepatch": "Eyepatch",
        "hat": "Hat",
        "hijab": "Hijab",
        "longHairBigHair": "Long Hair Big Hair",
        "longHairBob": "Long Hair Bob",
        "longHairBun": "Long Hair Bun",
        "longHairCurly": "Long Hair Curly",
        "longHairCurvy": "Long Hair Curvy",
        "longHairDreads": "Long Hair Dreads",
        "longHairFrida": "Long Hair Frida",
        "longHairFro": "Long Hair Fro",
        "longHairFroBand": "Long Hair Fro Band",
        "longHairMiaWallace": "Long Hair Mia Wallace",
        "longHairNotTooLong": "Long Hair Not Too Long",
        "longHairShavedSides": "Long Hair Shaved Sides",
        "longHairStraight1": "Long Hair Straight 1",
        "longHairStraight2": "Long Hair Straight 2",
        "longHairStraightStrand": "Long Hair Straight Strand",
        "shortHairDreads1": "Short Hair Dreads 1",
        "shortHairDreads2": "Short Hair Dreads 2",
        "shortHairFrizzle": "Short Hair Frizzle",
        "shortHairShaggyMullet": "Short Hair Shaggy Mullet",
        "shortHairShortCurly": "Short Hair Short Curly",
        "shortHairShortRound": "Short Hair Short Round",
        "shortHairShortWaved": "Short Hair Short Waved",
        "shortHairSides": "Short Hair Sides",
        "shortHairTheCaesar": "Short Hair The Caesar",
        "shortHairTheCaesarSidePart": "Short Hair The Caesar Side Part",
        "title": "Top",
        "turban": "Turban",
        "winterHat1": "Winter Hat 1",
        "winterHat2": "Winter Hat 2",
        "winterHat3": "Winter Hat 3",
        "winterHat4": "Winter Hat 4"
      }
    },
    "boxes": {
      "create": "Create Box",
      "edit": "Edit Box",
      "name": "Name",
      "status": {
        "checkin": "Checkin",
        "checkout": "Checkout",
        "free": "Free",
        "occupied": "Ocuppied",
        "title": "Status"
      },
      "title": "Boxes"
    },
    "brands": {
      "brand": {
        "name": "Name",
        "title": "Brand"
      },
      "description": "See all brands",
      "form": {
        "placeholder": {
          "name": "Name"
        }
      },
      "messages": {
        "createdSuccess": "Brand successfully created"
      },
      "new": {
        "title": "New Brand"
      },
      "title": "Brands"
    },
    "breeds": {
      "0": "Unknown",
      "1": "English Pointer",
      "10": "Border Terrier",
      "100": "Wire-haired Pointing Griffon Korthals",
      "101": "Picardy Spaniel",
      "102": "Clumber Spaniel",
      "103": "Curly Coated Retriever",
      "104": "Golden Retriever",
      "105": "Briard",
      "106": "Pont-audemer Spaniel",
      "107": "Saint Germain Pointer",
      "108": "Dogue De Bordeaux",
      "109": "Deutsch Langhaar",
      "11": "Bull Terrier",
      "110": "Large Munsterlander",
      "111": "German Short- Haired Pointing Dog",
      "112": "Irish Red Setter",
      "113": "Flat Coated Retriever",
      "114": "Labrador Retriever",
      "115": "Field Spaniel",
      "116": "Irish Water Spaniel",
      "117": "English Springer Spaniel",
      "118": "Welsh Springer Spaniel",
      "119": "Sussex Spaniel",
      "12": "Fox Terrier (Smooth)",
      "120": "King Charles Spaniel",
      "121": "Smålandsstövare",
      "122": "Drever",
      "123": "Schillerstövare",
      "124": "Hamiltonstövare",
      "125": "French Pointing Dog - Gascogne Type",
      "126": "French Pointing Dog - Pyrenean Type",
      "127": "Swedish Lapphund",
      "128": "Cavalier King Charles Spaniel",
      "129": "Pyrenean Mountain Dog",
      "13": "English Toy Terrier",
      "130": "Pyrenean Sheepdog - Smooth Faced",
      "131": "Irish Terrier",
      "132": "Boston Terrier",
      "133": "Long-haired Pyrenean Sheepdog",
      "134": "Slovakian Chuvach",
      "135": "Dobermann",
      "136": "Boxer",
      "137": "Leonberger",
      "138": "Rhodesian Ridgeback",
      "139": "Rottweiler",
      "14": "Swedish Vallhund",
      "140": "Bulldog",
      "141": "Serbian Hound",
      "142": "Istrian Short-haired Hound",
      "143": "Istrian Wire-haired Hound",
      "144": "Dalmatian",
      "145": "Posavatz Hound",
      "146": "Bosnian Broken-haired Hound - Called Barak",
      "147": "Collie Rough",
      "148": "Bullmastiff",
      "149": "Greyhound",
      "15": "Belgian Shepherd Dog",
      "150": "English Foxhound",
      "151": "Irish Wolfhound",
      "152": "Beagle",
      "153": "Whippet",
      "154": "Basset Hound",
      "155": "Deerhound",
      "156": "Italian Spinone",
      "157": "German Shepherd Dog",
      "158": "American Cocker Spaniel",
      "159": "Dandie Dinmont Terrier",
      "16": "Old English Sheepdog",
      "160": "Fox Terrier (Wire)",
      "161": "Portuguese cattle dog",
      "162": "Bouvier Des Ardennes",
      "163": "Poodle",
      "164": "Estrela Mountain Dog",
      "165": "French Spaniel",
      "166": "Picardy Sheepdog",
      "167": "Ariege Pointing Dog",
      "168": "Bourbonnais Pointing Dog",
      "169": "Auvergne Pointer",
      "17": "Griffon Nivernais",
      "170": "Giant Schnauzer",
      "171": "Schnauzer",
      "172": "Miniature Schnauzer",
      "173": "German Pinscher",
      "174": "Miniature Pinscher",
      "175": "Affenpinscher",
      "176": "Portuguese Pointing Dog",
      "177": "Sloughi",
      "178": "Finnish Lapphund",
      "179": "Hovawart",
      "18": "Briquet Griffon Vendeen",
      "180": "Bouvier Des Flandres",
      "181": "Kromfohrländer",
      "182": "Borzoi - Russian Hunting Sighthound",
      "183": "Bergamasco Shepherd Dog",
      "184": "Italian Volpino",
      "185": "Bolognese",
      "186": "Neapolitan Mastiff",
      "187": "Italian Rough-haired Segugio",
      "188": "Cirneco Dell'etna",
      "189": "Italian Sighthound",
      "19": "Ariegeois",
      "190": "Maremma And The Abruzzes Sheepdog",
      "191": "Italian Pointing Dog",
      "192": "Norwegian Hound",
      "193": "Spanish Hound",
      "194": "Chow Chow",
      "195": "Japanese Chin",
      "196": "Pekingese",
      "197": "Shih Tzu",
      "198": "Tibetan Terrier",
      "199": "Canadian Eskimo Dog",
      "2": "English Setter",
      "20": "Gascon Saintongeois",
      "200": "Samoyed",
      "201": "Hanoverian Scent Hound",
      "202": "Hellenic Hound",
      "203": "Bichon Frise",
      "204": "Pudelpointer",
      "205": "Bavarian Mountain Scent Hound",
      "206": "Chihuahua",
      "207": "French Tricolour Hound",
      "208": "French White & Black Hound",
      "209": "Frisian Water Dog",
      "21": "Great Gascony Blue",
      "210": "Stabijhoun",
      "211": "Dutch Shepherd Dog",
      "212": "Drentsche Partridge Dog",
      "213": "Fila Brasileiro",
      "214": "Landseer (European Continental Type)",
      "215": "Lhasa Apso",
      "216": "Afghan Hound",
      "217": "Serbian Tricolour Hound",
      "218": "Tibetan Mastiff",
      "219": "Tibetan Spaniel",
      "22": "Poitevin",
      "220": "Deutsch Stichelhaar",
      "221": "Little Lion Dog",
      "222": "Xoloitzcuintle",
      "223": "Great Dane",
      "224": "Australian Silky Terrier",
      "225": "Norwegian Buhund",
      "226": "Mudi",
      "227": "Hungarian Wire-haired Pointer",
      "228": "Hungarian Greyhound",
      "229": "Hungarian Hound - Transylvanian Scent Hound",
      "23": "Billy",
      "230": "Norwegian Elkhound Grey",
      "231": "Alaskan Malamute",
      "232": "Slovakian Hound",
      "233": "Bohemian Wire-haired Pointing Griffon",
      "234": "Cesky Terrier",
      "235": "Atlas Mountain Dog (Aidi)",
      "236": "Pharaoh Hound",
      "237": "Majorca Mastiff",
      "238": "Havanese",
      "239": "Polish Lowland Sheepdog",
      "24": "Artois Hound",
      "240": "Tatra Shepherd Dog",
      "241": "Pug",
      "242": "Alpine Dachsbracke",
      "243": "Akita",
      "244": "Shiba",
      "245": "Japanese Terrier",
      "246": "Tosa",
      "247": "Hokkaido",
      "248": "Japanese Spitz",
      "249": "Chesapeake Bay Retriever",
      "25": "Porcelaine",
      "250": "Mastiff",
      "251": "Norwegian Lundehund",
      "252": "Hygen Hound",
      "253": "Halden Hound",
      "254": "Norwegian Elkhound Black",
      "255": "Saluki",
      "256": "Siberian Husky",
      "257": "Bearded Collie",
      "258": "Norfolk Terrier",
      "259": "Canaan Dog",
      "26": "Small Blue Gascony",
      "260": "Greenland Dog",
      "261": "Norrbottenspitz",
      "262": "Croatian Shepherd Dog",
      "263": "Karst Shepherd Dog",
      "264": "Montenegrin Mountain Hound",
      "265": "Old Danish Pointing Dog",
      "266": "Grand Griffon Vendeen",
      "267": "Coton De Tulear",
      "268": "Lapponian Herder",
      "269": "Spanish Greyhound",
      "27": "Blue Gascony Griffon",
      "270": "American Staffordshire Terrier",
      "271": "Australian Cattle Dog",
      "272": "Chinese Crested Dog",
      "273": "Icelandic Sheepdog",
      "274": "Beagle Harrier",
      "275": "Eurasian",
      "276": "Dogo Argentino",
      "277": "Australian Kelpie",
      "278": "Otterhound",
      "279": "Harrier",
      "28": "Grand Basset Griffon Vendeen",
      "280": "Collie Smooth",
      "281": "Border Collie",
      "282": "Romagna Water Dog",
      "283": "German Hound",
      "284": "Black And Tan Coonhound",
      "285": "American Water Spaniel",
      "286": "Irish Glen Of Imaal Terrier",
      "287": "American Foxhound",
      "288": "Russian-european Laika",
      "289": "East Siberian Laika",
      "29": "Norman Artesien Basset",
      "290": "West Siberian Laika",
      "291": "Azawakh",
      "292": "Dutch Smoushond",
      "293": "Shar Pei",
      "294": "Peruvian Hairless Dog",
      "295": "Saarloos Wolfhond",
      "296": "Nova Scotia Duck Tolling Retriever",
      "297": "Dutch Schapendoes",
      "298": "Nederlandse Kooikerhondje",
      "299": "Broholmer",
      "3": "Kerry Blue Terrier",
      "30": "Blue Gascony Basset",
      "300": "French White And Orange Hound",
      "301": "Kai",
      "302": "Kishu",
      "303": "Shikoku",
      "304": "Wirehaired Slovakian Pointer",
      "305": "Majorca Shepherd Dog",
      "306": "Great Anglo-french Tricolour Hound",
      "307": "Great Anglo-french White And Black Hound",
      "308": "Great Anglo-french White & Orange Hound",
      "309": "Medium-sized Anglo-french Hound",
      "31": "Basset Fauve De Bretagne",
      "310": "South Russian Shepherd Dog",
      "311": "Russian Black Terrier",
      "312": "Caucasian Shepherd Dog",
      "313": "Canarian Warren Hound",
      "314": "Irish Red And White Setter",
      "315": "Kangal Shepherd Dog",
      "316": "Czechoslovakian Wolfdog",
      "317": "Polish Greyhound",
      "318": "Korea Jindo Dog",
      "319": "Central Asia Shepherd Dog",
      "32": "Portuguese Water Dog",
      "320": "Spanish Water Dog",
      "321": "Italian Short-haired Segugio",
      "322": "Thai Ridgeback Dog",
      "323": "Parson Russell Terrier",
      "324": "Saint Miguel Cattle Dog",
      "325": "Brazilian Terrier",
      "326": "Australian Shepherd",
      "327": "Italian Cane Corso",
      "328": "American Akita",
      "329": "Jack Russell Terrier",
      "33": "Welsh Corgi (Cardigan)",
      "330": "Dogo Canario",
      "331": "White Swiss Shepherd Dog",
      "332": "Taiwan Dog",
      "333": "Romanian Mioritic Shepherd Dog",
      "334": "Romanian Carpathian Shepherd Dog",
      "335": "Russian Toy",
      "336": "Cimarrón Uruguayo",
      "337": "Polish Hunting Dog",
      "338": "Bosnian And Herzegovinian - Croatian Shepherd Dog",
      "339": "Miniature Bull Terrier",
      "34": "Welsh Corgi (Pembroke)",
      "340": "Abyssinian",
      "341": "American Bobtail",
      "342": "American Bobtail Shorthair",
      "343": "American Curl",
      "344": "American Curl Longhair",
      "345": "American Curl Shorthair",
      "346": "American Shorthair",
      "347": "American Wirehair",
      "348": "Australian Mist",
      "349": "Balinese",
      "35": "Irish Soft Coated Wheaten Terrier",
      "350": "Bengal",
      "351": "Bengal Longhair",
      "352": "Birman",
      "353": "Bombay",
      "354": "British",
      "355": "British Longhair",
      "356": "British Shorthair",
      "357": "Burmese",
      "358": "Burmilla",
      "359": "Burmilla Longhair",
      "36": "Yugoslavian Shepherd Dog - Sharplanina",
      "360": "Chartreux",
      "361": "Chausie",
      "362": "Colorpoint Shorthair",
      "363": "Cornish Rex",
      "364": "Cymric",
      "365": "Devon Rex",
      "366": "Donskoy",
      "367": "Egyptian Mau",
      "368": "Domestic",
      "369": "European Burmese",
      "37": "Jämthund",
      "370": "Exotic",
      "371": "Exotic Shorthair",
      "372": "Havana",
      "373": "German Rex",
      "374": "Himalayan",
      "375": "Javanese",
      "376": "Japanese Bobtail",
      "377": "Japanese Bobtail Longhair",
      "378": "Khao Manee",
      "379": "Korat",
      "38": "Basenji",
      "380": "Kurilian Bobtail",
      "381": "Kurilian Bobtail Shorthair",
      "382": "Kurilian Bobtail Longhair",
      "383": "LaPerm",
      "384": "LaPerm Shorthair",
      "385": "Lykoi",
      "386": "Maine Coon",
      "387": "Manx",
      "388": "Minuet",
      "389": "Minuet Longhair",
      "39": "Berger De Beauce",
      "390": "Munchkin",
      "391": "Munchkin Longhair",
      "392": "Nebelung",
      "393": "Norwegian Forest",
      "394": "Ocicat",
      "395": "Oriental",
      "396": "Oriental Shorthair",
      "397": "Oriental Longhair",
      "398": "Persian",
      "399": "Peterbald",
      "4": "Cairn Terrier",
      "40": "Bernese Mountain Dog",
      "400": "Pixiebob",
      "401": "Pixiebob Longhair",
      "402": "Ragamuffin",
      "403": "Ragdoll",
      "404": "Russian Blue",
      "405": "Savannah",
      "406": "Scottish Fold",
      "407": "Scottish Fold Longhair",
      "408": "Scottish Straight",
      "409": "Scottish Straight Longhair",
      "41": "Appenzell Cattle Dog",
      "410": "Selkirk Rex",
      "411": "Selkirk Rex Longhair",
      "412": "Seychellois",
      "413": "Seychellois Shorthair",
      "414": "Seychellois Longhair",
      "415": "Siamese",
      "416": "Siberian",
      "417": "Singapura",
      "418": "Snowshoe",
      "419": "Sokoke",
      "42": "Entlebuch Cattle Dog",
      "420": "Somali",
      "421": "Sphynx",
      "422": "Thai",
      "423": "Tonkinese",
      "424": "Toyger",
      "425": "Turkish Angora",
      "426": "Turkish Van",
      "428": "Teckel",
      "429": "Transmontano Mastiff",
      "43": "Karelian Bear Dog",
      "44": "Finnish Spitz",
      "45": "Newfoundland",
      "46": "Finnish Hound",
      "47": "Polish Hound",
      "48": "Komondor",
      "49": "Kuvasz",
      "5": "English Cocker Spaniel",
      "50": "Puli",
      "51": "Pumi",
      "52": "Hungarian Short-Haired Pointer (Vizsla)",
      "53": "Great Swiss Mountain Dog",
      "54": "Swiss Hound",
      "55": "Small Swiss Hound",
      "56": "St. Bernard",
      "57": "Coarse-haired Styrian Hound",
      "58": "Austrian Black And Tan Hound",
      "59": "Austrian Pinscher",
      "6": "Gordon Setter",
      "60": "Maltese",
      "61": "Fawn Brittany Griffon",
      "62": "Petit Basset Griffon Vendeen",
      "63": "Tyrolean Hound",
      "64": "Lakeland Terrier",
      "65": "Manchester Terrier",
      "66": "Norwich Terrier",
      "67": "Scottish Terrier",
      "68": "Sealyham Terrier",
      "69": "Skye Terrier",
      "7": "Airedale Terrier",
      "70": "Staffordshire Bull Terrier",
      "71": "Continental Toy Spaniel",
      "72": "Welsh Terrier",
      "73": "Griffon Bruxellois",
      "74": "Griffon Belge",
      "75": "Petit Brabançon",
      "76": "Schipperke",
      "77": "Bloodhound",
      "78": "West Highland White Terrier",
      "79": "Yorkshire Terrier",
      "8": "Australian Terrier",
      "80": "Catalan Sheepdog",
      "81": "Shetland Sheepdog",
      "82": "Ibizan Podenco",
      "83": "Burgos Pointing Dog",
      "84": "Spanish Mastiff",
      "85": "Pyrenean Mastiff",
      "86": "Portuguese Sheepdog",
      "87": "Portuguese Warren Hound-portuguese Podengo",
      "88": "Brittany Spaniel",
      "89": "Rafeiro Of Alentejo",
      "9": "Bedlington Terrier",
      "90": "German Spitz",
      "91": "German Wire- Haired Pointing Dog",
      "92": "Weimaraner",
      "93": "Westphalian Dachsbracke",
      "94": "French Bulldog",
      "95": "Kleiner Münsterländer",
      "96": "German Hunting Terrier",
      "97": "German Spaniel",
      "98": "French Water Dog",
      "99": "Blue Picardy Spaniel"
    },
    "bugReports": {
      "bugReport": {
        "message": "Message",
        "subject": "Subject",
        "title": "Bug report"
      },
      "title": "Bug reports"
    },
    "bundle": {
      "newBundle": "New Bundle"
    },
    "bundles": {
      "bundle": {
        "title": "Bundle"
      },
      "messages": {
        "createdSuccess": "Bundle successfully created"
      },
      "title": "Bundles"
    },
    "calendar": {
      "title": "Calendar"
    },
    "campaigns": {
      "campaign": {
        "audience": "Audience",
        "audienceDetermined": "The audience are all the patients with periodics scheduled between",
        "audienceEveryone": "Audience is every customer",
        "description": "Description",
        "ended": "Ended",
        "errors": {
          "periodicsAlreadyCommunicated": "The selected periodics have already been communicated"
        },
        "message": "Message",
        "name": "Name",
        "sendDate": "Send Date",
        "species": "Species",
        "started": "Started",
        "title": "Campaign"
      },
      "new": {
        "createdSuccess": "Campaign successfully created",
        "title": "New Campaign"
      },
      "sendConfirmation": "Are you sure you want to send this campaign?",
      "title": "Campaigns"
    },
    "charges": {
      "title": "Charges"
    },
    "chores": {
      "chore": {
        "title": "Others"
      },
      "title": "Others"
    },
    "communicationErrors": {
      "communicationError": {
        "appointmentDate": "Date",
        "campaign": "Campaign",
        "customer": "Customer",
        "patient": "Patient",
        "phoneNumber": "Number",
        "started": "Send date",
        "text": "Text",
        "title": "Communication Error"
      },
      "title": "Communication Errors"
    },
    "communications": {
      "communication": {},
      "comunication": {
        "title": "Communication"
      }
    },
    "companies": {
      "details": {
        "disabled": "This company does not yet have an associated plan, to start a test plan please contact us by email!"
      },
      "new": {
        "title": "New Company"
      }
    },
    "compoundProduct": {
      "newCompoundProduct": "New Compound Item",
      "title": "Compound Item"
    },
    "compoundProducts": {
      "action": {
        "addPrice": "Click here to price the item",
        "dontUsePrice": "Click here if you don't want to use this price in the article"
      },
      "messages": {
        "createdSuccess": "Compound Item successfully created"
      }
    },
    "configurations": {
      "breeds": {
        "dangerous": "Dangerous",
        "description": "See all breeds",
        "name": "Name",
        "new": "New breed",
        "proportion": "Proportion",
        "title": "Breeds"
      },
      "clinic": {
        "title": "Clinic",
        "visitTypes": {
          "description": "See all visit types",
          "descriptionVisitTypes": "Visit Type",
          "name": "Name",
          "new": "New visit types",
          "parent": "Parent",
          "title": "Visit Types"
        }
      },
      "configItems": {
        "acceptQuotation": "Accept Quotation",
        "acceptedQuotation": "Accepted Quotation",
        "enum": {
          "0": {
            "name": "Desparatização",
            "value": "0"
          },
          "1": {
            "name": "Monitorização",
            "value": "1"
          },
          "2": {
            "name": "Exames",
            "value": "2"
          },
          "3": {
            "name": "Tratamentos",
            "value": "3"
          },
          "4": {
            "name": "Vacina",
            "value": "4"
          }
        },
        "generatePDF": "Generate PDF",
        "generatePayment": "Generate payment",
        "generateReceipt": "Generate receipt",
        "generatedPDF": "Generated PDF",
        "generatedReceipt": "Generated receipt",
        "goToPos": "Go to Pos",
        "rejectQuotation": "Reject Quotation",
        "rejectedQuotation": "Rejected Quotation",
        "transformIntoOrder": "Transform into order",
        "transformedIntoOrder": "Transformed into order"
      },
      "general": {
        "title": "General"
      },
      "item": {
        "brands": {
          "list": "See all brands",
          "new": "New brand",
          "title": "Brands"
        },
        "itemTypes": {
          "list": "See all Item Types",
          "new": "New item type",
          "title": "Item Types"
        },
        "models": {
          "list": "See all models",
          "new": "New model",
          "title": "Models"
        },
        "unitDimensions": {
          "list": "See all unit dimensions",
          "new": "New unit dimension",
          "title": "Unit Dimensions"
        },
        "units": {
          "list": "See all units",
          "new": "New unit",
          "title": "Units"
        }
      },
      "organization": {
        "advanced": "Advanced",
        "edit": "Organization Edit",
        "featureUnavailable": "To access this you must have the {feature} module",
        "name": "Organization Name",
        "receiptSlip": "Receipt Bead",
        "sender": "Sender Name",
        "slip": "Bead Invoices",
        "title": "Organization"
      },
      "organizationCommunication": {
        "beforeSchedule": "These SMS will be sent automatically the day before the appointment",
        "consultationText": "Consultation Appointment Text",
        "consultationTextTitle": "Hello! Please be advised that @@pa has a consultation scheduled for @@dd, at @@hh. Thank you for your preference.",
        "customerBirthdayText": "Customer birthday text",
        "customerBirthdayTextTitle": "Congratulations!",
        "daysBefore": "Days Before",
        "externalDewormingText": "External Deworming Text",
        "externalDewormingTextTitle": "Hello! Please be advised that @@pa has to perform external deworming this month. Thank you for your preference.",
        "internalDewormingText": "Internal Deworming Text",
        "internalDewormingTextTitle": "Hello! Please be advised that @@pa has to carry out the internal deworming this month. Thank you for your preference.",
        "othersText": "Text for others",
        "othersTextTitle": "Hi! We inform you that @@pa has a journal scheduled for this month. Thanks for the preference.",
        "patientBirthdayText": "Patient birthday text",
        "patientBirthdayTextTitle": "Congratulations!",
        "proceduresAppointmentText": "Procedures Appointment Text",
        "proceduresAppointmentTextTitle": "Hello! Please be advised that @@pa has a procedure scheduled for @@dd, by @@hh. Thanks for your preference.",
        "sendHour": "Send Hour",
        "sendMinute": "Send Minute",
        "surgeryAppointmentText": "Surgery Appointment Text",
        "surgeryAppointmentTextTitle": "Hello! Please be advised that @@pa has surgery scheduled for @@dd, at @@hh. We remind you that you should fast. Thank you for your preference.",
        "title": "Organization Communication",
        "vaccineAppointmentText": "Vaccine Appointment Text",
        "vaccineAppointmentTextTitle": "Hello! Please be advised that @@pa has a vaccine scheduled for @@dd, by @@hh. Thank you for your preference.",
        "vaccineText": "Vaccine Text",
        "vaccineTextTitle": "Hello! Please be advised that @@pa has to be vaccinated this month. Thank you for your preference.",
        "wellBeingAppointmentText": "Well being Appointment Text",
        "wellBeingAppointmentTextTitle": "Hello! Please be advised that @@pa has an appointment scheduled for @@dd, by @@hh. Thank you for your preference."
      },
      "periodicTypes": {
        "description": "See all periodics",
        "descriptionPeriodic": "Description",
        "name": "Name",
        "new": "New periodic type",
        "period": "Period",
        "reinforcementNumber": "Reinforcement Number",
        "reinforcementPeriod": "Reinforcement Period",
        "title": "Periodics Type"
      },
      "species": {
        "description": "See all species",
        "name": "Name",
        "new": "New specie",
        "title": "Species"
      },
      "title": "Configurations"
    },
    "consultations": {
      "consultation": {
        "bathCutReport": "Bath and shearing report",
        "bathCutRequest": "Bath and shearing requisition",
        "buttons": {
          "backToConsultation": "Back to Visit",
          "confirmation": "Are you really sure you want to end this visit?",
          "finishAndPay": "End and pay",
          "finishWithoutPay": "End without paying",
          "finishedConsultation": "End",
          "pausedConsultation": "Paused Visit"
        },
        "cantSave": "You must insert a valid date and time",
        "checkUpSeniorCat": "Feline Senior CHeck-Up Report",
        "checkUpSeniorDog": "Canine Senior CHeck-Up Report",
        "countTime": "Time",
        "creator": "Visit creator",
        "debtConsent": "Statement of Debt Recognition and Payment Plan",
        "declarationRGPD": "Statement and consent of GDPR",
        "dischargeLetter": "Discharge Letter",
        "ecoReport": "Ultrasound Report",
        "electrocardiogramCat": "Electrocardiogram for cat",
        "electrocardiogramDog": "Electrocardiogram for dog",
        "euthanasia": "Euthanasia Statement of responsibility",
        "generalAnesthesia": "General Anesthesia Statement of responsibility",
        "hospitalizationConsent": "Hospitalization Consent",
        "internmentDischarge": "Hospitalization Discharge",
        "labAnalysisReport": "Laboratory Analysis Report",
        "responsabilityTermInternment": "Hospitalization statement of responsibility",
        "responsabilityTermSurgery": "Surgery statement of responsability",
        "sanityCertificate": "Sanity Certiticate",
        "searchOptions": {
          "date": "Date"
        },
        "soap": {
          "anamnesis": "Anamnesis",
          "assessment": "Assessment",
          "description": "Treatment Plan Description",
          "diagnosisDescription": "Diagnosis Description",
          "diagnostic": "Diagnosis",
          "medication": "Medication",
          "objective": "Objective",
          "observations": "Internal Notes",
          "physicalTest": {
            "abdomen": "Abdomen",
            "attitude": "Attitude",
            "bloodPressure": "Blood Press.",
            "capillaryRepletionTime": "Capillary Time",
            "edit": {
              "title": "Edit Physical Test"
            },
            "ganglia": "Ganglia",
            "heartRate": "Heart Rate",
            "hydration": "Hydration",
            "mucous": "Mucous",
            "observation": "Observations",
            "pain": "Pain",
            "parameters": "Parameters",
            "respiratoryFrequency": "Resp. Period",
            "temperature": "Temperature",
            "title": "Physical Test",
            "value": "Value"
          },
          "prescription": {
            "delivered": "Delivered",
            "prescribed": "Prescribe",
            "title": "Prescription"
          },
          "subjective": "Subjective",
          "symptom": "Clinical Signs",
          "symptomDescription": "Clinical Signs Description",
          "title": "S.O.A.P.",
          "treatmentPlan": "Plan"
        },
        "surgeryDischarge": "Surgery Discharge",
        "surgeryReport": "Surgery Report",
        "title": "Visit",
        "vet": "Veterinarian",
        "visitDate": "Visit Date",
        "workerObservation": "Visit Observation"
      },
      "messages": {
        "consultationFinished": "Visit succesfully ended",
        "consultationSaved": "Visit successfully saved"
      },
      "title": "Visits"
    },
    "creator": {
      "title": "Created by"
    },
    "crud": {
      "associate": "Associate",
      "cancel": "Cancel",
      "close": "Close",
      "create": "Create",
      "createAndAdd": "Create and Add New",
      "createSuccess": "Successful registration for",
      "created": "Created",
      "delete": "Remove",
      "details": "Details",
      "register": "Register",
      "registered": "Registered",
      "revert": "Revert",
      "save": "Save",
      "saved": "Saved",
      "schedule": "Schedule",
      "update": "Update",
      "updated": "Updated"
    },
    "current": {
      "amountOwed": "Amount Owed",
      "title": "Current account"
    },
    "customerFieldTypes": {
      "customerFieldType": {
        "editable": "Editable",
        "label": "Label",
        "mandatory": "Mandatory",
        "title": "Custom properties",
        "type": "Type",
        "types": {
          "boolean": "Boolean",
          "date": "Date",
          "double": "Floating point",
          "integer": "Number",
          "string": "Text"
        },
        "unique": "Unique"
      },
      "title": "Custom properties"
    },
    "customers": {
      "actions": {
        "disableCustomer": "Disable Customer"
      },
      "customer": {
        "accumulatedPoints": "Accumulated Points",
        "address": "Address",
        "addresses": "Shiping Addresses",
        "avatar": "Avatar",
        "balcon": "Balcon",
        "barcode": "Barcode",
        "birthdate": "Birthdate",
        "building": "Building",
        "cc": "Citizen Card",
        "code": "Code",
        "comment": "Comment",
        "contacts": {
          "authorizations": "Authorizations",
          "contact": "Contact",
          "contactType": "Contact Type",
          "contacts": "Contacts",
          "email": "Email",
          "mobilePhone": "Mobile Phone",
          "new": "New Contact",
          "phone": "Phone",
          "preferential": "Preferential",
          "preferentialcontact": "Contacto Preferencial",
          "typesTranslation": {
            "1": "Newsletter",
            "2": "Periodics",
            "3": "Promotions",
            "4": "Forbidden"
          }
        },
        "country": "Country",
        "customerName": "Customer Name",
        "customerSince": "Customer since:",
        "customerTitle": "Title",
        "enable": "Enable",
        "errors": {
          "alreadyUsed": "This customer has already been used in documents and cannot be updated",
          "noNumber": "This customer does not have an associated contact"
        },
        "female": "Woman",
        "generalDiscount": "General Discount",
        "headerDocuments": "Header Documents",
        "iban": "IBAN",
        "irsRetention": "IRS Retention",
        "male": "Man",
        "name": "Name",
        "newAddress": "New Address",
        "nif": "VAT",
        "noAnimal": "No Animal",
        "origin": "Origin",
        "paymentTerm": "Payment Term",
        "personalCharacteristics": "Personal Characteristics",
        "preferedPaymentMethod": "Prefered payment method",
        "price": "Price",
        "profession": "Profession",
        "reason": "Reason",
        "referencedBy": "Referenced By",
        "sex": "Sex",
        "stickyNote": "Sticky Note",
        "street": "Street",
        "tariff": "Tariff",
        "title": "Customer",
        "town": "Town",
        "unknown": "Unknown",
        "usedPoints": "Used Points",
        "validCC": "Valid C.C.",
        "visitDetails": "Visit Details",
        "warningList": "Warning List",
        "zip": "Zip Code"
      },
      "disabled": "Disabled",
      "edit": {
        "title": "Edit Customer"
      },
      "enabled": "Enabled",
      "form": {
        "allreadyExistsVat": "This VAT is already registered",
        "category": {
          "address": "Address",
          "complementarDetails": "Complementar Details",
          "contacts": "Contacts",
          "financialDetails": "Financial Details",
          "personalInformation": "Personal Information",
          "rpContacts": "Representative Contacts"
        },
        "invalidContact": "This contact is not valid",
        "invalidVat": "A VAT must have 9 characters",
        "placeholder": {
          "addresses": "Shiping Addresses",
          "bancon": "Bancon",
          "building": "Building",
          "cc": "Citizen Card",
          "comment": "Comment",
          "contact": "Contact",
          "email": "Email",
          "generalDiscount": "General Discount",
          "iban": "IBAN",
          "mobilePhone": "Mobile Phone",
          "name": "Example: John Lewis",
          "nif": "VAT",
          "personalCharacteristics": "Personal Characteristics",
          "phone": "Phone",
          "profession": "Profession",
          "stickyNote": "Sticky Note",
          "street": "Street",
          "town": "Town",
          "zip": "Zip Code"
        },
        "select": {
          "contact": "Select Contact Type",
          "country": "Select Country",
          "origin": "Select Origin",
          "paymentTerm": "Payment Term",
          "price": "Select Price",
          "reason": "Select Reason",
          "referencedBy": "Referenced By",
          "removeReferencedBy": "Change referenced By"
        },
        "vatAnotherCountry": "This VAT is not valid"
      },
      "inactiveCustomers": "Inactive Customers",
      "merge": {
        "crud": "Merge",
        "messages": {
          "confirm": "Are you sure you want to merge these customers?",
          "error": "Error merging customers",
          "errorSame": "You must select two different customers",
          "info": "This action cannot be undone!",
          "success": "Customers successfully merged"
        },
        "title": "Merge Customers"
      },
      "messages": {
        "createdSuccess": "Customer successfully created",
        "disable": "Are you sure you want to disable this customer?",
        "hasDebt": "This customer has debts, do you wish to continue?"
      },
      "new": {
        "saveAndAssociatePatient": "Associate Patient",
        "title": "New Customer"
      },
      "title": "Customers"
    },
    "dayWeek": {
      "0": "Sunday",
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednesday",
      "4": "Thursday",
      "5": "Friday",
      "6": "Saturday"
    },
    "declarations": {
      "prescription": "Prescription"
    },
    "documents": {
      "accountingAddress": "Accounting Address",
      "accountingName": "Accounting Name",
      "accountingTaxId": "NIF",
      "annul": "Annul",
      "annulDocument": "Annul document",
      "annulReason": "Annul reason",
      "date": "Payment Date",
      "document": {
        "title": "Document"
      },
      "edit": {
        "title": "Edit Document"
      },
      "financialAccount": "Financial Account",
      "internalNotes": "Internal Note",
      "invoiceNotes": "Footnote",
      "payableAmount": "Payable Amount",
      "paymentMethod": "Payment Method",
      "quotationNotes": "Footnote",
      "reference": "Reference",
      "title": "Documents",
      "warning": "Are your sure you want to cancel the registration of the document"
    },
    "drugs": {
      "drug": {
        "concentration": "Concentration",
        "delete": "Delete Drug",
        "description": "Description",
        "dosageTotal": "Dosage total",
        "edit": "Edit Drug",
        "name": "Name",
        "pills": "Pills",
        "quantity": "Quantity",
        "title": "Drug",
        "via": "Via",
        "view": "View Drug Details",
        "volumeTotal": "Total volume"
      },
      "edit": {
        "addMoreTypes": "Can't find the type you need? Send an email to",
        "nameNotNull": "Drug must have a type",
        "selectDrugType": "Drug Type",
        "title": "Edit Drug"
      },
      "messages": {
        "createdSuccess": "Drug created successfully"
      },
      "new": {
        "addMoreTypes": "Can't find the type you need? Send an email to",
        "nameNotNull": "Drug must have a type",
        "selectDrugType": "Drug Type",
        "title": "New Drug"
      },
      "title": "Drugs"
    },
    "emailValidations": {
      "title": "Email Validation",
      "validated": "Your email was validated successfully, you can now login"
    },
    "enterprises": {
      "edit": {
        "title": "Edit Enterprise"
      },
      "messages": {
        "title": "Enterprise successfully created"
      },
      "new": {
        "title": "New Enterprise"
      },
      "title": "Enterprise"
    },
    "enums": {
      "chronos": {
        "date": "date",
        "dateUpper": "Date",
        "day": "day",
        "dayUpper": "Day",
        "days": "days",
        "hour": "hour",
        "hourUpper": "Hour",
        "hours": "hours",
        "hoursUpper": "Hours",
        "month": "month",
        "monthUpper": "Month",
        "months": "months",
        "today": "today",
        "todayUpper": "Today",
        "waitingTime": "waiting time",
        "waitingTimeUpper": "Waiting Time",
        "week": "week",
        "weekUpper": "Week",
        "year": "year",
        "yearUpper": "Year",
        "years": "years"
      },
      "countries": {
        "AD": "Andorra",
        "AE": "United Arab Emirates",
        "AF": "Afghanistan",
        "AG": "Antigua and Barbuda",
        "AI": "Anguilla",
        "AL": "Albania",
        "AM": "Armenia",
        "AN": "Netherlands Antilles",
        "AO": "Angola",
        "AQ": "Antarctica",
        "AR": "Argentina",
        "AS": "American Samoa",
        "AT": "Austria",
        "AU": "Australia",
        "AW": "Aruba",
        "AZ": "Azerbaijan",
        "BA": "Bosnia and Herzegovina",
        "BB": "Barbados",
        "BD": "Bangladesh",
        "BE": "Belgium",
        "BF": "Burkina Faso",
        "BG": "Bulgaria",
        "BH": "Bahrain",
        "BI": "Burundi",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BN": "Brunei Darussalam",
        "BO": "Bolivia",
        "BR": "Brazil",
        "BS": "Bahamas",
        "BT": "Bhutan",
        "BV": "Bouvet Islands",
        "BW": "Botswana",
        "BY": "Belarus",
        "BZ": "Belize",
        "CA": "Canada",
        "CC": "Cocos (keeling) Islands",
        "CD": "Congo, The Democratic Republic of the",
        "CF": "Central African Republic",
        "CG": "Congo",
        "CH": "Switzerland",
        "CI": "Côte d’Ivoire",
        "CK": "Cook Islands",
        "CL": "Chile",
        "CM": "Cameroon",
        "CN": "China",
        "CO": "Colombia",
        "CR": "Costa Rica",
        "CU": "Cuba",
        "CV": "Cape Verde",
        "CX": "Christmas Island",
        "CY": "Cyprus",
        "CZ": "Czech Republic",
        "DE": "Germany",
        "DJ": "Djibouti",
        "DK": "Denmark",
        "DM": "Dominica",
        "DO": "Dominican Republic",
        "DZ": "Algeria",
        "EC": "Ecuador",
        "EE": "Estonia",
        "EG": "Egypt",
        "EH": "Western Sahara",
        "ER": "Eritrea",
        "ES": "Spain",
        "ET": "Ethiopia",
        "FI": "Finland",
        "FJ": "Fiji",
        "FK": "Falkand Islands (Malvinas)",
        "FM": "Micronesia, Federated States of",
        "FO": "Faroe Islands",
        "FR": "France",
        "GA": "Gabon",
        "GB": "United Kingdom",
        "GD": "Grenada",
        "GE": "Georgia",
        "GF": "French Guiana",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GL": "Greenland",
        "GM": "Gambia",
        "GN": "Guinea",
        "GP": "Guadeloupe",
        "GQ": "Equatorial Guinea",
        "GR": "Greece",
        "GS": "South Georgia and the South Sandwich Islands",
        "GT": "Guatemala",
        "GU": "Guam",
        "GW": "Guinea-Bissau",
        "GY": "Guyana",
        "HK": "Hong Kong",
        "HM": "Heard Islands and McDonald Islands",
        "HN": "Honduras",
        "HR": "Croatia",
        "HT": "Haiti",
        "HU": "Hungary",
        "ID": "Indonesia",
        "IE": "Ireland",
        "IL": "Israel",
        "IN": "India",
        "IO": "British Indian Ocean Territory",
        "IQ": "Iraq",
        "IR": "Iran, Islamic Republic of",
        "IS": "Iceland",
        "IT": "Italy",
        "JM": "Jamaica",
        "JO": "Jordan",
        "JP": "Japan",
        "KE": "Kenya",
        "KG": "Kyrgyzstan",
        "KH": "Cambodia",
        "KI": "Kiribati",
        "KM": "Comoros",
        "KN": "Saint Kitts and Nevis",
        "KP": "Korea, Democratic People’s Republic of",
        "KR": "Korea, Republic of",
        "KW": "Kuwait",
        "KY": "Cayman Islands",
        "KZ": "Kazakstan",
        "LA": "Lao People’s Democratic Republic",
        "LB": "Lebanon",
        "LC": "Saint Lucia",
        "LI": "Liechtenstein",
        "LK": "Sri Lanka",
        "LR": "Liberia",
        "LS": "Lesotho",
        "LT": "Lithuania",
        "LU": "Luxembourg",
        "LV": "Latvia",
        "LY": "Libyan Arab Jamahiriya",
        "MA": "Morocco",
        "MC": "Monaco",
        "MD": "Moldova, Republic of",
        "MG": "Madagascar",
        "MH": "Marshall Islands",
        "MK": "Macedonia, The Former Yugoslav Republic of",
        "ML": "Mali",
        "MM": "Myanmar",
        "MN": "Mongolia",
        "MO": "Macau",
        "MP": "Northern Mariana Islands",
        "MQ": "Martinique",
        "MR": "Mauritania",
        "MS": "Montserrat",
        "MT": "Malta",
        "MU": "Mauritius",
        "MV": "Maldives",
        "MW": "Malawi",
        "MX": "Mexico",
        "MY": "Malaysia",
        "MZ": "Mozambique",
        "NA": "Namibia",
        "NC": "New Caledonia",
        "NE": "Niger",
        "NF": "Norfolk Islands",
        "NG": "Nigeria",
        "NI": "Nicaragua",
        "NL": "Netherlands",
        "NO": "Norway",
        "NP": "Nepal",
        "NR": "Nauru",
        "NU": "Niue",
        "NZ": "New Zealand",
        "OM": "Oman",
        "PA": "Panama",
        "PE": "Peru",
        "PF": "French Polynesia",
        "PG": "Papua New Guinea",
        "PH": "Philippines",
        "PK": "Pakistan",
        "PL": "Poland",
        "PM": "Saint Pierre and Miquelon",
        "PN": "Pitcairn",
        "PR": "Puerto Rico",
        "PS": "Palestinian Territory, Occupied",
        "PT": "Portugal",
        "PW": "Palau",
        "PY": "Paraguay",
        "QA": "Qatar",
        "RE": "Réunion",
        "RO": "Romania",
        "RU": "Russian Federation",
        "RW": "Rwanda",
        "SA": "Saudi Arabia",
        "SB": "Solomon Islands",
        "SC": "Seychelles",
        "SD": "Sudan",
        "SE": "Sweden",
        "SG": "Singapore",
        "SH": "Saint Helena",
        "SI": "Slovenia",
        "SJ": "Svalbard and Jan Mayen",
        "SK": "Slovakia",
        "SL": "Sierra Leone",
        "SM": "San Marino",
        "SN": "Senegal",
        "SO": "Somalia",
        "SR": "Suriname",
        "ST": "Sao Tome and Principe",
        "SV": "El Salvador",
        "SY": "Syrian Arab Republic",
        "SZ": "Swaziland",
        "TC": "Turks and Caicos Islands",
        "TD": "Chad",
        "TF": "French Southern Territories",
        "TG": "Togo",
        "TH": "Thailand",
        "TJ": "Tajikistan",
        "TK": "Tokelau",
        "TM": "Turkmenistan",
        "TN": "Tunisia",
        "TO": "Tonga",
        "TP": "East Timor",
        "TR": "Turkey",
        "TT": "Trinidad and Tobago",
        "TV": "Tuvalu",
        "TW": "Taiwan, Province of China",
        "TZ": "Tanzania, United Republic of",
        "UA": "Ukraine",
        "UG": "Uganda",
        "UM": "United States Minor Outlying Islands",
        "US": "United States",
        "UY": "Uruguay",
        "UZ": "Uzbekistan",
        "VA": "Holy See (Vatican City State)",
        "VC": "Saint Vincent and the Grenadines",
        "VE": "Venezuela",
        "VG": "Virgin Islands, British",
        "VI": "Virgin Islands, U. S.",
        "VN": "Viet Nam",
        "VU": "Vanuatu",
        "WF": "Wallis and Futuna",
        "WS": "Samoa",
        "YE": "Yemen",
        "YT": "Mayotte",
        "YU": "Yugoslavia",
        "ZA": "South Africa",
        "ZM": "Zambia",
        "ZW": "Zimbabwe"
      },
      "currencies": {
        "$": "Dollar",
        "EUR": "Euro",
        "GBP": "Pound",
        "R$": "Real"
      },
      "mobileCountries": {
        "ES": "Spain +354",
        "PT": "Portugal +351"
      },
      "sex": {
        "female": {
          "initial": "F",
          "name": "Female"
        },
        "male": {
          "initial": "M",
          "name": "Male"
        }
      }
    },
    "equipmentParameterTypes": {
      "code": "Code",
      "description": "Description",
      "new": {
        "title": "New parameter types"
      },
      "title": "Parameter Types"
    },
    "equipmentParameters": {
      "maxVal": "Maximum Value",
      "minVal": "Minimum Value",
      "new": {
        "title": "New Parameter"
      },
      "title": "Parameters",
      "type": "Parameters type"
    },
    "equipmentTypes": {
      "code": "Code",
      "description": "Description",
      "new": {
        "title": "New equipment types"
      },
      "title": "Equipment Types"
    },
    "equipments": {
      "new": {
        "title": "New equipment"
      },
      "title": "Equipments",
      "type": "Equipment type"
    },
    "error": {
      "adapter": {
        "forbidden": "You don't have permissions to do this"
      },
      "noInternet": "We can't find a connection to the internet",
      "noPermission": "You don't have permissions to access this page!",
      "systemDown": "The system is currently down",
      "unexistingBreed": "You must select a breed from the list"
    },
    "events": {
      "edit": {
        "title": "Edit Event"
      },
      "messages": {
        "title": "Event Successfully created"
      },
      "new": {
        "title": "New Event"
      },
      "title": "Events"
    },
    "exams": {
      "all": "All",
      "delected": "Delected",
      "doneDate": "Done",
      "edit": {
        "title": "Edit Exam"
      },
      "exam": {
        "description": "Description",
        "externalLab": "External lab",
        "name": "Name",
        "notes": "Results",
        "started": "Date",
        "title": "Exam",
        "view": "View Exam"
      },
      "examTypes": {
        "exam_abdominocentesis": "Abdominocentesis",
        "exam_bio": "Biochemicals",
        "exam_biopsy": "Biopsy",
        "exam_bloodCount": "Blood Count",
        "exam_cat": "CAT",
        "exam_cystocentesis": "Cystocentesis",
        "exam_echocardiogram": "Echocardiogram",
        "exam_fnac": "FNAC",
        "exam_ionogram": "Ionogram",
        "exam_other": "Other",
        "exam_pleuralFluidAnalysis": "Pleural Fluid Analysis",
        "exam_smear": "Smear",
        "exam_ultrasound": "Ultrasound",
        "exam_urethralCatheterization": "Urethral Catheterization",
        "exam_xRay": "X-Ray",
        "title": "Type"
      },
      "held": "Held in",
      "messages": {
        "createdSuccess": "Exam successfully created"
      },
      "new": {
        "addMoreTypes": "Can't find the type you need? Send an email to",
        "nameNotNull": "Exame must have a type",
        "selectExamType": "Exam Type",
        "title": "New Exam"
      },
      "observations": "Observations",
      "parameters": {
        "exam_bio_alb": "Albumin",
        "exam_bio_alp": "Alkaline Phosphatase",
        "exam_bio_amyl": "Amylase",
        "exam_bio_bun": "Urea",
        "exam_bio_ca": "Calcium",
        "exam_bio_cl": "Chlorine",
        "exam_bio_cpk": "Creatinine Kinase",
        "exam_bio_cre": "Creatinine",
        "exam_bio_ggt": "Glutamyl Transferase Range",
        "exam_bio_glu": "Glucose",
        "exam_bio_k": "Potassium",
        "exam_bio_lap": "LAP",
        "exam_bio_ldh": "Lactate Dehydrogenase",
        "exam_bio_lip": "Lipase",
        "exam_bio_mg": "Magnesium",
        "exam_bio_na": "Sodium",
        "exam_bio_nh3": "Amonia",
        "exam_bio_tbil": "Total Bilirubin",
        "exam_bio_tcho": "Total Cholesterol",
        "exam_bio_tg": "Triglycerides",
        "exam_bio_tp": "Total Proteins",
        "exam_bio_ua": "Uric Acid",
        "title": "Parameters"
      },
      "putResults": "Put Results",
      "requestDate": "Requested",
      "results": "Write Results",
      "status": {
        "done": "Done",
        "pending": "Pending",
        "title": "Status"
      },
      "title": "Exams"
    },
    "families": {
      "family": {
        "children": "Children",
        "familyType": "Family Type",
        "name": "Name",
        "parent": "Parent",
        "title": "Family"
      },
      "form": {
        "placeholder": {
          "children": "Children",
          "familyType": "Family Type",
          "name": "Name",
          "parent": "Parent"
        }
      },
      "title": "Families"
    },
    "files": {
      "title": "Files"
    },
    "filters": {
      "all": "All",
      "bundles": "Bundles",
      "byFamily": "By family",
      "byUser": "By user",
      "cashTotal": "Cash total",
      "checkTotal": "Check total",
      "compoundProducts": "Compound Products",
      "creditTotal": "Credit Total",
      "creditors": "Creditors",
      "debtors": "Debtors",
      "deliveries": "Deliveries",
      "directDebitTotal": "Debit Total",
      "invoices": "Invoices",
      "invoicesTotal": "Invoices Total",
      "memos": "Credit Notes",
      "memosTotal": "Memos Total",
      "none": "None",
      "orders": "Orders",
      "payments": "Payments",
      "pending": "Pending",
      "quotations": "Quotations",
      "receipts": "Receipts",
      "requests": "Requests",
      "total": "Total",
      "totalFA": "Invoice Total",
      "totalFR": "Invoice Receipt Total",
      "totalFS": "Simplified Invoice Total",
      "totalPayment": "Payments Total",
      "totalReceipt": "Receipts Total",
      "transferTotal": "Transfer Total"
    },
    "financialAccounts": {
      "actions": {
        "close": "Close Financial Account",
        "open": "Open Financial Account"
      },
      "automaticManagment": "You don't have financial accounts in manual management",
      "bankAccount": "Bank Account",
      "choose": "Choose a financial account",
      "close": "Close",
      "description": "See all financial accounts",
      "edit": {
        "title": "Edit financial account"
      },
      "financialAccount": {
        "accountNumber": "Account Number",
        "billed": "Billed",
        "currency": "Currency",
        "description": "Description",
        "financialAccountFund": "Financial Account Fund",
        "history": "History",
        "iban": "IBAN",
        "institution": "Financial Institution",
        "isManual": "Manual Management",
        "name": "Name",
        "received": "Received",
        "swift": "SWIFT",
        "title": "Financial account",
        "total": "Total",
        "totals": "Totals",
        "type": "Type"
      },
      "messages": {
        "allreadyClosed": "This financial account is allready closed",
        "allreadyOpen": "This financial account is allready open",
        "createdSuccess": "Financial Account successfully created",
        "editedSuccess": "Financial Account successfully edited",
        "noFinancialAccounts": "There are no financial accounts available",
        "noFinancialAccountsTypeBankAccount": "You have no financial accounts of type bank account created",
        "noFinancialAccountsTypeRegister": "You have no financial accounts of type register created"
      },
      "new": {
        "title": "New financial account"
      },
      "purpose": "Here, you can open and close financial accounts",
      "register": "Register",
      "seeHistory": "To see the historical data access the statistics module",
      "title": "Financial accounts"
    },
    "footer": {
      "rights": "All rights reserved",
      "toggle": "Change view"
    },
    "form": {
      "administrative": "Administrative",
      "chooseOne": " -- choose one --",
      "extra": "Extra",
      "general": "General",
      "health": "Health",
      "look": "Look",
      "required": "This field is required"
    },
    "getChrome": {
      "message": "This application only works with Google Chrome."
    },
    "header": {
      "logout": "Logout"
    },
    "help": {
      "faq": {
        "last-rev-date": "29th of June 2016",
        "link": "FAQ",
        "title": "Frequently Asked Questions"
      },
      "manual": {
        "last-rev-date": "23rd of June 2016",
        "link": "Manual",
        "title": "Manual"
      },
      "title": "Help"
    },
    "howTo": {
      "steps": {
        "createCampaign": "Create a campaign",
        "goToPeriodics": "Go to the Periodics Menu",
        "makeAvailable": "Contact us to make it available",
        "startSendingMessages": "The SMS will be sent automatically in the chosen date"
      },
      "text": "Pet Universal now has a message functionality",
      "title": "See More"
    },
    "icons": {
      "absence": "hand-o-down",
      "absences": "hand-o-down",
      "action": "check-square-o",
      "actions": "check-square-o",
      "alert": "exclamation-triangle",
      "annul": "ban",
      "appointment": "calendar-o",
      "appointmentDone": "calendar-check-o",
      "appointmentNew": "calendar-plus-o",
      "appointments": "calendar-o",
      "at": "usd",
      "avatar": "image",
      "avatars": "image",
      "boxes": "home",
      "calendar": "calendar-o",
      "calendarMenu": "hourglass-start",
      "calendarPlus": "calendar-plus",
      "campaign": "bullhorn",
      "campaigns": "bullhorn",
      "caretDown": "caret-down",
      "caretUp": "caret-up",
      "charge": "credit-card",
      "charges": "credit-card",
      "check": "check",
      "checkout": "sign-out",
      "clinical": "stethoscope",
      "clinicalMenu": "stethoscope",
      "comment": "comment-o",
      "compoundProduct": "tags",
      "configs": "cog",
      "consultation": "medkit",
      "consultations": "medkit",
      "crm": "address-book-o",
      "currency": "AED",
      "currentAccount": "folder-o",
      "customer": "user",
      "customers": "user",
      "delete": "trash",
      "deleteO": "trash-o",
      "detail": "eye",
      "disable": "toggle-off",
      "dischargeLetter": "envelope-square",
      "document": "file-text-o",
      "documentDetails": "folder-open",
      "documents": "folder-o",
      "download": "download",
      "edit": "pencil",
      "email": "envelope-o",
      "enable": "toggle-on",
      "error": "warning",
      "events": "th-large",
      "exam": "eyedropper",
      "examion": "eyedropper",
      "exams": "eyedropper",
      "eye": "eye",
      "female": "venus",
      "file": "file-o",
      "files": "files-o",
      "filter": "filter",
      "financialAccounts": "inbox",
      "financialAccountsClosed": "stop-circle-o",
      "financialAccountsOpen": "play-circle-o",
      "flag": "flag",
      "genderless": "genderless",
      "generatePayment": "file-text",
      "generateReceipt": "file-text-o",
      "giveNext": "retweet",
      "goTo": "arrow-right",
      "group": "sitemap",
      "hermaphrodite": "intersex",
      "hotel": "hotel",
      "houseCalls": "home",
      "hr": "id-badge",
      "internment": "h-square",
      "internments": "h-square",
      "inventory": "archive",
      "invites": "user-plus",
      "invoice": "file-o",
      "item": "tag",
      "itemType": "star-o",
      "laboratories": "flask",
      "laboratory": "flask",
      "liaisons": "hand-o-right",
      "listings": "list-ol",
      "load": "spinner fa-spin",
      "male": "mars",
      "manufacturers": "institution",
      "mobilePhone": "mobile",
      "moveStock": "arrows-v",
      "neutral": "neuter",
      "notes": "sticky-note",
      "order": "shopping-cart",
      "organization": "hospital-o",
      "patient": "paw",
      "patients": "paw",
      "pencilSquare": "pencil",
      "periodic": "history",
      "periodics": "history",
      "phone": "phone",
      "phonecall": "phone",
      "plusCircle": "plus-circle",
      "pointOfSales": "money",
      "prescription": "file-text-o",
      "processing": "hourglass-start",
      "procurement": "handshake-o",
      "purchaseInvoice": "file-text",
      "purchaseInvoices": "file-text",
      "purchaseOrder": "cart-arrow-down",
      "purchaseOrders": "cart-arrow-down",
      "purchaseRequest": "cart-plus",
      "purchaseRequests": "cart-plus",
      "purchases": "exchange",
      "referral": "exchange",
      "referrals": "exchange",
      "reject": "times",
      "remove": "times",
      "reward": "certificate",
      "rewards": "certificate",
      "room": "map-marker",
      "rooms": "map-marker",
      "saft": "file-zip-o",
      "sales": "shopping-basket",
      "salesMenu": "shopping-basket",
      "save": "floppy-o",
      "search": "search",
      "secondTimeEmission": "file-pdf-o",
      "send": "paper-plane",
      "sendCampaign": "mail-forward",
      "sheltereds": "paw",
      "shift": "hand-o-up",
      "shifts": "hand-o-up",
      "shoppingCart": "shopping-cart",
      "simpleView": "tv",
      "sms": "comments",
      "stats": "line-chart",
      "stays": "ticket",
      "study": "x-ray",
      "supplier": "industry",
      "supplierItem": "truck",
      "supplierItems": "truck",
      "suppliers": "industry",
      "task": "tasks",
      "tasks": "tasks",
      "taxes": "bank",
      "template": "file-word-o",
      "templates": "file-word-o",
      "thumbsUp": "thumbs-up",
      "timesCircle": "times-circle",
      "transferStock": "exchange",
      "undetermined": "question",
      "view": "columns",
      "visit": "suitcase",
      "waitingRoom": "sign-in",
      "warehouse": "cubes",
      "warehouses": "cubes",
      "weight": "balance-scale",
      "worker": "user-md",
      "workers": "users"
    },
    "inbounds": {
      "customer": "Customer",
      "facebook": "Facebook",
      "geolocation": "Geolocation",
      "partner": "Partner",
      "publicity": "Publicity",
      "reference": "Reference",
      "unknown": "Unknown",
      "website": "Website"
    },
    "index": {
      "addInternments": {
        "description": "Create or Search customer, fill the animal details and intern.",
        "title": "Internments"
      },
      "associateCollaborators": {
        "description": "Associate collaborators with differente roles.",
        "title": "Collaborators"
      },
      "comments": "If you have any doubts or comments please contact",
      "createAccount": {
        "description": "Fill in your details and register your account.",
        "title": "Create Account"
      },
      "createHospital": {
        "description": "Add the details of the hospital and create.",
        "title": "Register Hospital"
      },
      "welcome.subtitle": "by {corp}"
    },
    "initials": {
      "catheterLeftForelimb": {
        "description": "Left forelimb",
        "name": "LFL"
      },
      "catheterLeftHindlimb": {
        "description": "Left hindlimb",
        "name": "LHL"
      },
      "catheterRightForelimb": {
        "description": "Right forelimb",
        "name": "RFL"
      },
      "catheterRightHindlimb": {
        "description": "Right hindlimb",
        "name": "RHL"
      },
      "didntEat": {
        "description": "Didn't eat",
        "name": "DE"
      },
      "faecesDigestedBlood": {
        "description": "Faeces digested blood",
        "name": "FDB"
      },
      "faecesHard": {
        "description": "Faeces hard",
        "name": "FH"
      },
      "faecesHardWithBlood": {
        "description": "Faeces hard with blood",
        "name": "FHB"
      },
      "faecesLiquid": {
        "description": "Faeces liquid",
        "name": "FL"
      },
      "faecesLiquidWithBlood": {
        "description": "Faeces liquid with blood",
        "name": "FLB"
      },
      "faecesLivingBlood": {
        "description": "Faeces living blood",
        "name": "FLB"
      },
      "faecesNormal": {
        "description": "Faeces normal",
        "name": "FN"
      },
      "faecesSoft": {
        "description": "Faeces soft",
        "name": "FS"
      },
      "faecesSoftMoulded": {
        "description": "Faeces soft moulded",
        "name": "FSM"
      },
      "fasting": {
        "description": "Fasting",
        "name": "FT"
      },
      "feedingForced": {
        "description": "Feeding forced",
        "name": "FF"
      },
      "feedingNormal": {
        "description": "Feeding normal",
        "name": "FN"
      },
      "foodForced": {
        "description": "Food forced",
        "name": "FF"
      },
      "foodNormal": {
        "description": "Food normal",
        "name": "FN"
      },
      "foodTube": {
        "description": "Food by tube",
        "name": "FT"
      },
      "hematuria": {
        "description": "Hematuria",
        "name": "UH"
      },
      "noFaeces": {
        "description": "No faeces",
        "name": "NF"
      },
      "noUrine": {
        "description": "No urine",
        "name": "NU"
      },
      "urineConcentrated": {
        "description": "Urine concentrated",
        "name": "UC"
      },
      "urineConcentratedByExpression": {
        "description": "Urina concentrated by expression",
        "name": "UCE"
      },
      "urineJaundiced": {
        "description": "Urine jaundiced",
        "name": "UJ"
      },
      "urineNormal": {
        "description": "Urine normal",
        "name": "UN"
      },
      "urineNormalByExpression": {
        "description": "Urine normal by expression",
        "name": "UNE"
      },
      "vomitBile": {
        "description": "vomit bile",
        "name": "VB"
      },
      "vomitFoamy": {
        "description": "Vomit foamy",
        "name": "VF"
      },
      "vomitFood": {
        "description": "Vomit food",
        "name": "VF"
      },
      "vomitRegurgitation": {
        "description": "Vomit with regurgitation",
        "name": "VR"
      },
      "vomitWithBlood": {
        "description": "Vomit with blood",
        "name": "VB"
      }
    },
    "internmentTypes": {
      "hospitalization": {
        "title": "Hospitalization"
      },
      "hotel": {
        "name": "Hotel",
        "namePlural": "Hotels"
      },
      "infected": {
        "name": "Infected",
        "namePlural": "Infected"
      },
      "intensiveCare": {
        "name": "Intensive Care",
        "namePlural": "Intensive Cares"
      },
      "normal": {
        "name": "Normal",
        "namePlural": "Normals"
      },
      "outpatient": {
        "name": "Outpatient",
        "namePlural": "Outpatients"
      },
      "reference": {
        "name": "Reference",
        "namePlural": "References"
      },
      "surgery": {
        "name": "Surgery",
        "namePlural": "Surgeries"
      }
    },
    "internments": {
      "actions": {
        "action": {
          "closeCart": "Close Cart",
          "create": {
            "doneBy": "Done by",
            "doubleClick": "To complete an action, just double-click on it.",
            "errorCreating": "We were unable to save your changes at this time, please try again later.",
            "lastAction": "Last action",
            "noNotes": "No notes",
            "saveNotes": "Save notes",
            "updateScheduling": "Update scheduling",
            "uploadImage": "Upload Image",
            "weightChangedDrug": "The values of this drug may be outdated because the animal's weight has changed.",
            "weightChangedSerum": "The values of this fluids may be outdated because the animal's weight has changed."
          },
          "details": {
            "gallery": "Gallery",
            "started": "Action date"
          },
          "initials": "Initials",
          "notes": "Notes",
          "started": "Started",
          "status": {
            "done": "Done",
            "removed": "Removed",
            "reverted": "Reverted"
          },
          "title": "Action"
        },
        "shoppingCart": "Shopping Cart Actions",
        "title": "Actions"
      },
      "clinicAnimal": {
        "admission": "admission",
        "entity": "Patient",
        "entityPlural": "Patients",
        "location": "location",
        "pmsID": "PMS ID",
        "status": "status",
        "temperament": "Temperament"
      },
      "clinicPerson": {
        "pmsID": "Person number",
        "role": "Role"
      },
      "clinicalEpisode": {
        "created": "Created",
        "date": "Date",
        "description": "Description",
        "entity": "Clinical Episode",
        "entityPlural": "Clinical History",
        "name": "Name",
        "type": "Type",
        "updated": "Updated"
      },
      "details": {
        "allEvents": "All events",
        "allTasks": "All tasks",
        "cantReopen": "This hospitalization can't be reopened please contact us for more information",
        "closed": "Closed",
        "columnsCount": "Nr of columns",
        "completedActions": "Completed actions",
        "currentDay": "Current day",
        "dailyConsumptions": "The consumptions shown here are only for this day",
        "dayTasks": "Tasks of the day",
        "dischargeBy": "Discharge scheduled",
        "doesntDefecate": "Doesn't defecate",
        "doesntEat": "Doesn't eat",
        "doesntUrinate": "Doesn't urinate",
        "highlight": "Highlight",
        "hospitalizedStatus": {
          "average": "Same",
          "bad": "Worse",
          "good": "Better"
        },
        "lock": "Lock",
        "locked": "Locked",
        "lockedBy": "Locked by",
        "owesMoney": "The client owes money",
        "reopen": "Reopen",
        "reopenCheck": "Are you sure you want to reopen?",
        "report": "Report",
        "startHour": "First hour",
        "step": "Hours step",
        "taskState": {
          "closed": "Closed",
          "open": "Open",
          "title": "State"
        },
        "total": "Total",
        "unlock": "Unlock",
        "warningReason": "You're seeing this warning because you haven't done an action of the task",
        "yesterdaysConsumptions": "To see consumptions of previous days go back on the chart"
      },
      "edit": {
        "title": "Edit hospitalization"
      },
      "internment": {
        "clinicalEvolution": "Clinical evolution",
        "consumptions": "Consumptions",
        "costEstimate": "New expense",
        "create": "Save",
        "details": {
          "allEvents": "All events",
          "allTasks": "All tasks",
          "cantReopen": "This hospitalization can't be reopened please contact us for more information",
          "closed": "Closed",
          "columnsCount": "Nr of columns",
          "completedActions": "Completed actions",
          "currentDay": "Current day",
          "dailyConsumptions": "The consumptions shown here are only for this day",
          "dayTasks": "Tasks of the day",
          "dischargeBy": "Discharge scheduled",
          "doesntDefecate": "Doesn't defecate",
          "doesntEat": "Doesn't eat",
          "doesntUrinate": "Doesn't urinate",
          "highlight": "Highlight",
          "hospitalizedStatus": {
            "average": "Same",
            "bad": "Worse",
            "good": "Better"
          },
          "lock": "Lock",
          "locked": "Locked",
          "lockedBy": "Locked by",
          "owesMoney": "The client owes money",
          "reopen": "Reopen",
          "reopenCheck": "Are you sure you want to reopen?",
          "report": "Report",
          "reports": "Reports",
          "startHour": "First hour",
          "step": "Hours step",
          "taskState": {
            "closed": "Closed",
            "open": "Open",
            "title": "State"
          },
          "total": "Total",
          "unlock": "Unlock",
          "warningReason": "You're seeing this warning because you haven't done an action of the task",
          "yesterdaysConsumptions": "To see consumptions of previous days go back on the chart"
        },
        "diagnosis": "Diagnosis",
        "discharge": "Schedule discharge",
        "dischargeDate": "Discharge date",
        "dischargeText": "Discharge notes",
        "discharged": "Discharge scheduled",
        "duration": "Duration",
        "end": "Discharge",
        "endReason": "Discharge note",
        "ended": "End",
        "event": {
          "catheter": "Catheter",
          "contactOwner": "Contact owner",
          "faeces": "Faeces",
          "feeding": "Feeding",
          "fluidRate": "Fluid rate",
          "food": "Food",
          "temperature": "Temperature",
          "urine": "Urine",
          "vomit": "Vomit"
        },
        "finances": "Finances",
        "financialReport": "Financial Report",
        "hotel": {
          "name": "Hotel",
          "namePlural": "Hotels"
        },
        "infected": {
          "name": "Infected",
          "namePlural": "Infected"
        },
        "internmentType": "Type",
        "location": "Location",
        "newMsg": "Hospitalization",
        "normal": {
          "name": "Normal",
          "namePlural": "Normals"
        },
        "notes": "Notes",
        "otherTasks": "Other",
        "outpatient": {
          "name": "Outpatient",
          "namePlural": "Outpatients"
        },
        "reason": "Reason",
        "reference": {
          "name": "Reference",
          "namePlural": "References"
        },
        "report": {
          "body": "Body / Colar",
          "clean": "Animal clean / dry",
          "dressing": "Bandage",
          "moreDetails": "See more details of this hospitalization on:",
          "release": "Release",
          "removeCatheter": "Remove catheter",
          "verify": "Check before handing the animal"
        },
        "responsibleVet": "Responsible",
        "revertDischarge": "Revert discharge",
        "started": "Start",
        "status": "Status",
        "statusDsc": {
          "close": "Ended",
          "open": "Underway"
        },
        "surgery": {
          "name": "Surgery",
          "namePlural": "Surgeries"
        },
        "symptoms": "Symptoms",
        "title": "Hospitalization",
        "warningMessage": "Warning",
        "workers": "Workers"
      },
      "new": {
        "title": "New hospitalization"
      },
      "title": "Hospitalisations",
      "welcome": {
        "hopi": "If you already use our hospitalization software then enter here",
        "trial": "Free trial",
        "trialMsg": "Experimente gratuitamente o novo módulo de hospitalização"
      }
    },
    "intl": {
      "attention": "Attention!",
      "close": "Are you sure you want to close?",
      "discharge": "Are you sure you want to schedule a discharge?",
      "emptyString": "--",
      "error": "Error",
      "finish": "Are you sure you want to finish?",
      "information": "info",
      "my": "My",
      "noContent": "There are no",
      "or": "or",
      "pause": "Are you sure you want to pause the consultation?",
      "remove": "Are you sure you want to remove?",
      "revert": "Are you sure you want to revert?",
      "revertDischarge": "Are you sure you want to revert the discharge?",
      "showLess": "Show less...",
      "showMore": "Show more...",
      "unsavedChanges": "When cancelling all unsaved changes will be lost. Do you wish to continue?"
    },
    "invite": {
      "invitedMsg": "Invite sent to: ",
      "title": "Invite"
    },
    "invoices": {
      "changeInvoice": "Change selected invoice",
      "invoice": {
        "annuled": "Document annuled",
        "date": "Date",
        "emissionDate": "Emission Date",
        "entity": "Entity",
        "error": "Error",
        "number": "Nº Invoice",
        "processing": "Processing",
        "reference": "Reference",
        "status": "Status",
        "title": "Invoice",
        "total": "Total"
      },
      "new": {
        "title": "New Invoice"
      },
      "title": "Invoices"
    },
    "itemAdjustments": {
      "itemAdjustment": {
        "title": "Item Adjusment"
      },
      "messages": {
        "createdSuccess": "Item Adjusment created successfully"
      },
      "new": {
        "title": "New Item Adjusment"
      },
      "title": "Item adjustments"
    },
    "itemSelectList": {
      "compoundTotal": "Compound total",
      "confirm": "Confirm",
      "name": "Name",
      "orderNumber": "Order",
      "pv": "PV",
      "pvSale": "PV",
      "pvp": "PVP1",
      "pvp2": "PVP2",
      "pvp3": "PVP3",
      "quantity": "Quantity",
      "value": "Value"
    },
    "itemTypes": {
      "description": "See all item types",
      "edit": {
        "title": "Edit Item Type"
      },
      "itemType": {
        "description": "Description",
        "name": "Name",
        "parent": "Parent",
        "stockable": "Can move stock",
        "title": "Type"
      },
      "new": {
        "title": "New Item Type"
      },
      "title": "Item Types",
      "translation": {
        "ADS": "ADS",
        "Reprodução": "Reproduction",
        "cmdt": "Exams (CMDT)",
        "cmdt-external_imagiology": "External Imagiology",
        "cmdt-external_lab": "External Lab",
        "cmdt-internal_imagiology": "Internal Imagiology",
        "cmdt-internal_lab": "Internal Lab",
        "complementary_funeral_services": "Funeral Services",
        "complementary_services": "Complementary Services",
        "complementary_services_recovery": "Complementary Recovery Services",
        "consultations": "Consultations",
        "consultations-external": "External",
        "consultations-first_consultation": "First Consultation",
        "consultations-followup": "Follow Up",
        "consultations-general": "General",
        "consultations-specialty": "Specialty",
        "consultations-urgency": "Urgency",
        "consumables": "Consumables",
        "consumables-cleaning": "Cleaning",
        "consumables-disposable": "Disposable",
        "consumables-mcdt": "MCDT",
        "consumables-stewardship": "Stewardship",
        "consumables-surgical": "Surgical",
        "drugs": "Drugs",
        "drugs-anesthetics": "Anesthetics",
        "drugs-anti_infective": "Anti Infective",
        "drugs-anti_inflammatory": "Anti Inflammatory",
        "drugs-antibiotics": "Antibiotics",
        "drugs-antidotes": "Antidotes",
        "drugs-antineoplastics": "Antineoplastics",
        "drugs-cardiovascular _system": "Cardiovascular System",
        "drugs-chemotherapy": "Chemotherapy",
        "drugs-digestive_system": "Gastrointestinal System",
        "drugs-endocrinological": "Endocrinological System",
        "drugs-external_Insulin": "Insulin",
        "drugs-external_otological": "Auditory System",
        "drugs-hormones": "Hormones",
        "drugs-immunostimulants": "Immunostimulants",
        "drugs-immunosuppressors": "Immunosuppressions",
        "drugs-integumentary_system": "Integumentary System",
        "drugs-musculoskeletal_system": "Locomotor System",
        "drugs-nervous_system": "Nervous System",
        "drugs-other": "Other",
        "drugs-respiratory_system": "Respiratory System",
        "drugs-urinary_system": "Urinary System",
        "drugs-visual_system": "Visual System",
        "food": "Food",
        "food-diet": "Diet",
        "food-physiological": "Physiological",
        "food-snaks": "Snacks",
        "health_insurance": "Health Insurance",
        "health_plans": "Health Plans",
        "hospitalization": "Hospitalization",
        "hospitalization-fluidotherapy": "Fluidotherapy",
        "hospitalization-hospitalization": "Hospitalization",
        "hospitalization-procedures": "Hospitalization Procedures",
        "hotel": "Hotel",
        "pecuaria": "Livestock",
        "petshop": "Pet Shop",
        "petshop-clothing": "Clothing",
        "petshop-handling": "Handling",
        "petshop-home": "Home",
        "petshop-hygiene": "Hygiene",
        "petshop-toys": "Toys",
        "procedures": "Procedures",
        "procedures_medical": "Medical Procedures",
        "procedures_nurse": "Nursing",
        "profilaxia": "Profilaxia",
        "profilaxia-external_deworming": "External Deworming",
        "profilaxia-identification": "Identification",
        "profilaxia-internal_deworming": "Internal Deworming",
        "reference_consultation": "Reference Consultation",
        "referral": "Referral",
        "referral-transportation": "Transportation",
        "rehabilitation": "Rehabilitation",
        "soft_tissue_breeder": "Soft Tissue Breeder System",
        "soft_tissue_cardiovascular": "Soft Tissue Cardiovascular System",
        "soft_tissue_gastrointestinal": "Soft Tissue Gastrointestinal System",
        "soft_tissue_integumentary": "Soft Tissue Integumentary System",
        "soft_tissue_locomotor": "Soft Tissue Locomotor System",
        "soft_tissue_nervous": "Soft Tissue Nervous System",
        "soft_tissue_respiratory": "Soft Tissue Respiratory System",
        "soft_tissue_urinary": "Soft Tissue Urinary System",
        "soft_tissue_visual": "Soft Tissue Visual System",
        "studies": "Studies",
        "suplementos": "Supplements",
        "surgery": "Surgery",
        "surgery-drugs": "Anesthesia and Analgesia",
        "surgery-orthopedics": "Orthopedics",
        "surgery-soft_tissue": "Soft Tissue",
        "vaccines": "Vaccines",
        "wellness": "Wellness",
        "wellness-recorte": "Grooming",
        "wellness-shower": "Shower"
      }
    },
    "items": {
      "chooseUnit": "The base unit field is mandatory",
      "companions": "Associated items",
      "disabled": "Disabled",
      "edit": {
        "title": "Edit Item",
        "types": "Edit Types"
      },
      "enabled": "Enabled",
      "entry": {
        "title": "Orders Entry"
      },
      "form": {
        "category": {
          "generic": "Generic",
          "lastPurchase": "Last purchase",
          "order": "Order",
          "period": "Period",
          "periodic": "Periodic",
          "sellable": "Sellable",
          "stockable": "Manages stock",
          "unstockable": "Doesn't manage stock"
        },
        "placeholder": {
          "name": "Name"
        }
      },
      "inactiveItems": "Inactive items",
      "item": {
        "barcode": "Barcode",
        "cantSelectSame": "This item was allready added",
        "companyItemSubType": "Item Sub Type",
        "complementaryDescription": "Internal Description",
        "cost": "unit cost",
        "defaultWarehouse": "Default Warehouse",
        "description": "Description",
        "duration": "Duration",
        "errors": {
          "nameAlreadyUsed": "This item's name cannot be changed because it was already used in documents",
          "typeAlreadyUsed": "Cannot be changed because it was already used in documents"
        },
        "examUnit": "Exam Unit",
        "inactive": "This article is inactive!",
        "itemClass": {
          "0": "Unknown",
          "1": "Services",
          "2": "Materiali",
          "3": "In Progress",
          "4": "Subproduct",
          "5": "Finished",
          "6": "Commodity",
          "7": "Immobilized",
          "title": "Class"
        },
        "linePrice": "Line Price",
        "linePriceOld": "Old line price",
        "linePriceWithVat": "Line Price w/vat",
        "maxStock": "Stock Max",
        "minStock": "Stock Min",
        "name": "Name",
        "parameter": "Parameter",
        "pmsID": "External ID",
        "prescription": "Prescription",
        "price": "Price (wo/VAT)",
        "priceAutomatic": "Automatic Price",
        "profit": "profit",
        "purchasePrice": "Purchase Price (wo/VAT)",
        "pvp": "Price",
        "pvp2": "Price 2",
        "pvp3": "Price 3",
        "pvpDiscount": "Price discount",
        "pvpDiscount2": "Price2 discount",
        "pvpDiscount3": "Price3 discount",
        "pvpMargin": "Margin",
        "pvpMargin2": "Margin 2",
        "pvpMargin3": "Margin 3",
        "pvpMarkup": "Markup",
        "pvpMarkup2": "Markup 2",
        "pvpMarkup3": "Markup 3",
        "quantity": "Quantity",
        "reservedQuantity": "Quantity reserved",
        "saleUnit": "Sales Unit",
        "saleUnits": "Sales Units",
        "stock": "Stock",
        "stockUnit": "Purchase Unit",
        "tarifVariable": "Dinamic price",
        "title": "Item",
        "type": "Type",
        "unitPrice": "Unit Price",
        "unitPriceWithVat": "Unit Price w/vat",
        "warehouse": "Warehouse"
      },
      "messages": {
        "createCondition": "For 1, just let it blank",
        "createdSuccess": "Item successfully created"
      },
      "new": {
        "duplicate": "Duplicate",
        "title": "New Item"
      },
      "originalRetailPrice": "Retail Price",
      "retailPrice1": "Retail Price 1",
      "retailPrice2": "Retail Price 2",
      "retailPrice3": "Retail Price 3",
      "title": "Items",
      "totalProducts": "Products Total",
      "totalServices": "Services Total"
    },
    "laboratories": {
      "actions": {
        "saveAndDoneAnalysis": "Save and Done"
      },
      "laboratory": {
        "name": "Name",
        "title": "Laboratory"
      },
      "laboratoryType": {
        "external": "External",
        "internal": "Internal",
        "title": "Laboratory Type"
      },
      "new": {
        "title": "New laboratory"
      },
      "search": {
        "title": "Search"
      },
      "title": "Laboratories"
    },
    "language-select": {
      "language": {
        "en": "English",
        "es": "Español",
        "es-mx": "Español Mexicano",
        "fr": "French",
        "pt": "Português",
        "pt-br": "Português do Brasil"
      }
    },
    "liaisons": {
      "messages": {
        "title": "Sender successfully created"
      },
      "new": {
        "title": "New sender veterinarian"
      },
      "title": "Senders",
      "vet": "Sender Veterinarian"
    },
    "listings": {
      "active": "Active",
      "all": "All",
      "expired": "Expired",
      "inflow": "Inflow",
      "others": "Others",
      "pending": "Pending",
      "salesByUser": "Sales by user",
      "taxes": "Taxes",
      "title": "Listings"
    },
    "login": {
      "forgot": "Forgot password?",
      "invalidPassword": "Invalid password",
      "missingAccount": "Do not have an account?",
      "submit": "Login",
      "subtitle": "Login into Pet Universal",
      "title": "Login",
      "unknownUser": "Unknown email"
    },
    "manufacturers": {
      "new": {
        "title": "New manufacturer"
      },
      "title": "Manufacturers"
    },
    "memos": {
      "advance": "Advance",
      "date": "Date",
      "discountAbsence": "Discount absence on invoice",
      "memo": {
        "title": "Memo"
      },
      "motive": "Motive",
      "paymentConditions": "Payment Conditions",
      "priceError": "Price Error",
      "priceincrease": "Price increase",
      "reference": "Reference",
      "rejectedProduct": "Rejected product",
      "stockRuture": "Stock break",
      "title": "Memos"
    },
    "menu": {
      "new": "New"
    },
    "models": {
      "company": {
        "child": "Sub-unidade",
        "children": "Sub-unidades",
        "country": "Country",
        "email": "E-mail",
        "entity": "Company",
        "entityPlural": "Companies",
        "facebook": "Facebook",
        "logo": "Logo",
        "name": "Name",
        "notes": "Notes",
        "parent": "Parent",
        "phone": "Phone",
        "street": "Street",
        "town": "Town",
        "vatNumber": "VAT",
        "zip": "Zip Code"
      },
      "description": "See all models",
      "employee": {
        "entity": "Employee",
        "entityPlural": "Employees",
        "profile": "Perfil"
      },
      "form": {
        "placeholder": {
          "name": "Name"
        }
      },
      "messages": {
        "createdSuccess": "Model successfully created"
      },
      "model": {
        "name": "Name",
        "title": "Model"
      },
      "new": {
        "title": "New Model"
      },
      "person": {
        "country": "Country",
        "email": "Email",
        "entity": "Person",
        "entityPlural": "People",
        "mobilePhone": "Mobile",
        "name": "Name",
        "nif": "UTR",
        "phone": "Phone",
        "street": "Address",
        "town": "Town",
        "zip": "Zip Code"
      },
      "role": {
        "administrator": "Administrator",
        "employee": "Employee",
        "undermanager": "Undermanager",
        "unknown": "Unknown role"
      },
      "title": "Models",
      "user": {
        "email": "Email",
        "entity": "User",
        "entityPlural": "Users",
        "name": "Name",
        "nif": "UTR",
        "password": "Password"
      }
    },
    "modelsTable": {
      "noDataToShow": "No Data To Show",
      "of": "of",
      "search": "Search",
      "show": "Show"
    },
    "modules": {
      "management": {
        "description": "Patients and customers management",
        "inactiveClient": "The selected client is inactive",
        "tariffNotIncluded": "Your current plan does not include this module",
        "title": "Management",
        "unavailableModule": "This feature is unavailable"
      },
      "sales": {
        "title": "Sales"
      }
    },
    "monitorings": {
      "monitoring": {
        "abdomen": "Abdomen",
        "attitude": "Attitude",
        "bloodPressure": "Blood pressure",
        "capillaryRepletionTime": "Capillary repletion time",
        "description": "Description",
        "ended": "End",
        "ganglia": "Ganglia",
        "glucose": "Glucose",
        "heartRate": "Heart rate",
        "hematocrit": "Hematocrit",
        "hydration": "Hydration",
        "maxValue": "Maximum value",
        "mentalState": "Mental state",
        "minValue": "Minimum value",
        "mucous": "Mucous",
        "name": "Name",
        "notes": "Notes",
        "other": "Other",
        "pain": "Pain",
        "period": "Day frequence",
        "physicalExame": "Physical exame",
        "pulse": "Pulse",
        "respiratoryFrequency": "Respiratory period",
        "started": "Start",
        "temperature": "Temperature",
        "title": "Monitoring",
        "totalProteins": "Total proteins"
      },
      "title": "Monitorings"
    },
    "navigation": {
      "addAsPatient": "Add as patient",
      "back": "Back",
      "confirm": "Confirm"
    },
    "noRecords": "No data to be displayed",
    "notes": {
      "contact": "Contact",
      "date": "Date",
      "name": "Name",
      "note": {
        "editNote": "Edit Note",
        "newNote": "New Note"
      },
      "noteType": "Note type",
      "outcomeType": "Outcome",
      "patient": "Patient",
      "title": "Notes",
      "value": "Note"
    },
    "openItems": {
      "amount": "Amount",
      "debtAmount": "Amount remaining to pay",
      "discount": "Discount",
      "dueDate": "Due Date",
      "name": "Document",
      "notes": "Notes",
      "payingAmount": "Amount being paid",
      "paymentCantBeZero": "A payment cant be issued with negative values",
      "receiptCantBeZero": "A receipt must have a positive value for it to be issued",
      "receiptInfoMessage": "Note: to close an invoice with an nc, it is not a receipt (does not allow zero total), in this case a payment is made, it allows zero"
    },
    "options": {
      "no": "No",
      "yes": "Yes"
    },
    "orderLines": {
      "new": {
        "title": "New line"
      },
      "orderLine": {
        "change": "Change",
        "discount": "Discount",
        "id": "ID",
        "liquid": "Liquid",
        "name": "Name",
        "productsAndServices": "Products and services",
        "quantity": "Quantity",
        "title": "Shopping cart Line",
        "total": "Total",
        "totalWithVAT": "Total w/VAT",
        "totalWithoutVAT": "Total wo/VAT",
        "vat": "VAT"
      },
      "title": "Shopping cart lines"
    },
    "orders": {
      "edit": {
        "backToStart": "Home"
      },
      "order": {
        "title": "Shopping cart"
      },
      "title": "Shopping carts"
    },
    "organizations": {
      "new": {
        "title": "New Organization"
      },
      "organization": {
        "avatar": "Avatar",
        "avatars": "Avatars",
        "businessHours": "Business Hours",
        "children": "Children",
        "company": "Company",
        "country": "Country",
        "created": "Created",
        "details": {
          "disabled": "Esta entidade não tem um plano associado. Por favor contacte a Pet Universal"
        },
        "email": "Email",
        "enable": "Enable",
        "facebook": "Facebook",
        "index": {
          "afterNoon": "Good afternoon",
          "hi": "Hi",
          "morning": "Good morning",
          "night": "Good night"
        },
        "name": "Name",
        "notes": "Notes",
        "parent": "Parent",
        "patientsCount": "Patients Count",
        "phone": "Phone",
        "plan": "Plan",
        "street": "Street",
        "title": "Organization",
        "town": "Town",
        "zip": "Zip Code"
      },
      "title": "Organizations"
    },
    "pages": {
      "absences": "Absences",
      "account": "Account",
      "actions": "Actions",
      "active": "Active",
      "appointments": "Appointments",
      "approval": "Absence Approval",
      "archive": "Archive",
      "avatar": "Avatar",
      "avatars": "Avatars",
      "backoffice": "Configurations",
      "boxes": "Boxes",
      "calendar": "Calendar",
      "campaign": "Campaign",
      "campaigns": "Campaigns",
      "checkinWait": "Waiting Room",
      "checkoutWait": "Check Out",
      "clinical": "Clinic",
      "clinicalHistory": "Clinical History",
      "clinicalReport": "Clinical report",
      "clinicalSummary": "Clinical Summary",
      "clinicalWaitingRooms": "Waiting Room",
      "companies": "Companies",
      "company": "Company",
      "configurations": "Configurations",
      "consultation": "Visit",
      "consultations": "Visits",
      "corporate": "Corporate",
      "crm": "CRM",
      "currentAccount": "Current Account",
      "currentAccounts": "Current Accounts",
      "custom": "Day",
      "customers": "Customers",
      "day": "Day",
      "day2": "Day v2",
      "debt": "Debt",
      "debts": "Debts",
      "details": "Details",
      "diagnosis": "Diagnosis",
      "documents": "Documents",
      "edit": "Edit",
      "employees": "Employees",
      "equipment": "Equipment",
      "equipmentParameter": "Parameter",
      "equipmentParameterType": "Parameter Type",
      "equipmentParameterTypes": "Parameter Types",
      "equipmentParameters": "Parameters",
      "equipmentType": "Equipment Type",
      "equipmentTypes": "Equipment Types",
      "equipments": "Equipments",
      "events": "Events",
      "exams": "Exames",
      "excess": "Excesses",
      "financialAccountAuto": "History",
      "financialAccounts": "Financial Accounts",
      "financialReport": "Financial Report",
      "general": "Rules",
      "group": "Group",
      "hotel": "Hotel",
      "howTo": "SMS",
      "hr": "Human resources",
      "internal": "Internal",
      "internment": "Hospitalization",
      "internments": "Hospitalizations",
      "inventory": "Inventory",
      "invite": "Invite",
      "invites": "Invites",
      "invoice": "Invoice",
      "invoices": "Invoices",
      "invoicesMemos": "Documents",
      "item": "Item",
      "itemAdjustments": "Item adjustments",
      "items": "Items",
      "jasmin": "Jasmin",
      "laboratories": "Laboratories",
      "liaisons": "Senders",
      "listings": "Listings",
      "manufacturer": "Manufacturer",
      "manufacturers": "Manufacturers",
      "movements": "Stock movements",
      "new": "New",
      "openItems": "Open Documents",
      "openPurchaseInvoice": "Open Purchase Invoices",
      "openPurchaseInvoices": "open Purchase Invoices",
      "organization": "Organization",
      "organizations": "Organizations",
      "overview": "Overview",
      "overviewShopping": "Overview Shopping Carts",
      "patients": "Patients",
      "periodics": "Periodics",
      "pointOfSales": "Point of sales",
      "procurement": "Procurement",
      "purchaseInvoice": "Purchase",
      "purchaseInvoices": "Purchases",
      "purchaseInvoicesDocuments": "Documents",
      "purchaseMemo": "Memo",
      "purchaseMemos": "Memos",
      "purchaseOrder": "Purchase Order Details",
      "purchaseOrders": "Purchase Orders",
      "purchaseRequest": "Purchase Request Details",
      "purchaseRequests": "Purchase Request",
      "purchases": "Purchases",
      "questionnaireInvite": "Questionnaire",
      "questionnaireInvites": "Questionnaire",
      "quotation": "Quotation",
      "quotations": "Quotations",
      "reception": "Reception",
      "recoverPassword": "Password recovery",
      "referral": "Referral",
      "referrals": "Referrals",
      "reward": "Points",
      "rewards": "Points",
      "saft": "SAF-T",
      "sales": "Sales",
      "schedule": "Schedule",
      "scheduleWait": "Delay",
      "security": "Security",
      "settings": "Settings",
      "sheltereds": "Sheltereds",
      "shifts": "Shifts",
      "shopping": "Shopping",
      "shoppingCart": "Shopping cart",
      "shoppingCarts": "Pending Shopping carts",
      "shortage": "Below Minimun",
      "simpleView": "Simple View",
      "sms": "SMS",
      "stats": "Statistics",
      "stays": "Stay",
      "supplier": "Supplier",
      "suppliers": "Suppliers",
      "tasks": "Tasks",
      "templates": "Reports",
      "today": "Current Stock",
      "todoChore": "Chore",
      "todoChores": "Chores",
      "todoDrug": "Drug",
      "todoDrugs": "Drugs",
      "todoExam": "Exams",
      "todoExams": "Exams",
      "todoMonitoring": "Monitorization",
      "todoMonitorings": "Monitorizations",
      "todoSerum": "Fluid",
      "todoSerums": "Fluids",
      "transferStock": "Transfer Stock",
      "updateItems": "Edit Item Types",
      "visitWait": "In Visit",
      "waitingRoom": "Waiting Room",
      "waitingRooms": "Waiting Room",
      "warehouse": "Warehouse",
      "warehouses": "Warehouses",
      "week": "Week",
      "workers": "Workers"
    },
    "patients": {
      "disabled": "Disabled",
      "edit": {
        "title": "Edit Patient"
      },
      "enabled": "Enabled",
      "form": {
        "category": {
          "fisicalDetails": "Fisical Details",
          "other": "Other",
          "personalInformation": "Personal Information"
        },
        "environmentType": {
          "both": "Both",
          "indoor": "Indoor",
          "outdoor": "Outdoor"
        },
        "placeholder": {
          "behavior": "Behavior",
          "census": "Census",
          "chip": "Chip Number",
          "comments": "Comments",
          "commentsExtra": "Comments Extra",
          "eyes": "Example: Blue",
          "fur": "Example: Short",
          "height": "Height",
          "insuranceType": "Insurance Type",
          "name": "Example: Boby",
          "notes": "Other Information",
          "passaport": "Number Passaport",
          "pathology": "Select Pathology",
          "pedigree": "Pedigree",
          "temperament": "Example: Calm"
        },
        "select": {
          "breed": "Select Breed",
          "diet": "Select Diet",
          "environmentType": "Select Environment Type",
          "owner": "Select Owner",
          "price": "Select Price",
          "species": "Select Species",
          "tail": "Select Tail Type",
          "veterinary": "Select Veterinary Prefered"
        }
      },
      "inactivePatients": "Inactive Patients",
      "messages": {
        "createdSuccess": "Patient successfully created",
        "mustHaveWeight": "The Patient must have a weight to use the calculator"
      },
      "new": {
        "title": "New Patient"
      },
      "number": "Number of patients",
      "patient": {
        "age": "Idade",
        "alert": "Alert",
        "avatar": "Avatar",
        "behavior": "Behavior",
        "birthdate": "Birthdate",
        "breed": "Breed",
        "census": "Census",
        "chip": "Chip",
        "comment": "Comments",
        "comments": "Comments",
        "commentsExtra": "Exta Comments",
        "deathReason": "Reason for death",
        "deathdate": "Death Date",
        "deceased": "Deceased",
        "deleted": "Deleted",
        "diet": "Diet",
        "environmentType": "Environment Type",
        "eyes": "Eyes",
        "female": "Female",
        "fur": "Fur",
        "furColor": "Color",
        "genderless": "Genderless",
        "height": "Height",
        "hermaphrodite": "Hermaphrodite",
        "id": "ID",
        "idcard": "ID Card",
        "inactive": "Inactive",
        "insurance": "Insurance",
        "insuranceType": "Insurance Type",
        "location": "Location",
        "male": "Male",
        "mixedbreed": "Mixed Breed",
        "name": "Name",
        "neutral": "Neutral",
        "newWeight": "New Weight",
        "noOwner": "Cannot schedule an appointment for a patient without owner",
        "noOwnerConsultation": "Cannot create a consultation for a patient without owner",
        "notes": "Notes",
        "otherSpecies": "Other Species",
        "ownerPrefered": "Owner Prefered",
        "passport": "Passaport",
        "pathologies": "Pathologies",
        "pathology": "Pathology",
        "patientSince": "Patient since",
        "pedigree": {
          "no": "No",
          "title": "Pedigree",
          "yes": "Yes"
        },
        "pmsID": "Old ID",
        "price": "Price",
        "sex": "Sex",
        "species": "Species",
        "status": "Status",
        "sterilized": "Sterilized",
        "tailType": "Tail Type",
        "tattoo": "Tattoo",
        "tattooNumber": "Tattoo Number",
        "temperament": {
          "agressive": "Agressive",
          "good": "Good",
          "moderate": "Moderate",
          "title": "Temperament"
        },
        "title": "Patient",
        "unknown": "Unknown",
        "veterinaryPrefered": "Veterinary Preferential",
        "weights": {
          "changedDrug": "The values of this drug may be outdated because the animal's weight has changed.",
          "changedSerum": "The values of this fluids may be outdated because the animal's weight has changed.",
          "title": "Weights",
          "weight": {
            "new": "New Weight",
            "title": "Weight",
            "weighted": "Date"
          }
        }
      },
      "title": "Patients"
    },
    "payments": {
      "noOpenItems": "This client doesn't have any credit memos"
    },
    "periodics": {
      "actions": {
        "done": "Mark as done"
      },
      "messages": {
        "createdSuccess": "Periodic successfully created"
      },
      "new": {
        "cadency": "Cadency",
        "description": "Description",
        "intake": "In Take",
        "labels": {
          "cadency": "Cadency",
          "description": "Description",
          "observations": "Observations",
          "product": "Select Product",
          "selectPeriodicType": "Periodic Type",
          "species": "Select Species"
        },
        "nextDate": "Next Administration",
        "numberOfReinforcements": "Nº of Reinforcements",
        "observations": "Observations",
        "pastDate": "You are entering a date in the past",
        "previousDate": "Last Administration",
        "product": "Product",
        "reinforcmentCadency": "Reinforcment Cadency",
        "species": "Species",
        "title": "New Periodic",
        "type": "Periodics Type"
      },
      "periodic": {
        "intake": "Take",
        "intakeDate": "Date",
        "nextPeriodic": "Next",
        "title": "Periodic",
        "type": "Type"
      },
      "periods": {
        "day": "Day",
        "drugs": {
          "noRepeat": "Always visible",
          "period1": "q1h",
          "period12": "BID",
          "period120": "q5d",
          "period168": "q7d",
          "period2": "q2h",
          "period24": "SID",
          "period3": "q3h",
          "period4": "q4h",
          "period48": "q48h",
          "period5": "q5h",
          "period6": "QID",
          "period72": "q72h",
          "period8": "TID",
          "period96": "q4d",
          "singleTake": "Single take"
        },
        "month": "Month",
        "today": "Today",
        "week": "Week",
        "year": "Year"
      },
      "status": {
        "communicated": "Communicated",
        "done": "Done",
        "nextDose": "Next Dose",
        "notCommunicated": "Not communicated",
        "scheduled": "Scheduled",
        "taken": "Taken",
        "title": "Status",
        "toTake": "To Take"
      },
      "title": "Periodics"
    },
    "plans": {
      "entity": "Plans",
      "new": {
        "title": "New Plan"
      },
      "plan": {
        "entity": "Plan",
        "name": "Name"
      }
    },
    "pointOfSales": {
      "animal": "Animal",
      "barcodeScan": "Barcode Scan",
      "bill": "Bill",
      "billingDatas": {
        "billingData": {
          "title": "Billing data"
        },
        "choose": "Choose",
        "finalCustomer": "Final Customer"
      },
      "camera": "Camera",
      "change": "Change",
      "discount": "Discount",
      "documentTypes": {
        "documentType": {
          "title": "Document type"
        },
        "invoice": "Invoice",
        "invoiceReceipt": "Invoice receipt",
        "simplifiedInvoice": "Simplified invoice",
        "title": "Document types"
      },
      "errors": {
        "debt": "You will owe!",
        "pdfNotEmiting": "The PDF is not available yet, please do a manual download",
        "pleaseTryDocuments": "The document was emited but there was an error, please try the documents menu"
      },
      "issue": "Issue",
      "payAndCheckout": "Pay and checkout",
      "paymentMethods": {
        "amountPaid": "Amount delivered",
        "card": "Electronic Card",
        "cash": "Cash",
        "check": "Check",
        "checkDate": "Check Date",
        "checkNumber": "Check Number",
        "directDebit": "Direct debit",
        "paymentMethod": {
          "title": "Payment method"
        },
        "title": "Payment methods",
        "transfer": "Transfer"
      },
      "quantity": "Quantity",
      "saveNewPrice": "Save the new price?",
      "search": "Search Client",
      "select": "Select Client",
      "title": "Point of sales",
      "warehouse": "Warehouse"
    },
    "prices": {
      "price": {
        "title": "Price"
      },
      "title": "Prices"
    },
    "procurement": {
      "title": "Procurement"
    },
    "purchaseInvoiceLines": {
      "messages": {
        "createdSuccess": "Purchase line created successfully"
      },
      "new": {
        "title": "New purchase line"
      },
      "title": "Purchase line"
    },
    "purchaseInvoices": {
      "duplicate": {
        "title": "Duplicate Purchase Invoice"
      },
      "edit": {
        "saving": "Saving",
        "savingLines": "Saving the lines"
      },
      "invalid": {
        "amount": "Invalid amount",
        "badAdjustment": "Missing data in inventory adjustment",
        "badHeader": "Missing data in header",
        "badLines": "Invalid lines",
        "cost": "Invalid cost",
        "noLines": "Missing lines",
        "noSupplier": "Missing supplier",
        "vat": "Invalid VAT"
      },
      "lastPurchasePrice": "Last Purchase Price",
      "new": {
        "date": "Received",
        "dueDate": "Due Date",
        "exists": "A document with this name already exists",
        "financialAccount": "Financial Account",
        "internalNotes": "Internal Notes",
        "name": "Name",
        "notes": "Notes",
        "paidAmount": "Paid Amount",
        "payment": "Payment",
        "paymentMethod": "Payment Method",
        "supplier": "Supplier",
        "total": "Total"
      },
      "open": {
        "title": "Open Purchase Invoices"
      },
      "purchaseInvoice": {
        "internalData": "Internal Data",
        "lines": "Lines",
        "payment": "Payment",
        "status": {
          "issued": "Emited",
          "saved": "Saved",
          "title": "Status"
        },
        "title": "Purchase"
      },
      "title": "Purchase"
    },
    "purchaseOrders": {
      "date": "Date",
      "details": {
        "title": "Purchase Order Details"
      },
      "done": {
        "title": "Are you sure you want to mark this purchase order as done?"
      },
      "duplicate": {
        "title": "Duplicate Purchase Order"
      },
      "edit": {
        "title": "Edit Purchase Order"
      },
      "info": {
        "billed": "Billed",
        "issued": "Issued",
        "noneBilled": "No Purchase Orders Billed",
        "noneIssued": "No Purchase Orders Issued",
        "noneSaved": "No Purchase Orders Saved",
        "saved": "Saved"
      },
      "item": "Item",
      "line": {
        "lastPrice": "Last $ /"
      },
      "markedDone": {
        "message": "Are you sure you want to mark this purchase order as done? This action cannot be undone.",
        "title": "Mark as done"
      },
      "messages": {
        "successfullyIssued": "Purchase Order issued successfully",
        "successfullySaved": "Purchase Order saved successfully"
      },
      "name": "Name",
      "new": {
        "title": "New Purchase Order"
      },
      "note": "Note",
      "payableAmount": "Estimated Payable Amount",
      "quantity": "Quantity",
      "receive": {
        "title": "Are you sure you want to mark this purchase order as received?"
      },
      "send": {
        "title": "Are you sure you want to send this purchase order?"
      },
      "status": {
        "markedDone": "Marked Done",
        "received": "Received",
        "sent": "Sent",
        "title": "Status"
      },
      "supplier": "Supplier",
      "title": "Purchase Order",
      "updatedSuccess": "Purchase Order updated successfully"
    },
    "purchaseRequests": {
      "date": "Date",
      "details": {
        "title": "Purchase Request Details"
      },
      "done": {
        "title": "Are you sure you want to mark this purchase request as done?"
      },
      "duplicate": {
        "title": "Duplicate Purchase Request"
      },
      "edit": {
        "title": "Edit Purchase Request"
      },
      "info": {
        "billed": "Billed",
        "inOrder": "In Order",
        "issued": "Issued",
        "noneBilled": "None Billed",
        "noneInOrder": "None In Order",
        "noneIssued": "None Issued",
        "noneOrdered": "None Ordered",
        "noneSaved": "None Saved",
        "ordered": "Ordered",
        "saved": "Saved"
      },
      "item": "Item",
      "line": {
        "lastPrice": "Last $ /"
      },
      "markedDone": {
        "message": "Tem a certeza que pretende marcar este Pedido como feito? Esta ação não pode ser desfeita.",
        "title": "Marcar como feito"
      },
      "messages": {
        "createdSuccess": "Purchase Request created successfully"
      },
      "name": "Name",
      "new": {
        "title": "New Purchase Request"
      },
      "note": "Note",
      "payableAmount": "Estimated Payable Amount",
      "quantity": "Quantity",
      "receive": {
        "title": "Are you sure you want to mark this purchase request as received?"
      },
      "send": {
        "title": "Are you sure you want to send this purchase request?"
      },
      "status": {
        "received": "Received",
        "sent": "Sent",
        "title": "Status"
      },
      "supplier": "Supplier",
      "title": "Purchase Request",
      "updatedSuccess": "Purchase Request updated successfully"
    },
    "purchases": {
      "discount": "Discount",
      "lot": "Lot",
      "messages": {
        "successfully": "Purchase made successfully",
        "successfullySaved": "Purchase saved successfully"
      },
      "price": "Price",
      "quantity": "Quantity",
      "quantityPromotion": "For free",
      "shelfLife": "Shelf life",
      "title": "Purchases"
    },
    "quotations": {
      "changeQuotation": "Change Quotation",
      "name": "Name",
      "new": "New quotation",
      "quotation": {
        "title": "Quotation"
      },
      "quotationsConsumed": "Percentage of quotation consumed",
      "removed": "Quotation unselected",
      "selected": "Quotation selected",
      "title": "Quotations"
    },
    "receipts": {
      "edit": {
        "title": "Edit Receipt"
      },
      "noOpenItems": "This client doesn't have any open invoices",
      "receipt": {
        "title": "Receipt"
      },
      "title": "Receipts"
    },
    "referrals": {
      "title": "References"
    },
    "requestLines": {
      "new": {
        "title": "New line"
      },
      "orderLine": {
        "change": "Change",
        "discount": "Discount",
        "id": "ID",
        "liquid": "Liquid",
        "name": "Name",
        "productsAndServices": "Products and services",
        "quantity": "Quantity",
        "title": "Shopping cart Line",
        "total": "Total",
        "totalWithVAT": "Total w/VAT",
        "totalWithoutVAT": "Total wo/VAT",
        "vat": "VAT"
      },
      "title": "Shopping cart lines"
    },
    "rewards": {
      "reward": {
        "available": "Available points",
        "title": "Points",
        "use": "Use points"
      },
      "title": "Points"
    },
    "rooms": {
      "new": {
        "title": "New Room"
      },
      "room": {
        "title": "Room"
      },
      "title": "Rooms"
    },
    "saft": {
      "creator": "Creator",
      "documentDate": "Date",
      "endDate": "End Date",
      "name": "Name",
      "new": {
        "title": "New SAF-T"
      },
      "startDate": "Start Date",
      "title": "SAF-T"
    },
    "sales": {
      "balance": "Balance",
      "billItem": {
        "new": "New Item"
      },
      "billed": "Billed",
      "credit": "Credit",
      "currentAccount": {
        "12to18": "12 - 18 months",
        "18to24": "18 - 24 months",
        "3to6": "3 - 6 months",
        "6to12": "6 - 12 months",
        "less3": "< 3 months",
        "more24": "> 24 months",
        "notDue": "Not due"
      },
      "directDebit": "Debit",
      "document": "Document number",
      "documents": {
        "title": "Documents"
      },
      "invoices": {
        "title": "Invoices"
      },
      "newItem": "Adicionar item  ao carrinho",
      "stats": {
        "assistants": "Assistants",
        "maps": "Maps",
        "nurses": "Nurses",
        "products": "Products",
        "services": "Services",
        "top5": "Top 5",
        "transactions": "Transactions",
        "vets": "Veterinarians"
      },
      "title": "Sales",
      "totalDebt": "Total Debt",
      "totalPaid": "Total paid",
      "value": "Value"
    },
    "salesUnits": {
      "title": "Sales Units"
    },
    "section": {
      "title": "Section"
    },
    "serums": {
      "serum": {
        "adjustment": "Adjustment",
        "adjustmentResult": "Final Rate",
        "amount": "Bottle",
        "description": "Description",
        "ended": "End",
        "maintenanceFluidRate": "Rate",
        "name": "Name",
        "period": "Day frequence",
        "started": "Start",
        "supplementation": "Supplementation",
        "title": "Fluid"
      },
      "title": "Fluids"
    },
    "settings": {
      "rules": "Rules"
    },
    "sheltereds": {
      "disabled": "Disabled",
      "edit": {
        "title": "Edit Sheltered"
      },
      "enabled": "Enabled",
      "form": {
        "category": {
          "fisicalDetails": "Fisical Details",
          "other": "Other",
          "personalInformation": "Personal Information"
        },
        "environmentType": {
          "both": "Both",
          "indoor": "Indoor",
          "outdoor": "Outdoor"
        },
        "placeholder": {
          "allergies": "Allergies",
          "behavior": "Behavior",
          "census": "Census",
          "chip": "Chip Number",
          "comments": "Comments",
          "commentsExtra": "Comments Extra",
          "eyes": "Example: Blue",
          "fur": "Example: Short",
          "furDescription": "Fur Description",
          "height": "Height",
          "insuranceType": "Insurance Type",
          "name": "Example: Boby",
          "notes": "Other Information",
          "passaport": "Number Passaport",
          "pathology": "Select Pathology",
          "pedigree": "Pedigree",
          "siraID": "Sira ID",
          "temperament": "Example: Calm"
        },
        "select": {
          "breed": "Select Breed",
          "diet": "Select Diet",
          "environmentType": "Select Environment Type",
          "owner": "Select Owner",
          "price": "Select Price",
          "species": "Select Species",
          "tail": "Select Tail Type",
          "veterinary": "Select Veterinary Prefered"
        }
      },
      "new": {
        "title": "New Sheltered"
      },
      "sheltered": {
        "admissionDate": "Admission Date",
        "admissionType": "Admission Type",
        "age": "Idade",
        "allergies": "Allergies",
        "avatar": "Avatar",
        "behavior": "Behavior",
        "birthdate": "Birthdate",
        "breed": "Breed",
        "census": "Census",
        "chip": "Chip",
        "comment": "Comments",
        "comments": "Comments",
        "commentsExtra": "Exta Comments",
        "deathdate": "Death Date",
        "deceased": "Deceased",
        "deleted": "Deleted",
        "diet": "Diet",
        "environmentType": "Environment Type",
        "eyes": "Eyes",
        "fur": "Fur",
        "furColor": "Color",
        "furDescription": "Fur Description",
        "furLength": "Fur Length",
        "furType": "Fur Type",
        "height": "Height",
        "id": "ID",
        "inactive": "Inactive",
        "insurance": "Insurance",
        "insuranceType": "Insurance Type",
        "location": "Location",
        "name": "Name",
        "newWeight": "New Weight",
        "notes": "Notes",
        "ownerPrefered": "Owner Prefered",
        "passport": "Passaport",
        "pathologies": "Pathologies",
        "pathology": "Pathology",
        "pedigree": "Pedigree",
        "price": "Price",
        "proportion": "Proportion",
        "releaseDate": "Release Date",
        "releaseType": "Releas eType",
        "sex": "Sex",
        "siraID": "Sira ID",
        "species": "Species",
        "status": "Status",
        "sterilized": "Sterilized",
        "tailType": "Tail Type",
        "tattoo": "Tattoo",
        "tattooNumber": "Tattoo Number",
        "temperament": {
          "agressive": "Agressive",
          "good": "Good",
          "moderate": "Moderate",
          "title": "Temperament"
        },
        "title": "Sheltered",
        "veterinaryPrefered": "Veterinary Preferential",
        "weights": {
          "title": "Weights",
          "weight": {
            "title": "Weight",
            "weighted": "Date"
          }
        }
      },
      "title": "Sheltereds"
    },
    "shifts": {
      "create": "Create Shift",
      "edit": "Edit Shift",
      "endDate": "End Date",
      "name": "Name",
      "notes": "Notes",
      "startDate": "Start Date",
      "title": "Shifts",
      "worker": "Worker"
    },
    "shoppingCartLines": {
      "new": {
        "title": "New line"
      },
      "shoppingCartLine": {
        "discount": "Discount",
        "id": "ID",
        "liquid": "Liquid",
        "name": "Name",
        "productsAndServices": "Products and services",
        "quantity": "Quantity",
        "title": "Shopping cart Line",
        "total": "Total",
        "totalWithVAT": "Total w/VAT",
        "totalWithoutVAT": "Total wo/VAT",
        "vat": "VAT"
      },
      "title": "Shopping cart lines"
    },
    "shoppingCarts": {
      "checkPaid": "Mark as Paid",
      "details": "View Shopping Cart",
      "edit": {
        "backToStart": "Home"
      },
      "markAsPaid": "Are you sure you want to mark as paid?",
      "name": "Name",
      "noLines": "Thisa cart has no lines",
      "onGoingInternment": "Ongoing hospitalization",
      "openTitle": "Open Shopping carts",
      "paid": "Paid",
      "pending": "Pending",
      "percentagePaid": "Percentage of paid carts",
      "shoppingCart": {
        "title": "Shopping cart"
      },
      "showPaidCarts": "Include Carts Already Paid",
      "status": "Status",
      "title": "Shopping carts"
    },
    "sms": {
      "new": {
        "title": "New SMS"
      },
      "sent": {
        "title": "SMS Sent"
      },
      "sms": {
        "appointmentDate": "Date",
        "campaign": "Campaign",
        "customer": "Customer",
        "patient": "Patient",
        "phoneNumber": "Number",
        "started": "Send date",
        "text": "Text",
        "title": "SMS"
      },
      "title": "SMS"
    },
    "species": {
      "101": "Red-billed leiothrix",
      "103": "Rosy-faced lovebird",
      "107": "Iberian Imperial Eagle",
      "108": "Bonnet Eagle",
      "109": "Aratinga",
      "113": "Pigeons",
      "117": "Sugar Glider",
      "118": "Gecko",
      "119": "Snakes",
      "12": "Common Blackbird",
      "120": "Chameleon",
      "121": "Pyrrhura",
      "125": "Chiken",
      "126": "Senegal parrot",
      "128": "Ring neck",
      "130": "Dove",
      "14": "Cockatoo",
      "16": "Macaws",
      "17": "Turquoise-Fronted Parrot",
      "18": "Burrowing Parrot",
      "2": "Chordata",
      "20": "Cockatiel",
      "21": "Parakeet",
      "22": "Grey Parrot",
      "28": "Pigs",
      "33": "Dogs",
      "34": "Felidae",
      "35": "Felis",
      "36": "Cats",
      "40": "Ferrets",
      "42": "Hedgehog",
      "46": "Rabbits",
      "50": "Guinea Pig",
      "53": "Short-tailed Chinchillas",
      "54": "Long-tailed Chinchillas",
      "56": "Hamsters",
      "59": "Mouses",
      "62": "Deguas",
      "64": "Prairie dogs",
      "68": "Pogona",
      "7": "Chloris chloris",
      "70": "Iguana",
      "72": "Varanus",
      "73": "Savannah Monitor",
      "74": "Sea Turtle",
      "79": "Horses",
      "8": "Atlantic Canary",
      "81": "Donkeys",
      "82": "Phodopus",
      "83": "Dwarf Hamster",
      "86": "Elephants",
      "9": "European Goldfinch",
      "90": "Cows",
      "92": "Capra",
      "93": "Goats",
      "95": "Sheep",
      "98": "Budgerigar"
    },
    "stats": {
      "averageTimes": "Average Time",
      "billedThisMonth": "Month",
      "billedThisWeek": "7 days",
      "billedThisYear": "Year",
      "billedToday": "Today",
      "decreased": "Decreased",
      "down": "Down",
      "female": "F",
      "increased": "Increased",
      "invoicesNumber": "Number of invoices",
      "listOfCreatedCustomers": "List of created customers",
      "male": "M",
      "mostRecentDate": "Most recent date",
      "new": "New",
      "organization": "Stats",
      "overview": "Overview",
      "salesByWorker": "Sales by worker",
      "same": "Same",
      "todayTotalChange": "Today Total Change",
      "total": "Total",
      "totalBilled": "Total Billed",
      "totalInInvoices": "Total in invoices",
      "totals": "Totals",
      "up": "Up",
      "variations": "Variations",
      "vsLastYear": "vs Last year"
    },
    "stays": {
      "box": "Box",
      "create": "Create Stay",
      "details": "Stay Details",
      "edit": "Edit Stay",
      "endDate": "End Date",
      "name": "Name",
      "patient": "Patient",
      "startDate": "Start Date",
      "staysArrivingToday": "Stays arriving today",
      "staysLeavingToday": "Stays leaving today",
      "title": "Stays"
    },
    "stockUnits": {
      "title": "Stock Units"
    },
    "subfamily": {
      "title": "Subfamily"
    },
    "supplierItems": {
      "new": {
        "title": "New supplier item"
      },
      "supplierItem": {
        "supplierId": "Supplier code",
        "title": "Supplier item"
      },
      "title": "Supplier items"
    },
    "suppliers": {
      "building": "Building",
      "country": "Country",
      "edit": {
        "title": "Edit Supplier"
      },
      "email": "Email",
      "messages": {
        "createdSuccess": "Supplier successfully created"
      },
      "mobilePhone": "Mobile Phone",
      "name": "Name",
      "new": {
        "title": "New Supplier"
      },
      "street": "Street",
      "supplier": {
        "supplierId": "Supplier ID",
        "title": "Supplier"
      },
      "title": "Suppliers",
      "town": "Town",
      "vat": "VAT",
      "zip": "Zip"
    },
    "system": {
      "alerts": {
        "offline": {
          "html": "<p>Cannot connect to our server.</p><p>Reestablishing the connection <i class='fa fa-spinner fa-pulse fa-lg fa-fw'></i></p>",
          "title": "No Internet connection!"
        },
        "online": {
          "text": "You can now resume your work.",
          "title": "You are online!"
        }
      }
    },
    "systemDown1": "The system will be down for maintenance at",
    "systemDown2": "for approximately two hours.",
    "tasks": {
      "archive": {
        "title": "Archive"
      },
      "create": {
        "addDrug1": "To add a new principle send an email to",
        "addSupplementation1": "To add a new supplementation",
        "addSupplementation2": "click here and send us an email",
        "amount": "Amount",
        "byKG": "By kg",
        "changes": "All changes to a task will be applied starting from the last action done",
        "createdBy": "Created by",
        "dosagePerKilogram": "Dosage per Kg",
        "doseCalculator": "Dose Calculator",
        "email": "info@petuniversal.com",
        "freqInfo": "A \"Always visible\" task is not scheduled and can be manually registered. Without scheduling the task does not appear in the overview.",
        "name": "Create task",
        "newActivePrinciple": "New active principle",
        "rateInfo": "Calculated according to: (weight * 30 + 70) / 24",
        "startedInfo": "Clicking the clock icon automatically inserts the current time.",
        "toAdminister": "To administer",
        "total": "Total",
        "type": {
          "injectable": "Injectable",
          "pill": "Pill",
          "title": "Type"
        },
        "volumePerKilogram": "Volume per Kg"
      },
      "done": "Done",
      "edit": {
        "title": "Edit Task"
      },
      "form": {
        "select": {
          "priority": "Select Priority",
          "worker": "Select Worker"
        }
      },
      "generatedBySystem": "This task was generated by the system",
      "messages": {
        "createdSuccess": "Task successfully created"
      },
      "myTasks": "My Tasks",
      "new": {
        "title": "New Task"
      },
      "priorities": {
        "high": "High",
        "low": "Low",
        "urgency": "Urgency"
      },
      "select": {
        "all": "All"
      },
      "status": {
        "done": "Done",
        "inDelay": "In Delay",
        "pending": "Pending"
      },
      "task": {
        "comment": "Comment",
        "description": "Notes",
        "name": "Description",
        "patientcustomer": "Patient/Customer",
        "period": "Period",
        "priority": "Priority",
        "status": "Status",
        "taskDate": "Date",
        "taskTime": "Hour",
        "title": "Task"
      },
      "taskType": "Task Type",
      "timeline": "Timeline",
      "title": "Tasks",
      "withoutSelectedPriority": "Without Selected Priority",
      "withoutTasks": "Without Tasks",
      "withoutWorker": "Without Worker"
    },
    "taxes": {
      "title": "Taxes",
      "vat": {
        "title": "VAT"
      }
    },
    "templates": {
      "name": "Name",
      "new": {
        "title": "New Report"
      },
      "template": {
        "title": "Report"
      },
      "templateSections": {
        "title": "Template Section"
      },
      "templateSectionsFields": {
        "title": "Template Section Fields"
      },
      "title": "Reports"
    },
    "terms": {
      "conditionedDischarge": "Statement of responsibility: Conditioned Discharge",
      "cookies": {
        "link": "Cookies",
        "title": "Cookies"
      },
      "debt": "Statement of responsibility: Debt",
      "general": {
        "link": "Terms",
        "title": "Terms and Conditions"
      },
      "language-disclaimer": "This agreement was written in Portuguese (pt-PT). To the extent any translated version of this agreement conflicts with the Portuguese version, the Portuguese version controls",
      "last-rev-date": "1st of january 2016",
      "last-rev-date-desc": "Last revision date",
      "privacy": {
        "link": "Privacy",
        "title": "Privacy Policy"
      },
      "procedure": "Statement of responsibility: Procedure",
      "responsability": "Statement of responsibility: Responsability",
      "title": "Forms",
      "translation-not-available": "This page is not available in English, for now we only provide a version in Portuguese."
    },
    "titles": {
      "arquitect": "Arquitect",
      "dr": "Dr",
      "dra": "Dra",
      "engineer": "Eng",
      "engineerFemale": "Eng",
      "father": "Father",
      "miss": "Miss",
      "mr": "Mr.",
      "mrs": "Mrs.",
      "nurse": "Nurse",
      "nurseFemale": "Nurse",
      "professor": "Professor",
      "professorFemale": "Professor",
      "sister": "Sister"
    },
    "transfersStock": {
      "aTDocCodeID": "AT code",
      "loading": {
        "loadingBuildingNumber": "Number",
        "loadingCityName": "City Name",
        "loadingCountry": "Country",
        "loadingDateTime": "Date",
        "loadingPostalZone": "Postal Zone",
        "loadingStreetName": "Street",
        "sourceWarehouse": "Origin",
        "title": "Loading"
      },
      "new": {
        "title": "New transfer of stock"
      },
      "title": "Transfers of Stock",
      "unloading": {
        "targetWarehouse": "Destination",
        "title": "Unloading",
        "unloadingBuildingNumber": "Number",
        "unloadingCityName": "City Name",
        "unloadingCountry": "Country",
        "unloadingDateTime": "Date",
        "unloadingPostalZone": "Postal Zone",
        "unloadingStreetName": "Street"
      }
    },
    "u-icons": {
      "bug": "bug",
      "company": "building-o",
      "employee": "users",
      "image": "image",
      "plan": "ticket",
      "plans": "ticket",
      "user": "user",
      "worker": "user-md"
    },
    "unexpectedError": "Unexpected error. Wait for a few minutes and try again. If the error persists please contact Pet Universal.",
    "unitDimensions": {
      "description": "See all unit dimensions",
      "form": {
        "placeholder": {
          "baseUnit": "Base Unit",
          "description": "Description",
          "name": "Name",
          "title": "Unit Dimensions"
        }
      },
      "new": {
        "title": "New Unit Dimensions"
      },
      "title": "Unit Dimensions",
      "unitDimension": {
        "baseUnit": "Base Unit",
        "description": "Description",
        "name": "Name",
        "title": "Dimension"
      }
    },
    "units": {
      "ampoules": "ampoules",
      "bandages": "bandages",
      "capsules": "capsules",
      "centimeters": "Centimeters",
      "description": "See all units",
      "drops": "drops",
      "form": {
        "placeholder": {
          "baseUnit": "Base Unit",
          "conversionFactor": "Conversion Factor",
          "externalID": "External ID",
          "name": "Name",
          "precisionDigits": "Precision Digits",
          "salesItems": "Sales Items",
          "stockItems": "Stock Items",
          "symbol": "Symbol",
          "title": "Unit"
        }
      },
      "grams": "g",
      "internationalUnits": "IU",
      "kilograms": "kg",
      "liters": "l",
      "micrograms": "μg",
      "microgramsPerMilliliter": "μg/ml",
      "milliequivalents": "mEq",
      "milligrams": "mg",
      "milligramsPerKilogram": "mg/kg",
      "milligramsPerMilliliter": "mg/ml",
      "milliliters": "ml",
      "millilitersPerHour": "ml/h",
      "millilitersPerKilogram": "ml/kg",
      "min": "Minutes",
      "new": {
        "title": "New Unit"
      },
      "percentage": "%",
      "pills": "pills",
      "puff": "puff",
      "sachets": "sachets",
      "title": "Units",
      "unit": {
        "baseUnit": "Base Unit",
        "conversionFactor": "Conversion Factor",
        "externalID": "External ID",
        "name": "Name",
        "precisionDigits": "Precision Digits",
        "salesItems": "Sales Items",
        "stockItems": "Stock Items",
        "symbol": "Symbol",
        "title": "Unit",
        "totalStock": "Total Stock"
      }
    },
    "user": {
      "edit": {
        "title": "User info"
      },
      "new": {
        "agree-terms": "By clicking Login, you agree to our terms and that you have read our privacy policy",
        "confirmPassword": "Confirm password",
        "email-example": "your.email@example.com",
        "name-example": "User Name",
        "password-example": "password",
        "passwordsDontMatch": "Passwords don't match",
        "title": "Sign-up",
        "validationEmailSent": "Thank you for registering, please go to your email and validate your account"
      },
      "recoverPassword.success": "An email was sent to you with your new password"
    },
    "validations": {
      "fieldRequired": "Mandatory field.",
      "maxCharacters": "{number} characters maximum",
      "minCharacters": "{number} characters minimum",
      "minQuantity": "Must have at least {number}",
      "numberCharacters": "Must have {number} characters",
      "specialCharacters": "You cannot insert special characters (ex: >, <) or end with a space"
    },
    "via": {
      "IntralesionalInjectable": "Injectable - IL",
      "auricularBoth": "Auricular both",
      "auricularLeft": "Auricular left",
      "auricularRight": "Auricular right",
      "inhalation": "Inhalation",
      "intramuscularInjectable": "Injectable - IM",
      "intraosseousInjectable": "Injectable - IO",
      "intraperitonealInjectable": "Injectable - IP",
      "intravenousInjectable": "Injectable - IV",
      "ocularBoth": "Ocular both",
      "ocularLeft": "Ocular left",
      "ocularRight": "Ocular right",
      "oral": "Oral",
      "rectal": "Rectal",
      "subcutaneousInjectable": "Injectable - SC",
      "topical": "Topical",
      "transdermal": "Transdermal"
    },
    "visits": {
      "futureVisits": "Future Visits",
      "historicalVisits": "Historical Visits",
      "newVisit": "New Visit",
      "status": {
        "closed": "Closed",
        "open": "Open",
        "title": "Status"
      },
      "title": "Visits",
      "visit": {
        "title": "Visit"
      },
      "visitTypes": {
        "visitSubType": "Select Visit Sub Type",
        "visitType": "Select Visit Type"
      }
    },
    "waitingRooms": {
      "arrival": "Arrival",
      "late": "Delay",
      "status": {
        "noPatientsCheckOut": "No patient checking out",
        "noPatientsConsultation": "Without Patient in Visit",
        "noPatientsWaiting": "No Patient Waiting",
        "pausedConsultations": "Paused Visit",
        "withScheduling": "With Scheduling",
        "withoutAppointments": "Without Appointments"
      },
      "title": "Waiting Room",
      "warning": "Warning",
      "warningDate": "Warning Date"
    },
    "warehouses": {
      "edit": {
        "title": "Edit Warehouse"
      },
      "form": {
        "category": {
          "base": "Base"
        },
        "placeholder": {
          "name": "Name"
        }
      },
      "messages": {
        "createdSuccess": "Warehouse successfully created"
      },
      "new": {
        "title": "New warehouse"
      },
      "stock": "Warehouse stock",
      "title": "Warehouses",
      "viewWarehouse": "View Warehouse",
      "warehouse": {
        "description": "Description",
        "name": "Name",
        "title": "Warehouse"
      }
    },
    "workers": {
      "edit": {
        "placeholder": {
          "address": "Address",
          "email": "example@email.com",
          "mobilePhone": "Mobile Phone",
          "name": "Example: John Lewis",
          "phone": "Phone",
          "professionalLetterNumber": "Professional Number"
        },
        "select": {
          "employee": "Employee",
          "role": "Select Role"
        },
        "title": "Edit Worker"
      },
      "form": {
        "select": {
          "worker": "Select Worker"
        }
      },
      "new": {
        "placeholder": {
          "address": "Address",
          "email": "example@email.com",
          "mobilePhone": "Mobile Phone",
          "name": "Example: John Lewis",
          "phone": "Phone",
          "professionalLetterNumber": "Professional Number"
        },
        "select": {
          "employee": "Employee",
          "role": "Select Role"
        },
        "title": "New Worker"
      },
      "title": "Workers",
      "worker": {
        "address": "Address",
        "birthdate": "Birthdate",
        "email": "Email",
        "enable": "Enable",
        "externalID": "External ID",
        "mobilePhone": "Mobile Phone",
        "name": "Name",
        "phone": "Phone",
        "professionalLetterNumber": "Professional Number",
        "role": "Role",
        "roles": {
          "administrator": "Administration",
          "assistant": "Assistant",
          "nurse": "Nurse",
          "other": "Other",
          "receptionist": "Receptionist",
          "veterinary": "Veterinary"
        },
        "title": "Worker"
      }
    }
  };
  _exports.default = _default;
});