define("crm/templates/components/input-stars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n60qqOGV",
    "block": "{\"symbols\":[\"value\",\"value\"],\"statements\":[[4,\"if\",[[20,[\"disabled\"]]],null,{\"statements\":[[4,\"each\",[[20,[\"values\"]]],null,{\"statements\":[[0,\"    \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-star\",[25,\"unless\",[[19,2,[\"active\"]],\"-o\"],null]]]],[7],[8],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[20,[\"values\"]]],null,{\"statements\":[[0,\"    \"],[6,\"a\"],[3,\"action\",[[19,0,[]],\"setValue\",[19,1,[\"value\"]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-2x fa-star\",[25,\"unless\",[[19,1,[\"active\"]],\"-o\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/input-stars.hbs"
    }
  });

  _exports.default = _default;
});