define("crm/components/historical-consultation", ["exports", "ember-models-table/themes/bootstrap3"], function (_exports, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ["consultations-history"],
    data: Ember.computed("model.[]", function () {
      return this.get("model") ? this.get("model").sortBy('start').reverse() : [];
    }),
    filtered: Ember.computed("data.[]", function () {
      return Ember.isEmpty(this.get("data")) ? [] : this.get("data").filter(function (historicalConsultation) {
        return !Ember.isEmpty(historicalConsultation);
      }).sortBy("started").reverse();
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": 'date',
        "component": "umt-date",
        "sortDirection": "asc",
        "sortPrecedence": 0,
        "title": this.get('intl').t("enums.chronos.dateUpper"),
        "className": "column-date"
      }, {
        "title": " ",
        "propertyName": "appointment.status",
        "component": "show-appointment-status",
        "className": "column-icon"
      }, {
        "className": "appointment-type-name",
        "propertyName": "appointment.display",
        "title": this.get('intl').t("appointments.appointment.appointmentType")
      }, {
        "className": "column-big hidden-xs",
        "component": "worker-display",
        "title": ""
      }, {
        "className": "column-small",
        "component": "motive-display",
        "title": this.get('intl').t('appointments.new.reason')
      }, {
        "className": "history-tags hidden-xs",
        "component": "show-appointment-symptoms",
        "title": this.get('intl').t('consultations.consultation.soap.symptom')
      }, {
        "className": "history-tags hidden-xs",
        "component": "show-appointment-objectives",
        "title": this.get('intl').t('consultations.consultation.soap.diagnostic')
      }, {
        "className": "history-tags hidden-xs",
        "component": "show-appointment-treatment-plans",
        "title": this.get('intl').t('consultations.consultation.soap.treatmentPlan')
      }, {
        "className": "column-small hidden-xs",
        "component": "prescription-display",
        "title": this.get('intl').t('consultations.consultation.soap.prescription.title')
      }, {
        "component": "patient-visit-actions",
        "className": "column-actions-3"
      }];
    }),
    themeInstance: Ember.computed(function () {
      return _bootstrap.default.create({
        messages: {
          "searchLabel": this.get('intl').t("modelsTable.search"),
          "tableSummary": this.get('intl').t('modelsTable.show') + " %@ - %@ " + this.get('intl').t('modelsTable.of') + " %@",
          "noDataToShow": this.get('intl').t('modelsTable.noDataToShow')
        },
        "sort-asc": "fa fa-caret-up",
        "sort-desc": "fa fa-caret-down",
        "clearFilterIcon": "fa fa-times form-control-feedback",
        "clearAllFiltersIcon": ""
      });
    }),
    actions: {
      openConsultation: function openConsultation(consultation) {
        this.sendAction("openConsultation", consultation);
      },
      document: function document(consultation) {
        this.sendAction("document", consultation);
      }
    }
  });

  _exports.default = _default;
});