define("crm/templates/components/show-invoice-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YtJ6Ncl3",
    "block": "{\"symbols\":[\"line\",\"index\"],\"statements\":[[4,\"each\",[[20,[\"record\",\"purchaseInvoiceLines\"]]],null,{\"statements\":[[0,\"    \"],[4,\"if\",[[19,2,[]]],null,{\"statements\":[[0,\"| \"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[\"organizations.organization.purchase-invoices.purchase-invoice\",[19,1,[\"purchaseInvoice\",\"id\"]]],[[\"class\"],[\"module-procurement\"]],{\"statements\":[[0,\"        \"],[1,[19,1,[\"purchaseInvoice\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-invoice-name.hbs"
    }
  });

  _exports.default = _default;
});