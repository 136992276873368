define("crm/templates/components/u-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3UYchV7X",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"canceled\"],[\"openCreatePDF\",[25,\"t\",[\"configurations.configItems.generatePDF\"],null],\"cancel\"]],{\"statements\":[[4,\"if\",[[20,[\"steps\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"js-pdf\",[[20,[\"steps\"]]],[[\"filename\"],[\"cena\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/u-pdf.hbs"
    }
  });

  _exports.default = _default;
});