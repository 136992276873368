define("crm/templates/organizations/organization/warehouses/warehouse/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jqckEqNH",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"u-second-nav\",null,[[\"class\",\"title\",\"titleIcon\"],[\"module-procurement\",[25,\"t\",[\"warehouses.warehouse.title\"],null],[25,\"t\",[\"icons.warehouse\"],null]]]],false],[0,\"\\n\\n\"],[6,\"div\"],[7],[0,\"\\n  \"],[6,\"section\"],[7],[0,\"\\n    \"],[6,\"form\"],[9,\"class\",\"form-horizontal\"],[3,\"action\",[[19,0,[]],\"editWarehouse\"],[[\"on\"],[\"submit\"]]],[7],[0,\"\\n      \"],[6,\"fieldset\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"title\"],[[25,\"t\",[\"actions.details\"],null]]],{\"statements\":[[0,\"          \"],[2,\" Name \"],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n            \"],[6,\"label\"],[9,\"class\",\"col-lg-4 control-label\"],[7],[1,[25,\"t\",[\"items.item.name\"],null],false],[0,\" \"],[6,\"span\"],[9,\"class\",\"text-danger\"],[10,\"title\",[25,\"t\",[\"validations.fieldRequired\"],null],null],[7],[0,\" *\"],[8],[8],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"col-lg-8\"],[7],[0,\"\\n              \"],[1,[25,\"input\",null,[[\"value\",\"placeholder\",\"class\",\"autofocus\",\"required\"],[[20,[\"model\",\"internalDescription\"]],[25,\"t\",[\"warehouses.form.placeholder.name\"],null],\"form-control\",true,true]]],false],[0,\"\\n            \"],[8],[0,\"\\n          \"],[8],[0,\"\\n\\n          \"],[6,\"div\"],[9,\"class\",\"text-right\"],[7],[0,\"\\n            \"],[1,[25,\"u-button\",null,[[\"text\",\"disabled\",\"loading\",\"editWarehouse\"],[[25,\"t\",[\"crud.save\"],null],[20,[\"disabledAction\"]],[20,[\"disabledAction\"]],\"editWarehouse\"]]],false],[0,\"\\n          \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/warehouses/warehouse/edit.hbs"
    }
  });

  _exports.default = _default;
});