define("crm/templates/organizations/organization/current-accounts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ivHrog8Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"model\",\"salesFeature\"]]],null,{\"statements\":[[4,\"u-second-nav\",null,[[\"class\",\"title\",\"titleIcon\"],[\"module-sales\",[25,\"t\",[\"pages.currentAccounts\"],null],[25,\"t\",[\"icons.currentAccount\"],null]]],{\"statements\":[[4,\"if\",[[20,[\"canDoCurrentAccounts\"]]],null,{\"statements\":[[4,\"if\",[[25,\"eq\",[[20,[\"exporting\"]],false],null]],null,{\"statements\":[[0,\"        \"],[6,\"li\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"export\",[20,[\"model\",\"createdBefore\"]],[20,[\"model\",\"createdAfter\"]]]],[7],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-cloud-download\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"actions.download\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"li\"],[7],[6,\"a\"],[9,\"class\",\"disabled\"],[7],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-cloud-download\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"actions.download\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[25,\"current-accounts-list\",null,[[\"model\",\"openModal\"],[[20,[\"model\",\"debts\"]],\"openModal\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"unavailable-feature\",null,[[\"module\",\"feature\"],[\"module-sales\",\"sales\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/current-accounts/index.hbs"
    }
  });

  _exports.default = _default;
});