define("crm/templates/organizations/organization/configurations/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HVOJrX/G",
    "block": "{\"symbols\":[\"aux\"],\"statements\":[[4,\"each\",[[20,[\"model\"]]],null,{\"statements\":[[4,\"if\",[[19,1,[\"feature\"]]],null,{\"statements\":[[4,\"if\",[[25,\"and\",[[19,1,[\"fields\"]],[19,1,[\"available\"]]],null]],null,{\"statements\":[[4,\"ibox-panel\",null,[[\"title\",\"class\",\"titleIcon\"],[[19,1,[\"feature\",\"name\"]],[25,\"concat\",[\"module-\",[19,1,[\"feature\",\"name\"]]],null],[25,\"t\",[[25,\"concat\",[\"icons.\",[19,1,[\"feature\",\"name\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[25,\"custom-form\",null,[[\"model\",\"save\"],[[19,1,[\"fields\"]],\"save\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[25,\"ibox-panel\",null,[[\"title\",\"class\",\"titleIcon\",\"colappsed\",\"hideTools\",\"noPadding\"],[[19,1,[\"feature\",\"name\"]],[25,\"concat\",[\"module-\",[19,1,[\"feature\",\"name\"]]],null],[25,\"t\",[[25,\"concat\",[\"icons.\",[19,1,[\"feature\",\"name\"]]],null]],null],true,true,true]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/configurations/general.hbs"
    }
  });

  _exports.default = _default;
});