define("crm/templates/components/empty-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uRcFFt8W",
    "block": "{\"symbols\":[],\"statements\":[[6,\"h2\"],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[25,\"if\",[[20,[\"icon\"]],[20,[\"icon\"]],\"warning\"],null],\" fa-2x\"]]],[7],[8],[8],[0,\"\\n\"],[6,\"h3\"],[7],[1,[25,\"t\",[\"noRecords\"],null],false],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/empty-set.hbs"
    }
  });

  _exports.default = _default;
});