define("crm/routes/organizations/organization/workers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('do worker')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model() {
      var organizationID = this.modelFor("organizations.organization").get("id");
      return this.store.query('worker', {
        organization: organizationID
      });
    },
    actions: {
      openCreateWorker: function openCreateWorker() {
        this.send('openModalCreateWorker');
      },
      openEditWorker: function openEditWorker(workerToEdit) {
        this.send('openEditWorkerModal', workerToEdit);
      }
    }
  });

  _exports.default = _default;
});