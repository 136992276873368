define("crm/templates/internal/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kal1uJ5L",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,null,{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"page-body\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"filters-panel\"],[7],[0,\"\\n    \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"actions.filters\"],null],false],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"visible-xs-block\"],[7],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"visible-sm-block\"],[7],[8],[0,\"\\n\\n    \"],[1,[25,\"date-filters\",null,[[\"search\"],[\"search\"]]],false],[0,\"\\n    \"],[6,\"hr\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"class\",\"title\",\"titleIcon\",\"titleCount\"],[\"module-internments\",[25,\"t\",[\"internments.title\"],null],[25,\"t\",[\"icons.internments\"],null],[20,[\"model\",\"length\"]]]],{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"model\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/internal/index.hbs"
    }
  });

  _exports.default = _default;
});