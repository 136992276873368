define("crm/templates/components/boxes-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j6tMz2mc",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openDetails\",\"editBoxModal\",\"class\",\"reloadModel\"],[[20,[\"aa\"]],[20,[\"columns\"]],\"openDetails\",\"editBoxModal\",\"hide-search\",\"reloadModel\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/boxes-list.hbs"
    }
  });

  _exports.default = _default;
});