define("crm/templates/components/campaigns-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FlsyycSa",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ibox-content m-b-lg\"],[7],[0,\"\\n    \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"actions.filters\"],null],false],[8],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"text-crm\"],[10,\"title\",[25,\"t\",[\"actions.goBackOneMonth\"],null],null],[3,\"action\",[[19,0,[]],\"previous\"]],[7],[6,\"i\"],[9,\"class\",\"fa fa-arrow-left\"],[7],[8],[8],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",[20,[\"minCreatedAfter\"]],[20,[\"createdBefore\"]],[20,[\"createdAfter\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",[20,[\"createdAfter\"]],[20,[\"maxCreatedBefore\"]],[20,[\"createdBefore\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n    \"],[6,\"a\"],[9,\"class\",\"text-crm\"],[10,\"title\",[25,\"t\",[\"actions.advanceOneMonth\"],null],null],[3,\"action\",[[19,0,[]],\"next\"]],[7],[6,\"i\"],[9,\"class\",\"fa fa-arrow-right\"],[7],[8],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[4,\"ibox-panel\",null,[[\"class\"],[\"module-crm\"]],{\"statements\":[[4,\"if\",[[20,[\"model\",\"length\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openDetails\",\"openEdit\",\"openAppointmentCreate\",\"sendCampaign\"],[[20,[\"model\"]],[20,[\"columns\"]],\"openDetails\",\"openEdit\",\"openAppointmentCreate\",\"sendCampaign\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.campaigns\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/campaigns-list.hbs"
    }
  });

  _exports.default = _default;
});