define("crm/templates/components/sms-sent-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KnBPGP5j",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[20,[\"hideDates\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"ibox-content m-b-lg\"],[7],[0,\"\\n        \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"actions.filters\"],null],false],[8],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",[20,[\"minCreatedAfter\"]],[20,[\"createdBefore\"]],[20,[\"createdAfter\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",[20,[\"createdAfter\"]],[20,[\"maxCreatedBefore\"]],[20,[\"createdBefore\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openDetails\"],[[20,[\"model\"]],[20,[\"columns\"]],\"openDetails\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/sms-sent-list.hbs"
    }
  });

  _exports.default = _default;
});