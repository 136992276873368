define("crm/routes/organizations/organization/tasks/archive", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.readOnly("catalogs.dateLimit"),
    queryParams: {
      startedAfter: {
        refreshModel: true
      },
      startedBefore: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (params.startedAfter && params.startedBefore) {
        var after = (0, _moment.default)(params.startedAfter);
        var before = (0, _moment.default)(params.startedBefore);

        if (after.isValid() && before.isValid() && after.diff(before, "days") < this.get("dateLimit")) {
          return this.store.query("task", {
            organization: this.modelFor("organizations.organization").get("id"),
            startedAfter: after.format('YYYY-MM-DD'),
            startedBefore: before.format('YYYY-MM-DD')
          });
        }
      }

      return [];
    }
  });

  _exports.default = _default;
});