define("crm/templates/organizations/organization/purchase-invoices-documents/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ehk2ll6e",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"class\",\"title\",\"titleIcon\"],[\"module-procurement\",[25,\"t\",[\"pages.purchaseInvoicesDocuments\"],null],[25,\"t\",[\"icons.documents\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ibox-content m-b-lg\"],[7],[0,\"\\n        \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"actions.filters\"],null],false],[8],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",[20,[\"minStartedAfter\"]],[20,[\"startedBefore\"]],[20,[\"startedAfter\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",[20,[\"startedAfter\"]],[20,[\"maxStartedBefore\"]],[20,[\"startedBefore\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n\\n\"],[4,\"ibox-panel\",null,[[\"class\"],[\"module-procurement\"]],{\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"callModal\"],[[20,[\"model\"]],[20,[\"columns\"]],[25,\"action\",[[19,0,[]],\"callModal\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.documents\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/purchase-invoices-documents/index.hbs"
    }
  });

  _exports.default = _default;
});