define("crm/templates/components/patient-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dx6i/Ona",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"canDoCustomer\"]]],null,{\"statements\":[[0,\"  \"],[2,\" disable/enable \"],[0,\"\\n\"],[4,\"if\",[[20,[\"record\",\"enabled\"]]],null,{\"statements\":[[0,\"    \"],[6,\"a\"],[9,\"class\",\"extra-action\"],[10,\"title\",[26,[[25,\"t\",[\"patients.enabled\"],null]]]],[3,\"action\",[[19,0,[]],\"disable\",[20,[\"record\"]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-\",[25,\"t\",[\"icons.enable\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"a\"],[9,\"class\",\"extra-action\"],[10,\"title\",[26,[[25,\"t\",[\"patients.disabled\"],null]]]],[3,\"action\",[[19,0,[]],\"enable\",[20,[\"record\"]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-\",[25,\"t\",[\"icons.disable\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/patient-status.hbs"
    }
  });

  _exports.default = _default;
});