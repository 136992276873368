define("crm/templates/organizations/organization/stats/financial-accounts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0QNwPLuw",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"documents-totals\",null,[[\"model\"],[[20,[\"filteredModels\"]]]]],false],[0,\"\\n\\n\"],[6,\"article\"],[9,\"class\",\"ibox module-sales\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ibox-title\"],[7],[0,\"\\n    \"],[6,\"h5\"],[7],[1,[25,\"t\",[\"documents.title\"],null],false],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[20,[\"queryCash\"]]]]],false],[0,\" \"],[1,[25,\"t\",[\"pointOfSales.paymentMethods.cash\"],null],false],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[20,[\"queryCard\"]]]]],false],[0,\" \"],[1,[25,\"t\",[\"pointOfSales.paymentMethods.card\"],null],false],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[20,[\"queryCheck\"]]]]],false],[0,\" \"],[1,[25,\"t\",[\"pointOfSales.paymentMethods.check\"],null],false],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[20,[\"queryTransfer\"]]]]],false],[0,\" \"],[1,[25,\"t\",[\"pointOfSales.paymentMethods.transfer\"],null],false],[0,\"\\n\\n    \"],[6,\"hr\"],[7],[8],[0,\"\\n\\n    \"],[1,[25,\"documents-table\",null,[[\"model\",\"openModal\",\"transitionToRoute\",\"updateQuery\",\"updateModel\",\"reloadModel\",\"hideActions\",\"showCreator\",\"hideName\"],[[20,[\"filteredModels\"]],\"openModal\",\"transitionToRoute\",\"updateQuery\",\"updateModel\",\"reloadModel\",true,true,true]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/stats/financial-accounts/index.hbs"
    }
  });

  _exports.default = _default;
});