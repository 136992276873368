define("crm/templates/components/approval-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HWWqcTX8",
    "block": "{\"symbols\":[],\"statements\":[[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[18,\"status\"]]]],[10,\"title\",[18,\"statusName\"],null],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/approval-status.hbs"
    }
  });

  _exports.default = _default;
});