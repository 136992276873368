define("crm/templates/organizations/organization/stats/internments/daily", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SJvbtqYx",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"u-second-nav\",null,[[\"title\",\"titleIcon\",\"class\"],[[25,\"t\",[\"internments.title\"],null],[25,\"t\",[\"icons.internment\"],null],\"module-internments\"]]],false],[0,\"\\n\\n\"],[6,\"div\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"m-b-lg\"],[7],[0,\"\\n    \"],[1,[25,\"ember-chart\",null,[[\"type\",\"options\",\"data\",\"height\"],[\"line\",[20,[\"chartOptions\"]],[20,[\"chartData\"]],75]]],false],[0,\"\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-6\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[4,\"if\",[[20,[\"model\",\"model1\"]]],null,{\"statements\":[[0,\"          \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"values1\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.calendar\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-6\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[4,\"if\",[[20,[\"model\",\"model2\"]]],null,{\"statements\":[[0,\"          \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"values2\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.calendar\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/stats/internments/daily.hbs"
    }
  });

  _exports.default = _default;
});