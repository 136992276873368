define("crm/routes/organizations/organization/transfer-stock/new", ["exports", "moment", "universe-gui/u-constants"], function (_exports, _moment, _uConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    countries: _uConstants.default.countries,
    model: function model() {
      return {
        documentDate: (0, _moment.default)().format("YYYY-MM-DD"),
        loadingDateTime: (0, _moment.default)().add(30, "minutes"),
        unloadingDateTime: (0, _moment.default)().add(60, "minutes")
      };
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var organization = this.modelFor("organizations.organization");
      controller.set("organization", organization);
      controller.set("warehousesOrg", this.store.query("warehouse", {
        organization: organization.get("id")
      }));
      controller.set("warehousesCompany", this.store.query("warehouse", {
        company: organization.get("company.id")
      }));
      controller.set("selectedLoadingCountry", this.get("countries").find(function (item) {
        return item.code === "PT";
      }));
      controller.set("selectedUnloadingCountry", this.get("countries").find(function (item) {
        return item.code === "PT";
      }));
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("documentLines", []);
      }
    }
  });

  _exports.default = _default;
});