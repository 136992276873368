define("crm/templates/components/warehouse-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jk3CqX1e",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"class\",\"title\",\"hideFooter\",\"reset\",\"closed\"],[\"modal-warehouse-create\",\"module-procurement\",[25,\"t\",[\"warehouses.new.title\"],null],true,\"reset\",[20,[\"closed\"]]]],{\"statements\":[[4,\"custom-form\",null,[[\"save\",\"canceled\",\"actionText\",\"reset\",\"disabledAction\"],[\"modalCreateWarehouse\",\"canceled\",\"crud.create\",\"reset\",[20,[\"disabledAction\"]]]],{\"statements\":[[0,\"    \"],[6,\"fieldset\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"legend\"],[7],[1,[25,\"t\",[\"warehouses.form.category.base\"],null],false],[8],[0,\"\\n        \"],[2,\" Name \"],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n          \"],[6,\"label\"],[9,\"class\",\"col-lg-4 control-label\"],[7],[1,[25,\"t\",[\"warehouses.warehouse.name\"],null],false],[0,\" \"],[6,\"span\"],[9,\"class\",\"text-danger\"],[10,\"title\",[25,\"t\",[\"validations.fieldRequired\"],null],null],[7],[0,\" *\"],[8],[8],[0,\"\\n          \"],[6,\"div\"],[9,\"class\",\"col-lg-8\"],[7],[0,\"\\n            \"],[1,[25,\"input\",null,[[\"value\",\"maxlength\",\"pattern\",\"title\",\"placeholder\",\"class\",\"autofocus\",\"required\"],[[20,[\"model\",\"internalDescription\"]],\"20\",\"[A-Za-z0-9-_]*\",[25,\"t\",[\"alerts.noSpaces\"],null],[25,\"t\",[\"warehouses.form.placeholder.name\"],null],\"form-control\",true,true]]],false],[0,\"\\n            \"],[6,\"span\"],[7],[1,[25,\"t\",[\"alerts.noSpaces\"],null],false],[8],[0,\"\\n            \"],[6,\"p\"],[9,\"class\",\"help-block\"],[7],[1,[25,\"characters-number\",null,[[\"max\",\"text\"],[20,[20,[\"model\",\"internalDescription\"]]]]],false],[8],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/warehouse-new.hbs"
    }
  });

  _exports.default = _default;
});