define("crm/templates/organizations/organization/internments/internment/shopping-carts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RDFPBsLF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\",\"class\"],[[25,\"t\",[\"shoppingCarts.title\"],null],[25,\"t\",[\"icons.shoppingCart\"],null],\"module-sales\"]],{\"statements\":[[4,\"if\",[[20,[\"organization\",\"salesFeature\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[9,\"class\",\"nav-separation module-sales\"],[7],[0,\"\\n      \"],[4,\"link-to\",[\"organizations.organization.pointOfSales\",[20,[\"invoiceQueryParams\"]]],null,{\"statements\":[[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-\",[25,\"t\",[\"icons.invoice\"],null]]]],[7],[8],[1,[25,\"t\",[\"invoices.new.title\"],null],false]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[1,[18,\"batatas\"],false],[0,\"\\n\\n\"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"titleIcon\",\"title\",\"titleCount\",\"class\"],[\"shopping-cart\",[25,\"t\",[\"shoppingCarts.title\"],null],[20,[\"openShoppingCarts\",\"length\"]],\"module-sales\"]],{\"statements\":[[0,\"    \"],[1,[25,\"open-shopping-carts\",null,[[\"model\",\"openModal\"],[[20,[\"model\"]],\"openModal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/internments/internment/shopping-carts/index.hbs"
    }
  });

  _exports.default = _default;
});