define("crm/routes/organizations/organization/transfer-stock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service()
    /*
       beforeModel(transition) {
          let result = this._super(...arguments);
    
          if(this.get("can").cannot('create item-adjustment')) {
             transition.abort();
             this.send("noPermissions");
          }
    
          return result;
       },
       */

  });

  _exports.default = _default;
});