define("crm/templates/components/customer-select-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VWgFDvP2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"class\",\"id\",\"title\",\"modalSize\",\"hideFooter\",\"reset\"],[\"module-crm max-z-index-important\",\"modal-customer-select\",[25,\"t\",[\"actions.selectCustomer\"],null],\"lg\",true,\"reset\"]],{\"statements\":[[0,\"    \"],[6,\"section\"],[9,\"class\",\"modal-body\"],[7],[0,\"\\n        \"],[1,[25,\"t\",[\"actions.search\"],null],false],[0,\"\\n        \"],[1,[25,\"u-search-form\",null,[[\"search\",\"lockButton\",\"searchText\",\"oldString\"],[[25,\"action\",[[19,0,[]],\"searchCustomers\"],null],[20,[\"lockButton\"]],[20,[\"searchText\"]],[20,[\"oldString\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"customers\",\"length\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"displayDataChangedAction\",\"class\"],[[20,[\"customers\"]],[20,[\"columns\"]],[25,\"action\",[[19,0,[]],\"rowSelected\"],null],\"hide-search\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[6,\"hr\"],[7],[8],[0,\"\\n            \"],[6,\"i\"],[7],[1,[25,\"t\",[\"appointments.new.noDataToShow\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/customer-select-modal.hbs"
    }
  });

  _exports.default = _default;
});