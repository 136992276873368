define("crm/templates/components/input-pills-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3sbveZvf",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"each\",[[20,[\"options\"]]],null,{\"statements\":[[0,\"    \"],[6,\"a\"],[10,\"class\",[26,[\"badge badge-\",[25,\"if\",[[19,1,[\"active\"]],\"primary\",\"default\"],null]]]],[3,\"action\",[[19,0,[]],\"select\",[19,1,[\"code\"]]]],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/input-pills-multiple.hbs"
    }
  });

  _exports.default = _default;
});