define("crm/templates/components/pdf2-details-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hG/HQgFM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"modalSize\"],[\"modal-pdf2-details\",\"lg\"]],{\"statements\":[[4,\"if\",[[20,[\"steps\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"js-pdf\",[[20,[\"steps\"]]],[[\"filename\"],[[25,\"if\",[[20,[\"model\",\"name\"]],[20,[\"model\",\"name\"]],[25,\"moment\",[[20,[\"moment\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[11,1],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/pdf2-details-modal.hbs"
    }
  });

  _exports.default = _default;
});