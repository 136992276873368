define("crm/routes/organizations/organization/warehouses/warehouse/index", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    es: Ember.inject.service("session"),
    intl: Ember.inject.service("session"),
    session: Ember.inject.service("session"),
    queryParams: {
      date: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var model = this.modelFor("organizations.organization.warehouses.warehouse");
      model.set("date", params.date);
      var self = this;

      if (model.get("date")) {
        var dateMoment = (0, _moment.default)(model.get("date"));

        if (dateMoment.isValid()) {
          return Ember.$.ajax({
            url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/inventory/today?organization=" + model.get("organization.id") + "&date=" + dateMoment.format("YYYY-MM-DD") + "&warehouse=" + model.get("name"),
            accept: "application/json",
            beforeSend: function beforeSend(request) {
              request.setRequestHeader("Accept-Language", self.get("intl.locale"));
              request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            }
          }).then(function (result) {
            model.set("loaded", true);
            model.set("inventory", JSON.parse(result.data));
            return model;
          }, function (result) {
            self.set("lockButton", false);
            self.get("es").errorReturned(result);
          });
        }
      } else {
        return null;
      }
    }
  });

  _exports.default = _default;
});