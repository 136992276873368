define("crm/templates/components/customer-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AFD7sesl",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",[26,[\"col-xs-12 \",[25,\"if\",[[20,[\"showDecoration\"]],\"col-md-8\"],null]]]],[7],[0,\"\\n        \"],[1,[25,\"u-search-form\",null,[[\"search\",\"lockButton\",\"searchText\",\"oldString\"],[[25,\"action\",[[19,0,[]],\"searchCustomersSelect\"],null],[20,[\"lockButton\"]],[20,[\"searchText\"]],[20,[\"oldString\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"showDecoration\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-md-4 border-left\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"ibox-content text-center bg-gray\"],[7],[0,\"\\n                \"],[6,\"h1\"],[7],[1,[25,\"t\",[\"customers.customer.title\"],null],false],[8],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"m-b-sm\"],[7],[0,\"\\n                    \"],[6,\"img\"],[9,\"alt\",\"image\"],[9,\"class\",\"rounded-circle w-100\"],[9,\"src\",\"assets/img/customer.png\"],[7],[8],[0,\"\\n                \"],[8],[0,\"\\n\\n                \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n                    \"],[6,\"a\"],[9,\"class\",\"btn btn-xs btn-primary\"],[3,\"action\",[[19,0,[]],\"rowSelected\",null]],[7],[1,[25,\"t\",[\"actions.finalConsumer\"],null],false],[8],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"customers\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"displayDataChangedAction\"],[[20,[\"customers\"]],[20,[\"columns\"]],[25,\"action\",[[19,0,[]],\"rowSelected\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/customer-select.hbs"
    }
  });

  _exports.default = _default;
});