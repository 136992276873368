define("crm/templates/custom/editmodal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZZgDJ/NO",
    "block": "{\"symbols\":[],\"statements\":[[6,\"a\"],[10,\"title\",[25,\"t\",[\"crud.update\"],null],null],[3,\"action\",[[19,0,[]],\"sendAction\",\"editmodal\",[20,[\"record\"]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.pencil\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/custom/editmodal.hbs"
    }
  });

  _exports.default = _default;
});