define("crm/templates/components/procurement/purchase-entity-line-mark-done", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g9YVql3n",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"table-actions\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"actions text-center\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"record\",\"enabled\"]]],null,{\"statements\":[[0,\"      \"],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"customers.disabled\"],null]]]],[3,\"action\",[[19,0,[]],\"disableLine\",[20,[\"record\"]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.enable\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"customers.enabled\"],null]]]],[3,\"action\",[[19,0,[]],\"enableLine\",[20,[\"record\"]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.disable\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/procurement/purchase-entity-line-mark-done.hbs"
    }
  });

  _exports.default = _default;
});