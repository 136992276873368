define("crm/templates/companies/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mA1E++JQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"pages.companies\"],null],[25,\"t\",[\"icons.organization\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[9,\"id\",\"companies-index\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n      \"],[1,[25,\"u-models-table\",null,[[\"data\",\"columns\"],[[20,[\"model\"]],[20,[\"columns\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"setion\"],[7],[0,\"\\n\"],[4,\"u-alert-panel\",null,[[\"message\"],[[25,\"concat\",[[25,\"t\",[\"intl.noContent\"],null],[25,\"concat\",[\" \",[25,\"t\",[\"models.company.entityPlural\"],null]],null]],null]]],{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"pull-right\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"companies.new\"],[[\"title\",\"class\"],[[25,\"t\",[\"crud.create\"],null],\"alert-warning\"]],{\"statements\":[[0,\"            \"],[6,\"i\"],[10,\"title\",[26,[[25,\"t\",[\"crud.create\",\"models.company.entity\"],null]]]],[9,\"class\",\"fa fa-plus-square\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/companies/index.hbs"
    }
  });

  _exports.default = _default;
});