define("crm/templates/components/show-appointment-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yfE8/jH9",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[10,\"title\",[18,\"title\"],null],[7],[6,\"i\"],[10,\"class\",[26,[\"fa \",[18,\"icon\"]]]],[7],[8],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-appointment-status.hbs"
    }
  });

  _exports.default = _default;
});