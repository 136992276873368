define("crm/templates/components/compound-product-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PyU/YbWF",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openEditItemModal\",\"openDetails\",\"openMoveStockModal\",\"disable\",\"enable\"],[[20,[\"model\"]],[20,[\"columns\"]],\"openEditItemModal\",\"openDetails\",\"openMoveStockModal\",\"disable\",\"enable\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/compound-product-list.hbs"
    }
  });

  _exports.default = _default;
});