define("crm/templates/components/authorizations-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NEIpnHs9",
    "block": "{\"symbols\":[\"auth\"],\"statements\":[[4,\"each\",[[20,[\"record\",\"authorizations\"]]],null,{\"statements\":[[0,\" \"],[1,[19,1,[\"authorizationType\",\"name\"]],false],[0,\" \"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/authorizations-display.hbs"
    }
  });

  _exports.default = _default;
});