define("crm/templates/components/absence-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LvQc9/3N",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"class\",\"title\",\"hideFooter\",\"modalSize\"],[\"modal-absence-details\",\"module-hr iboxes\",[25,\"t\",[\"absences.title\"],null],true,\"md\"]],{\"statements\":[[0,\"    \"],[1,[25,\"absence-ibox\",null,[[\"model\"],[[20,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/absence-details.hbs"
    }
  });

  _exports.default = _default;
});