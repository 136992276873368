define("crm/templates/organizations/organization/internments/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g8mNzI63",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"class\",\"title\",\"titleIcon\",\"titleCount\"],[\"module-internments\",[25,\"t\",[\"pages.internments\"],null],[25,\"t\",[\"icons.internment\"],null],[20,[\"model\",\"length\"]]]],{\"statements\":[[4,\"if\",[[25,\"can\",[\"create internment\"],null]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"internments.new.title\"],null]]]],[3,\"action\",[[19,0,[]],\"openInternmentCreate\"]],[7],[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.internment\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"internments.new.title\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ibox-content m-b-lg\"],[7],[0,\"\\n    \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"actions.filters\"],null],false],[8],[0,\"\\n\"],[0,\"    \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",\"2000-01-01\",[20,[\"startedBefore\"]],[20,[\"startedAfter\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",[20,[\"startedAfter\"]],\"2100-12-31\",[20,[\"startedBefore\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n\"],[0,\"  \"],[8],[0,\"\\n\\n\"],[4,\"ibox-panel\",null,[[\"class\"],[\"module-internments\"]],{\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"callModal\"],[[20,[\"model\"]],[20,[\"columns\"]],\"callModal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.internments\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/internments/index.hbs"
    }
  });

  _exports.default = _default;
});