define("crm/templates/components/current-account-list-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eNIz5/Z3",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"table-actions\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"hidle text-center\"],[7],[0,\"\\n        \"],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-ellipsis-h disabled\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[9,\"class\",\"actions text-center\"],[7],[0,\"\\n        \"],[2,\" details \"],[0,\"\\n        \"],[4,\"link-to\",[\"organizations.organization.customers.customer.open-items\",[20,[\"record\",\"customer\",\"id\"]]],[[\"title\"],[[25,\"t\",[\"actions.details\"],null]]],{\"statements\":[[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.detail\"],null]]]],[7],[8]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"canDoCurrentAccounts\"]]],null,{\"statements\":[[0,\"        \"],[2,\" update \"],[0,\"\\n        \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"actions.editProfile\"],null],null],[3,\"action\",[[19,0,[]],\"openEditDebt\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.pencilSquare\"],null]]]],[7],[8],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"allowReceipt\"]]],null,{\"statements\":[[0,\"            \"],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"configurations.configItems.generateReceipt\"],null]]]],[3,\"action\",[[19,0,[]],\"openReceiptGenerate\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.generateReceipt\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"allowPayment\"]]],null,{\"statements\":[[0,\"            \"],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"configurations.configItems.generatePayment\"],null]]]],[3,\"action\",[[19,0,[]],\"openPaymentGenerate\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.generatePayment\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/current-account-list-actions.hbs"
    }
  });

  _exports.default = _default;
});