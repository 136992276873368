define("crm/routes/organizations/organization/warehouses/warehouse/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var warehouseID = this.modelFor("organizations.organization.warehouses.warehouse").get('id');
      return this.get('store').findRecord('warehouse', warehouseID, {
        reload: true
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.get("controller.model").rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});