define("crm/routes/organizations/organization/warehouses/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      return Ember.RSVP.hash({
        organization: organization,
        warehouses: this.store.query('warehouse', {
          organization: organization.get("id")
        })
      }).then(function (hashResult) {
        var warehouses = hashResult.warehouses;

        if (self.get("can").cannot("do god") && warehouses && warehouses.get("length") == 1) {
          self.transitionTo('organizations.organization.warehouses.warehouse', warehouses.get("firstObject.id"));
        }

        Ember.set(warehouses, "organization", hashResult.organization);
        return warehouses;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (model) {
        controller.set('warehouses', model);
      }
    },
    actions: {
      openCreateWarehouse: function openCreateWarehouse() {
        this.send('openModalCreateWarehouse');
      },
      openEditWarehouse: function openEditWarehouse(warehouseToEdit) {
        this.send('openEditWarehouseModal', warehouseToEdit);
      }
    }
  });

  _exports.default = _default;
});