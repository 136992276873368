define("crm/templates/components/purchase-order-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9/DqmWaY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"class\",\"titleIcon\",\"title\",\"hideFooter\",\"modalSize\"],[\"modal-purchase-order-details\",\"module-procurement iboxes\",[25,\"t\",[\"icons.purchaseOrders\"],null],[20,[\"model\",\"name\"]],true,\"lg\"]],{\"statements\":[[0,\"    \"],[1,[25,\"purchase-order-ibox\",null,[[\"model\"],[[20,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/purchase-order-details.hbs"
    }
  });

  _exports.default = _default;
});