define("crm/models/subject", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    invoices: _emberData.default.hasMany('invoice')
    /*orders:         DS.hasMany('order', {inverse: 'subject'}),
     currentOrder: Ember.computed('id', 'orders.@each.name', function() {
        if(this.get('orders.length')) {
            return this.get('orders').objectAt(this.get('orders.length') - 1);
        }
    })*/

  });

  _exports.default = _default;
});