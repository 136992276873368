define("crm/templates/organizations/organization/quotations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eRmUdOBW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"class\",\"title\",\"titleIcon\"],[\"module-sales\",[25,\"t\",[\"quotations.title\"],null],[25,\"t\",[\"icons.documents\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"class\"],[\"module-sales\"]],{\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"transitionToRoute\"],[[20,[\"model\"]],[20,[\"columns\"]],\"transitionToRoute\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.charge\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/quotations/index.hbs"
    }
  });

  _exports.default = _default;
});