define("crm/templates/components/shift-ibox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VWYTMV7+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ibox-panel\",null,[[\"title\",\"contentHeading\",\"hideCollapse\",\"hideTools\"],[[20,[\"model\",\"name\"]],[20,[\"model\",\"name\"]],[20,[\"hideCollapse\"]],[20,[\"hideTools\"]]]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"list-group\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"startDate\"]]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"list-group-item\"],[7],[6,\"strong\"],[7],[1,[25,\"t\",[\"shifts.startDate\"],null],false],[8],[0,\": \"],[1,[25,\"pu-chronos-date-time\",[[20,[\"model\",\"startDate\"]]],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"endDate\"]]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"list-group-item\"],[7],[6,\"strong\"],[7],[1,[25,\"t\",[\"shifts.endDate\"],null],false],[8],[0,\": \"],[1,[25,\"pu-chronos-date-time\",[[20,[\"model\",\"endDate\"]]],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"worker\"]]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"list-group-item\"],[7],[6,\"strong\"],[7],[1,[25,\"t\",[\"shifts.worker\"],null],false],[8],[0,\": \"],[1,[20,[\"model\",\"worker\",\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"room\"]]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"list-group-item\"],[7],[6,\"strong\"],[7],[1,[25,\"t\",[\"rooms.room.title\"],null],false],[8],[0,\": \"],[1,[20,[\"model\",\"room\",\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"notes\"]]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"list-group-item\"],[7],[6,\"strong\"],[7],[1,[25,\"t\",[\"shifts.notes\"],null],false],[8],[0,\": \"],[1,[20,[\"model\",\"notes\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/shift-ibox.hbs"
    }
  });

  _exports.default = _default;
});