define("crm/templates/components/periodic-lines-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yxMmb2fj",
    "block": "{\"symbols\":[\"line\",\"type\"],\"statements\":[[6,\"table\"],[9,\"class\",\"table table-striped\"],[7],[0,\"\\n  \"],[6,\"thead\"],[7],[0,\"\\n    \"],[6,\"tr\"],[7],[0,\"\\n      \"],[6,\"th\"],[7],[1,[25,\"t\",[\"items.item.name\"],null],false],[8],[0,\"\\n      \"],[6,\"th\"],[9,\"class\",\"column-text-2\"],[7],[1,[25,\"t\",[\"periodics.periodic.nextPeriodic\"],null],false],[8],[0,\"\\n      \"],[6,\"th\"],[9,\"class\",\"column-status\"],[7],[0,\" \"],[8],[0,\"\\n      \"],[6,\"th\"],[9,\"class\",\"column-actions-1\"],[7],[0,\" \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"tbody\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"lines\"]]],null,{\"statements\":[[0,\"      \"],[6,\"tr\"],[7],[0,\"\\n        \"],[6,\"td\"],[10,\"title\",[19,1,[\"name\"]],null],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n        \"],[6,\"td\"],[10,\"title\",[19,1,[\"period\"]],null],[7],[1,[25,\"input\",null,[[\"value\",\"class\"],[[19,1,[\"period\"]],\"form-control\"]]],false],[8],[0,\"\\n        \"],[6,\"td\"],[7],[0,\"\\n\"],[4,\"power-select\",null,[[\"triggerClass\",\"searchField\",\"allowClear\",\"options\",\"selected\",\"onchange\"],[\"form-control\",\"name\",true,[20,[\"periodTypes\"]],[19,1,[\"selectedPeriod\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[19,1,[\"selectedPeriod\"]]],null]],null]]],{\"statements\":[[0,\"            \"],[1,[19,2,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[8],[0,\"\\n        \"],[6,\"td\"],[7],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"actions.delete\"],null]]]],[3,\"action\",[[19,0,[]],\"delete\",[19,1,[]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.delete\"],null]]]],[7],[8],[8],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/periodic-lines-list.hbs"
    }
  });

  _exports.default = _default;
});