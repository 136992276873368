define("crm/templates/components/comparable-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q9rQSEsn",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[7],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-md-4 col-md-offset-4\"],[7],[0,\"\\n      \"],[1,[25,\"ember-chart\",null,[[\"type\",\"options\",\"data\",\"height\"],[\"line\",[20,[\"chartOptions\"]],[20,[\"chartData\"]],150]]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-6\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[4,\"if\",[[20,[\"model1\"]]],null,{\"statements\":[[0,\"          \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"values1\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.calendar\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-6\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[4,\"if\",[[20,[\"model2\"]]],null,{\"statements\":[[0,\"          \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"values2\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.calendar\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/comparable-list.hbs"
    }
  });

  _exports.default = _default;
});