define("crm/templates/components/patient-visit-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qxZgE+Pe",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"table-actions\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"hidle text-center\"],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-ellipsis-h disabled\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"actions\"],[7],[0,\"\\n    \"],[2,\" See consultation \"],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-3 col-md-3 col-sm-3 col-lg-3\"],[7],[0,\"\\n      \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"actions.openConsultation\"],null],null],[3,\"action\",[[19,0,[]],\"openConsultation\",[20,[\"record\"]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.detail\"],null]]]],[7],[8],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n    \"],[2,\" Go to consultation \"],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-3 col-md-3 col-sm-3 col-lg-3\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"organizations.organization.consultations.consultation\",[20,[\"record\",\"id\"]]],[[\"title\"],[[25,\"t\",[\"actions.viewProfile\"],null]]],{\"statements\":[[0,\"        \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.goTo\"],null]]]],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\\n      \"],[6,\"div\"],[9,\"class\",\"col-xs-3 col-md-3 col-sm-3 col-lg-3\"],[7],[0,\"\\n          \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"configurations.configItems.generatePDF\"],null],null],[3,\"action\",[[19,0,[]],\"document\",[20,[\"record\"]]]],[7],[6,\"i\"],[9,\"class\",\"fa fa-fw fa-lg fa-file-pdf-o\"],[7],[8],[8],[0,\"\\n      \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/patient-visit-actions.hbs"
    }
  });

  _exports.default = _default;
});