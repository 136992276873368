define("crm/templates/organizations/organization/open-purchase-invoices/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zUIYvZkF",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"u-second-nav\",null,[[\"class\",\"title\",\"titleIcon\"],[\"module-procurement\",[25,\"t\",[\"purchaseInvoices.title\"],null],[25,\"t\",[\"icons.purchaseInvoice\"],null]]]],false],[0,\"\\n\\n\"],[6,\"div\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n    \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"actions.filters\"],null],false],[8],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",[20,[\"minCreatedAfter\"]],[20,[\"createdBefore\"]],[20,[\"dueDate\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"ibox-content m-t-lg\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"model\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.purchases\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/open-purchase-invoices/index.hbs"
    }
  });

  _exports.default = _default;
});