define("crm/templates/organizations/organization/listings/crm/customers/all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PAPwFUNE",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"customers.title\"],null],[25,\"t\",[\"icons.customer\"],null]]]],false],[0,\"\\n\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"scroll-x\"],[7],[0,\"\\n      \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"model\"]],[20,[\"columns\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.customers\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/listings/crm/customers/all.hbs"
    }
  });

  _exports.default = _default;
});