define("crm/templates/components/stay-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1aiKAL3H",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"class\",\"titleIcon\",\"title\",\"hideFooter\",\"modalSize\"],[\"modal-stay-details\",\"module-hotel\",[25,\"t\",[\"icons.stays\"],null],[20,[\"model\",\"box\",\"name\"]],true,\"sm\"]],{\"statements\":[[0,\"    \"],[1,[25,\"stay-ibox\",null,[[\"model\"],[[20,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/stay-details.hbs"
    }
  });

  _exports.default = _default;
});