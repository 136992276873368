define("crm/templates/organizations/organization/patients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FDqgFcjo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"model\",\"crmFeature\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"module-crm\"],[7],[0,\"\\n        \"],[1,[18,\"outlet\"],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"unavailable-feature\",null,[[\"module\",\"feature\"],[\"module-crm\",\"crm\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/patients.hbs"
    }
  });

  _exports.default = _default;
});