define("crm/services/terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    procedure: Ember.computed("intl", function () {
      return [{
        type: {
          type: 0,
          name: "Procedimento Clínico"
        }
      }, {
        type: {
          type: 2,
          name: "Data do Procedimento"
        }
      }, {
        type: {
          type: 4,
          name: "Quantia"
        }
      }];
    })
  });

  _exports.default = _default;
});