define("crm/templates/components/show-appointment-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BiTdbdsG",
    "block": "{\"symbols\":[],\"statements\":[[6,\"a\"],[9,\"class\",\"btn btn-default\"],[3,\"action\",[[19,0,[]],\"sendAction\",\"createAppointment\",[20,[\"record\"]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.check\"],null]]]],[7],[8],[0,\" \"],[2,\"TODO translation \"],[8],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-appointment-actions.hbs"
    }
  });

  _exports.default = _default;
});