define("crm/templates/components/crm/patient-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sPQNG4NP",
    "block": "{\"symbols\":[],\"statements\":[[6,\"article\"],[9,\"class\",\"col-xs-12 col-md-6\"],[7],[0,\"\\n  \"],[1,[25,\"patient-card\",null,[[\"model\",\"patientWeights\",\"disableLink\"],[[20,[\"model\",\"patient\"]],[20,[\"patientWeights\"]],true]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"article\"],[9,\"class\",\"col-xs-12 col-md-6\"],[7],[0,\"\\n  \"],[1,[25,\"customer-card\",null,[[\"model\",\"disableLink\"],[[25,\"if\",[[20,[\"model\",\"customer\"]],[20,[\"model\",\"customer\"]],[20,[\"model\",\"patient\",\"owner\"]]],null],true]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/crm/patient-cards.hbs"
    }
  });

  _exports.default = _default;
});