define("crm/templates/components/customer-workers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rvWooxbe",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"sortedData\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"sortedData\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"empty-set\",null,[[\"icon\"],[\"hourglass\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/customer-workers.hbs"
    }
  });

  _exports.default = _default;
});