define("crm/templates/components/purchase-invoice-lines-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PGSQPzn6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ibox-panel\",null,[[\"titleIcon\",\"title\",\"class\",\"collapsed\"],[[25,\"t\",[\"icons.purchases\"],null],[25,\"t\",[\"pages.purchases\"],null],\"module-sales\",true]],{\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"reloadModel\",\"openModal\"],[[20,[\"model\"]],[20,[\"columns\"]],\"reloadModel\",\"openModal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.purchases\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/purchase-invoice-lines-list.hbs"
    }
  });

  _exports.default = _default;
});