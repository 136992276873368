define("crm/templates/components/supplier-item-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2L2I4C5A",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"model\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.supplierItems\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/supplier-item-list.hbs"
    }
  });

  _exports.default = _default;
});