define("crm/templates/questionnaire-reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VlsZ8oAl",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"container m-b-md\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-md-6 col-md-offset-3 text-center\"],[7],[0,\"\\n      \"],[6,\"img\"],[10,\"src\",[20,[\"avatar\",\"base64\"]],null],[9,\"class\",\"img-responsive\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"h1\"],[9,\"class\",\"text-center\"],[7],[1,[20,[\"model\",\"invite\",\"invoice\",\"organization\",\"name\"]],false],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"jumbotron text-center\"],[7],[0,\"\\n      \"],[6,\"h1\"],[7],[0,\"A sua opinião é muito importante!\"],[8],[0,\"\\n      \"],[6,\"p\"],[7],[0,\"Olá \"],[1,[25,\"pu-str-two-words\",[[20,[\"model\",\"invite\",\"customerName\"]]],null],false],[0,\", o que nos pode dizer sobre o nosso serviço?\"],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n\"],[4,\"ibox-panel\",null,[[\"title\",\"hideTools\",\"hideFooter\"],[\"Questionário\",true,true]],{\"statements\":[[0,\"      \"],[1,[25,\"custom-form\",null,[[\"model\",\"save\",\"actionText\",\"disabledAction\",\"canceled\"],[[20,[\"answers\"]],\"create\",\"crud.create\",[20,[\"disableBtn\"]],\"cancel\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"jumbotron text-center\"],[7],[0,\"\\n      \"],[6,\"h1\"],[7],[0,\"Obrigado!\"],[8],[0,\"\\n      \"],[6,\"p\"],[7],[0,\"Este convite já não está disponível.\"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/questionnaire-reply.hbs"
    }
  });

  _exports.default = _default;
});