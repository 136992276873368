define("crm/templates/components/prescription-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z1qeKBAo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"record\",\"prescription\"]]],null,{\"statements\":[[0,\"    \"],[6,\"i\"],[9,\"class\",\"fa fa-comment-o\"],[10,\"title\",[20,[\"record\",\"prescription\"]],null],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"     \\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/prescription-display.hbs"
    }
  });

  _exports.default = _default;
});