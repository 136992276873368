define("crm/templates/components/item-unit-edit-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VXnRbidS",
    "block": "{\"symbols\":[\"unit\"],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"class\",\"title\",\"hideFooter\"],[\"modal-item-unit-edit\",\"module-sales\",[25,\"t\",[\"items.edit.title\"],null],true]],{\"statements\":[[4,\"custom-form\",null,[[\"fieldTypes\",\"save\",\"actionText\",\"model\",\"reset\",\"disabledAction\",\"hideIcon\",\"canceled\"],[[20,[\"fieldTypes\"]],\"modalEditItemUnit\",\"crud.update\",[20,[\"model\"]],\"reset\",[20,[\"disabledAction\"]],[20,[\"hideIcon\"]],\"cancelEditItemUnitModal\"]],{\"statements\":[[0,\"        \"],[6,\"fieldset\"],[9,\"class\",\"row\"],[7],[0,\"\\n            \"],[2,\" Base Unit \"],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n                \"],[6,\"label\"],[9,\"class\",\"col-lg-4 control-label\"],[7],[1,[25,\"t\",[\"units.form.placeholder.baseUnit\"],null],false],[8],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"col-lg-8\"],[7],[0,\"\\n\"],[4,\"power-select\",null,[[\"searchField\",\"allowClear\",\"options\",\"selected\",\"onchange\"],[\"name\",true,[20,[\"units\"]],[20,[\"model\",\"item\",\"baseUnit\"]],[25,\"action\",[[19,0,[]],\"setBaseUnit\"],null]]],{\"statements\":[[0,\"                        \"],[1,[19,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/item-unit-edit-modal.hbs"
    }
  });

  _exports.default = _default;
});