define("crm/templates/components/show-table-created-date-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0VkO8jkP",
    "block": "{\"symbols\":[],\"statements\":[[6,\"label\"],[9,\"class\",\"control-label\"],[7],[1,[25,\"moment-format\",[[20,[\"record\",\"created\"]],\"DD-MM-YYYY\"],null],false],[8],[0,\"\\n\"],[6,\"small\"],[7],[1,[25,\"moment-format\",[[20,[\"record\",\"created\"]],\"HH:mm\"],null],false],[8],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-table-created-date-time.hbs"
    }
  });

  _exports.default = _default;
});