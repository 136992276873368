define("crm/templates/components/dashboard-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aL3CYDFv",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ibox\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ibox-title\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"label\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"ibox-tools\"],[7],[0,\"\\n        \"],[6,\"span\"],[10,\"class\",[26,[\" pull-right label label-\",[18,\"text\"]]]],[7],[1,[18,\"label\"],false],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[6,\"h5\"],[9,\"class\",\"ellipsis\"],[7],[1,[18,\"title\"],false],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n    \"],[6,\"h1\"],[9,\"class\",\"no-margins\"],[7],[1,[25,\"price-display\",null,[[\"price\"],[[20,[\"priceListed\"]]]]],false],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"percentage\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[10,\"class\",[26,[\"stat-percent font-bold text-\",[18,\"text\"]]]],[10,\"title\",[18,\"oldValue\"],null],[7],[1,[18,\"percentage\"],false],[0,\"% \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-level-\",[18,\"arrow\"]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[6,\"small\"],[7],[1,[25,\"if\",[[20,[\"description\"]],[20,[\"description\"]]],null],false],[0,\" \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/dashboard-box.hbs"
    }
  });

  _exports.default = _default;
});