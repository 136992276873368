define("crm/templates/components/stats-sales-item-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9W65AVcW",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[0,\"\\n    \"],[6,\"small\"],[7],[0,\"x\"],[1,[20,[\"model\",\"quantity\"]],false],[8],[0,\" \"],[6,\"b\"],[7],[1,[25,\"price-display\",null,[[\"price\"],[[20,[\"model\",\"price\"]]]]],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"span\"],[10,\"title\",[20,[\"model\",\"name\"]],null],[7],[1,[20,[\"model\",\"name\"]],false],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/stats-sales-item-line.hbs"
    }
  });

  _exports.default = _default;
});