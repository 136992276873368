define("crm/templates/organizations/organization/manufacturers/manufacturer/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nERVBz3A",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"pages.manufacturers\"],null],[25,\"t\",[\"icons.manufacturers\"],null]]]],false],[0,\"\\n\\n\"],[4,\"ibox-panel\",null,[[\"title\",\"hideTools\"],[[25,\"t\",[\"crud.update\"],null],true]],{\"statements\":[[4,\"custom-form\",null,[[\"fieldTypes\",\"save\",\"actionText\",\"canceled\",\"reset\",\"disabledAction\"],[[20,[\"fieldTypes\"]],\"createManufacturer\",\"crud.create\",\"canceled\",\"reset\",[20,[\"disabledSubmission\"]]]],{\"statements\":[[0,\"    \"],[6,\"fieldset\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n        \"],[6,\"label\"],[9,\"class\",\"col-xs-12 col-sm-3 control-label\"],[7],[1,[25,\"t\",[\"boxes.name\"],null],false],[0,\" \"],[6,\"span\"],[10,\"title\",[25,\"t\",[\"validations.fieldRequired\"],null],null],[9,\"class\",\"text-danger\"],[7],[0,\" *\"],[8],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-9\"],[7],[0,\"\\n          \"],[1,[25,\"input\",null,[[\"value\",\"class\",\"required\"],[[20,[\"model\",\"name\"]],\"form-control\",true]]],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/manufacturers/manufacturer/edit.hbs"
    }
  });

  _exports.default = _default;
});