define("crm/templates/components/activities/card-exam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0pI934Ud",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"vertical-timeline-icon bg-clinical\"],[7],[0,\" \"],[0,\"\\n    \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[25,\"t\",[\"icons.exam\"],null]]]],[7],[8],[0,\" \"],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"vertical-timeline-content\"],[7],[0,\"\\n\\n\"],[0,\"    \"],[6,\"h4\"],[7],[0,\"\\n      \"],[6,\"small\"],[9,\"class\",\"pull-right\"],[7],[1,[20,[\"model\",\"creator\",\"name\"]],false],[8],[0,\"\\n\\n      \"],[1,[20,[\"model\",\"name\"]],false],[0,\"\\n\\n      \"],[6,\"pre\"],[9,\"class\",\"pull-left\"],[7],[1,[25,\"pu-chronos-date\",[[20,[\"model\",\"doneDate\"]]],null],false],[0,\" \"],[6,\"small\"],[9,\"class\",\"text-inherit\"],[7],[1,[25,\"pu-chronos-time\",[[20,[\"model\",\"doneDate\"]]],null],false],[8],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/activities/card-exam.hbs"
    }
  });

  _exports.default = _default;
});