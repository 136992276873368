define("crm/templates/components/organization-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nzdTxj0E",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"class\",\"hideFooter\",\"reset\"],[\"modal-organization-edit\",[25,\"t\",[\"configurations.organization.edit\"],null],\"module-system\",true,\"reset\"]],{\"statements\":[[4,\"custom-form\",null,[[\"fieldTypes\",\"save\",\"actionText\",\"canceled\",\"reset\",\"disabledAction\"],[[20,[\"fieldTypes\"]],\"editOrgName\",\"actions.edit\",\"canceled\",\"reset\",[20,[\"disabledSubmission\"]]]],{\"statements\":[[0,\"        \"],[6,\"fieldset\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n                \"],[6,\"label\"],[9,\"class\",\"col-xs-12 col-sm-3 control-label\"],[7],[1,[25,\"t\",[\"configurations.organization.name\"],null],false],[0,\" \"],[6,\"span\"],[10,\"title\",[25,\"t\",[\"validations.fieldRequired\"],null],null],[9,\"class\",\"text-danger\"],[7],[0,\" *\"],[8],[8],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-9\"],[7],[0,\"\\n                    \"],[1,[25,\"input\",null,[[\"class\",\"maxlength\",\"value\",\"required\"],[\"form-control\",\"255\",[20,[\"model\",\"name\"]],true]]],false],[0,\"\\n                    \"],[6,\"p\"],[9,\"class\",\"help-block\"],[7],[1,[25,\"characters-number\",null,[[\"max\",\"text\"],[255,[20,[\"model\",\"name\"]]]]],false],[8],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/organization-edit.hbs"
    }
  });

  _exports.default = _default;
});