define("crm/routes/organizations/organization/financial-accounts/financial-account/financial-account-opens/financial-account-open", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    afterModel: function afterModel(model) {
      var _this = this;

      this.set("breadCrumb", {
        finalTitle: (0, _moment.default)(model.get("created")).format("YYYY-MM-DD")
      });
      var financialAccountID = model.get("financialAccount").get("id");
      var createdAfter = (0, _moment.default)(model.get("created")).format();
      this.store.query("financial-account-close", {
        financialAccount: financialAccountID,
        createdAfter: createdAfter,
        latest: true
      }).then(function (close) {
        var createdBefore = (0, _moment.default)(close.get("created")).format();
        Ember.RSVP.hash({
          invoices: _this.get("store").query("invoice", {
            status: null,
            financialAccount: financialAccountID,
            createdAfter: createdAfter,
            createdBefore: createdBefore
          }),
          receipts: _this.get("store").query("receipt", {
            status: null,
            financialAccount: financialAccountID,
            createdAfter: createdAfter,
            createdBefore: createdBefore
          }),
          purchaseInvoices: _this.get("store").query("purchase-invoice", {
            status: null,
            financialAccount: financialAccountID,
            createdAfter: createdAfter,
            createdBefore: createdBefore
          }),
          payments: _this.get("store").query("payment", {
            status: null,
            financialAccount: financialAccountID,
            createdAfter: createdAfter,
            createdBefore: createdBefore
          })
        }).then(function (r) {
          var documents = Ember.A();
          r.invoices && r.invoices.filter(function (i) {
            return i.get("documentType") !== "FA" && !i.get("status");
          }).forEach(function (d) {
            return documents.addObject(d);
          });
          r.purchaseInvoices && r.purchaseInvoices.forEach(function (d) {
            return documents.addObject(d);
          });
          r.receipts && r.receipts.forEach(function (d) {
            return documents.addObject(d);
          });
          r.payment && r.payment.forEach(function (d) {
            return documents.addObject(d);
          });
          model.set("documents", documents);
        });
      });
    }
  });

  _exports.default = _default;
});