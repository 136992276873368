define("crm/templates/organizations/organization/warehouses/warehouse/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9j9HU6pV",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[20,[\"model\",\"internalDescription\"]],[25,\"t\",[\"icons.warehouses\"],null]]],{\"statements\":[[4,\"if\",[[25,\"can\",[\"edit warehouse\"],null]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[4,\"link-to\",[\"organizations.organization.warehouses.warehouse.edit\",[20,[\"model\",\"id\"]]],[[\"title\"],[[25,\"t\",[\"warehouses.edit.title\"],null]]],{\"statements\":[[0,\" \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.edit\"],null]]]],[7],[8],[0,\" \"],[1,[25,\"t\",[\"actions.edit\"],null],false],[0,\" \"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ibox-content m-b-lg\"],[7],[0,\"\\n  \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"actions.filters\"],null],false],[8],[0,\"\\n  \"],[1,[25,\"input\",null,[[\"type\",\"value\"],[\"date\",[20,[\"date\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"ibox-content module-sales\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"loaded\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"model\",\"inventory\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"hideComponentFooter\",\"singlePage\"],[[20,[\"model\",\"inventory\"]],[20,[\"columns\"]],true,true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[25,\"empty-set\",null,[[\"icon\"],[\"cube\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"p\"],[9,\"class\",\"tex-center\"],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"fa fa-pulse fa-spin fa-3x\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/warehouses/warehouse/index.hbs"
    }
  });

  _exports.default = _default;
});