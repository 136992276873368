define("crm/templates/components/open-shopping-carts-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Ox+9YeR",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"table-actions\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"hidle text-center\"],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-ellipsis-h disabled\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"actions text-center\"],[7],[0,\"\\n    \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"shoppingCarts.details\"],null],null],[3,\"action\",[[19,0,[]],\"openDetails\",[20,[\"record\"]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.detail\"],null]]]],[7],[8],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"canDoCustomer\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"organization\",\"salesFeature\"]]],null,{\"statements\":[[4,\"unless\",[[20,[\"cannotClose\"]]],null,{\"statements\":[[0,\"          \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"shoppingCarts.checkPaid\"],null],null],[3,\"action\",[[19,0,[]],\"openCloseShoppingCart\",[20,[\"record\"]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.salesMenu\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"isPayable\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"customerisEnabled\"]]],null,{\"statements\":[[0,\"          \"],[4,\"link-to\",[\"organizations.organization.pointOfSales\",[20,[\"queryParams\"]]],null,{\"statements\":[[0,\" \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"configurations.configItems.goToPos\"],null],null],[7],[0,\" \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-fw fa-\",[25,\"t\",[\"icons.goTo\"],null]]]],[7],[8],[8],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/open-shopping-carts-actions.hbs"
    }
  });

  _exports.default = _default;
});