define("crm/templates/components/invoices-list-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uHtyFBwH",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"table-actions\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"hidle text-center\"],[7],[0,\"\\n        \"],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-ellipsis-h disabled\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[9,\"class\",\"actions\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-6 col-sm-4\"],[7],[0,\"\\n\\n        \"],[8],[0,\"\\n\\n        \"],[6,\"div\"],[9,\"class\",\"col-x-6 col-sm-4\"],[7],[0,\"\\n            \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"configurations.configItems.generatePDF\"],null],null],[3,\"action\",[[19,0,[]],\"showInvoicePDF\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.detail\"],null]]]],[7],[8],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/invoices-list-actions.hbs"
    }
  });

  _exports.default = _default;
});