define("crm/templates/organizations/organization/financial-accounts/financial-account/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6I8Z+FTL",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"well\"],[7],[0,\"\\n  \"],[1,[25,\"date-filters\",null,[[\"startMin\",\"nextDateStart\",\"endMax\",\"nextDateEnd\",\"search\",\"module\",\"class\"],[[25,\"moment-format\",[[25,\"moment-subtract\",[[25,\"moment\",null,null],7,\"d\"],null],\"YYYY-MM-DD\"],null],[25,\"readonly\",[[20,[\"createdAfter\"]]],null],[25,\"moment-format\",[[25,\"moment\",null,null],\"YYYY-MM-DD\"],null],[25,\"readonly\",[[20,[\"createdBefore\"]]],null],\"search\",\"module-sales\",\"no-margin\"]]],false],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[1,[25,\"documents-totals\",null,[[\"model\"],[[20,[\"model\",\"documents\"]]]]],false],[0,\"\\n\\n\"],[1,[25,\"documents-ibox\",null,[[\"model\",\"hideActions\"],[[20,[\"model\",\"documents\"]],true]]],false],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/financial-accounts/financial-account/archive.hbs"
    }
  });

  _exports.default = _default;
});