define("crm/templates/components/payment-method-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HsJGVB/J",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"input-pills\",null,[[\"values\",\"selected\",\"onchange\"],[[20,[\"values\"]],[25,\"readonly\",[[20,[\"selected\"]]],null],[25,\"action\",[[19,0,[]],[20,[\"onchange\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/payment-method-select.hbs"
    }
  });

  _exports.default = _default;
});