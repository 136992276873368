define("crm/templates/organizations/organization/tasks/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K2BFiN7A",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"tasks.archive.title\"],null],[25,\"t\",[\"icons.task\"],null]]],{\"statements\":[[4,\"if\",[[20,[\"canDoTasks\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"openCreate\"]],[7],[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.task\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"tasks.new.title\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ibox-content m-b-lg\"],[7],[0,\"\\n    \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"actions.filters\"],null],false],[8],[0,\"\\n    \"],[1,[25,\"date-filters\",null,[[\"nextDateStart\",\"nextDateEnd\",\"search\",\"module\",\"class\"],[[25,\"readonly\",[[20,[\"startedAfter\"]]],null],[25,\"readonly\",[[20,[\"startedBefore\"]]],null],\"search\",\"module-calendar\",\"no-margin\"]]],false],[0,\"\\n\\n  \"],[8],[0,\"\\n\\n\\n\"],[4,\"ibox-panel\",null,[[\"title\",\"titleIcon\",\"titleCount\"],[[25,\"t\",[\"pages.tasks\"],null],[25,\"t\",[\"icons.task\"],null],[20,[\"filteredModels\",\"length\"]]]],{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"class\",\"data\",\"columns\",\"singlePage\",\"callModal\",\"updateModel\",\"openDone\",\"openCommentCreate\"],[\"table-fixed\",[20,[\"filteredModel\"]],[20,[\"columns\"]],true,[25,\"action\",[[19,0,[]],\"callModal\"],null],\"updateModel\",\"openDone\",\"openCommentCreate\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/tasks/archive.hbs"
    }
  });

  _exports.default = _default;
});