define("crm/templates/components/appointments-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SZ25Bkc3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[20,[\"showAll\"]]]]],false],[0,\" \"],[1,[25,\"t\",[\"exams.all\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"multipleColumnsSorting\",\"multipleSelect\",\"filteringIgnoreCase\",\"selectRowOnClick\",\"showColumnsDropdown\",\"useFilteringByColumns\",\"columnsAreUpdateable\",\"callModal\"],[[20,[\"appointmentsList\"]],[20,[\"columns\"]],false,false,true,true,false,[20,[\"useFilteringByColumns\"]],true,\"callModal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.calendar\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/appointments-list.hbs"
    }
  });

  _exports.default = _default;
});