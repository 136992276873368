define("crm/templates/components/open-shopping-carts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jr0ia7IA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"model\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[20,[\"showPay\"]]]]],false],[0,\" \"],[1,[25,\"t\",[\"shoppingCarts.showPaidCarts\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"filteredShopping\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"organization\",\"openDetails\",\"openCloseShoppingCart\"],[[20,[\"filteredShopping\"]],[20,[\"columns\"]],[20,[\"organization\"]],\"openDetails\",\"openCloseShoppingCart\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.order\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/open-shopping-carts.hbs"
    }
  });

  _exports.default = _default;
});