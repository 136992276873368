define("crm/templates/organizations/organization/suppliers-current-accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eFIQIlg7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"organization\",\"procurementFeature\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"module-procurement\"],[7],[0,\"\\n\"],[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"pages.suppliers\"],null],[25,\"t\",[\"icons.suppliers\"],null]]],{\"statements\":[[4,\"if\",[[20,[\"canDoSuppliers\"]]],null,{\"statements\":[[0,\"        \"],[6,\"li\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"newSupplier\"]],[7],[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.suppliers\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"suppliers.new.title\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"class\",\"title\",\"titleIcon\",\"titleCount\"],[\"module-procurement\",[25,\"t\",[\"suppliers.title\"],null],[25,\"t\",[\"pages.currentAccounts\"],null],[20,[\"model\",\"length\"]]]],{\"statements\":[[0,\"      \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"model\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"unavailable-feature\",null,[[\"module\",\"feature\"],[\"module-procurement\",\"procurement\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/suppliers-current-accounts.hbs"
    }
  });

  _exports.default = _default;
});