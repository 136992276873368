define("crm/templates/components/shopping-cart-line-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zBfwJRNq",
    "block": "{\"symbols\":[],\"statements\":[[6,\"h4\"],[10,\"class\",[26,[[25,\"unless\",[[20,[\"hideLinkDetails\"]],\"clickable\"],null],\" \",[25,\"if\",[[20,[\"model\",\"lineGroup\"]],\"text-primary\"],null]]]],[3,\"action\",[[19,0,[]],\"openShoppingCartLineDetails\",[20,[\"model\"]]]],[7],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[0,\"\\n        (\"],[1,[20,[\"model\",\"quantity\"]],false],[0,\"x) \"],[4,\"if\",[[20,[\"model\",\"discount\"]]],null,{\"statements\":[[0,\"(\"],[1,[20,[\"model\",\"discount\"]],false],[0,\"% \"],[1,[18,\"discount\"],false],[0,\" \"],[1,[25,\"t\",[\"icons.currency\"],null],false],[0,\")\"]],\"parameters\":[]},null],[0,\" \"],[1,[20,[\"model\",\"linePrice\"]],false],[0,\" \"],[1,[25,\"t\",[\"icons.currency\"],null],false],[0,\"\\n    \"],[8],[0,\"\\n\\n    \"],[6,\"strong\"],[7],[1,[20,[\"model\",\"item\",\"name\"]],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/shopping-cart-line-card.hbs"
    }
  });

  _exports.default = _default;
});