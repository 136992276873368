define("crm/templates/components/purchase-entity-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZSMiKwb4",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"table-actions text-center\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"hidle\"],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-fw fa-ellipsis-h disabled\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"actions text-center\"],[7],[0,\"\\n\"],[4,\"if\",[[25,\"or\",[[25,\"eq\",[[20,[\"record\",\"status\"]],0],null],[25,\"eq\",[[20,[\"record\",\"status\"]],1],null]],null]],null,{\"statements\":[[0,\"      \"],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"actions.edit\"],null]]]],[3,\"action\",[[19,0,[]],\"edit\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.pencilSquare\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"items.new.duplicate\"],null],null],[3,\"action\",[[19,0,[]],\"duplicate\"]],[7],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-fw fa-copy\"],[7],[8],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"isPurchaseOrder\"]]],null,{\"statements\":[[4,\"if\",[[25,\"eq\",[[20,[\"record\",\"status\"]],3],null]],null,{\"statements\":[[0,\"        \"],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"actions.receive\"],null]]]],[3,\"action\",[[19,0,[]],\"receive\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.goTo\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/purchase-entity-actions.hbs"
    }
  });

  _exports.default = _default;
});