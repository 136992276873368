define("crm/templates/components/item-companion-ibox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8ZxlK7Ll",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"list-group\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"companion\",\"name\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"list-group-item\"],[7],[6,\"strong\"],[7],[1,[25,\"t\",[\"items.item.name\"],null],false],[8],[0,\": \"],[1,[20,[\"model\",\"companion\",\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"quantity\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"list-group-item\"],[7],[6,\"strong\"],[7],[1,[25,\"t\",[\"itemSelectList.quantity\"],null],false],[8],[0,\": \"],[1,[20,[\"model\",\"quantity\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"companion\",\"pvp\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"list-group-item\"],[7],[6,\"strong\"],[7],[1,[25,\"t\",[\"items.item.pvp\"],null],false],[8],[0,\": \"],[1,[25,\"u-num-round2\",[[20,[\"model\",\"companion\",\"pvp\"]]],null],false],[0,\" \"],[1,[25,\"t\",[\"icons.currency\"],null],false],[0,\" \"],[4,\"if\",[[20,[\"model\",\"companion\",\"baseUnit\",\"name\"]]],null,{\"statements\":[[0,\"/ \"],[1,[20,[\"model\",\"companion\",\"baseUnit\",\"name\"]],false],[0,\" \"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"companion\",\"salePrice\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"list-group-item\"],[7],[6,\"strong\"],[7],[1,[25,\"t\",[\"items.item.price\"],null],false],[8],[0,\": \"],[1,[25,\"u-num-round2\",[[20,[\"model\",\"companion\",\"salePrice\"]]],null],false],[0,\" \"],[1,[25,\"t\",[\"icons.currency\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/item-companion-ibox.hbs"
    }
  });

  _exports.default = _default;
});