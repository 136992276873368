define("crm/templates/components/loop-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JMmoE7Kw",
    "block": "{\"symbols\":[\"time\",\"&default\"],\"statements\":[[4,\"each\",[[20,[\"numOfTimes\"]]],null,{\"statements\":[[0,\"  \"],[11,2],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/loop-for.hbs"
    }
  });

  _exports.default = _default;
});