define("crm/templates/components/contact-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pclZWIzY",
    "block": "{\"symbols\":[\"auth\"],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"modalSize\"],[\"modal-contact-details\",[25,\"t\",[\"customers.customer.contacts.contacts\"],null],\"md\"]],{\"statements\":[[0,\"    \"],[6,\"h1\"],[9,\"class\",\"text-center\"],[7],[1,[20,[\"model\",\"name\"]],false],[8],[0,\"\\n    \"],[6,\"hr\"],[7],[8],[0,\"\\n    \"],[6,\"dl\"],[9,\"class\",\"row mb-0\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-sm-4 text-sm-right\"],[7],[6,\"dt\"],[7],[1,[25,\"t\",[\"customers.customer.contacts.contact\"],null],false],[0,\":\"],[8],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-sm-8 text-sm-left\"],[7],[6,\"dd\"],[9,\"class\",\"mb-1\"],[7],[1,[20,[\"model\",\"contact\"]],false],[8],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"model\",\"authorizations\",\"length\"]]],null,{\"statements\":[[0,\"        \"],[6,\"dl\"],[9,\"class\",\"row mb-0\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"col-sm-4 text-sm-right\"],[7],[6,\"dt\"],[7],[1,[25,\"t\",[\"customers.customer.contacts.authorizations\"],null],false],[0,\":\"],[8],[8],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"col-sm-8 text-sm-left\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"model\",\"authorizations\"]]],null,{\"statements\":[[0,\"                    \"],[6,\"li\"],[9,\"class\",\"mb-1\"],[7],[1,[19,1,[\"authorizationType\",\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/contact-details.hbs"
    }
  });

  _exports.default = _default;
});