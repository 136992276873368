define("crm/templates/components/document-annul", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "65xpP4IX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"class\",\"title\",\"hideFooter\",\"modalSize\",\"closed\",\"reset\"],[\"modal-document-annul\",\"module-sales\",[25,\"t\",[\"documents.annulDocument\"],null],true,\"md\",[20,[\"closed\"]],\"reset\"]],{\"statements\":[[4,\"custom-form\",null,[[\"fieldTypes\",\"save\",\"actionText\",\"reset\",\"canceled\",\"disabledAction\"],[[20,[\"fieldTypes\"]],\"edit\",\"documents.annul\",\"reset\",\"canceled\",[20,[\"disabledAction\"]]]],{\"statements\":[[0,\"    \"],[6,\"fieldset\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n        \"],[6,\"h2\"],[7],[1,[25,\"t\",[\"documents.warning\"],null],false],[0,\" \\\"\"],[1,[20,[\"model\",\"name\"]],false],[0,\"\\\" ?\"],[8],[0,\"\\n      \"],[8],[0,\"\\n\\n      \"],[2,\" Reason \"],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n        \"],[6,\"label\"],[9,\"class\",\"control-label\"],[7],[1,[25,\"t\",[\"documents.annulReason\"],null],false],[0,\" \"],[6,\"span\"],[9,\"class\",\"text-danger\"],[10,\"title\",[25,\"t\",[\"validations.fieldRequired\"],null],null],[7],[0,\" *\"],[8],[8],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"minlength\",\"maxlength\",\"value\",\"class\",\"required\"],[\"3\",\"255\",[20,[\"model\",\"deletedReason\"]],\"form-control\",true]]],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/document-annul.hbs"
    }
  });

  _exports.default = _default;
});