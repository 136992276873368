define("crm/templates/components/patient-select-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2o5p4plN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"class\",\"id\",\"title\",\"modalSize\",\"hideFooter\",\"reset\"],[\"module-crm max-z-index-important\",\"modal-patient-select\",[25,\"t\",[\"actions.selectPatient\"],null],\"lg\",true,\"reset\"]],{\"statements\":[[0,\"    \"],[6,\"section\"],[9,\"class\",\"modal-body\"],[7],[0,\"\\n        \"],[1,[25,\"patient-select\",null,[[\"organization\",\"selectedPatient\",\"searchText\",\"oldString\",\"patients\"],[[20,[\"organization\"]],[20,[\"selectedPatient\"]],[20,[\"searchText\"]],[20,[\"oldString\"]],[20,[\"patients\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n\\n    \"],[6,\"section\"],[9,\"class\",\"modal-footer\"],[7],[0,\"\\n\"],[4,\"u-loading-button\",null,[[\"class\",\"click\"],[\"pull-right\",[25,\"action\",[[19,0,[]],\"close\"],null]]],{\"statements\":[[0,\"            \"],[6,\"span\"],[7],[1,[25,\"t\",[\"actions.close\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/patient-select-modal.hbs"
    }
  });

  _exports.default = _default;
});