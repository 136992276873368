define("crm/templates/components/internment/list-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xNSt4GFr",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"table-actions\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"hidle text-center\"],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-ellipsis-h disabled\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"actions text-center\"],[7],[0,\"\\n    \"],[2,\" open \"],[0,\"\\n    \"],[4,\"link-to\",[\"organizations.organization.internments.internment\",[20,[\"record\",\"id\"]]],null,{\"statements\":[[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.goTo\"],null]]]],[7],[8]],\"parameters\":[]},null],[0,\"\\n\\n    \"],[2,\" edit \"],[0,\"\\n    \"],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"actions.edit\"],null]]]],[3,\"action\",[[19,0,[]],\"callModal\",\"edit\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.edit\"],null]]]],[7],[8],[8],[0,\"\\n\\n    \"],[2,\" delete \"],[0,\"\\n    \"],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"actions.delete\"],null]]]],[3,\"action\",[[19,0,[]],\"delete\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.delete\"],null]]]],[7],[8],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/internment/list-actions.hbs"
    }
  });

  _exports.default = _default;
});