define("crm/templates/components/historical-consultation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TeNjSL8z",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"filtered\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"multipleColumnsSorting\",\"multipleSelect\",\"filteringIgnoreCase\",\"selectRowOnClick\",\"showColumnsDropdown\",\"useFilteringByColumns\",\"columnsAreUpdateable\",\"class\",\"openConsultation\",\"document\"],[[20,[\"data\"]],[20,[\"columns\"]],false,false,true,true,false,[20,[\"useFilteringByColumns\"]],true,[25,\"if\",[[20,[\"hideSearch\"]],\"hide-search\"],null],\"openConsultation\",\"document\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"empty-set\",null,[[\"icon\"],[\"stethoscope\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/historical-consultation.hbs"
    }
  });

  _exports.default = _default;
});