define("crm/templates/organizations/organization/campaigns/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FqqaRxvC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"class\",\"title\",\"titleIcon\"],[\"module-crm\",[25,\"t\",[\"campaigns.title\"],null],[25,\"t\",[\"icons.campaigns\"],null]]],{\"statements\":[[4,\"if\",[[20,[\"canDoPeriodic\"]]],null,{\"statements\":[],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[1,[25,\"campaigns-list\",null,[[\"model\",\"openDetails\",\"openEdit\",\"reloadModel\",\"updateModel\"],[[20,[\"model\",\"campaigns\"]],\"openDetails\",\"openEdit\",\"reloadModel\",\"updateModel\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/campaigns/index.hbs"
    }
  });

  _exports.default = _default;
});