define("crm/templates/components/periodic-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cNW1Wfr3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"class\",\"titleIcon\",\"title\",\"hideFooter\",\"modalSize\",\"closed\",\"reset\"],[\"modal-periodic-details\",\"module-clinical\",[25,\"t\",[\"icons.periodic\"],null],[20,[\"model\",\"type\",\"name\"]],true,\"sm\",[20,[\"closed\"]],\"reset\"]],{\"statements\":[[0,\"\\n    \"],[6,\"h3\"],[7],[1,[25,\"t\",[\"periodics.periodic.nextPeriodic\"],null],false],[8],[0,\"\\n    \"],[1,[25,\"periodics-list\",null,[[\"model\",\"doNextPeriodic\",\"hidePatient\",\"showSMS\",\"hideDate\",\"hideComponentFooter\"],[[20,[\"lastPeriodicArray\"]],[25,\"action\",[[19,0,[]],\"doNextPeriodic\"],null],true,true,true,true]]],false],[0,\"\\n\\n    \"],[6,\"h3\"],[7],[1,[25,\"t\",[\"periodics.title\"],null],false],[8],[0,\"\\n    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"editPeriodic\",\"showGlobalFilter\"],[[20,[\"allPeriodics\"]],[20,[\"columns\"]],\"editPeriodic\",false]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/periodic-details.hbs"
    }
  });

  _exports.default = _default;
});