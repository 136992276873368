define("crm/templates/organizations/organization/group/overview-sales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UYwuULsc",
    "block": "{\"symbols\":[\"option\",\"option\"],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\",\"class\"],[[25,\"t\",[\"modules.sales.title\"],null],[25,\"t\",[\"icons.item\"],null],\"module-sales\"]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-6\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"filters-panel\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"calendarPeriodTypes\"]]],null,{\"statements\":[[0,\"          \"],[6,\"a\"],[10,\"class\",[26,[\"badge badge-\",[25,\"if\",[[19,2,[\"active\"]],\"primary\",\"default\"],null]]]],[3,\"action\",[[19,0,[]],\"setFilter1\",[19,2,[]]]],[7],[1,[19,2,[\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"],[8],[0,\"\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[0,\"        \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"model\",\"model1\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-6\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"filters-panel\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"calendarPeriodTypes\"]]],null,{\"statements\":[[0,\"          \"],[6,\"a\"],[10,\"class\",[26,[\"badge badge-\",[25,\"if\",[[19,1,[\"active\"]],\"primary\",\"default\"],null]]]],[3,\"action\",[[19,0,[]],\"setFilter2\",[19,1,[]]]],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[8],[0,\"\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[0,\"        \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"model\",\"model2\"]],[20,[\"columns2\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/group/overview-sales.hbs"
    }
  });

  _exports.default = _default;
});