define("crm/templates/components/loading-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tSw/hPJR",
    "block": "{\"symbols\":[],\"statements\":[[6,\"section\"],[9,\"class\",\"page-body text-center\"],[7],[0,\"\\n  \"],[6,\"article\"],[9,\"class\",\"p-sm\"],[7],[0,\"\\n    \"],[6,\"p\"],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-5x fa-\",[25,\"t\",[[25,\"concat\",[\"icons.\",[20,[\"icon\"]]],null]],null],\" text-grey\"]]],[7],[8],[8],[0,\"\\n\"],[4,\"unless\",[[20,[\"hideSearch\"]]],null,{\"statements\":[[0,\"      \"],[6,\"form\"],[9,\"class\",\"form-inline\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n          \"],[6,\"input\"],[9,\"type\",\"text\"],[9,\"class\",\"form-control ember-text-field\"],[9,\"disabled\",\"\"],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"button\"],[9,\"class\",\"btn btn-primary loading\"],[9,\"disabled\",\"\"],[7],[6,\"i\"],[9,\"class\",\"fa fa-fw fa-spinner fa-spin\"],[7],[8],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n  \"],[6,\"article\"],[9,\"class\",\"ibox\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"m-t-lg\"],[7],[0,\" \"],[8],[0,\"\\n      \"],[6,\"p\"],[9,\"class\",\"m-t-lg m-b-md\"],[7],[6,\"i\"],[9,\"class\",\"fa fa-3x fa-spinner fa-pulse text-grey\"],[7],[8],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/loading-page.hbs"
    }
  });

  _exports.default = _default;
});