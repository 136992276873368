define("crm/templates/components/quotations-list-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l/p6b0rC",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"table-actions text-center\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"hidle\"],[7],[0,\"\\n        \"],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-fw fa-ellipsis-h disabled\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[9,\"class\",\"actions\"],[7],[0,\"\\n        \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"configurations.configItems.goToPos\"],null],null],[3,\"action\",[[19,0,[]],\"goToPos\",[20,[\"record\"]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-fw fa-\",[25,\"t\",[\"icons.goTo\"],null]]]],[7],[8],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/quotations-list-actions.hbs"
    }
  });

  _exports.default = _default;
});