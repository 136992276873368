define("crm/templates/components/disable-customer-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DNSYA0NS",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"class\",\"hideFooter\"],[\"modal-disable-customer\",[25,\"t\",[\"customers.actions.disableCustomer\"],null],\"module-crm\",true]],{\"statements\":[[4,\"custom-form\",null,[[\"fieldTypes\",\"save\",\"actionText\",\"canceled\",\"reset\"],[[20,[\"fieldTypes\"]],\"save\",\"actions.continue\",\"canceled\",\"reset\"]],{\"statements\":[[4,\"if\",[[20,[\"hasShoppingCarts\"]]],null,{\"statements\":[[0,\"            \"],[6,\"fieldset\"],[7],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n                    \"],[6,\"h2\"],[7],[1,[25,\"t\",[\"customers.messages.hasDebt\"],null],false],[8],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[6,\"fieldset\"],[7],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n                    \"],[6,\"h2\"],[7],[1,[25,\"t\",[\"customers.messages.disable\"],null],false],[8],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/disable-customer-modal.hbs"
    }
  });

  _exports.default = _default;
});