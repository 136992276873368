define("crm/templates/organizations/organization/plans/plan/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Smq8k+GU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ibox-panel\",null,[[\"title\",\"contentHeading\",\"newRouteIcon\",\"newRoute\"],[[25,\"t\",[\"plans.plan.entity\"],null],[20,[\"model\",\"name\"]],\"pencil\",\"organizations.organization.plans.plan.edit\"]],{\"statements\":[[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/plans/plan/index.hbs"
    }
  });

  _exports.default = _default;
});