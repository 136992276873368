define("crm/templates/organizations/organization/items/item/contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E4TaRPke",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"page-body module-sales\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"filters-panel\"],[7],[0,\"\\n        \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"actions.filters\"],null],false],[8],[0,\"\\n\\n        \"],[1,[25,\"date-filters\",null,[[\"search\",\"module\"],[\"search\",\"module-sales\"]]],false],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"visible-lg-block visible-xl-block\"],[7],[0,\"\\n            \"],[6,\"hr\"],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"title\"],[[25,\"t\",[\"documents.title\"],[[\"titleIcon\"],[[25,\"t\",[\"icons.documents\"],null]]]]]],{\"statements\":[[0,\"        \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"singlePage\"],[[20,[\"model\"]],[20,[\"columns\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/items/item/contacts.hbs"
    }
  });

  _exports.default = _default;
});