define("crm/routes/organizations/organization/templates/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord("template", params.template_id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      this.store.query("template-section", {
        template: model.get("id")
      }).then(function (lines) {
        if (!Ember.isEmpty(lines.get("content"))) {
          lines.forEach(function (line) {
            controller.set("section", line);
          });
        } else {
          controller.set("section", []);
        }
      });
      var listFields = [];
      controller.set("fields", listFields);
      this.store.query("template-section-field", {
        template: model.get("id")
      }).then(function (lines) {
        if (lines) {
          lines.forEach(function (line) {
            listFields.addObject(line);
          });
        } else {
          controller.set("fields", [{}]);
        }
      });
    },
    willTransition: function willTransition() {
      if (this.get("controller.model") && !this.get("controller.model.id")) {
        this.store.unloadRecord(this.get("controller.model"));
      }
    }
  });

  _exports.default = _default;
});