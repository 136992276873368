define("crm/templates/components/owners-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5hvWPe4x",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"ibox-card\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"organizations.organization.customers.customer\",[20,[\"model\",\"id\"]]],[[\"class\",\"title\"],[\"message-author\",[25,\"t\",[\"customers.customer.title\"],null]]],{\"statements\":[[0,\"            \"],[6,\"span\"],[9,\"class\",\"title\"],[7],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-user text-navy\"],[7],[8],[0,\" \"],[1,[20,[\"model\",\"name\"]],false],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"label label-default\"],[10,\"title\",[26,[[25,\"t\",[\"customers.customer.email\"],null]]]],[7],[6,\"i\"],[9,\"class\",\"fa fa-envelope\"],[7],[8],[0,\" \"],[4,\"if\",[[20,[\"model\",\"email\"]]],null,{\"statements\":[[0,\"\\n            \"],[1,[20,[\"model\",\"email\"]],false],[0,\"\\n        \"]],\"parameters\":[]},null],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n\"],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/owners-details.hbs"
    }
  });

  _exports.default = _default;
});