define("crm/templates/components/invoice-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RPdu0hS8",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"m-b-xs\"],[7],[6,\"strong\"],[7],[1,[20,[\"model\",\"name\"]],false],[8],[8],[0,\"\\n  \"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"payableAmount\"]]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[7],[1,[20,[\"model\",\"payableAmount\"]],false],[0,\" \"],[1,[25,\"t\",[\"icons.currency\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n  \"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"paymentMethod\"]]],null,{\"statements\":[[0,\"      \"],[6,\"span\"],[9,\"class\",\"no-wrap\"],[7],[1,[20,[\"model\",\"paymentMethod\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n  \"],[6,\"div\"],[7],[0,\"\\n    \"],[4,\"if\",[[20,[\"model\",\"created\"]]],null,{\"statements\":[[6,\"span\"],[9,\"class\",\"no-wrap\"],[7],[1,[18,\"date\"],false],[8]],\"parameters\":[]},null],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/invoice-card.hbs"
    }
  });

  _exports.default = _default;
});