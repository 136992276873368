define("crm/templates/components/clinical/exam-ibox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BgSWTLxY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ibox-panel\",null,[[\"title\",\"titleIcon\",\"contentHeading\",\"contentHeadingSmall\"],[[25,\"t\",[\"actions.details\"],null],[25,\"t\",[\"icons.exam\"],null],[20,[\"model\",\"name\"]],[20,[\"model\",\"description\"]]]],{\"statements\":[[0,\"    \"],[6,\"ul\"],[9,\"class\",\"list-group\"],[7],[0,\"\\n        \"],[6,\"li\"],[9,\"class\",\"list-group-item\"],[7],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[1,[25,\"exam-status\",[[20,[\"model\",\"status\"]]],null],false],[8],[6,\"strong\"],[7],[1,[25,\"t\",[\"exams.status.title\"],null],false],[8],[8],[0,\"\\n        \"],[6,\"li\"],[9,\"class\",\"list-group-item\"],[7],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[1,[25,\"pu-chronos-date-time\",[[20,[\"model\",\"requestDate\"]]],null],false],[8],[6,\"strong\"],[7],[1,[25,\"t\",[\"exams.requestDate\"],null],false],[8],[8],[0,\"\\n        \"],[6,\"li\"],[9,\"class\",\"list-group-item\"],[7],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[1,[25,\"pu-chronos-date-time\",[[20,[\"model\",\"doneDate\"]]],null],false],[8],[6,\"strong\"],[7],[1,[25,\"t\",[\"exams.doneDate\"],null],false],[8],[8],[0,\"\\n        \"],[6,\"li\"],[9,\"class\",\"list-group-item\"],[7],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[1,[20,[\"model\",\"creator\",\"name\"]],false],[8],[6,\"strong\"],[7],[1,[25,\"t\",[\"workers.title\"],null],false],[8],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/clinical/exam-ibox.hbs"
    }
  });

  _exports.default = _default;
});