define("crm/templates/components/current-accounts-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xLYmjwUf",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n    \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"actions.filters\"],null],false],[8],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[20,[\"showReceivingDebt\"]]]]],false],[0,\" \"],[1,[25,\"t\",[\"filters.debtors\"],null],false],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[20,[\"showGivingDebt\"]]]]],false],[0,\" \"],[1,[25,\"t\",[\"filters.creditors\"],null],false],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"filteredModels\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openModal\",\"hideComponentFooter\",\"pageSize\"],[[20,[\"filteredModels\"]],[20,[\"columns\"]],\"openModal\",true,[20,[\"filteredModels\",\"length\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.currentAccount\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/current-accounts-list.hbs"
    }
  });

  _exports.default = _default;
});