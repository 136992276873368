define("crm/templates/components/inventory-stock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EjCm1NxJ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[10,\"class\",[26,[\"text-right \",[25,\"if\",[[20,[\"record\",\"IsNegativeStock\"]],\"text-danger\"],null]]]],[7],[1,[20,[\"record\",\"Stock\"]],false],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/inventory-stock.hbs"
    }
  });

  _exports.default = _default;
});