define("crm/templates/organizations/organization/listings/sales/pending", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5d2ui5L2",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"u-second-nav\",null,[[\"title\",\"titleIcon\",\"titleCount\"],[\"Pendentes\",\"folder-open-o\",[20,[\"model\",\"size\"]]]]],false],[0,\"\\n\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[4,\"if\",[[20,[\"model\",\"data\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"scroll-x\"],[7],[0,\"\\n      \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"singlePage\"],[[20,[\"model\",\"data\"]],[20,[\"columns\"]],true]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"empty-set\",null,[[\"icon\"],[\"folder-open-o\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/listings/sales/pending.hbs"
    }
  });

  _exports.default = _default;
});