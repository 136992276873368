define("crm/services/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    dischargeLetter: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: this.get("intl").t("consultations.consultation.dischargeLetter"),
          type: 6
        }
      }];
    }),
    responsabilityTerm: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: "Procedimento Clínico",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Data do Procedimento",
          type: 2
        }
      }, {
        value: undefined,
        type: {
          name: "Quantia",
          type: 4
        }
      }];
    }),
    generalAnesthesia: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: "Intervenção",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Médico Veterinário",
          mandatory: true,
          type: 0
        }
      }];
    }),
    bathCutRequest: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: "O Animal tem doenças associadas?",
          type: 3
        }
      }, {
        value: undefined,
        type: {
          name: "Banho",
          type: 3
        }
      }, {
        value: undefined,
        type: {
          name: "Tipo de Tosquia",
          type: 5,
          options: [{
            code: "Normal (pelo todo aparado)",
            name: "Normal (pelo todo aparado)"
          }, {
            code: "Higiénica",
            name: "Higiénica"
          }, {
            code: "Raça",
            name: "Raça"
          }]
        }
      }, {
        value: undefined,
        type: {
          name: "Corte de Unhas",
          type: 3
        }
      }, {
        value: undefined,
        type: {
          name: "Limpeza de Ouvidos",
          type: 3
        }
      }, {
        value: undefined,
        type: {
          name: "Aspressão de Glândulas Anais",
          type: 3
        }
      }, {
        value: undefined,
        type: {
          name: "Contra-indicação",
          type: 0
        }
      }];
    }),
    bathCutReport: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: "Problemas de Pele",
          type: 3
        }
      }, {
        value: undefined,
        type: {
          name: "Unhas",
          type: 3
        }
      }, {
        value: undefined,
        type: {
          name: "Ouvidos",
          type: 3
        }
      }, {
        value: undefined,
        type: {
          name: "Dentes",
          type: 5,
          options: [{
            code: "Grau 1",
            name: "Grau 1"
          }, {
            code: "Grau 2",
            name: "Grau 2"
          }, {
            code: "Grau 3",
            name: "Grau 3"
          }, {
            code: "Grau 4",
            name: "Grau 4"
          }, {
            code: "Grau 5",
            name: "Grau 5"
          }]
        }
      }, {
        value: undefined,
        type: {
          name: "Parasitas externos",
          type: 5,
          options: [{
            code: "Não",
            name: "Não"
          }, {
            code: "Pulgas",
            name: "Pulgas"
          }, {
            code: "Carraças",
            name: "Carraças"
          }]
        }
      }, {
        value: undefined,
        type: {
          name: "Glândulas Anais",
          type: 5,
          options: [{
            code: "Aspressão conteúdo",
            name: "Aspressão conteúdo"
          }, {
            code: "Impactados",
            name: "Impactados"
          }, {
            code: "Infectados",
            name: "Infectados"
          }]
        }
      }, {
        value: undefined,
        type: {
          name: "PRÓXIMO BANHO",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "PRÓXIMA TOSQUIA",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "RECOMENDAÇÕES",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "CHAMPÔ",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "OUTROS",
          type: 0
        }
      }];
    }),
    ecoReport: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: "Data da Ecografia: ",
          type: 2
        }
      }, {
        value: undefined,
        type: {
          name: "Motivo da Ecografia: ",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Descrição: ",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Conclusão: ",
          type: 6
        }
      }];
    }),
    checkUpSeniorDog: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: "Estado Vacinal",
          type: 5,
          options: [{
            code: "Raiva",
            name: "Raiva"
          }, {
            code: "Anual Múltipla (DHPPi + L4)",
            name: "Anual Múltipla (DHPPi + L4)"
          }, {
            code: "Tosse do Canil",
            name: "Tosse do Canil"
          }, {
            code: "Leishmaniose",
            name: "Leishmaniose"
          }]
        }
      }, {
        value: undefined,
        type: {
          name: "Desparasitação",
          type: 5,
          options: [{
            code: "Interna",
            name: "Interna"
          }, {
            code: "Externa",
            name: "Externa"
          }]
        }
      }, {
        value: "Foi examinado o estado mental, pêlo, pele e mucosas, gânglios linfáticos, aparelho locomotor, aparelho cardio-respiratório, aparelho urogenital, glândulas mamárias e aparelho gastrointestinal, não se registando quaisquer alterações clinicas",
        type: {
          name: "Exame Físico Geral",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Condição Corporal",
          type: 5,
          options: [{
            code: "Muito magro",
            name: "Muito magro"
          }, {
            code: "Magro",
            name: "Magro"
          }, {
            code: "Normal",
            name: "Normal"
          }, {
            code: "Excesso de peso",
            name: "Excesso de peso"
          }, {
            code: "Obeso",
            name: "Obeso"
          }]
        }
      }, {
        value: "Após limpeza auricular, foi efectuada visualização do canal auditivo e do tímpano sem visualização de alterações",
        type: {
          name: "Exame Otológico",
          type: 0
        }
      }, {
        value: "Foram examinadas pálpebras e conjuntiva sem visualização de alterações",
        type: {
          name: "Exame Oftalmológico",
          type: 0
        }
      }, {
        value: "Examinada a mucosa oral, gengivas e dentes sem visualização de alterações.",
        type: {
          name: "Exame Dentário",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Análises Clínicas",
          type: 5,
          options: [{
            code: "Hemograma",
            name: "Hemograma"
          }, {
            code: "Painel V",
            name: "Painel V"
          }, {
            code: "Urina",
            name: "Urina"
          }]
        }
      }, {
        value: undefined,
        type: {
          name: "PAM (Pressão Arterial Média)",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "FC (frequência Cardíaca)",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Raio-X de Tórax e Coluna (imagens em CD)",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Ecografia FAST (imagens em CD)",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Recomendações",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Acompanhamento",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Consulta/Exame",
          type: 0
        }
      }];
    }),
    checkUpSeniorCat: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: "Estado Vacinal",
          type: 5,
          options: [{
            code: "Raiva",
            name: "Raiva"
          }, {
            code: "Anual Múltipla",
            name: "Anual Múltipla"
          }, {
            code: "Leishmaniose",
            name: "Leishmaniose"
          }, {
            code: "Outras",
            name: "Outras"
          }]
        }
      }, {
        value: undefined,
        type: {
          name: "Desparasitação",
          type: 0
        }
      }, {
        value: "Foi examinado o estado mental, pêlo, pele e mucosas, gânglios linfáticos, aparelho locomotor, aparelho cardio-respiratório, aparelho urogenital, glândulas mamárias e aparelho gastrointestinal, não se registando quaisquer alterações clinicas",
        type: {
          name: "Exame Físico Geral",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Condição Corporal",
          type: 5,
          options: [{
            code: "Muito magro",
            name: "Muito magro"
          }, {
            code: "Magro",
            name: "Magro"
          }, {
            code: "Normal",
            name: "Normal"
          }, {
            code: "Excesso de peso",
            name: "Excesso de peso"
          }, {
            code: "Obeso",
            name: "Obeso"
          }]
        }
      }, {
        value: "Após limpeza auricular, foi efectuada visualização do canal auditivo e do tímpano sem visualização de alterações",
        type: {
          name: "Exame Otológico",
          type: 0
        }
      }, {
        value: "Foram examinadas pálpebras e conjuntiva sem visualização de alterações",
        type: {
          name: "Exame Oftalmológico",
          type: 0
        }
      }, {
        value: "Examinada a mucosa oral, gengivas e dentes sem visualização de alterações.",
        type: {
          name: "Exame Dentário",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Análises Clínicas",
          type: 5,
          options: [{
            code: "Hemograma",
            name: "Hemograma"
          }, {
            code: "Painel V",
            name: "Painel V"
          }, {
            code: "Urina",
            name: "Urina"
          }, {
            code: "T4",
            name: "T4"
          }]
        }
      }, {
        value: undefined,
        type: {
          name: "PAM (Pressão Arterial Média)",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "FC (frequência Cardíaca)",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Raio-X de Tórax e Coluna (imagens em CD)",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Ecografia FAST (imagens em CD)",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Recomendações",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Acompanhamento",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Consulta/Exame",
          type: 0
        }
      }];
    }),
    electrocardiogramDog: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: "Frequência cachorro",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Frequência miniatura",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Frequência cardíaca",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Frequência gigante",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Duração de P (s)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Amplitude de P (mv)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Intervalo P-R (s)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Duração de QRS (s)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Amplitude de R (mv)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Segmento S-T (mv)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Amplitude de T (mv)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Intervalo Q-T (s)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Eixo elétrico cardíaco (º)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Diagnóstico Electrocardiográfico:",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Comentários:",
          type: 6
        }
      }];
    }),
    electrocardiogramCat: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: "Frequência cardíaca",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Duração de P",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Amplitude de P",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Intervalo P-R",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Duração de QRS",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Amplitude de R",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Segmento S-T",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Amplitude de T",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Intervalo Q-T",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Eixo elétrico cardíaco",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Diagnóstico Electrocardiográfico:",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Comentários:",
          type: 6
        }
      }];
    }),
    surgeryReport: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: "Intervenção",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Médico Veterinário Cirurgião",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Pré-medicação",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Indução",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Manutenção",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Fio-de-sutura",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Relatório de cirurgia",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Indicações pós-cirúrgicas",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Fisioterapia",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Exames complementares",
          type: 6
        }
      }];
    }),
    labAnalysisReport: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: "Grupo de parâmetros: ",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Glóbulos Brancos",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Linfócitos",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Monócitos",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Granulócitos",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Linfócitos (%)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Monócitos (%)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Monócitos (%)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Glóbulos Vermelhos",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Hemoglobina",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Hematócrito",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "VMC",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "HMC",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "CHCM",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Plaquetas",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "VPM",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Comentários:",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Data de Conclusão: ",
          type: 2
        }
      }];
    }),
    labAnalysisExamReport: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: "Exame: ",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Glóbulos Brancos",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Linfócitos",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Monócitos",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Granulócitos",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Linfócitos (%)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Monócitos (%)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Monócitos (%)",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Glóbulos Vermelhos",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Hemoglobina",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Hematócrito",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "VMC",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "HMC",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "CHCM",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Plaquetas",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "VPM",
          type: 1
        }
      }, {
        value: undefined,
        type: {
          name: "Comentários:",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Data de Conclusão: ",
          type: 2
        }
      }];
    }),
    surgeryDischarge: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: "Intervenção",
          type: 0
        }
      }, {
        value: "A alimentação deve ser iniciada hoje às xxhoras, em pequenas doses, várias vezes ao dia. A administração de água deve ser iniciada hoje, também de forma controlada. Em relação ao tipo de alimento, pode fazer a dieta normal",
        type: {
          name: "Alimentação e água:",
          type: 6
        }
      }, {
        value: "Pacientes em recuperação pós-cirúrgica devem permanecer num local abrigado e com contenção de movimentos. Deve portanto colocar o animal num pequeno espaço um período mínimo de 10 dias",
        type: {
          name: "Exercício actividade:",
          type: 6
        }
      }, {
        value: "É normal que o seu animal não defeque nas primeiras 24-36 horas após a cirurgia. ",
        type: {
          name: "Eliminação:",
          type: 6
        }
      }, {
        value: "O animal não deve lamber os pontos. Se o animal levar colar, não o retire. Se a sutura apresentar corrimento purulento ou pontos soltos, leve o animal ao Hospital para ser observado. Os pontos são reabsorvíveis, dependendo do animal, em 15 – 30 dias pelo que não precisam de ser retirados. Desinfecte a linha da sutura diariamente, de 12-12h, com Betadine (solução dermatológica) numa diluição de 1:1 em água",
        type: {
          name: "Sutura:",
          type: 6
        }
      }, {
        value: " Poderá ser normal uma diminuição da actividade e do apetite durante um a dois dias. Em caso de persistência dos seguintes sintomas, contacte o Hospital: perda de apetite por mais de um ou dois dias, recusa em beber água por mais de um dia, fraqueza, depressão, vómito e diarreia",
        type: {
          name: "Vigiar:",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Próxima consulta:",
          type: 2
        }
      }, {
        value: undefined,
        type: {
          name: "Próximos exames:",
          type: 2
        }
      }];
    }),
    internmentDischarge: Ember.computed("intl", function () {
      return [{
        value: undefined,
        type: {
          name: "Diagnóstico:",
          type: 0
        }
      }, {
        value: undefined,
        type: {
          name: "Alimentação e água:",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Exercício e actividade:",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Eliminação:",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Sutura:",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Vigiar:",
          type: 6
        }
      }, {
        value: undefined,
        type: {
          name: "Próxima consulta:",
          type: 2
        }
      }, {
        value: undefined,
        type: {
          name: "Próximos exames:",
          type: 2
        }
      }];
    })
  });

  _exports.default = _default;
});