define("crm/templates/components/invoices-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cFhw8Vrl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ibox-panel\",null,[[\"titleIcon\",\"class\",\"titleIcon\",\"title\",\"hideTools\"],[[25,\"t\",[\"icons.invoice\"],null],\"module-sales\",\"invoices\",[25,\"t\",[\"invoices.title\"],null],true]],{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"invoices\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/invoices-list.hbs"
    }
  });

  _exports.default = _default;
});