define("crm/templates/organizations/organization/group/purchase-requests/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uGfojblC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"class\",\"titleIcon\"],[[25,\"t\",[\"purchaseRequests.title\"],null],\"module-procurement\",[25,\"t\",[\"icons.purchaseRequests\"],null]]],{\"statements\":[[4,\"if\",[[25,\"can\",[\"create purchaseRequest\"],null]],null,{\"statements\":[[0,\"        \"],[6,\"li\"],[7],[4,\"link-to\",[\"organizations.organization.purchase-requests.new\"],[[\"class\"],[\"module-procurement\"]],{\"statements\":[[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.purchaseRequests\"],null]]]],[7],[8],[0,\" \"],[1,[25,\"t\",[\"purchaseRequests.new.title\"],null],false]],\"parameters\":[]},null],[8],[0,\"\\n\"],[4,\"if\",[[25,\"eq\",[[20,[\"exporting\"]],false],null]],null,{\"statements\":[[0,\"            \"],[6,\"li\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"export\"]],[7],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-cloud-download\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"actions.download\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[6,\"li\"],[7],[6,\"a\"],[9,\"class\",\"disabled\"],[7],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-cloud-download\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"actions.download\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ibox\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n        \"],[1,[25,\"purchase-order-list\",null,[[\"model\",\"columns\",\"singlePage\",\"updateModel\",\"callModal\",\"receivePurchaseRequest\"],[[20,[\"model\"]],[20,[\"columns\"]],true,\"updateModel\",[25,\"action\",[[19,0,[]],\"callModal\"],null],\"receivePurchaseRequest\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/group/purchase-requests/index.hbs"
    }
  });

  _exports.default = _default;
});