define("crm/templates/components/recursive-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vG+bi5cl",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[6,\"select\"],[9,\"class\",\"form-control\"],[10,\"required\",[18,\"required\"],null],[10,\"onchange\",[25,\"action\",[[19,0,[]],\"setSelectedOption\"],[[\"value\"],[\"target.value\"]]],null],[7],[0,\"\\n    \"],[6,\"option\"],[7],[8],[0,\"\\n\\n\"],[4,\"each\",[[20,[\"contentSorted\"]]],null,{\"statements\":[[0,\"        \"],[6,\"option\"],[10,\"value\",[19,1,[\"id\"]],null],[10,\"selected\",[25,\"eq\",[[19,1,[\"id\"]],[20,[\"selectedOption\",\"id\"]]],null],null],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"selectedOption\",\"children\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"recursive-select\",null,[[\"content\",\"modelName\",\"parentAction\",\"seed\"],[[20,[\"selectedOption\",\"children\"]],[20,[\"modelName\"]],[25,\"action\",[[19,0,[]],\"beCalled\"],null],[20,[\"subSeed\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/recursive-select.hbs"
    }
  });

  _exports.default = _default;
});