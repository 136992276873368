define("crm/templates/components/show-worker-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GpJ6JU+y",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"role-display\",[[20,[\"record\",\"role\"]]],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-worker-role.hbs"
    }
  });

  _exports.default = _default;
});