define("crm/templates/organizations/organization/stats/sales/totals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sAG3YxZh",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"u-second-nav\",null,[[\"title\",\"titleIcon\",\"class\"],[[25,\"t\",[\"pages.documents\"],null],[25,\"t\",[\"icons.documents\"],null],\"module-stats\"]]],false],[0,\"\\n\\n\"],[1,[25,\"invoice-memos-total\",null,[[\"model\"],[[20,[\"filteredModel\"]]]]],false],[0,\"\\n\\n\"],[1,[25,\"documents-totals\",null,[[\"model\"],[[20,[\"filteredModel\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/stats/sales/totals.hbs"
    }
  });

  _exports.default = _default;
});