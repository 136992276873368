define("crm/templates/organizations/organization/shopping-carts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rENcpnIl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"pages.shoppingCarts\"],null],[25,\"t\",[\"icons.shoppingCart\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n  \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"organization\",\"openDetails\",\"openCloseShoppingCart\"],[[20,[\"model\"]],[20,[\"columns\"]],[20,[\"organization\"]],\"openDetails\",\"openCloseShoppingCart\"]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/shopping-carts/index.hbs"
    }
  });

  _exports.default = _default;
});