define("crm/routes/organizations/organization/suppliers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var organization = this.modelFor("organizations.organization");

      if (!organization.get("featureNames").includes("procurement")) {
        return null;
      }

      if (this.get("can").cannot('create supplier')) {
        transition.abort();
        this.send("noPermissions");
      }
    },
    model: function model() {
      var organization = this.modelFor("organizations.organization");

      if (!organization.get("featureNames").includes("procurement")) {
        return null;
      }

      return organization.get("company.suppliers");
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set("organization", this.modelFor("organizations.organization"));
    }
  });

  _exports.default = _default;
});