define("crm/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ULSGKtX4",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"loginColumns animated fadeInRight\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-sm-6 hidden-xs bg-primary\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"module\"]]],null,{\"statements\":[[0,\"                 \\n                \"],[6,\"hr\"],[9,\"class\",\"m-t-lg\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[20,[\"corporation\"]]],null,{\"statements\":[[0,\"                \"],[6,\"h2\"],[7],[1,[18,\"corporation\"],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[20,[\"module\"]]],null,{\"statements\":[[0,\"                \"],[6,\"br\"],[7],[8],[0,\"\\n                \"],[6,\"br\"],[7],[8],[0,\"\\n                \"],[6,\"br\"],[7],[8],[0,\"\\n                \"],[6,\"br\"],[7],[8],[0,\"\\n                \"],[6,\"br\"],[7],[8],[0,\"\\n                \"],[6,\"br\"],[7],[8],[0,\"\\n                \"],[6,\"br\"],[7],[8],[0,\"\\n                \"],[6,\"br\"],[7],[8],[0,\"\\n                \"],[6,\"h2\"],[7],[1,[18,\"moduleDescription\"],false],[8],[0,\"\\n                \"],[6,\"br\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-sm-6\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"title\",\"hideTools\"],[[25,\"t\",[\"login.title\"],null],true]],{\"statements\":[[0,\"                \"],[6,\"br\"],[7],[8],[0,\"\\n                \"],[1,[18,\"pu-login-form\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/index.hbs"
    }
  });

  _exports.default = _default;
});