define("crm/templates/organizations/organization/patients/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uSTD7UPX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"pages.patients\"],null],[25,\"t\",[\"icons.patients\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[1,[25,\"loading-page\",null,[[\"icon\"],[\"patients\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/patients/loading.hbs"
    }
  });

  _exports.default = _default;
});