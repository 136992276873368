define("crm/templates/components/purchase-order-lines-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bppmx44u",
    "block": "{\"symbols\":[\"line\"],\"statements\":[[6,\"table\"],[9,\"class\",\"table table-striped table-hover table-fixed\"],[7],[0,\"\\n    \"],[6,\"thead\"],[7],[0,\"\\n        \"],[6,\"tr\"],[7],[0,\"\\n            \"],[6,\"th\"],[9,\"class\",\"column-worker\"],[7],[1,[25,\"t\",[\"items.item.name\"],null],false],[8],[0,\"\\n            \"],[6,\"th\"],[9,\"class\",\"column-id\"],[7],[1,[25,\"t\",[\"purchaseOrders.quantity\"],null],false],[8],[0,\"\\n            \"],[6,\"th\"],[9,\"class\",\"column-actions-1\"],[7],[0,\" \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"tbody\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"lines\"]]],null,{\"statements\":[[0,\"            \"],[6,\"tr\"],[7],[0,\"\\n                \"],[6,\"td\"],[9,\"class\",\"column-worker\"],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n                \"],[6,\"td\"],[9,\"class\",\"column-id\"],[7],[0,\"\\n                    \"],[1,[25,\"input\",null,[[\"value\",\"class\"],[[19,1,[\"quantity\"]],\"form-control\"]]],false],[0,\"\\n                \"],[8],[0,\"\\n                \"],[6,\"td\"],[9,\"class\",\"column-actions-1\"],[7],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"actions.delete\"],null]]]],[3,\"action\",[[19,0,[]],\"delete\",[19,1,[]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.delete\"],null]]]],[7],[8],[8],[8],[0,\"\\n            \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/purchase-order-lines-list.hbs"
    }
  });

  _exports.default = _default;
});