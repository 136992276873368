define("crm/templates/components/laboratory-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CeXp6Zd+",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"table-actions\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"hidle text-center\"],[7],[0,\"\\n        \"],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-ellipsis-h disabled\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[9,\"class\",\"actions\"],[7],[0,\"\\n\\n        \"],[2,\" details \"],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-4 col-md-4 col-sm-4 col-lg-4\"],[7],[0,\"\\n            \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"exams.exam.view\"],null],null],[3,\"action\",[[19,0,[]],\"openModalMDCDetails\",[20,[\"exam\",\"examClass\"]]]],[7],[0,\"\\n                \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[25,\"t\",[\"icons.detail\"],null]]]],[7],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\\n\"],[4,\"unless\",[[20,[\"exam\",\"examClass\",\"doneDate\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"col-xs-4 col-md-4 col-sm-4 col-lg-4\"],[7],[0,\"\\n            \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"tasks.done\"],null],null],[3,\"action\",[[19,0,[]],\"openModalUpdateMDC\",[20,[\"exam\",\"examClass\"]]]],[7],[0,\"\\n                \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[25,\"t\",[\"icons.pencilSquare\"],null]]]],[7],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-4 col-md-4 col-sm-4 col-lg-4\"],[7],[0,\"\\n            \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"exams.delected\"],null],null],[3,\"action\",[[19,0,[]],\"deleteExam\",[20,[\"exam\",\"examClass\"]]]],[7],[0,\"\\n                \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[25,\"t\",[\"icons.delete\"],null]]]],[7],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/laboratory-actions.hbs"
    }
  });

  _exports.default = _default;
});