define("crm/templates/components/stats-sales-item-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eDiemp4X",
    "block": "{\"symbols\":[\"elem\",\"elem\"],\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",[26,[\"widget style1 \",[25,\"if\",[[20,[\"parent\"]],\"item-type-bg\",\"white-bg\"],null],\" \",[18,\"parent\"]]]],[7],[0,\"\\n        \"],[6,\"b\"],[9,\"class\",\"pull-right\"],[7],[1,[25,\"price-display\",null,[[\"price\"],[[20,[\"model\",\"firstObject\",\"price\"]]]]],false],[8],[0,\"\\n        \"],[6,\"b\"],[7],[1,[20,[\"model\",\"firstObject\",\"name\"]],false],[8],[0,\"\\n        \"],[6,\"br\"],[7],[8],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[0,\"x\"],[1,[20,[\"model\",\"firstObject\",\"quantity\"]],false],[8],[0,\" \\n    \"],[8],[0,\"\\n\\n    \"],[6,\"hr\"],[7],[8],[0,\"\\n\\n\"],[4,\"each\",[[20,[\"top\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"stats-sales-item-line\",null,[[\"class\",\"model\"],[[25,\"if\",[[20,[\"parent\"]],\"item-type-bg\",\"white-bg\"],null],[19,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n    \"],[6,\"hr\"],[7],[8],[0,\"\\n    \"],[6,\"hr\"],[7],[8],[0,\"\\n\\n\"],[4,\"each\",[[20,[\"rest\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"stats-sales-item-line\",null,[[\"class\",\"model\"],[\"white-bg\",[19,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/stats-sales-item-list.hbs"
    }
  });

  _exports.default = _default;
});