define("crm/templates/components/report-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UB4BKKsR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"hideFooter\",\"reset\",\"closed\",\"modalSize\"],[\"modal-report-new\",[25,\"t\",[\"documents.document.title\"],null],true,\"reset\",[20,[\"closed\"]],\"lg\"]],{\"statements\":[[0,\"    \"],[1,[25,\"custom-form\",null,[[\"model\",\"save\",\"actionText\",\"canceled\",\"reset\",\"disabledAction\"],[[20,[\"customFormFields\"]],\"save\",\"crud.create\",\"canceled\",\"reset\",[20,[\"disabledSubmission\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/report-new.hbs"
    }
  });

  _exports.default = _default;
});