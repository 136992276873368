define("crm/templates/components/number-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "egDQdlrr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"number\"]]],null,{\"statements\":[[0,\"    \"],[1,[18,\"unit\"],false],[4,\"if\",[[20,[\"decimal\"]]],null,{\"statements\":[[0,\",\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"]],\"parameters\":[]}],[6,\"small\"],[7],[4,\"if\",[[20,[\"decimal\"]]],null,{\"statements\":[[1,[18,\"decimal\"],false]],\"parameters\":[]},{\"statements\":[[0,\"   \"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    0,\"],[6,\"small\"],[7],[0,\"00\"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/number-display.hbs"
    }
  });

  _exports.default = _default;
});