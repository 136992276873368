define("crm/templates/components/task-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lB7WeQkI",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"clickable item-type-bg resize animated fadeIn appointment-card task-card \",[25,\"if\",[[20,[\"done\"]],\"done\"],null],\" \",[25,\"concat\",[\"task-card-mine\",[20,[\"model\",\"priority\"]]],null]]]],[10,\"id\",[25,\"concat\",[\"task-\",[20,[\"model\",\"id\"]]],null],null],[10,\"style\",[18,\"innerStyle\"],null],[3,\"action\",[[19,0,[]],\"openDetails\"]],[7],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"no-margin\"],[7],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"ellipsis\"],[10,\"title\",[20,[\"model\",\"name\"]],null],[7],[6,\"b\"],[7],[1,[20,[\"model\",\"name\"]],false],[8],[8],[0,\"\\n        \"],[6,\"br\"],[7],[8],[6,\"small\"],[9,\"class\",\"ellipsis text-weak\"],[10,\"title\",[20,[\"model\",\"description\"]],null],[7],[1,[20,[\"model\",\"description\"]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/task-card.hbs"
    }
  });

  _exports.default = _default;
});