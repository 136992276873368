define("crm/templates/organizations/organization/group/schedule-wait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "geoQSYsV",
    "block": "{\"symbols\":[\"elem\"],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"pages.scheduleWait\"],null],[25,\"t\",[\"icons.appointment\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[9,\"id\",\"stats\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"class\"],[\"module-stats\"]],{\"statements\":[[0,\"    \"],[6,\"ul\"],[9,\"class\",\"list-group clear-list m-t\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"scheduledWaitByType\"]]],null,{\"statements\":[[0,\"        \"],[6,\"li\"],[9,\"class\",\"list-group-item\"],[7],[0,\"\\n          \"],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[0,\"\\n            \"],[1,[25,\"moment-seconds-str\",[[19,1,[\"duration\"]]],null],false],[0,\"\\n          \"],[8],[0,\"\\n          \"],[6,\"span\"],[9,\"class\",\"label label-success\"],[7],[1,[19,1,[\"quantity\"]],false],[8],[0,\" \"],[1,[19,1,[\"name\"]],false],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/group/schedule-wait.hbs"
    }
  });

  _exports.default = _default;
});