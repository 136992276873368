define("crm/routes/organizations/organization/customers/customer/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var customer = this.modelFor("organizations.organization.customers.customer");
      return Ember.RSVP.hash({
        customer: this.store.findRecord("customer", customer.get("id"), {
          reload: true
        })
      }).then(function (hashResult) {
        return hashResult.customer;
      });
    },
    afterModel: function afterModel(customer) {
      this.store.query("reward", {
        customer: customer.get("id")
      });
      Ember.set(customer, "currentOrganization", this.modelFor("organizations.organization"));
      Ember.set(customer, "contacts", this.store.query("contact", {
        customer: customer.get("id")
      }));
      Ember.set(customer, "animals", this.store.query("patient", {
        owner: customer.get("id")
      }));
      Ember.set(customer, "customerInvoices", this.store.query("invoice", {
        customer: customer.get("id")
      }));
      Ember.set(customer, "addresses", this.store.query("address", {
        customer: customer.get("id")
      }));
      Ember.set(customer, "quotations", this.store.query("quotation", {
        customer: customer.get("id")
      }));
      Ember.set(customer, "memos", this.store.query("memo", {
        customer: customer.get("id")
      }));
      Ember.set(customer, "orders", this.store.query("order", {
        customer: customer.get("id")
      }));
      Ember.set(customer, "receipts", this.store.query("receipt", {
        customer: customer.get("id")
      }));
      Ember.set(customer, "payments", this.store.query("payment", {
        customer: customer.get("id")
      }));
      Ember.set(customer, "customerWorkers", this.store.query("customer-worker", {
        customer: customer.get("id")
      }));
      Ember.set(customer, "appointmentsByCustomer", this.store.query("appointment", {
        customer: customer.get("id")
      }));
      Ember.set(customer, "shoppingCartsByCustomer", this.store.query("shopping-cart", {
        customer: customer.get("id")
      }));
      Ember.set(customer, "periodics", this.store.query("periodic", {
        customer: customer.get("id")
      }));
      Ember.set(customer, "exams", this.store.query("exam", {
        customer: customer.get("id")
      }));
      Ember.set(customer, "customerTasks", this.store.query("task", {
        customer: customer.get("id")
      }));
      Ember.set(customer, "notes", this.store.query("note", {
        customer: customer.get("id")
      }));
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("CustomerFieldInts", this.store.query("CustomerFieldInt", {
        "customer": model.get("id")
      }));
      controller.set("CustomerFieldBooleans", this.store.query("CustomerFieldBoolean", {
        "customer": model.get("id")
      }));
      controller.set("CustomerFieldDates", this.store.query("CustomerFieldDate", {
        "customer": model.get("id")
      }));
      controller.set("CustomerFieldDoubles", this.store.query("CustomerFieldDouble", {
        "customer": model.get("id")
      }));
      controller.set("CustomerFieldStrings", this.store.query("CustomerFieldString", {
        "customer": model.get("id")
      }));
      controller.set("organization", this.modelFor("organizations.organization"));
      model.get("notes").sortBy("started");
    },
    actions: {
      getContacts: function getContacts() {
        var model = this.modelFor("organizations.organization.customers.customer");
        this.store.query("contact", {
          customer: model.get("id")
        }).then(function (contacts) {
          Ember.set(model, "contacts", contacts);
        });
      },
      getNotes: function getNotes() {
        var model = this.modelFor("organizations.organization.customers.customer");
        this.store.query("note", {
          customer: model.get("id")
        }).then(function (notes) {
          Ember.set(model, "notes", notes);
        });
      },
      openUpdateCustomer: function openUpdateCustomer(customerToEdit) {
        this.send("openUpdateCustomerModal", customerToEdit);
      }
    }
  });

  _exports.default = _default;
});