define("crm/templates/components/drug-details-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xOPw89Es",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"hideFooter\",\"modalSize\"],[\"modal-drug-details\",[20,[\"model\",\"name\"]],true,\"sm\"]],{\"statements\":[[0,\"    \"],[1,[25,\"drug-ibox\",null,[[\"model\"],[[20,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/drug-details-modal.hbs"
    }
  });

  _exports.default = _default;
});