define("crm/templates/components/specie-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ODJ2KwHc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"hideFooter\",\"class\"],[\"modal-specie-list\",[25,\"t\",[\"configurations.species.title\"],null],true,\"module-crm\"]],{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"disable\",\"enable\"],[[20,[\"model\"]],[20,[\"columns\"]],\"disable\",\"enable\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/specie-list.hbs"
    }
  });

  _exports.default = _default;
});