define("crm/templates/components/report-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cO9vya59",
    "block": "{\"symbols\":[\"file\",\"exam\"],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"hideFooter\",\"reset\",\"closed\",\"modalSize\"],[\"modal-report-edit\",[25,\"t\",[\"documents.document.title\"],null],true,\"reset\",[20,[\"closed\"]],\"lg\"]],{\"statements\":[[4,\"custom-form\",null,[[\"model\",\"save\",\"actionText\",\"canceled\",\"reset\",\"disabledAction\"],[[20,[\"customFormFields\"]],\"save\",\"crud.update\",\"canceled\",\"reset\",[20,[\"disabledSubmission\"]]]],{\"statements\":[[0,\"    \"],[6,\"fieldset\"],[7],[0,\"\\n      \"],[6,\"legend\"],[7],[1,[25,\"t\",[\"exams.exam.title\"],null],false],[8],[0,\"\\n\"],[4,\"power-select\",null,[[\"class\",\"searchField\",\"allowClear\",\"options\",\"selected\",\"onchange\"],[\"form-control\",\"name\",true,[20,[\"model\",\"patient\",\"exams\"]],[20,[\"model\",\"exam\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"model\",\"exam\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[19,2,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"exam\",\"filesSorted\"]]],null,{\"statements\":[[0,\"        \"],[6,\"hr\"],[7],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"model\",\"exam\",\"filesSorted\"]]],null,{\"statements\":[[0,\"            \"],[6,\"div\"],[9,\"class\",\"col-xs-6 col-md-4 col-xl-3\"],[7],[0,\"\\n              \"],[1,[25,\"file-display\",null,[[\"model\",\"hideDelete\"],[[19,1,[]],true]]],false],[0,\"\\n            \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[1,[18,\"files\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/report-edit.hbs"
    }
  });

  _exports.default = _default;
});