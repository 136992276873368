define("crm/templates/organizations/organization/warehouses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Plsdnw7R",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"model\",\"procurementFeature\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"module-procurement\"],[7],[0,\"\\n    \"],[1,[18,\"outlet\"],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"unavailable-feature\",null,[[\"module\",\"feature\"],[\"module-procurement\",\"procurement\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/warehouses.hbs"
    }
  });

  _exports.default = _default;
});