define("crm/templates/email-validated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uoJJEuv3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ibox-panel\",null,[[\"title\",\"hideTools\"],[[25,\"t\",[\"emailValidations.title\"],null],true]],{\"statements\":[[0,\"    \"],[6,\"h2\"],[7],[1,[25,\"t\",[\"emailValidations.validated\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/email-validated.hbs"
    }
  });

  _exports.default = _default;
});