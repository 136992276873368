define("crm/templates/components/weights-timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a/BA6r2a",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"vertical-timeline-icon \",[20,[\"model\",\"className\"]]]]],[7],[0,\"\\n    \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[25,\"if\",[[20,[\"activity\",\"icon\"]],[20,[\"activity\",\"icon\"]],\"clock-o\"],null]]]],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"vertical-timeline-content\"],[7],[0,\"\\n    \"],[6,\"h2\"],[7],[1,[20,[\"activity\",\"title\"]],false],[8],[0,\"\\n    \"],[6,\"p\"],[7],[1,[20,[\"activity\",\"description\"]],false],[8],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"vertical-date\"],[7],[1,[25,\"pu-chronos-date\",[[20,[\"activity\",\"date\"]]],null],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/weights-timeline.hbs"
    }
  });

  _exports.default = _default;
});