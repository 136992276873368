define("crm/templates/organizations/organization/group/suppliers-current-accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ICqMPpm8",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-12\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"class\",\"title\",\"titleIcon\",\"titleCount\"],[\"module-procurement\",[25,\"t\",[\"suppliers.title\"],null],[25,\"t\",[\"pages.currentAccounts\"],null],[20,[\"model\",\"length\"]]]],{\"statements\":[[0,\"            \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"model\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/group/suppliers-current-accounts.hbs"
    }
  });

  _exports.default = _default;
});