define("crm/templates/components/role-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sv1mr4iy",
    "block": "{\"symbols\":[],\"statements\":[[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw \",[18,\"faClass\"]]]],[10,\"title\",[26,[[18,\"id2str\"]]]],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/role-icon.hbs"
    }
  });

  _exports.default = _default;
});