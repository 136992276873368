define("crm/models/document-line", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    description: _emberData.default.attr("string"),
    discount: _emberData.default.attr("number"),
    itemIsStockable: _emberData.default.attr("boolean"),
    itemUnitName: _emberData.default.attr("string"),
    lineGroup: _emberData.default.attr("number"),
    lineGroupTree: _emberData.default.attr("string"),
    treeLevel: _emberData.default.attr("number"),
    linePrice: _emberData.default.attr("number"),
    quantity: _emberData.default.attr("number"),
    unitPrice: _emberData.default.attr("number"),
    warehouseName: _emberData.default.attr("string"),
    linePriceWoVat: _emberData.default.attr("number"),
    vatLinePrice: _emberData.default.attr("number"),
    vatAmount: _emberData.default.attr("number"),
    unitPriceWoVat: _emberData.default.attr("number"),
    item: _emberData.default.belongsTo("item"),
    itemUnit: _emberData.default.belongsTo("item-unit"),
    warehouse: _emberData.default.belongsTo("warehouse"),
    vat: _emberData.default.belongsTo("tax"),
    // AKA company-vat
    lineGroupOdd: Ember.computed("lineGroup", function () {
      return this.get("lineGroup") % 2;
    }),
    getVat: Ember.computed("vat.id", "item.vat.id", function () {
      return this.get("vat.id") ? this.get("vat") : this.get("item.vat");
    }),
    unitPriceFinal: Ember.computed("unitPrice", "itemUnit.quantity", function () {
      return this.get("itemUnit.quantity") ? this.get("unitPrice") / this.get("itemUnit.quantity") : this.get("unitPrice");
    }),
    tax: Ember.computed("vat", function () {
      return this.get("vat");
    }),
    unit: Ember.computed("itemUnit.unit.symbol", "item.baseUnit.symbol", function () {
      return this.get("itemUnit.unit") ? this.get("itemUnit.unit") : this.get("item.baseUnit");
    })
  });

  _exports.default = _default;
});