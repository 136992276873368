define("crm/templates/organizations/organization/sheltereds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+7FN5Od3",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"module-crm\"],[7],[0,\"\\n    \"],[1,[18,\"outlet\"],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/sheltereds.hbs"
    }
  });

  _exports.default = _default;
});