define("crm/templates/components/invoice-memos-total", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dlVngwU6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ibox-panel\",null,[[\"class\",\"title\",\"hideCollapse\"],[\"module-sales\",[25,\"t\",[\"modules.sales.title\"],null],[20,[\"hideCollapse\"]]]],{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n      \"],[6,\"ul\"],[9,\"class\",\"col-xs-12 col-sm-offset-3 col-sm-6\"],[7],[0,\"\\n        \"],[6,\"li\"],[9,\"class\",\"list-group-item m-b-sm\"],[7],[0,\"\\n          \"],[6,\"strong\"],[9,\"class\",\"pull-right\"],[7],[1,[18,\"total\"],false],[0,\" \"],[1,[25,\"t\",[\"icons.currency\"],null],false],[8],[6,\"strong\"],[7],[1,[25,\"t\",[\"filters.total\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n      \"],[6,\"ul\"],[9,\"class\",\"col-xs-12 col-sm-6\"],[7],[0,\"\\n        \"],[6,\"li\"],[9,\"class\",\"list-group-item m-b-sm\"],[7],[0,\"\\n          \"],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[1,[18,\"invoiceTotal\"],false],[0,\" \"],[1,[25,\"t\",[\"icons.currency\"],null],false],[8],[6,\"strong\"],[7],[1,[25,\"t\",[\"invoices.title\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"ul\"],[9,\"class\",\"col-xs-12 col-sm-6\"],[7],[0,\"\\n        \"],[6,\"li\"],[9,\"class\",\"list-group-item m-b-sm\"],[7],[0,\"\\n          \"],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[1,[18,\"memoTotal\"],false],[0,\" \"],[1,[25,\"t\",[\"icons.currency\"],null],false],[8],[6,\"strong\"],[7],[1,[25,\"t\",[\"filters.memos\"],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/invoice-memos-total.hbs"
    }
  });

  _exports.default = _default;
});