define("crm/templates/components/warehouse-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eo6zy1TG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"class\",\"title\",\"hideFooter\",\"reset\",\"closed\"],[\"openEditWarehouse\",\"module-sales\",[25,\"t\",[\"warehouses.edit.title\"],null],true,\"reset\",[20,[\"closed\"]]]],{\"statements\":[[4,\"custom-form\",null,[[\"save\",\"actionText\",\"model\",\"disabledAction\",\"canceled\"],[\"modalEditWarehouse\",\"crud.update\",[20,[\"model\"]],[20,[\"disabledAction\"]],\"cancelEditWarehouseModal\"]],{\"statements\":[[0,\"    \"],[6,\"fieldset\"],[7],[0,\"\\n      \"],[2,\" Name \"],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n        \"],[6,\"label\"],[9,\"class\",\"col-xs-12 col-sm-3 control-label\"],[7],[1,[25,\"t\",[\"warehouses.warehouse.name\"],null],false],[0,\" \"],[6,\"span\"],[9,\"class\",\"text-danger\"],[10,\"title\",[25,\"t\",[\"validations.fieldRequired\"],null],null],[7],[0,\" *\"],[8],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-9\"],[7],[0,\"\\n          \"],[1,[25,\"input\",null,[[\"value\",\"placeholder\",\"class\",\"autofocus\",\"required\"],[[20,[\"model\",\"internalDescription\"]],[25,\"t\",[\"warehouses.form.placeholder.name\"],null],\"form-control\",true,true]]],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/warehouse-edit.hbs"
    }
  });

  _exports.default = _default;
});