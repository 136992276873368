define("crm/templates/components/show-breeds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wqkooJTe",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"eq\",[[20,[\"record\",\"breed\"]],0],null]],null,{\"statements\":[[0,\"    --\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[20,[\"record\",\"breed\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-breeds.hbs"
    }
  });

  _exports.default = _default;
});