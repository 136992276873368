define("crm/templates/components/suppliers-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Odviyo8w",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"table-actions\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"hidle text-center\"],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-ellipsis-h disabled\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"actions\"],[7],[0,\"\\n    \"],[2,\" details \"],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-4 col-md-4 col-sm-4 col-lg-4\"],[7],[0,\"\\n      \"],[4,\"link-to\",[\"organizations.organization.suppliers.supplier\",[20,[\"record\",\"id\"]]],[[\"title\"],[[25,\"t\",[\"actions.details\"],null]]],{\"statements\":[[0,\" \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.detail\"],null]]]],[7],[8],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"canDoSuppliers\"]]],null,{\"statements\":[[0,\"      \"],[2,\" edit \"],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"col-xs-4 col-md-4 col-sm-4 col-lg-4\"],[7],[0,\"\\n        \"],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"actions.edit\"],null]]]],[3,\"action\",[[19,0,[]],\"openSupplierEdit\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.edit\"],null]]]],[7],[8],[8],[0,\"\\n      \"],[8],[0,\"\\n\\n\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/suppliers-actions.hbs"
    }
  });

  _exports.default = _default;
});