define("crm/templates/components/notes-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1OjoqaKc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"filtered\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"class\",\"openModal\",\"openEdit\"],[[20,[\"filtered\"]],[20,[\"columns\"]],\"hide-search table-fixed\",\"openModal\",\"openEdit\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.notes\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/notes-list.hbs"
    }
  });

  _exports.default = _default;
});