define("crm/templates/components/procurement/purchase-entity-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UmB4Kk4j",
    "block": "{\"symbols\":[\"savedEntity\"],\"statements\":[[6,\"div\"],[9,\"class\",\"models-table-wrapper u-models-table table-actions-wrapper\"],[7],[0,\"\\n  \"],[6,\"table\"],[9,\"class\",\"table table-striped table-bordered table-condensed\"],[7],[0,\"\\n    \"],[6,\"thead\"],[7],[0,\"\\n    \"],[6,\"tr\"],[7],[0,\"\\n      \"],[6,\"th\"],[9,\"class\",\"column-number-4\"],[7],[0,\" \"],[8],[0,\"\\n      \"],[6,\"th\"],[9,\"class\",\"hidden-xs hidden-sm column-date\"],[7],[0,\" \"],[8],[0,\"\\n      \"],[6,\"th\"],[7],[0,\" \"],[8],[0,\"\\n      \"],[6,\"th\"],[7],[0,\" \"],[8],[0,\"\\n      \"],[6,\"th\"],[10,\"class\",[26,[\"column-actions-\",[25,\"if\",[[20,[\"actionsCols\"]],[20,[\"actionsCols\"]],\"4\"],null]]]],[7],[0,\" \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"tbody\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[6,\"tr\"],[7],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"text-right\"],[7],[4,\"link-to\",[[20,[\"entityLink\"]],[19,1,[\"id\"]]],null,{\"statements\":[[1,[19,1,[\"code\"]],false]],\"parameters\":[]},null],[8],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"hidden-xs hidden-sm\"],[7],[1,[25,\"pu-chronos-date\",[[19,1,[\"updated\"]]],null],false],[8],[0,\"\\n        \"],[6,\"td\"],[7],[4,\"link-to\",[\"organizations.organization.suppliers.supplier\",[19,1,[\"supplier\",\"id\"]]],null,{\"statements\":[[1,[19,1,[\"supplier\",\"name\"]],false]],\"parameters\":[]},null],[8],[0,\"\\n        \"],[6,\"td\"],[9,\"class\",\"ellipsis\"],[10,\"title\",[19,1,[\"notes\"]],null],[7],[1,[19,1,[\"notes\"]],false],[8],[0,\"\\n        \"],[6,\"td\"],[7],[1,[25,\"purchase-entity-actions\",null,[[\"record\",\"transitionToRoute\",\"receivePurchaseOrder\"],[[19,1,[]],[20,[\"transitionToRoute\"]],[20,[\"receivePurchaseOrder\"]]]]],false],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/procurement/purchase-entity-table.hbs"
    }
  });

  _exports.default = _default;
});