define("crm/templates/components/sms-sent-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XOkJjhr/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"class\",\"title\",\"modalSize\",\"hideFooter\"],[\"modal-sms-sent-details\",\"module-crm\",[25,\"t\",[\"sms.sent.title\"],null],\"md\",true]],{\"statements\":[[0,\"    \"],[4,\"if\",[[20,[\"model\",\"text\"]]],null,{\"statements\":[[6,\"p\"],[9,\"class\",\"well\"],[7],[1,[20,[\"model\",\"text\"]],false],[8]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/sms-sent-details.hbs"
    }
  });

  _exports.default = _default;
});