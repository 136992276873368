define("crm/routes/organizations/organization/templates/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var model = [];
      model.set("organization", this.modelFor("organizations.organization"));
      return model;
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("model", {});
        controller.set("section", []);
        controller.set("fields", [{}]);
        controller.set("control", 0);
      }
    }
  });

  _exports.default = _default;
});