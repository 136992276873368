define("crm/templates/components/title-models-table-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v8OKeh/O",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"and\",[[20,[\"column\",\"path\"]],[20,[\"pathId\"]]],null]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",[[20,[\"column\",\"path\"]],[20,[\"pathId\"]]],[[\"title\"],[[20,[\"title\"]]]],{\"statements\":[[0,\" \"],[6,\"span\"],[7],[1,[18,\"title\"],false],[8],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"span\"],[10,\"title\",[18,\"title\"],null],[7],[1,[18,\"title\"],false],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/title-models-table-display.hbs"
    }
  });

  _exports.default = _default;
});