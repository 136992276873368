define("crm/templates/organizations/organization/manufacturers/manufacturer/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zj/YcdUI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"pages.manufacturer\"],null],[25,\"t\",[\"icons.manufacturers\"],null]]],{\"statements\":[[0,\"  \"],[6,\"li\"],[7],[4,\"link-to\",[\"organizations.organization.manufacturers.manufacturer.edit\"],[[\"title\"],[[25,\"t\",[\"actions.edit\"],null]]],{\"statements\":[[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.pencilSquare\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"actions.edit\"],null],false]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"hideCollapse\"],[true]],{\"statements\":[[0,\"    \"],[6,\"h3\"],[7],[1,[20,[\"model\",\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/manufacturers/manufacturer/index.hbs"
    }
  });

  _exports.default = _default;
});