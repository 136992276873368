define("crm/templates/components/umt-quantity-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rBZUiF82",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"record\",\"quantity\"]]],null,{\"statements\":[[4,\"unless\",[[20,[\"hideSymbol\"]]],null,{\"statements\":[[0,\"        \"],[6,\"small\"],[7],[0,\"x\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[20,[\"record\",\"quantity\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/umt-quantity-display.hbs"
    }
  });

  _exports.default = _default;
});