define("crm/templates/components/purchase-order-lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XYq45YTN",
    "block": "{\"symbols\":[\"purchaseOrderLine\",\"warehouse\"],\"statements\":[[4,\"each\",[[20,[\"purchaseOrderLines\"]]],null,{\"statements\":[[0,\"    \"],[2,\" PRODUCT \"],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-4\"],[7],[0,\"\\n            \"],[6,\"h4\"],[9,\"class\",\"ellipsis\"],[7],[6,\"strong\"],[7],[1,[19,1,[\"item\",\"name\"]],false],[8],[8],[0,\"\\n            \"],[6,\"h4\"],[7],[0,\"\\n                \"],[6,\"small\"],[9,\"class\",\"ellipsis\"],[7],[1,[19,1,[\"item\",\"description\"]],false],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-3\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"input-group\"],[7],[0,\"\\n                \"],[1,[25,\"input\",null,[[\"type\",\"class\",\"value\",\"placeholder\",\"min\",\"step\",\"required\"],[\"number\",\"form-control\",[19,1,[\"quantity\"]],\"0\",\"1\",\"1\",true]]],false],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"input-group-addon\"],[7],[0,\"x\"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-3\"],[7],[0,\"\\n\"],[4,\"power-select\",null,[[\"class\",\"options\",\"selected\",\"onchange\"],[\"form-control\",[20,[\"warehouses\"]],[19,1,[\"warehouse\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[19,1,[\"warehouse\"]]],null]],null]]],{\"statements\":[[0,\"                \"],[1,[19,2,[\"internalDescription\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-2 pull-right\"],[7],[0,\"\\n            \"],[6,\"a\"],[3,\"action\",[[19,0,[]],\"deletePurchaseOrderLine\",[19,1,[]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[25,\"t\",[\"icons.delete\"],null]]]],[9,\"aria-hidden\",\"true\"],[7],[8],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"hr\"],[7],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/purchase-order-lines.hbs"
    }
  });

  _exports.default = _default;
});