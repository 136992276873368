define("crm/templates/components/campaign-ibox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LNV+PZZR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ibox-panel\",null,[[\"titleIcon\",\"title\",\"class\",\"title\",\"contentHeading\",\"hideCollapse\",\"hideTools\"],[[25,\"t\",[\"icons.campaign\"],null],[25,\"t\",[\"campaigns.campaign.title\"],null],\"module-crm\",[20,[\"model\",\"title\"]],[20,[\"model\",\"description\"]],[20,[\"hideCollapse\"]],[20,[\"hideTools\"]]]],{\"statements\":[[0,\"    \"],[1,[25,\"campaign-details\",null,[[\"model\"],[[20,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/campaign-ibox.hbs"
    }
  });

  _exports.default = _default;
});