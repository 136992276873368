define("crm/templates/organizations/organization/internments/overview-shopping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qGakThln",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[7],[0,\"\\n    \"],[6,\"section\"],[9,\"class\",\"page-body\"],[7],[0,\"\\n        \"],[6,\"article\"],[9,\"class\",\"ibox\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n                \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openDetails\"],[[20,[\"model\"]],[20,[\"columns\"]],\"openDetails\"]]],false],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/internments/overview-shopping.hbs"
    }
  });

  _exports.default = _default;
});