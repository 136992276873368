define("crm/templates/components/stays-leaving-today", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zXvvHm4x",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ibox-panel\",null,[[\"titleIcon\",\"title\",\"class\",\"hideCollapse\",\"hideTools\"],[[25,\"t\",[\"icons.checkout\"],null],[25,\"t\",[\"stays.staysLeavingToday\"],null],\"module-hotel\",true,true]],{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"multipleColumnsSorting\",\"multipleSelect\",\"filteringIgnoreCase\",\"selectRowOnClick\",\"showColumnsDropdown\",\"class\"],[[20,[\"model\"]],[20,[\"columns\"]],false,false,true,true,false,\"hide-search\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/stays-leaving-today.hbs"
    }
  });

  _exports.default = _default;
});