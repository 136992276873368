define("crm/templates/organizations/organization/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l8bHEyYw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"templates.title\"],null],[25,\"t\",[\"icons.purchases\"],null]]],{\"statements\":[[0,\"  \"],[6,\"li\"],[7],[4,\"link-to\",[\"organizations.organization.templates.new\"],[[\"title\"],[[25,\"t\",[\"crud.create\"],null]]],{\"statements\":[[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.purchases\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"templates.new.title\"],null],false]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"u-models-table\",null,[[\"data\",\"columns\",\"callModal\",\"document\",\"deleteExam\"],[[20,[\"model\",\"templates\"]],[20,[\"columns\"]],\"callModal\",\"document\",\"deleteExam\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.purchases\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/templates/index.hbs"
    }
  });

  _exports.default = _default;
});