define("crm/templates/components/pdf-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RK09hfwD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"modalSize\"],[\"modal-pdf-viewer-view-pdf\",\"lg\"]],{\"statements\":[[0,\"    \"],[1,[25,\"js-pdf\",null,[[\"src\"],[[20,[\"src\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/pdf-viewer.hbs"
    }
  });

  _exports.default = _default;
});