define("crm/templates/organizations/organization/workers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XeW/A9Ff",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"workers.title\"],null],[25,\"t\",[\"icons.workers\"],null]]],{\"statements\":[[4,\"if\",[[25,\"can\",[\"create worker\"],null]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[4,\"link-to\",[\"organizations.organization.workers.new\"],null,{\"statements\":[[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"fa-stack\"],[7],[0,\"\\n          \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[25,\"t\",[\"icons.worker\"],null],\" fa-stack-1x\"]]],[7],[8],[0,\"\\n          \"],[6,\"i\"],[9,\"class\",\"fa fa-exchange fa-stack-05x\"],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[1,[25,\"t\",[\"workers.new.title\"],null],false]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"title\"],[[25,\"t\",[\"models.role.administrator\"],null]]],{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openEditWorkerModal\"],[[20,[\"managers\"]],[20,[\"columns\"]],\"openEditWorkerModal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"ibox-panel\",null,[[\"title\"],[[25,\"t\",[\"models.role.undermanager\"],null]]],{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openEditWorkerModal\"],[[20,[\"undermanagers\"]],[20,[\"columns\"]],\"openEditWorkerModal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"ibox-panel\",null,[[\"title\"],[[25,\"t\",[\"workers.title\"],null]]],{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openEditWorkerModal\"],[[20,[\"others\"]],[20,[\"columns\"]],\"openEditWorkerModal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/workers/index.hbs"
    }
  });

  _exports.default = _default;
});