define("crm/templates/organizations/organization/liaisons/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "57oe9n0B",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\",\"class\"],[[25,\"t\",[\"pages.liaisons\"],null],[25,\"t\",[\"icons.liaisons\"],null],\"module-referral\"]],{\"statements\":[[4,\"if\",[[20,[\"canDoLiaisons\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[6,\"a\"],[9,\"class\",\"module-referral\"],[3,\"action\",[[19,0,[]],\"newEnterprise\"]],[7],[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.liaisons\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"enterprises.new.title\"],null],false],[8],[8],[0,\"\\n    \"],[6,\"li\"],[7],[6,\"a\"],[9,\"class\",\"module-referral\"],[3,\"action\",[[19,0,[]],\"newLiaison\"]],[7],[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.workers\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"liaisons.new.title\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"class\"],[\"module-referral\"]],{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"callModal\"],[[20,[\"model\",\"enterprises\"]],[20,[\"columns\"]],\"callModal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/liaisons/index.hbs"
    }
  });

  _exports.default = _default;
});