define("crm/templates/components/internment/financial-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BVPSZKij",
    "block": "{\"symbols\":[],\"statements\":[[6,\"strong\"],[7],[1,[25,\"t\",[\"quotations.quotationsConsumed\"],null],false],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"progress\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"progress-bar progress-bar-striped\"],[9,\"role\",\"progressbar\"],[10,\"style\",[26,[\"width: \",[18,\"quotationFilled\"],\"%\"]]],[9,\"aria-valuenow\",\"0\"],[9,\"aria-valuemin\",\"0\"],[9,\"aria-valuemax\",\"0\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"br\"],[7],[8],[0,\"\\n\"],[6,\"strong\"],[7],[1,[25,\"t\",[\"shoppingCarts.percentagePaid\"],null],false],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"progress\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"progress-bar progress-bar-striped\"],[9,\"role\",\"progressbar\"],[10,\"style\",[26,[\"width: \",[18,\"shoppingCartsFilled\"],\"%\"]]],[9,\"aria-valuenow\",\"0\"],[9,\"aria-valuemin\",\"0\"],[9,\"aria-valuemax\",\"0\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/internment/financial-status.hbs"
    }
  });

  _exports.default = _default;
});