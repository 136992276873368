define("crm/templates/organizations/organization/equipment-parameters/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QTeDpEP9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"pages.equipmentParameters\"],null],[25,\"t\",[\"icons.manufacturers\"],null]]],{\"statements\":[[4,\"if\",[[20,[\"canDoEquipmentParameters\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[4,\"link-to\",[\"organizations.organization.equipment-parameters.new\"],[[\"title\"],[[25,\"t\",[\"crud.create\"],null]]],{\"statements\":[[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.manufacturers\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"equipmentParameters.new.title\"],null],false]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"hideCollapse\"],[true]],{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"transitionToRoute\",\"useFilteringByColumns\"],[[20,[\"model\"]],[20,[\"columns\"]],\"transitionToRoute\",true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/equipment-parameters/index.hbs"
    }
  });

  _exports.default = _default;
});