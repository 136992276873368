define("crm/templates/components/item-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H0DZuBQm",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"item-type-border bg-white appointment-card  \",[25,\"if\",[[20,[\"isLoading\"]],\"clickable disabled\"],null],\" \",[20,[\"model\",\"codes\"]]]]],[10,\"id\",[25,\"concat\",[\"item-\",[20,[\"model\",\"id\"]]],null],null],[7],[0,\"\\n  \"],[6,\"h3\"],[9,\"class\",\"ellipsis no-margin\"],[10,\"title\",[20,[\"model\",\"name\"]],null],[7],[1,[20,[\"model\",\"name\"]],false],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"hasComplementary\"]]],null,{\"statements\":[[0,\"    \"],[6,\"p\"],[9,\"class\",\"ellipsis no-margin\"],[7],[0,\"\\n      \"],[6,\"small\"],[9,\"class\",\"text-weak\"],[7],[0,\"\\n        \"],[6,\"span\"],[10,\"title\",[20,[\"model\",\"complementaryDescription\"]],null],[7],[1,[20,[\"model\",\"complementaryDescription\"]],false],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/item-card.hbs"
    }
  });

  _exports.default = _default;
});