define("crm/routes/organizations/organization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    menu: Ember.inject.service(),
    can: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    beforeModel: function beforeModel(transition) {
      var orgID = transition.params["organizations.organization"].organization_id;

      if (["999"].includes(orgID)) {
        window.location.replace("https://app.petuniversal.com/beta/#/organizations/" + orgID);
      }
    },
    model: function model(params) {
      var self = this;
      return Ember.RSVP.hash({
        model: this.store.findRecord("organization", params.organization_id),
        ornanizationPet: this.store.findRecord("organization-pet", params.organization_id),
        oFeatures: this.store.query("organization-feature", {
          organization: params.organization_id
        }),
        me: this.store.query("worker", {
          organization: params.organization_id,
          me: true
        }),
        organizationSettingBooleans: this.store.query("organizationSettingBoolean", {
          organization: params.organization_id
        }),
        symptoms: this.store.query("symptom", {
          organization: params.organization_id
        }),
        diagnostics: this.store.query("diagnostic", {
          organization: params.organization_id
        }),
        treatmentPlans: this.store.query("treatmentPlan", {
          organization: params.organization_id
        }),
        enterprises: this.store.query("enterprise", {
          organization: params.organization_id
        }),
        liaisons: this.store.query("liaison", {
          organization: params.organization_id
        }),
        warehouses: this.store.query("warehouse", {
          organization: params.organization_id
        }),
        siblings: this.store.query("organization", {
          organization: params.organization_id,
          siblings: true
        }),
        workers: this.store.query("worker", {
          organization: params.organization_id
        })
      }).then(function (hashResult) {
        self.store.query("worker", {
          organization: params.organization_id
        });
        hashResult.model.set("pet", hashResult.ornanizationPet);
        Ember.set(hashResult.model, "siblings", hashResult.siblings);

        if (hashResult.me.get("length")) {
          var worker = hashResult.me.get("firstObject");
          self.get("sessionAccount").set("currentWorker", worker);
          Ember.$("body").addClass("wr" + worker ? worker.get("role") : "");
        } else if (!self.store.peekRecord("worker", 0)) {
          self.get("sessionAccount").set("currentWorker", self.store.createRecord("worker", self.store.peekRecord("worker", 0) || {
            id: 0,
            name: "God"
          }));
        }

        return hashResult.model;
      });
    },
    afterModel: function afterModel(model) {
      var self = this;
      this.set("breadCrumb", {
        finalTitle: model.get("name")
      });
      this.store.query("tax", {
        company: model.get("company.id"),
        purchase: true
      }); // needs explicit load due lack of relation to organization

      /*
         this.store.findRecord("organization-avatar", model.get("id"))
         .then(a => model.set("avatar", a), () => { false });
      */

      this.store.query("organization-avatar", {
        organization: model.get("id")
      }).then(function (avatars) {
        if (avatars && avatars.get("length")) {
          model.set("avatar", avatars.get("firstObject"));
        }
      });
      var menu = [];

      for (var key in this.get("menuLinks")) {
        var section = this.get("menuLinks")[key];
        section.section = key;

        if (!section.free && !section.future) {
          Ember.set(section, "blocked", true);

          if (section.menu) {
            section.menu.forEach(function (menuItemItem) {
              if (!menuItemItem.future) {
                if (!menuItemItem.free) {
                  Ember.set(menuItemItem, "blocked", true);
                } else {
                  Ember.set(section, "blocked", false);
                }
              }
            });
          }
        }

        menu = menu.concat(section);
      }
      /*
       * Left Menu
       */


      this.store.peekAll("feature").forEach(function (feature) {
        model.set(feature.get("name") + 'Feature', false);
      });
      model.get("features").forEach(function (feature) {
        model.set(feature.get("name") + 'Feature', true);
        menu.forEach(function (section) {
          if (section.section == feature.get("name") || section.feature == feature.get("name")) {
            Ember.set(section, "blocked", false);

            if (section.menu) {
              section.menu.forEach(function (subsection) {
                if (!subsection.feature) {
                  if (subsection.skipFeature && model.get("features").map(function (f) {
                    return f.get("name");
                  }).includes(subsection.skipFeature)) {// set(menuItemItem, "blocked", true);
                  } else {
                    Ember.set(subsection, "blocked", false);
                  }
                }
              });
            }
          } else if (section.menu) {
            section.menu.forEach(function (subsection) {
              if (feature.get("name") === subsection.feature) {
                Ember.set(subsection, "blocked", false);
                Ember.set(section, "blocked", false);
              }
            });
          }
        });
      });

      if (this.get("can").can("do god")) {
        menu.push({
          iconName: this.get('intl').t("icons.manufacturers"),
          name: this.get('intl').t("pages.manufacturers"),
          class: "module-system",
          title: this.get('intl').t("pages.manufacturers"),
          menu: [{
            link: "organizations.organization.manufacturers",
            iconName: this.get('intl').t("icons.manufacturers"),
            name: this.get('intl').t("manufacturers.title"),
            title: this.get('intl').t("manufacturers.title"),
            free: true
          }, {
            link: "organizations.organization.equipments",
            iconName: this.get('intl').t("icons.manufacturers"),
            name: this.get('intl').t("pages.equipments"),
            title: this.get('intl').t("pages.equipments"),
            free: true
          }, {
            link: "organizations.organization.equipment-types",
            iconName: this.get('intl').t("icons.manufacturers"),
            name: this.get('intl').t("pages.equipmentTypes"),
            title: this.get('intl').t("pages.equipmentTypes"),
            free: true
          }, {
            link: "organizations.organization.equipment-parameters",
            iconName: this.get('intl').t("icons.manufacturers"),
            name: this.get('intl').t("pages.equipmentParameters"),
            title: this.get('intl').t("pages.equipmentParameters"),
            free: true
          }, {
            link: "organizations.organization.equipment-parameter-types",
            iconName: this.get('intl').t("icons.manufacturers"),
            name: this.get('intl').t("pages.equipmentParameterTypes"),
            title: this.get('intl').t("pages.equipmentParameterTypes"),
            free: true
          }]
        });
        menu.push({
          link: "organizations.organization.jasmin",
          iconName: "gears",
          name: "Admin",
          class: "module-system",
          title: "Admin",
          free: true
        });
      }

      self.get("menu").set("links", menu);
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      var organizationID = model.get("id");
      var companyID = model.get("company.id");
      var self = this;
      controller.set("model", model);
      var pet = this.store.findRecord("organization-pet", organizationID);
      model.set("pet", pet);
      controller.set("organizationPet", pet);
      controller.set('organizationCommunication', this.store.findRecord('organization-communication', organizationID));
      /* Items */

      this.store.query("company-item-type", {
        company: companyID
      }).then(function () {
        return controller.set("itemTypes", model.get("company.itemTypes"));
      });
      controller.set("taxes", model.get("company.taxes"));
      controller.set("units", this.store.query("unit", {
        company: companyID
      }));
      controller.set("enterprises", model.get("enterprises"));
      model.get("features").forEach(function (feature) {
        if ("crm" === feature.get('name')) {
          /* customer */
          controller.set('customerTitles', _this.store.query('customer-title', {
            organization: organizationID
          }));
          controller.set("noteTypes", _this.store.peekAll('note-type'));
          controller.set("outcomeTypes", _this.store.peekAll('outcome-type'));
          controller.set("customerFieldTypes", _this.store.query("customerFieldType", {
            organization: organizationID
          }));
          /* patients */

          controller.set("breeds", _this.store.peekAll('breed'));
          controller.get("breeds").forEach(function (breed) {
            Ember.set(breed, "name", self.get('intl').t("breeds." + breed.get("id")));
          });
          controller.set("deathTypes", _this.store.query("death-type", {
            organization: organizationID
          }));
          controller.set("diets", _this.store.peekAll('diet'));
          controller.set("tails", _this.store.peekAll('tail'));
          controller.set("species", _this.store.peekAll('species'));
          controller.get("species").forEach(function (species) {
            Ember.set(species, "name", self.get('intl').t("species." + species.get("id")));
          });
        }

        if ("calendar" === feature.get('name')) {
          controller.set('rooms', _this.store.query("room", {
            organization: organizationID
          }));
        }

        if ("clinical" === feature.get('name')) {
          Ember.RSVP.hash({
            ept: _this.store.query("equipment-parameter-type", {
              organization: organizationID
            }),
            m: _this.store.findAll("manufacturer"),
            et: _this.store.peekAll("equipment-type"),
            e: _this.store.query("equipment", {
              organization: organizationID
            })
          }).then(function (h) {
            controller.set("parameters", h.ept);
            controller.set("manufacturers", h.m);
            controller.set("equipmentTypes", h.et);
            controller.set("equipments", h.e);
          });
        }

        if ("sales" === feature.get('name')) {
          var adjustmentReasons = _this.store.findAll("adjustment-reason");

          if (!model.get("hasInternalConsumption")) {
            adjustmentReasons.removeObject(adjustmentReasons.filterBy("code", "05").objectAt(0));
          }

          controller.set("adjustmentReasons", adjustmentReasons);
          controller.set('brands', _this.store.query('brand', {
            organization: organizationID
          }));
          controller.set("debtStatuses", _this.store.query("debt-status", {
            company: companyID
          }));
          controller.set("financialAccounts", _this.store.query("financial-account", {
            organization: organizationID
          }));
          controller.set('models', _this.store.query('model', {
            organization: organizationID
          }));
          controller.set("warehouses", model.get("warehouses"));
          controller.set("companyItemSubTypes", _this.store.query("company-item-sub-type", {
            company: companyID
          }));
        }

        if ("procurement" === feature.get('name')) {
          _this.store.query('supplier', {
            company: companyID
          }).then(function (suppliers) {
            if (!Ember.isEmpty(suppliers)) {
              suppliers.forEach(function (s) {
                return s.set("company", model.get("company"));
              });
            }

            controller.set("suppliers", suppliers);
          });
        }

        if ("hotel" === feature.get('name')) {
          controller.set("boxes", _this.store.query("box", {
            organization: organizationID
          }));
        }

        if ("hr" === feature.get('name')) {
          Ember.RSVP.hash({
            absenceTypes: _this.store.query("absence-type", {
              organization: organizationID
            })
          }).then(function (h) {
            h.absenceTypes.forEach(function (absenceType) {
              Ember.set(absenceType, "name", self.get('intl').t("absences.type." + absenceType.get("code")));
            });
            controller.set("absenceTypes", h.absenceTypes);
          });
        }
      });
    },
    actions: {
      openModal: function openModal(container) {
        var modalName = "modal-" + container.entity + "-" + container.action;
        var modelName = modalName + "_model";
        var controller = this.get("controller");

        if (Ember.isEmpty(container.model)) {
          container.model = {
            "organization": controller.get("model")
          };
        } else if (Ember.isEmpty(container.model.organization)) {
          Ember.set(container.model, "organization", controller.get("model"));
        }

        controller.set(modelName, container.model);
        Ember.$("#" + modalName).modal();
      },
      resetQueryParams: function resetQueryParams() {
        var controller = this.get("controller");
        Ember.set(controller, "oldAppointment", null);
      },
      noPermissions: function noPermissions() {
        this.get("controller").set("showPermissionsDenied", true);
      },

      /* Worker */
      //New
      openModalCreateWorker: function openModalCreateWorker() {
        Ember.$("#openCreateWorker").modal();
      },
      //Edit
      openEditWorkerModal: function openEditWorkerModal(workerToEdit) {
        var controller = this.get("controller");
        controller.set("workerToEdit", workerToEdit);
        Ember.$("#openEditWorker").modal();
      },

      /* Appointment */
      //New
      openModalCreateAppointment: function openModalCreateAppointment() {
        Ember.$("#modal-appointment-create").modal();
      },
      // Details
      openModalAppointmentDetails: function openModalAppointmentDetails(appointment) {
        var controller = this.get("controller");
        controller.set("appointmentDetail", appointment);
        Ember.$("#openAppointmentDetails").modal();
      },

      /* Customer */
      // Edit
      openUpdateCustomerModal: function openUpdateCustomerModal(customerToEdit) {
        var controller = this.get("controller");
        controller.set("customerToEdit", customerToEdit);
        Ember.$("#openUpdateCustomer").modal();
      },

      /* Exam */
      // New
      openModalCreateExam: function openModalCreateExam() {
        Ember.$("#openCreateExam").modal();
      },
      // Add
      addObjectExam: function addObjectExam(exam) {
        if (exam) {
          this.get("exams").addObject(exam._internalModel);
        }
      },
      // Edit
      openEditExamModal: function openEditExamModal(examToEdit) {
        var controller = this.get("controller");
        controller.set("examToEdit", examToEdit);
        Ember.$("#openUpdateExam").modal();
      },

      /* Patient */
      //Edit
      openEditPatientModal: function openEditPatientModal(patientToEdit) {
        var controller = this.get("controller");
        controller.set("patientToEdit", patientToEdit);
        Ember.$("#openEditPatient").modal();
      },
      //Update
      updatePatient: function updatePatient(patientToUpdate) {
        var controller = this.get("controller");
        controller.get("patients").removeObject(patientToUpdate);
        controller.get("patients").addObject(patientToUpdate._internalModel);
      },

      /* Sheltered */
      //New
      openModalCreateSheltered: function openModalCreateSheltered(options) {
        var controller = this.controllerFor('organizations.organization');

        if (options) {
          if (options["newCustomer"]) {
            controller.set('newCustomer', options["newCustomer"]);
          }
        }

        Ember.$('#openCreateSheltered').modal();
      },
      //Edit
      openEditShelteredModal: function openEditShelteredModal(shelteredToEdit) {
        var controller = this.controllerFor('organizations.organization');
        controller.set('shelteredToEdit', shelteredToEdit);
        Ember.$('#openEditSheltered').modal();
      },

      /* Items */
      //New
      openModalCreateItem: function openModalCreateItem() {
        Ember.$("#openCreateItem").modal();
      },
      //Entry
      openModalEntryItem: function openModalEntryItem() {
        Ember.$("#openEntryItem").modal();
      },
      //Edit
      openEditItemModal: function openEditItemModal(itemToEdit) {
        var controller = this.get("controller");
        controller.set("itemToEdit", itemToEdit);
        Ember.$("#openEditItem").modal();
      },

      /* Warehouses */
      //New
      openModalCreateWarehouse: function openModalCreateWarehouse() {
        Ember.$("#openCreateWarehouse").modal();
      },
      //Edit
      openEditWarehouseModal: function openEditWarehouseModal(warehouseToEdit) {
        var controller = this.get("controller");
        controller.set("warehouseToEdit", warehouseToEdit);
        Ember.$("#openEditWarehouse").modal();
      },

      /* Helpers */
      reloadModel: function reloadModel() {
        this.refresh();
      },
      openModalPeriodicTypeCreate: function openModalPeriodicTypeCreate() {
        Ember.$("#openCreatePeriodicType").modal();
      },
      openModalPeriodicCreate: function openModalPeriodicCreate(patient, consultation, shoppingCart) {
        var controller = this.get("controller");
        var options = {
          organization: controller.get("model.id")
        };

        if (patient.get("species.id")) {
          options["species"] = patient.get("species.id");
        }

        controller.set("periodicPatient", this.get("store").peekRecord("patient", patient.get("id")));
        controller.set("periodicConsultation", this.get("store").peekRecord("consultation", consultation.get("id")));
        controller.set("periodicShoppingCart", this.get("store").peekRecord("shopping-cart", shoppingCart.get("id")));
        Ember.$("#openCreatePeriodic").modal();
      },
      openPDFModal: function openPDFModal(content) {
        var controller = this.get("controller");
        controller.set("pdfContent", content);
        Ember.$("#openCreatePDF").modal();
      },
      openOrgItemSelectModal: function openOrgItemSelectModal(consultation, orderId) {
        var controller = this.get("controller");
        controller.set("consultationCustomer", consultation);
        controller.set("orderId", orderId);
        Ember.$("#openItemSelectModal").modal();
      },
      orderLineCreated: function orderLineCreated(orderId) {
        var controller = this.get("controller");
        this.store.query("orderLine", {
          order: orderId,
          organization: controller.get("model.id")
        }).then(function (orderLines) {
          controller.set("orderLines", orderLines);
        });
      },
      transitionToRouteAppointmentsDay: function transitionToRouteAppointmentsDay(queryParams) {
        var controller = this.get("controller");

        if (Ember.isEmpty(queryParams)) {
          queryParams = {
            patient: null,
            worker: null,
            oldAppointment: null,
            customer: null,
            intakeDate: null
          };
        }

        this.transitionTo("organizations.organization.appointments.custom", controller.get("model.id"), {
          queryParams: queryParams
        });
        this.send("reloadModel");
      },
      transitionToRouteAppointmentsWeek: function transitionToRouteAppointmentsWeek(queryParams) {
        var controller = this.get("controller");

        if (Ember.isEmpty(queryParams)) {
          queryParams = {
            patient: null,
            worker: null,
            oldAppointment: null,
            customer: null,
            intakeDate: null
          };
        }

        this.transitionTo("organizations.organization.appointments.week", controller.get("model.id"), {
          queryParams: queryParams
        });
        this.send("reloadModel");
      },
      transitionToRoute: function transitionToRoute(route, params) {
        this.transitionTo(route, {
          queryParams: params
        });
      },
      transitionToRouteID: function transitionToRouteID(route, id) {
        this.transitionTo(route, id);
      },
      makeSale: function makeSale(parameters) {
        var queryParams = {
          patientId: parameters.get("id"),
          customerId: parameters.get("owner.id")
        };
        this.send("transitionToRoute", "organizations.organization.pointOfSales", queryParams);
      },
      openCreateAppointmentModal: function openCreateAppointmentModal(model) {
        var queryParams = {
          patient: model.get("id")
        };
        this.send("transitionToRoute", "organizations.organization.appointments.custom", queryParams);
      },
      setEnabled: function setEnabled(model) {
        this.store.findRecord(model._internalModel.modelName, model.get("id")).then(function (entity) {
          entity.set('enabled', !entity.get("enabled"));
          entity.save();
        });
      },
      retrieveModel: function retrieveModel(model) {
        this.store.findRecord(model.content._internalModel.modelName, model.get("id"));
      },
      generateDeclarationRGPDPDF: function generateDeclarationRGPDPDF(content) {
        this.send("openPDFModal", content);
      }
    },
    menuLinks: Ember.computed("intl", function () {
      return {
        crm: {
          iconName: this.get('intl').t("icons.crm"),
          name: this.get('intl').t("pages.crm"),
          class: "module-crm",
          title: this.get('intl').t("pages.crm"),
          menu: [{
            class: "module-crm",
            link: "organizations.organization.customers",
            iconName: this.get('intl').t("icons.customers"),
            name: this.get('intl').t("customers.title"),
            title: this.get('intl').t("customers.title")
          }, {
            class: "module-crm",
            link: "organizations.organization.patients",
            iconName: this.get('intl').t("icons.patients"),
            name: this.get('intl').t("patients.title"),
            title: this.get('intl').t("patients.title")
          }, {
            class: "module-crm",
            link: "organizations.organization.campaigns",
            iconName: this.get('intl').t("icons.campaigns"),
            name: this.get('intl').t("campaigns.title"),
            title: this.get('intl').t("campaigns.title"),
            feature: "campaigns"
          }, {
            class: "module-crm",
            link: "organizations.organization.sms",
            iconName: this.get('intl').t("icons.sms"),
            name: this.get('intl').t("sms.sent.title"),
            title: this.get('intl').t("sms.sent.title"),
            feature: "campaigns"
          }]
        },
        calendar: {
          iconName: this.get('intl').t("icons.calendarMenu"),
          name: this.get('intl').t("pages.reception"),
          class: "module-calendar",
          title: this.get('intl').t("pages.reception"),
          menu: [{
            link: "organizations.organization.appointments.custom",
            iconName: this.get('intl').t("icons.appointments"),
            name: this.get('intl').t("calendar.title"),
            class: "module-calendar",
            title: this.get('intl').t("calendar.title")
          }, {
            link: "organizations.organization.schedule",
            iconName: this.get('intl').t("icons.appointments"),
            name: this.get('intl').t("pages.schedule"),
            class: "module-calendar",
            title: this.get('intl').t("pages.schedule")
          }, {
            link: "organizations.organization.waitingRooms",
            iconName: this.get('intl').t("icons.waitingRoom"),
            name: this.get('intl').t("waitingRooms.title"),
            class: "module-calendar",
            title: this.get('intl').t("waitingRooms.title"),
            skipFeature: "houseCalls"
          }, {
            link: "organizations.organization.tasks",
            iconName: this.get('intl').t("icons.task"),
            name: this.get('intl').t("tasks.title"),
            class: "module-calendar",
            title: this.get('intl').t("tasks.title")
          }]
        },
        clinical: {
          iconName: this.get('intl').t("icons.clinicalMenu"),
          name: this.get('intl').t("pages.clinical"),
          class: "module-clinical",
          title: this.get('intl').t("pages.clinical"),
          menu: [{
            link: "organizations.organization.consultations",
            iconName: this.get('intl').t("icons.visit"),
            name: this.get('intl').t("visits.title"),
            class: "module-clinical",
            title: this.get('intl').t("visits.title")
          }, {
            link: "organizations.organization.periodics",
            iconName: this.get('intl').t("icons.periodic"),
            name: this.get('intl').t("periodics.title"),
            class: "module-clinical",
            title: this.get('intl').t("periodics.title")
          }, {
            link: "organizations.organization.exams",
            iconName: this.get('intl').t("icons.exam"),
            name: this.get('intl').t("exams.title"),
            class: "module-clinical",
            title: this.get('intl').t("exams.title")
          }, {
            link: "organizations.organization.clinicalWaitingRooms",
            iconName: this.get('intl').t("icons.waitingRoom"),
            name: this.get('intl').t("waitingRooms.title"),
            class: "module-clinical",
            title: this.get('intl').t("waitingRooms.title"),
            skipFeature: "houseCalls"
          }, {
            link: "organizations.organization.templates",
            iconName: this.get('intl').t("icons.template"),
            name: this.get('intl').t("templates.title"),
            class: "module-clinical",
            title: this.get('intl').t("templates.title")
          }]
        },
        internments: {
          iconName: this.get('intl').t("icons.internments"),
          name: this.get('intl').t("pages.internments"),
          class: "module-internments",
          title: this.get('intl').t("pages.internments"),
          menu: [{
            link: "organizations.organization.internments.overview",
            iconName: this.get('intl').t("icons.internments"),
            name: this.get('intl').t("pages.overview"),
            title: this.get('intl').t("pages.overview"),
            class: "module-internments"
          }, {
            link: "organizations.organization.internments.index",
            iconName: this.get('intl').t("icons.internments"),
            name: this.get('intl').t("pages.internments"),
            title: this.get('intl').t("pages.internments"),
            class: "module-internments"
          }]
        },
        sales: {
          iconName: this.get('intl').t("icons.salesMenu"),
          name: this.get('intl').t("pages.sales"),
          class: "module-sales",
          title: this.get('intl').t("pages.sales"),
          menu: [{
            link: "organizations.organization.items",
            iconName: this.get('intl').t("icons.item"),
            name: this.get('intl').t("items.title"),
            class: "module-sales",
            title: this.get('intl').t("items.title"),
            free: true
          }, {
            link: "organizations.organization.pointOfSales",
            iconName: this.get('intl').t("icons.pointOfSales"),
            name: this.get('intl').t("pointOfSales.title"),
            class: "module-sales",
            title: this.get('intl').t("pointOfSales.title")
          }, {
            link: "organizations.organization.documents",
            iconName: this.get('intl').t("icons.documents"),
            name: this.get('intl').t("documents.title"),
            class: "module-sales",
            title: this.get('intl').t("documents.title")
          }, {
            link: "organizations.organization.financialAccounts",
            iconName: this.get('intl').t("icons.financialAccounts"),
            name: this.get('intl').t("financialAccounts.title"),
            class: "module-sales",
            title: this.get('intl').t("financialAccounts.title")
          }, {
            link: "organizations.organization.saft",
            iconName: this.get('intl').t("icons.saft"),
            name: this.get('intl').t("saft.title"),
            class: "module-sales",
            title: this.get('intl').t("saft.title")
          }, {
            link: "organizations.organization.charges",
            iconName: this.get('intl').t("icons.charge"),
            name: this.get('intl').t("charges.title"),
            class: "module-sales",
            title: this.get('intl').t("charges.title")
          }, {
            link: "organizations.organization.quotations",
            iconName: this.get('intl').t("icons.documents"),
            name: this.get('intl').t("quotations.title"),
            class: "module-sales",
            title: this.get('intl').t("quotations.title")
          }, {
            link: "organizations.organization.listings.sales.receivables",
            iconName: this.get('intl').t("icons.currentAccount"),
            name: this.get('intl').t("pages.currentAccounts"),
            class: "module-sales",
            title: this.get('intl').t("pages.currentAccounts")
          }, {
            link: "organizations.organization.shopping-carts",
            iconName: this.get('intl').t("icons.shoppingCart"),
            name: this.get('intl').t("shoppingCarts.title"),
            class: "module-sales",
            title: this.get('intl').t("shoppingCarts.title"),
            free: true
          }]
        },
        procurement: {
          iconName: this.get('intl').t("icons.procurement"),
          name: this.get('intl').t("procurement.title"),
          class: "module-procurement",
          title: this.get('intl').t("procurement.title"),
          menu: [{
            link: "organizations.organization.suppliers",
            iconName: this.get('intl').t("icons.suppliers"),
            name: this.get('intl').t("suppliers.title"),
            class: "module-procurement",
            title: this.get('intl').t("suppliers.title")
          }, {
            link: "organizations.organization.purchase-invoices",
            iconName: this.get('intl').t("icons.purchases"),
            name: this.get('intl').t("purchases.title"),
            class: "module-procurement",
            title: this.get('intl').t("pages.purchases")
          }, {
            link: "organizations.organization.purchase-orders",
            iconName: this.get('intl').t("icons.purchaseOrders"),
            name: this.get('intl').t("pages.purchaseOrders"),
            class: "module-procurement",
            title: this.get('intl').t("pages.purchaseOrders")
          }, {
            link: "organizations.organization.purchase-requests",
            iconName: this.get('intl').t("icons.purchaseRequests"),
            name: this.get('intl').t("pages.purchaseRequests"),
            class: "module-procurement",
            title: this.get('intl').t("pages.purchaseRequests")
          }, {
            link: "organizations.organization.warehouses",
            iconName: this.get('intl').t("icons.warehouses"),
            name: this.get('intl').t("warehouses.title"),
            class: "module-procurement",
            title: this.get('intl').t("warehouses.title")
          }, {
            link: "organizations.organization.inventory.shortage",
            iconName: this.get('intl').t("icons.transferStock"),
            name: this.get('intl').t("pages.shortage"),
            class: "module-procurement",
            title: this.get('intl').t("pages.shortage")
          }, {
            link: "organizations.organization.item-adjustments",
            iconName: this.get("intl").t("icons.transferStock"),
            name: this.get("intl").t("itemAdjustments.title"),
            class: "module-procurement",
            title: this.get("intl").t("itemAdjustments.title")
          }, {
            link: "organizations.organization.suppliers-current-accounts",
            iconName: this.get('intl').t("icons.currentAccount"),
            name: this.get('intl').t("pages.currentAccounts"),
            class: "module-procurement",
            title: this.get('intl').t("pages.currentAccounts")
          }, {
            link: "organizations.organization.procurement.documents",
            iconName: this.get('intl').t("icons.documents"),
            name: this.get('intl').t("documents.title"),
            class: "module-procurement",
            title: this.get('intl').t("documents.title")
          }]
        },
        referral: {
          iconName: this.get('intl').t("icons.referrals"),
          name: this.get('intl').t("referrals.title"),
          class: "module-referral",
          title: this.get('intl').t("referrals.title"),
          menu: [{
            link: "organizations.organization.liaisons",
            iconName: this.get('intl').t("icons.liaisons"),
            name: this.get('intl').t("liaisons.title"),
            class: "module-referral",
            title: this.get('intl').t("liaisons.title")
          }, {
            link: "organizations.organization.events",
            iconName: this.get('intl').t("icons.events"),
            name: this.get('intl').t("events.title"),
            class: "module-referral",
            title: this.get('intl').t("events.title")
          }]
        },
        hotel: {
          iconName: this.get('intl').t("icons.hotel"),
          name: this.get('intl').t("pages.hotel"),
          class: "module-hotel",
          title: this.get('intl').t("pages.hotel"),
          menu: [{
            link: "organizations.organization.hotel.boxes",
            iconName: this.get('intl').t("icons.boxes"),
            name: this.get('intl').t("pages.boxes"),
            class: "module-hotel",
            title: this.get('intl').t("pages.boxes")
          }, {
            link: "organizations.organization.hotel.stays",
            iconName: this.get('intl').t("icons.stays"),
            name: this.get('intl').t("pages.stays"),
            class: "module-hotel",
            title: this.get('intl').t("pages.stays")
          }]
        },
        stats: {
          link: "organizations.organization.stats",
          iconName: this.get('intl').t("icons.stats"),
          name: this.get('intl').t("pages.stats"),
          class: "module-stats",
          title: this.get('intl').t("pages.stats")
        },
        listings: {
          link: "organizations.organization.listings",
          iconName: this.get('intl').t("icons.listings"),
          name: this.get('intl').t("pages.listings"),
          class: "module-stats",
          title: this.get('intl').t("pages.listings"),
          feature: "stats"
        },
        group: {
          iconName: this.get('intl').t("icons.group"),
          name: this.get('intl').t("pages.group"),
          class: "module-group",
          title: this.get('intl').t("pages.group"),
          menu: [
          /*
          {
             link: "organizations.organization.group.customers",
             iconName: this.get('intl').t("icons.customers"),
             name: this.get('intl').t("pages.customers"),
             class: "module-crm",
             title: this.get('intl').t("pages.customers")
          }, {
             link: "organizations.organization.group.patients",
             iconName: this.get('intl').t("icons.patients"),
             name: this.get('intl').t("pages.patients"),
             class: "module-crm",
             title: this.get('intl').t("pages.patients")
          }, {
             link: "organizations.organization.group.calendar",
             iconName: this.get('intl').t("icons.calendar"),
             name: this.get('intl').t("pages.calendar"),
             class: "module-calendar",
             title: this.get('intl').t("pages.calendar")
          }, {
             link: "organizations.organization.group.waitingRoom",
             iconName: this.get('intl').t("icons.calendarMenu"),
             name: this.get('intl').t("pages.reception"),
             class: "module-calendar",
             title: this.get('intl').t("pages.reception")
          }, {
             link: "organizations.organization.group.consultations",
             iconName: this.get('intl').t("icons.consultations"),
             name: this.get('intl').t("pages.consultations"),
             class: "module-clinical",
             title: this.get('intl').t("pages.consultations")
          }, {
             link: "organizations.organization.group.hr",
             iconName: this.get('intl').t("icons.hr"),
             name: this.get('intl').t("pages.hr"),
             class: "module-sales",
             title: this.get('intl').t("pages.hr")
          }, {
             link: "organizations.organization.group.items",
             iconName: this.get('intl').t("icons.item"),
             name: this.get('intl').t("pages.items"),
             class: "module-sales",
             title: this.get('intl').t("pages.items")
          }, {
             link: "organizations.organization.group.documents",
             iconName: this.get('intl').t("icons.documents"),
             name: this.get('intl').t("documents.title"),
             class: "module-sales",
             title: this.get('intl').t("documents.title")
          }, {
             link: "organizations.organization.group.financialAccounts",
             iconName: this.get('intl').t("icons.financialAccounts"),
             name: this.get('intl').t("pages.financialAccounts"),
             class: "module-sales",
             title: this.get('intl').t("pages.financialAccounts")
          },
          */
          {
            link: "organizations.organization.managment",
            iconName: this.get('intl').t("icons.salesMenu"),
            name: this.get('intl').t("pages.sales"),
            class: "module-sales",
            title: this.get('intl').t("pages.sales")
          }, {
            link: "organizations.organization.group.salesTableDays",
            iconName: this.get('intl').t("icons.salesMenu"),
            name: this.get('intl').t("pages.sales"),
            class: "module-sales",
            title: this.get('intl').t("pages.sales")
          }, {
            link: "organizations.organization.group.sales",
            iconName: this.get('intl').t("icons.salesMenu"),
            name: this.get('intl').t("stats.vsLastYear"),
            class: "module-sales",
            title: this.get('intl').t("stats.vsLastYear")
          }, {
            link: "organizations.organization.transfer-stock",
            iconName: this.get("intl").t("icons.transferStock"),
            name: this.get("intl").t("actions.transferStock"),
            class: "module-procurement",
            title: this.get("intl").t("actions.transferStock")
          }, {
            link: "organizations.organization.group.purchase-invoices",
            iconName: this.get('intl').t("icons.purchases"),
            name: this.get('intl').t("purchases.title"),
            class: "module-procurement",
            title: this.get('intl').t("pages.purchases")
          }, {
            link: "organizations.organization.group.purchase-orders",
            iconName: this.get('intl').t("icons.purchaseOrders"),
            name: this.get('intl').t("pages.purchaseOrders"),
            class: "module-procurement",
            title: this.get('intl').t("pages.purchaseOrders")
          }, {
            link: "organizations.organization.group.purchase-requests",
            iconName: this.get('intl').t("icons.purchaseRequests"),
            name: this.get('intl').t("pages.purchaseRequests"),
            class: "module-procurement",
            title: this.get('intl').t("pages.purchaseRequests")
          }]
        },
        workers: {
          iconName: this.get('intl').t("icons.worker"),
          name: this.get('intl').t("pages.hr"),
          class: "module-hr",
          title: this.get('intl').t("pages.hr"),
          menu: [{
            link: "organizations.organization.workers",
            iconName: this.get('intl').t("icons.workers"),
            name: this.get('intl').t("workers.title"),
            class: "module-hr",
            title: this.get('intl').t("workers.title"),
            free: true
          }, {
            link: "organizations.organization.invites",
            iconName: this.get('intl').t("icons.invites"),
            name: this.get('intl').t("pages.invites"),
            class: "module-hr",
            title: this.get('intl').t("pages.invites"),
            free: true
          }, {
            feature: "shifts",
            link: "organizations.organization.shifts",
            iconName: this.get('intl').t("icons.shifts"),
            name: this.get('intl').t("pages.shifts"),
            class: "module-hr",
            title: this.get('intl').t("pages.shifts")
          }, {
            feature: "shifts",
            link: "organizations.organization.absences",
            iconName: this.get('intl').t("icons.absences"),
            name: this.get('intl').t("pages.absences"),
            class: "module-hr",
            title: this.get('intl').t("pages.absences")
          }]
        },
        settings: {
          link: "organizations.organization.configurations",
          iconName: this.get('intl').t("icons.configs"),
          name: this.get('intl').t("configurations.title"),
          class: "module-system",
          title: this.get('intl').t("configurations.title"),
          free: true
        }
      };
    })
  });

  _exports.default = _default;
});