define("crm/templates/organizations/organization/stats/sales/items/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wld4BLLZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"pages.items\"],null],[25,\"t\",[\"icons.item\"],null]]],{\"statements\":[[0,\"  \"],[6,\"li\"],[7],[4,\"link-to\",[\"organizations.organization.stats.sales.overview.itemTypes.all\"],null,{\"statements\":[[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[25,\"t\",[\"icons.item\"],null]]]],[7],[8],[0,\" \"],[1,[25,\"t\",[\"pages.items\"],null],false]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"parameters\":[]},null],[6,\"div\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-md-6\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-6\"],[7],[0,\"\\n                    \"],[1,[25,\"stats-sales-item-list\",null,[[\"model\"],[[20,[\"itemsTodayByType\"]]]]],false],[0,\"\\n                \"],[8],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-6\"],[7],[0,\"\\n                    \"],[1,[25,\"stats-sales-item-list\",null,[[\"model\"],[[20,[\"itemsThisWeekByType\"]]]]],false],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\\n        \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-md-6\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-6\"],[7],[0,\"\\n                    \"],[1,[25,\"stats-sales-item-list\",null,[[\"model\"],[[20,[\"itemsThisMonthByType\"]]]]],false],[0,\"\\n                \"],[8],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-6\"],[7],[0,\"\\n                    \"],[1,[25,\"stats-sales-item-list\",null,[[\"model\"],[[20,[\"itemsThisYearByType\"]]]]],false],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/stats/sales/items/index.hbs"
    }
  });

  _exports.default = _default;
});