define("crm/templates/components/financial-account-choose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jgDxAwPu",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"class\",\"title\",\"hideFooter\",\"modalSize\"],[\"modal-financialAccount-choose\",\"module-sales\",[25,\"t\",[\"financialAccounts.choose\"],null],true,\"md\"]],{\"statements\":[[4,\"custom-form\",null,[[\"fieldTypes\",\"save\",\"actionText\",\"reset\",\"disabledAction\",\"canceled\"],[[20,[\"fieldTypes\"]],\"create\",\"crud.create\",\"reset\",[20,[\"disabledAction\"]],\"canceled\"]],{\"statements\":[[0,\"        \"],[6,\"fieldset\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n                \"],[6,\"label\"],[9,\"class\",\"col-lg-4 control-label\"],[7],[1,[25,\"t\",[\"financialAccounts.title\"],null],false],[6,\"span\"],[10,\"title\",[26,[[25,\"t\",[\"validations.fieldRequired\"],null]]]],[9,\"class\",\"text-danger\"],[7],[0,\" *\"],[8],[8],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"col-lg-8\"],[7],[0,\"\\n\"],[4,\"power-select\",null,[[\"searchField\",\"allowClear\",\"options\",\"selected\",\"required\",\"onchange\"],[\"name\",true,[20,[\"model\"]],[20,[\"selectedFinancialAccount\"]],true,[25,\"action\",[[19,0,[]],\"setSelectedFinancialAccount\"],null]]],{\"statements\":[[0,\"                        \"],[1,[19,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/financial-account-choose.hbs"
    }
  });

  _exports.default = _default;
});