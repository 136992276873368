define("crm/components/procurement/purchase-invoice-line-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    changeUnitPrice: false,
    changeLinePrice: true,
    tagName: "tr",
    classNames: ["visible-hover-wapper"],
    es: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get("line.unitPrice")) {
        this.set("unitPriceWithVatLock", true);

        if (this.get("line.vat.vat.amount")) {
          this.set("line.unitPriceWithVat", this.round(this.get("line.unitPrice") * (100 + this.get("line.vat.vat.amount")) / 100));
        }
      }

      if (this.get("line.discount")) {
        this.set("discount2Lock", false);
        this.set("discountMoneyLock", true);
      } else {
        this.set("discount2Lock", true);
        this.set("discountMoneyLock", false);
      }

      if (!this.get("line.quantity")) {
        this.set("quantityPromotionLock", true);
      }

      this.discountMoneyUpdate();

      if (!this.get("line.linePriceWithVat")) {
        this.lineTotalUpdate();
      }
    },
    didRender: function didRender() {
      // Disable Mouse scrolling
      Ember.$('input[type=number]').on('mousewheel', function () {
        Ember.$(this).blur();
      }); // Disable keyboard scrolling

      Ember.$('input[type=number]').on('keydown', function (e) {
        var key = e.charCode || e.keyCode; // Disable Up and Down Arrows on Keyboard

        if (key == 38 || key == 40) {
          e.preventDefault();
        } else {
          return;
        }
      });
    },
    cost: Ember.computed("line.unitPrice", "line.discount", "line.discount2", "line.quantity", "line.quantityPromotion", function () {
      var result = null;

      if (this.get("line.unitPrice")) {
        result = this.get("line.unitPrice");

        if (this.get("line.discount")) {
          result = result * (100 - this.get("line.discount")) / 100;
        }

        if (this.get("line.discount2")) {
          result = result * (100 - this.get("line.discount2")) / 100;
        }

        if (this.get("line.quantity") && this.get("line.quantityPromotion")) {
          result = result * this.get("line.quantity") / (this.get("line.quantity") + this.get("line.quantityPromotion"));
        }

        if (this.get("line.item.stockItemUnit.quantity")) {
          result = result / this.get("line.item.stockItemUnit.quantity");
        }
      }

      this.set("line.purchasePrice", this.round(result));
      return result;
    }),
    discountAmount: Ember.computed("line.unitPrice", "line.discount", function () {
      return this.get("line.unitPrice") && this.get("line.discount") ? this.round(this.get("line.unitPrice") * this.get("line.discount") / 100) : undefined;
    }),
    discountAmount2: Ember.computed("line.unitPrice", "line.discount", "line.discount2", function () {
      var unitPrice = this.get("line.unitPrice");
      var discount1 = this.get("line.discount");
      var discount2 = this.get("line.discount2");
      return unitPrice && discount1 && discount2 ? this.round(unitPrice * (discount1 * (100 - discount2) / 10000)) : undefined;
    }),
    discountTotalPercentage: Ember.computed("line.discount", "line.discount2", function () {
      var discount1 = this.get("line.discount");
      var discount2 = this.get("line.discount2");
      return this.round(discount1 / 100 + discount1 * (100 - discount2) / 10000);
    }),
    unitPriceDiff: Ember.computed("line.unitPrice", "line.lastPurchaseInvoiceLine.unitPrice", function () {
      return this.get("line.unitPrice") && this.get("line.lastPurchaseInvoiceLine.unitPrice") ? this.get("line.unitPrice") - this.get("line.lastPurchaseInvoiceLine.unitPrice") : null;
    }),
    unitPricePricier: Ember.computed("unitPriceDiff", function () {
      return this.get("unitPriceDiff") > 0;
    }),
    save: function save() {
      var _this = this;

      this.set("line.saving", true);
      this.get("line").save().then(function () {//this.sendAction("saved")
      }, function (error) {
        self.get("es").handle(error);
      }).finally(function () {
        _this.set("line.saving", false);
      });
    },
    saveSupplierId: function saveSupplierId() {
      var _this2 = this;

      var self = this;
      var supplierItem = this.get("line.supplierItem");

      if (supplierItem) {
        if (this.get("line.supplierId")) {
          // UPDATE
          supplierItem.set("supplierId", this.get("line.supplierId"));
          supplierItem.save().then(function () {//this.sendAction("saved")
          }, function (error) {
            self.get("es").handle(error);
          }).finally(function () {
            _this2.set("line.saving", false);
          });
        } else {
          // DELETE
          supplierItem.destroyRecord().then(function () {
            _this2.set("line.supplierItem", null);
          }, function (error) {
            self.get("es").handle(error);
          }).finally(function () {
            _this2.set("line.saving", false);
          });
        }
      } else {
        if (this.get("line.supplierId")) {
          // CREATE
          this.get("store").createRecord("supplier-item", {
            supplierId: this.get("line.supplierId"),
            supplier: this.get("line.purchaseInvoice.supplier"),
            item: this.get("line.item")
          }).save().then(function (savedSupplierItem) {
            _this2.set("line.supplierItem", savedSupplierItem);
          }, function (error) {
            self.get("es").handle(error);
          }).finally(function () {
            _this2.set("line.saving", false);
          });
        } else {// ??
        }
      }
    },

    /******************************************************************************************************************
     * Update fields
     *****************************************************************************************************************/
    discountMoneyUpdate: function discountMoneyUpdate() {
      var unitPrice = this.get("line.unitPrice");
      var discount1 = this.get("line.discount");
      var discount2 = this.get("line.discount2");

      if (discount2 && discount2 > 0) {
        var aux = unitPrice * discount1 / 100;
        aux += unitPrice * (discount1 * (100 - discount2) / 10000);
        this.set("line.discountMoney", this.round(aux));
      } else {
        this.set("line.discountMoney", this.round(unitPrice * discount1 / 100));
      }
    },
    lineTotalUpdate: function lineTotalUpdate() {
      if ((this.get("unitPriceLock") || this.get("unitPriceWithVatLock")) && this.get("line.quantity")) {
        if (this.get("line.discount")) {
          this.set("lineTotalLock", true);
          this.set("lineTotalWithVatLock", true);
        } else {
          this.set("lineTotalLock", false);
          this.set("lineTotalWithVatLock", false);
        }

        var lineUnitPrice = this.get("line.unitPrice");

        if (this.get("line.discount")) {
          lineUnitPrice = this.round(lineUnitPrice * (100 - this.get("line.discount")) / 100);
        }

        if (this.get("line.discount2")) {
          lineUnitPrice = this.round(lineUnitPrice * (100 - this.get("line.discount2")) / 100);
        }

        this.set("line.linePrice", lineUnitPrice * this.get("line.quantity"));

        if (this.get("line.vat.vat.amount") === undefined || this.get("line.vat.vat.amount") === null) {
          this.set("line.linePriceWithVat", undefined);
        } else if (this.get("line.vat.vat.amount") == 0) {
          this.set("line.linePriceWithVat", this.round(lineUnitPrice * this.get("line.quantity")));
        } else {
          this.set("line.linePriceWithVat", this.round(lineUnitPrice * this.get("line.quantity") * (100 + this.get("line.vat.vat.amount")) / 100));
        }
      } else {
        this.set("lineTotalLock", false);
        this.set("lineTotalWithVatLock", false);
        this.set("line.linePrice", undefined);
        this.set("line.linePriceWithVat", undefined);
      }

      this.set("line.saving", true);
      Ember.run.debounce(this, this.save, 4000);
    },
    calcAfterLine: function calcAfterLine() {
      if (this.get("unitPriceLock") || this.get("unitPriceWithVatLock")) {
        if (this.get("discount2Lock")) {
          // No discount
          if (this.get("line.quantity")) {
            this.set("line.discount", 100 - this.get("line.linePrice") * 100 / this.get("line.quantity") / this.get("line.unitPrice")); // this.set("discountLock", true);
          } else {
            this.set("line.quantity", this.get("line.linePrice") / this.get("line.unitPrice")); // this.set("quantityLock", true);
          }
        } else {
          // has discount
          if (this.get("line.discount")) {
            this.set("line.quantity", this.get("line.linePrice") / (this.get("line.unitPrice") - this.get("line.discountMoney")));
          } else {
            this.set("line.quantity", this.get("line.linePrice") / this.get("line.unitPrice"));
          } // this.set("quantityLock", true);

        }
      } else {
        // -> Unit Price
        if (this.get("line.discount")) {
          if (this.get("line.quantity")) {
            if (this.get("line.discount2")) {
              this.set("line.unitPrice", this.get("line.linePrice") * 10000 / this.get("line.quantity") / (100 - this.get("line.discount")) / (100 - this.get("line.discount2")));
            } else {
              this.set("line.unitPrice", this.get("line.linePrice") * 100 / this.get("line.quantity") / this.get("line.discount"));
            }
          } else {
            if (this.get("line.discount2")) {
              this.set("line.unitPrice", this.get("line.linePrice") * 10000 / (100 - this.get("line.discount")) / (100 - this.get("line.discount2")));
            } else {
              this.set("line.unitPrice", this.get("line.linePrice") * 100 / (100 - this.get("line.discount")));
            }
          }
        } else {
          if (this.get("line.quantity")) {
            this.set("line.unitPrice", this.get("line.linePrice") / this.get("line.quantity"));
          } else {
            this.set("line.unitPrice", this.get("line.linePrice"));
          }
        } // this.set("unitPriceLock", true);
        // this.set("unitPriceLockWithVat", true);

      }
    },
    afterUnitPrice: function afterUnitPrice() {
      if (this.get("discountMoneyLock")) {
        this.discountMoneyUpdate();
      } else if (this.get("discountLock")) {
        this.set("line.discount", this.get("line.discountMoney") / this.get("line.unitPrice"));
      }

      this.lineTotalUpdate();
    },

    /******************************************************************************************************************
     * Actions
     *****************************************************************************************************************/
    actions: {
      selectItem: function selectItem() {
        Ember.$("#modal-purchase-line-" + this.get("line.placement")).modal();
      },
      setItem: function setItem(item) {
        var _this3 = this;

        this.set("line.saving", true);
        this.set("line.item", item);
        var oldName = this.get("line.name");
        this.set("line.name", item.get("name"));
        this.get("store").query("itemUnit", {
          item: item.get("id"),
          inbound: true
        }).then(function (savedItemUnits) {
          if (savedItemUnits && savedItemUnits.get("length")) {
            _this3.set("line.itemUnit", savedItemUnits.get("firstObject"));
          }
        });

        if (oldName.match("[0-9]+ - .+")) {
          this.send("setSupplierId", oldName.split(" - ")[0]);
        }

        this.save();
        Ember.$("#modal-purchase-line-" + this.get("line.placement")).modal("hide");
      },
      setSupplierId: function setSupplierId(supplierId) {
        this.set("line.supplierId", supplierId);
        this.set("line.saving", true);
        Ember.run.debounce(this, this.saveSupplierId, 2000);
      },
      setField: function setField(field, value) {
        if (typeof value === "string") {
          value = value.trim();
        }

        this.set("line." + field, value);
        this.set("line.saving", true);
        Ember.run.debounce(this, this.save, 2000);
      },
      deleteLine: function deleteLine() {
        this.deleteLine(this.get("line"));
      },
      setUnitPrice: function setUnitPrice(unitPrice) {
        if (unitPrice && unitPrice > 0) {
          this.set("line.unitPrice", Number(unitPrice));
          this.set("unitPriceWithVatLock", true);
          this.set("line.unitPriceWithVat", unitPrice * (100 + this.get("line.vat.vat.amount")) / 100);
          this.afterUnitPrice();
        } else {
          this.set("line.unitPrice", null);
          this.set("line.unitPriceWithVat", null);
          this.set("unitPriceWithVatLock", false);
        }

        this.set("line.saving", true);
        Ember.run.debounce(this, this.save, 4000);
      },
      setUnitPriceWithVat: function setUnitPriceWithVat(unitPriceWithVat) {
        if (unitPriceWithVat && unitPriceWithVat > 0) {
          this.set("unitPriceLock", true);
          this.set("line.unitPriceWithVat", Number(unitPriceWithVat));
          this.set("line.unitPrice", this.round(unitPriceWithVat * 100 / (100 + this.get("line.vat.vat.amount"))));
          this.afterUnitPrice();
        } else {
          this.set("line.unitPrice", null);
          this.set("line.unitPriceWithVat", null);
          this.set("unitPriceLock", false);
        }

        this.set("line.saving", true);
        Ember.run.debounce(this, this.save, 4000);
      },
      setVat: function setVat(vatObj) {
        this.set("line.vat", vatObj);

        if (vatObj === undefined || vatObj === null) {
          if (this.get("unitPriceLock")) {
            this.set("line.unitPrice", undefined);
          } else if (this.get("unitPriceWithVatLock")) {
            this.set("line.unitPriceWithVat", undefined);
          }
        } else {
          var vat = vatObj.get("vat.amount");

          if (this.get("unitPriceLock")) {
            this.set("line.unitPrice", this.round(this.get("line.unitPriceWithVat") * 100 / (100 + vat)));
          } else if (this.get("unitPriceWithVatLock")) {
            this.set("line.unitPriceWithVat", this.get("line.unitPrice") * (100 + vat) / 100);
          }
        }

        this.lineTotalUpdate();
      },
      setDiscount: function setDiscount(discount) {
        this.set("line.discount2", undefined);
        this.set("discountMoneyLock", true);

        if (discount && discount > 0) {
          this.set("line.discount", Number(discount));
          var unitPrice = this.get("line.unitPrice");
          var discount1 = this.get("line.discount");
          this.set("discount2Lock", false);
          this.set("line.discountMoney", unitPrice * discount1 / 100);
        } else {
          this.set("line.discount", undefined);
          this.set("discount2Lock", true);
          this.set("line.discountMoney", undefined);
          this.set("discountMoneyLock", false);
        }

        this.lineTotalUpdate();
      },
      setDiscount2: function setDiscount2(discount2) {
        if (discount2 && discount2 > 0) {
          this.set("line.discount2", discount2);
        } else {
          this.set("line.discount2", undefined);
        }

        this.discountMoneyUpdate();
        this.lineTotalUpdate();
      },
      setDiscountMoney: function setDiscountMoney(discountMoney) {
        if (discountMoney && discountMoney > 0)
          /* because it can be a string */
          {
            this.set("line.discountMoney", discountMoney);
            this.set("discount2Lock", true);
            this.set("line.discount2", undefined);
            this.set("discountLock", true);
            this.set("line.discount", discountMoney / this.get("line.unitPrice") * 100);
          } else {
          this.set("line.discountMoney", undefined);
          this.set("line.discount2", undefined);
          this.set("line.discount", undefined);
          this.set("discountLock", false);
          this.set("discount2Lock", true);
        }

        this.lineTotalUpdate();
      },
      setQuantity: function setQuantity(quantity) {
        if (quantity && quantity > 0)
          /* because it can be a string */
          {
            this.set("line.quantity", Number(quantity));
            this.set("quantityPromotionLock", false);
          } else {
          this.set("line.quantity", undefined);
          this.set("line.quantityPromotion", undefined);
          this.set("quantityPromotionLock", true);
        }

        this.lineTotalUpdate();
      },
      setQuantityPromotion: function setQuantityPromotion(quantityPromotion) {
        if (quantityPromotion && quantityPromotion > 0)
          /* because it can be a string */
          {
            this.set("line.quantityPromotion", Number(quantityPromotion));
          } else {
          this.set("line.quantityPromotion", undefined);
        }

        this.set("line.saving", true);
        Ember.run.debounce(this, this.save, 4000);
      },
      setLinePrice: function setLinePrice(linePrice) {
        if (linePrice && linePrice > 0)
          /* because it can be a string */
          {
            this.set("line.linePrice", Number(linePrice));
            this.set("lineTotalWithVatLock", true);
            this.set("line.linePriceWithVat", this.round(this.get("line.linePrice") * (100 + this.get("line.vat.vat.amount")) / 100));
            this.calcAfterLine();
          } else {
          this.set("line.linePrice", undefined);
          this.set("line.linePriceWithVat", undefined);
          this.set("lineTotalLock", false);
          this.set("lineTotalWithVatLock", false);
        }

        this.set("line.saving", true);
        Ember.run.debounce(this, this.save, 4000);
      },
      setLinePriceWithVat: function setLinePriceWithVat(linePriceWithVat) {
        if (linePriceWithVat && linePriceWithVat > 0)
          /* because it can be a string */
          {
            this.set("line.linePriceWithVat", Number(linePriceWithVat));
            this.set("lineTotalLock", true);
            this.set("line.linePrice", this.round(this.get("line.linePriceWithVat") * 100 / (100 + this.get("line.vat.vat.amount"))));
            this.calcAfterLine();
          } else {
          this.set("line.linePrice", undefined);
          this.set("line.linePriceWithVat", undefined);
          this.set("lineTotalLock", false);
          this.set("lineTotalWithVatLock", false);
        }

        this.set("line.saving", true);
        Ember.run.debounce(this, this.save, 4000);
      }
    },
    round: function round(number) {
      return Math.round((number + Number.EPSILON) * 100) / 100;
    }
  });

  _exports.default = _default;
});