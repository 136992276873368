define("crm/templates/components/breed-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x5lr9RU8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"class\",\"hideFooter\"],[\"modal-breed-create\",[25,\"t\",[\"configurations.breeds.new\"],null],\"module-crm\",true]],{\"statements\":[[4,\"custom-form\",null,[[\"fieldTypes\",\"save\",\"actionText\",\"canceled\"],[[20,[\"fieldTypes\"]],\"create\",\"crud.create\",\"canceled\"]],{\"statements\":[[0,\"        \"],[6,\"fieldset\"],[7],[0,\"\\n            \"],[2,\" Name \"],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n                \"],[6,\"label\"],[9,\"class\",\"col-lg-4 control-label\"],[7],[0,\"\\n                    \"],[1,[25,\"t\",[\"configurations.breeds.name\"],null],false],[0,\" \"],[6,\"span\"],[9,\"class\",\"text-danger\"],[10,\"title\",[25,\"t\",[\"validations.fieldRequired\"],null],null],[7],[0,\" *\"],[8],[0,\"\\n                \"],[8],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"col-lg-8\"],[7],[0,\"\\n                    \"],[1,[25,\"input\",null,[[\"value\",\"class\",\"autofocus\",\"placeholder\",\"required\"],[[20,[\"model\",\"name\"]],\"form-control\",true,[25,\"t\",[\"configurations.breeds.name\"],null],true]]],false],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/breed-new.hbs"
    }
  });

  _exports.default = _default;
});