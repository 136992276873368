define("crm/components/periodics-new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    lines: [],
    disableCreateBtn: false,
    catalogs: Ember.inject.service(),
    periodTypes: Ember.computed("catalogs.periodTypes", function () {
      return this.get("catalogs.periodTypes");
    }),
    periodTypes2: Ember.computed("periodTypes.[]", function () {
      var result = {};

      if (this.get("periodTypes")) {
        this.get("periodTypes").forEach(function (t) {
          return result[t.id] = t;
        });
      }

      return result;
    }),
    addedIds: Ember.computed("lines.[]", function () {
      return this.get("lines.length") ? this.get("lines").map(function (l) {
        return l.item;
      }) : [];
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super();

      this.reset();
    },
    reset: function reset() {
      this.set("disabledAction", false);
      this.set("loading", false);
      this.set("lines", []);
      this.set('selectedItemType', null);
    },
    close: function close() {
      this.toggleProperty("closed");
    },
    calcNextDate: function calcNextDate(line) {
      if (line.intakeDate) {
        var x = "days";

        switch (line.selectedPeriod.id) {
          case 168:
            x = "weeks";
            break;

          case 720:
            x = "months";
            break;

          case 8760:
            x = "years";
        }

        Ember.set(line, "nextPeriodic", (0, _moment.default)(line.intakeDate).add(line.period, x).format("YYYY-MM-DD"));
      }
    },
    calcPeriod: function calcPeriod(line, hours) {
      if (hours / 8760 >= 1) {
        Ember.set(line, "period", hours / 8760);
        Ember.set(line, "selectedPeriod", this.get("periodTypes2")[8760]);
      } else if (hours / 720 >= 1) {
        Ember.set(line, "period", hours / 720);
        Ember.set(line, "selectedPeriod", this.get("periodTypes2")[720]);
      } else if (hours / 168 >= 1) {
        Ember.set(line, "period", hours / 168);
        Ember.set(line, "selectedPeriod", this.get("periodTypes2")[168]);
      } else {
        Ember.set(line, "period", hours / 24);
        Ember.set(line, "selectedPeriod", this.get("periodTypes2")[24]);
      }
    },
    actions: {
      setIntakeDate: function setIntakeDate(line, intakeDate) {
        Ember.set(line, "intakeDate", intakeDate);

        if (intakeDate) {
          Ember.set(line, "intake", true);

          if (line.period && line.selectedPeriod) {
            this.calcNextDate(line);
          } else if (line.nextPeriodic) {
            this.calcPeriod(line, (0, _moment.default)(line.nextPeriodic).diff(line.intakeDate, "hours"));
          }
        } else {
          Ember.set(line, "period", null);
          Ember.set(line, "selectedPeriod", null);
          Ember.set(line, "intake", false);
        }
      },
      setPeriod: function setPeriod(line, period) {
        Ember.set(line, "period", period);

        if (period) {
          this.calcNextDate(line);
        } else {
          Ember.set(line, "period", null);
          Ember.set(line, "nextPeriodic", null);
        }
      },
      setSelectedPeriod: function setSelectedPeriod(line, selectedPeriod) {
        Ember.set(line, "selectedPeriod", selectedPeriod);

        if (selectedPeriod) {
          this.calcNextDate(line);
        } else {
          Ember.set(line, "selectedPeriod", null);
          Ember.set(line, "nextPeriodic", null);
        }
      },
      setNextPeriodic: function setNextPeriodic(line, nextPeriodic) {
        Ember.set(line, "nextPeriodic", nextPeriodic);

        if (nextPeriodic) {
          if (line.intakeDate) {
            this.calcPeriod(line, (0, _moment.default)(nextPeriodic).diff(line.intakeDate, "hours"));
          } else {
            Ember.set(line, "period", null);
            Ember.set(line, "selectedPeriod", null);
          }
        }
      },
      addLine: function addLine(item) {
        var _this = this;

        var self = this;

        if (!this.get("addedIds").includes(item.get("id"))) {
          this.get("store").findRecord("item", item.get("id"), {
            reload: true
          }).then(function (savedItem) {
            var now = (0, _moment.default)();
            var line = {
              name: item.get("name"),
              type: item,
              item: item,
              intakeDate: now.format("YYYY-MM-DD"),
              consultation: self.get("model.consultation"),
              patient: self.get("model.patient"),
              intake: true
            };

            if (savedItem.get("period")) {
              var hours = savedItem.get("period");

              _this.calcPeriod(line, hours);

              _this.calcNextDate(line);
            }

            self.get("lines").addObject(line);
          });
        }
      },
      create: function create() {
        var self = this;
        this.set("disabledAction", true);
        this.set("loading", true);
        this.get("lines").forEach(function (line) {
          Ember.set(line, "nextPeriodic", new Date(line.nextPeriodic));

          if (line.intake) {
            Ember.set(line, "intakeDate", new Date(line.intakeDate));

            if (line.selectedPeriod && line.period) {
              Ember.set(line, "period", Number(line.selectedPeriod.id) * Number(line.period));
            } else {
              Ember.set(line, "period", null);
            }
          } else {
            Ember.set(line, "intakeDate", null);
            Ember.set(line, "period", null);
          }

          self.get("store").createRecord("periodic", line).save().catch(function (result) {
            self.set('disabledAction', false);
            self.set('loading', false);
            self.get("flashMessages").danger(result.errors ? result.errors[0] ? Object.keys(result.errors[0])[0] : "Error" : "Error");
          });
        });
        this.set("disabledAction", false);
        this.set("loading", false);
        this.close();
      },
      canceled: function canceled() {
        this.close();
      },
      delete: function _delete(line) {
        if (confirm(this.get('intl').t("intl.remove"))) {
          line.type.exists = false;
          this.get("lines").removeObject(line);
        }
      }
    }
  });

  _exports.default = _default;
});