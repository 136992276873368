define("crm/templates/organizations/organization/listings/sales/financial-accounts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e+s/DdoQ",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"financialAccounts.title\"],null],[25,\"t\",[\"icons.financialAccounts\"],null]]]],false],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n  \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"model\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/listings/sales/financial-accounts/index.hbs"
    }
  });

  _exports.default = _default;
});