define("crm/templates/components/show-task-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FqAjvTpU",
    "block": "{\"symbols\":[],\"statements\":[[6,\"label\"],[10,\"class\",[26,[\"control-label \",[25,\"if\",[[20,[\"lateTask\"]],\"text-danger\"],null]]]],[7],[1,[25,\"moment-format\",[[20,[\"record\",\"taskDate\"]],\"DD-MM-YYYY\"],null],false],[8],[0,\"\\n\"],[4,\"unless\",[[20,[\"taskTime\"]]],null,{\"statements\":[[0,\"    \"],[6,\"small\"],[7],[1,[25,\"moment-format\",[[20,[\"record\",\"taskDate\"]],\"HH:mm\"],null],false],[0,\" \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-task-date.hbs"
    }
  });

  _exports.default = _default;
});