define("crm/templates/custom/is-before-today", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+m5VEQsa",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"moment-format\",[[20,[\"record\",\"validity\"]],\"DD-MM-YYYY\"],null],false],[0,\"\\n\"],[4,\"if\",[[25,\"is-before-today\",[[20,[\"record\",\"validity\"]]],null]],null,{\"statements\":[[0,\"        \"],[6,\"i\"],[9,\"class\",\"fa fa-exclamation-triangle\"],[9,\"aria-hidden\",\"true\"],[7],[8],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"sr-only\"],[7],[1,[25,\"t\",[\"intl.error\"],null],false],[0,\":\"],[8],[1,[25,\"t\",[\"models.sales.expired\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/custom/is-before-today.hbs"
    }
  });

  _exports.default = _default;
});