define("crm/templates/components/purchase-order-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MozynusL",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"callModal\",\"openPurchaseOrder\",\"receivePurchaseOrder\",\"openPurchaseRequest\",\"receivePurchaseRequest\"],[[20,[\"model\"]],[20,[\"columns\"]],\"callModal\",\"openPurchaseOrder\",\"receivePurchaseOrder\",\"openPurchaseRequest\",\"receivePurchaseRequest\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/purchase-order-list.hbs"
    }
  });

  _exports.default = _default;
});