define("crm/components/receipt-generate-new", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service("session"),
    es: Ember.inject.service(),
    errorReturned: Ember.computed.alias("es.errorReturned"),
    slip: null,
    customer: null,
    selectedFinancialAccount: null,
    paymentMethod: "NUM",
    saving: false,
    init: function init() {
      this._super();

      this.set("slip", this.get("organizationPet.receiptSlip"));
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.notifyPropertyChange('customer');

      if (this.get("model.customer") && this.get("model.customer.enabled")) {
        this.set("customer", this.get("model.customer"));
      }

      if (this.get("financialAccounts.length") == 1 && this.get("financialAccounts.firstObject.financialAccountType") == 1) {
        if (!this.get("financialAccounts.firstObject.isManual") || this.get("financialAccounts.firstObject.isManual") && this.get("financialAccounts.firstObject.isOpen")) this.set("model.financialAccount", this.get("financialAccounts.firstObject"));
      }
    },
    close: function close() {
      this.set("closed", this.get("closed") ? false : true);
    },
    reset: function reset() {
      this.set("saving", false);
      this.set("customer", null);
      this.set("selectedFinancialAccount", null);
      this.set("cash", null);
      this.set("checkNumber", null);
      this.set("checkDate", null);
      this.set("internalNotes", null);
      this.set("checkAll", false);
      this.set("paymentMethod", "NUM");
      this.set("model", {});
    },
    fillOutSettledObserver: Ember.observer("openItems.@each.open", function () {
      if (this.get("openItems.length")) {
        this.get("openItems").forEach(function (openItem) {
          openItem.set("settled", openItem.get("open"));
          openItem.set("discount", 0);
        });
      }
    }),
    financialAccountsUsable: Ember.computed("financialAccounts.[]", "financialAccounts.@each.financialAccountType", "financialAccounts.@each.isManual", "financialAccounts.@each.isOpen", function () {
      return Ember.isEmpty(this.get("financialAccounts")) ? [] : this.get("financialAccounts").filter(function (fa) {
        return !fa.get("isManual") || fa.get("isManual") && fa.get("isOpen");
      });
    }),
    settled: Ember.computed("openItems.@each.selected", "openItems.@each.settled", function () {
      if (!this.get("openItems.length")) {
        return 0;
      }

      var total = 0;

      if (this.get("openItems")) {
        this.get("openItems").forEach(function (openItem) {
          if (openItem.get("selected")) {
            total += Number(openItem.get("settled"));
          }
        });
      }

      return total;
    }),
    totalDiscount: Ember.computed("openItems.@each.selected", "openItems.@each.discount", function () {
      if (!this.get("openItems.length")) {
        return 0;
      }

      var total = 0;

      if (this.get("openItems")) {
        this.get("openItems").forEach(function (openItem) {
          if (openItem.get("selected")) {
            total += Ember.isEmpty(Number(openItem.get("discount"))) ? 0 : Number(openItem.get("discount"));
          }
        });
      }

      return total;
    }),
    total: Ember.computed("openItems.@each.selected", "openItems.@each.settled", "openItems.@each.discount", function () {
      if (!this.get("openItems.length")) {
        return 0;
      }

      var total = 0;

      if (this.get("openItems")) {
        this.get("openItems").forEach(function (openItem) {
          if (openItem.get("selected")) {
            total += Number(openItem.get("settled"));
            total -= Ember.isEmpty(Number(openItem.get("discount"))) ? 0 : Number(openItem.get("discount"));
          }
        });
      }

      return this.money(total);
    }),
    change: Ember.computed("cash", "total", function () {
      return this.get("cash") ? this.money(Number(this.get("cash")) - this.get("total")) : 0;
    }),
    hasErrors: Ember.computed("total", function () {
      return this.get("openItems").filter(function (o) {
        return o.get("selected");
      }).get("length") && this.get("total") <= 0;
    }),
    disableBtn: Ember.computed("total", "openItems.@each.selected", "saving", "change", "model.financialAccount", "paymentMethod", "checkNumber", "checkDate", function () {
      return this.get("saving") || this.get("total") <= 0 || Ember.isEmpty(this.get("openItems").filterBy("selected")) || Ember.isEmpty(this.get("model.financialAccount")) || Ember.isEmpty(this.get("paymentMethod")) || this.get("change") < 0 && this.get("change") !== 0 - this.get("total") || this.get("paymentMethod") === "CHQ" && (Ember.isEmpty(this.get("checkNumber")) || Ember.isEmpty(this.get("checkDate")));
    }),
    noFinancialAccountTypeBankAccount: Ember.computed("financialAccountsUsable.[]", "financialAccountsUsable.@each.financialAccountType", function () {
      return Ember.isEmpty(this.get("financialAccountsUsable")) ? [] : this.get("financialAccountsUsable").filterBy("financialAccountType", 2).get("length") > 0;
    }),
    noFinancialAccountTypeRegister: Ember.computed("financialAccountsUsable.[]", "financialAccountsUsable.@each.financialAccountType", "paymentMethod", function () {
      return Ember.isEmpty(this.get("financialAccountsUsable")) ? [] : this.get("financialAccountsUsable").filterBy("financialAccountType", 1).get("length") > 0 || this.get("paymentMethod") === "DEB";
    }),
    openItems: Ember.computed("customer.id", "organization.hasFAs", "organization.company.id", function () {
      if (!this.get("organization.company.id")) {
        return null;
      }

      if (this.get("customer.id")) {
        return this.get("store").query("openItem", {
          customer: this.get("customer.id"),
          company: this.get("organization.company.id")
        }, {
          reload: true
        });
      } else {
        return this.get("store").query("openItem", {
          company: this.get("organization.company.id")
        }, {
          reload: true
        });
      }
    }),
    openItemsSum: Ember.computed("openItems.@each.settled", function () {
      var result = 0;

      if (this.get("openItems")) {
        this.get("openItems").forEach(function (openItem) {
          result += openItem.get("settled");
        });
      }

      return result;
    }),
    currentEnterprise: Ember.computed("openItems.@each.selected", function () {
      if (Ember.isEmpty(this.get("openItems"))) {
        return null;
      }

      var result = null;
      this.get("openItems").forEach(function (i) {
        if (i.get("selected")) {
          result = i.get("enterprise");
        }
      });
      return result;
    }),
    openItemsFiltered: Ember.computed("openItems.[]", function () {
      if (Ember.isEmpty(this.get("openItems"))) {
        return null;
      }

      var result = {};
      this.get("openItems").forEach(function (i) {
        if (!result[i.get("enterprise")]) {
          result[i.get("enterprise")] = [];
        }

        result[i.get("enterprise")].push(i);
      });
      return result;
    }),
    actions: {
      reset: function reset() {
        this.reset();
      },
      canceled: function canceled() {
        this.close();
      },
      setCustomer: function setCustomer(customer) {
        this.set("customer", customer);
      },
      resetCustomer: function resetCustomer() {
        this.set("customer", null);
        this.set("selectedPatient", null);
      },
      toggleAll: function toggleAll() {
        var enterprise = this.get("openItems").objectAt(0).get("enterprise");
        this.set("checkAll", !this.get("checkAll"));
        var self = this;
        this.get("openItems").forEach(function (openItem) {
          if (openItem.get("enterprise") == enterprise) {
            openItem.set("selected", self.get("checkAll"));
          }
        });
      },
      create: function create() {
        this.set('saving', true);
        var self = this;
        var newReceipt = {
          customer: this.get("customer"),
          patient: this.get("selectedPatient"),
          sourceDocs: [],
          financialAccount: this.get("model.financialAccount"),
          paymentMethod: this.get("paymentMethod"),
          checkNumber: this.get("checkNumber") ? this.get("checkNumber") : null,
          checkDate: this.get("checkDate") ? this.get("checkDate") : null,
          organization: this.get("organization"),
          internalNotes: this.get("internalNotes"),
          slip: this.get("slip")
        };

        if (!Ember.isEmpty(self.get('openItems.length'))) {
          self.get('openItems').forEach(function (openItem) {
            if (openItem.get('selected')) {
              newReceipt.sourceDocs.addObject(openItem);
            }
          });
        }

        this.get('store').createRecord('receipt', newReceipt).save().then(function (savedReceipt) {
          Ember.$.ajax({
            method: 'GET',
            beforeSend: function beforeSend(request) {
              request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
              request.setRequestHeader("Content-Type", "application/pdf");
            },
            url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/receipts" + '/' + savedReceipt.get("id") + '/pdf',
            xhrFields: {
              responseType: 'blob'
            },
            success: function success(blob) {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = savedReceipt.get("name") + ".pdf";
              link.click();
            }
          });
          self.get("openItems").forEach(function (openItem) {
            openItem.set("selected", false);
            openItem.set("settled", null);
          });
          self.sendAction("created", savedReceipt);
          self.close();
        }).catch(function (result) {
          self.set('saving', false);
          self.get("es").errorReturned(result);
        });
      }
    },
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    }
  });

  _exports.default = _default;
});