define("crm/templates/components/show-document-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+czg5WNX",
    "block": "{\"symbols\":[],\"statements\":[[6,\"i\"],[10,\"title\",[18,\"title\"],null],[10,\"class\",[26,[\"fa fa-\",[18,\"status\"]]]],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-document-status.hbs"
    }
  });

  _exports.default = _default;
});