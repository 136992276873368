define("crm/templates/components/supplier-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yrN5rBiK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"col-xs-8 col-sm-8 col-md-8 col-lg-8\"],[7],[0,\"\\n\"],[0,\"    \"],[6,\"h3\"],[9,\"class\",\"m-b-xs\"],[7],[0,\"\\n      \"],[6,\"strong\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"name\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"disableLink\"]]],null,{\"statements\":[[0,\"            \"],[1,[20,[\"model\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[4,\"link-to\",[\"organizations.organization.suppliers.supplier\",[20,[\"model\",\"id\"]]],[[\"class\"],[\"module-crm\"]],{\"statements\":[[1,[20,[\"model\",\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n    \"],[11,1],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",[26,[[25,\"unless\",[[20,[\"showActions\"]],\"hide\"],null]]]],[7],[0,\"\\n  \"],[6,\"hr\"],[7],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"actions text-center\"],[7],[0,\"\\n    \"],[4,\"link-to\",[\"organizations.organization.suppliers.supplier\",[20,[\"model\",\"id\"]]],[[\"title\",\"class\"],[[25,\"t\",[\"actions.viewProfile\"],null],[25,\"unless\",[[20,[\"model\",\"enabled\"]],\"disabled\"],null]]],{\"statements\":[[0,\" \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.detail\"],null]]]],[7],[8],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/supplier-card.hbs"
    }
  });

  _exports.default = _default;
});