define("crm/templates/organizations/organization/saft/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ioQA1WDs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"model\",\"salesFeature\"]]],null,{\"statements\":[[4,\"u-second-nav\",null,[[\"class\",\"title\",\"titleIcon\"],[\"module-sales\",[25,\"t\",[\"pages.saft\"],null],[25,\"t\",[\"icons.saft\"],null]]],{\"statements\":[[4,\"if\",[[25,\"can\",[\"create saft\"],null]],null,{\"statements\":[[0,\"      \"],[6,\"li\"],[7],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"saft.new.title\"],null]]]],[3,\"action\",[[19,0,[]],\"openSaftNew\"]],[7],[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.saft\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"saft.new.title\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n  \"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"class\"],[\"module-sales\"]],{\"statements\":[[0,\"      \"],[1,[25,\"saft-list\",null,[[\"model\",\"openSaftDetails\"],[[20,[\"model\",\"legalDeclarations\"]],\"openSaftDetails\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"unavailable-feature\",null,[[\"module\",\"feature\"],[\"module-sales\",\"sales\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/saft/index.hbs"
    }
  });

  _exports.default = _default;
});