define("crm/templates/organizations/organization/plans/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BhFmr8IE",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ibox-panel\",null,[[\"hideTools\",\"title\"],[true,[25,\"t\",[\"plans.new.title\"],null]]],{\"statements\":[[0,\"    \"],[6,\"form\"],[9,\"class\",\"form-horizontal\"],[3,\"action\",[[19,0,[]],\"create\"],[[\"on\"],[\"submit\"]]],[7],[0,\"\\n        \"],[6,\"fieldset\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n                \"],[6,\"label\"],[9,\"class\",\"col-lg-4 control-label\"],[7],[1,[25,\"t\",[\"plans.plan.name\"],null],false],[8],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"col-lg-8\"],[7],[0,\"\\n                    \"],[1,[25,\"input\",null,[[\"value\",\"class\",\"required\"],[[20,[\"model\",\"name\"]],\"form-control\",true]]],false],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"fieldset\"],[7],[0,\"\\n            \"],[6,\"hr\"],[7],[8],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"pull-right\"],[7],[0,\"\\n                \"],[1,[25,\"pu-button-cancel\",null,[[\"canceled\",\"disabled\",\"isDirty\"],[\"cancel\",[20,[\"disableBtn\"]],[20,[\"model\",\"hasDirtyAttributes\"]]]]],false],[0,\"\\n                \"],[1,[25,\"pu-button\",null,[[\"lockButton\",\"icon\",\"text\"],[[20,[\"disableBtn\"]],\"floppy-o\",[25,\"t\",[\"crud.create\"],null]]]],false],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/plans/new.hbs"
    }
  });

  _exports.default = _default;
});