define("crm/components/lines-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    linesPriceTotal: Ember.computed("lines.@each.linePrice", "lines.[]", function () {
      var self = this;
      var result = 0;

      if (!Ember.isEmpty(this.get("lines"))) {
        this.get("lines").forEach(function (line) {
          if (!Ember.isEmpty(Ember.get(line, "linePrice"))) {
            result += self.money(Ember.get(line, "linePrice"));
          }
        });
      }

      result = this.money(result);

      if (this.get("lines")) {
        this.set("lines.totalAmount", result);
      }

      return result;
    }),
    linesTotal: Ember.computed("lines.@each.unitPrice", "lines.@each.quantity", "linesPriceTotal", function () {
      var total = 0;

      if (!Ember.isEmpty(this.get("lines"))) {
        this.get("lines").forEach(function (line) {
          if (!Ember.isEmpty(Ember.get(line, "unitPrice"))) {
            total += Ember.get(line, "unitPrice") * Ember.get(line, "quantity");
          }
        });
      }

      return total;
    }),
    linesDiscountTotal: Ember.computed("linesTotal", "linesPriceTotal", function () {
      return this.get("linesTotal") - this.get("linesPriceTotal") > 0.01 ? this.get("linesTotal") - this.get("linesPriceTotal") : 0;
    }),
    sortedLines0: Ember.computed("lines.@each.lineGroupTree", function () {
      if (!this.get("lines")) {
        return null;
      }

      return this.get("lines").sortBy("lineGroupTree");
    }),
    sortedLines: Ember.computed("sortedLines0.@each.treeLevel", function () {
      var odd = false;

      if (!this.get("sortedLines0")) {
        return null;
      }

      this.get("sortedLines0").forEach(function (l) {
        if (Ember.get(l, "item")) {
          if (Ember.get(l, "lineGroup") && !(Ember.get(l, "treeLevel") > 0)) {
            odd = !odd;
          }

          Ember.set(l, "odd", odd);
        }
      });
      return this.get("sortedLines0");
    }),
    actions: {
      clearCart: function clearCart() {
        if (confirm(this.get("intl").t("intl.remove"))) {
          this.sendAction("disableCustomerShoppingCarts");
          this.sendAction("clearLines");
          this.set("lines", []);
        }
      },
      deleteLine: function deleteLine(line) {
        if (confirm(this.get("intl").t("intl.remove"))) {
          if (Ember.get(line, "lineGroup") && Ember.get(line, "item.children.length")) {
            var self = this;
            var linesToDelete = this.get("lines").filter(function (l) {
              return Ember.get(l, "lineGroupTree") && Ember.get(l, "lineGroupTree").startsWith(Ember.get(line, "lineGroupTree"));
            });
            linesToDelete.forEach(function (lineToDelete) {
              self.removeLine(lineToDelete);
            });
          } else {
            this.removeLine(line);
          }
        }
      },
      openDetails: function openDetails(line) {
        var _this = this;

        this.get("store").query("item-item", {
          parent: Ember.get(line, "item.id")
        }).then(function (r) {
          if (Ember.isEmpty(r) || Ember.get(line, "item.isFixedPriceBundle")) {
            _this.sendAction("openDetails", line);
          } else {
            _this.sendAction("openDetailsModal", "order-line-compound-product", line);
          }
        });
      }
    },
    removeLine: function removeLine(line) {
      if (Ember.get(line, "id")) {
        line.destroyRecord();
      }

      this.get("lines").removeObject(line);
    },
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    }
  });

  _exports.default = _default;
});