define("crm/components/exam-new-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),
    newExam: {},
    beingSaved: false,
    didUpdateAttrs: function didUpdateAttrs() {
      if (!Ember.isEmpty(this.get("model.type"))) {
        this.set("newExam.type", this.get("model.type"));
        this.set("selectedExamType", this.get("model.type"));
      }

      this.set("requestedSeed", (0, _moment.default)());
      this.set("newExam.requestDate", (0, _moment.default)());
    },
    examTypesRoot: Ember.computed("itemTypes.@each.parent", function () {
      var result = [];

      if (this.get("itemTypes")) {
        result = this.get("itemTypes").filter(function (type) {
          return type.get("isExam") && !type.get("parent.id");
        });

        if (result && result.length === 1) {
          result = this.get("itemTypes").filter(function (type) {
            return type.get("parent.id") === result.get("firstObject.id");
          });
        }
      }

      return result.map(function (t) {
        return {
          name: t.get("name"),
          code: t
        };
      });
    }),
    disabledSubmissionExam: Ember.computed("newExam.type", "beingSaved", function () {
      return Ember.isEmpty(this.get("newExam.type")) || this.get("beingSaved");
    }),
    cannotChange: Ember.computed("model.type", function () {
      return this.get("model.type");
    }),
    sortedChildrenAux: Ember.computed("newExam.type.id", function () {
      if (this.get("newExam.type.id")) {
        return this.get("store").query("item-item", {
          parent: this.get("newExam.type.id")
        });
      } else {
        return [];
      }
    }),
    sortedChildren: Ember.computed("sortedChildrenAux.[]", function () {
      if (this.get("sortedChildrenAux.length")) {
        var self = this;
        this.get("sortedChildrenAux").forEach(function (itemItem) {
          if (!Ember.isEmpty(itemItem)) {
            itemItem.set("checked", true); // Parameters are checked by default

            self.get("store").findRecord("item", itemItem.get("child.id"));
          }
        });
        return this.get("sortedChildrenAux").sortBy("orderNumber");
      } else {
        return [];
      }
    }),
    filteredExams: Ember.computed("organization.company.id", "selectedExamType.id", function () {
      if (this.get("organization.company.id") && this.get("selectedExamType.id")) {
        return this.get("store").query("item", {
          company: this.get("organization.company.id"),
          type: this.get("selectedExamType.id"),
          sellable: true
        });
      } else {
        return [];
      }
    }),
    close: function close() {
      Ember.$("#modal-exam-create").modal("hide");
    },
    actions: {
      selectPatient: function selectPatient() {
        this.sendAction("openModal", {
          entity: "patient",
          action: "select"
        });
      },
      unselectPatientAndCustomer: function unselectPatientAndCustomer() {
        this.set("model.patient", null);
        this.set("model.customer", null);
      },
      canceled: function canceled() {
        this.close();
      },
      createMCD: function createMCD() {
        var self = this;
        this.set("beingSaved", true);

        if (this.get("laboratoryID")) {
          var laboratory = this.get("store").peekRecord("laboratory", this.get("laboratoryID"));
          this.set("newExam.externalLab", laboratory);
        }

        this.set("newExam.name", this.get("newExam.type.name"));

        if (this.get("model._internalModel.modelName") === "consultation") {
          this.set("newExam.consultation", this.get("model"));
        } else {
          this.set("newExam.patient", this.get("model.patient"));
        }

        this.set("newExam.organization", this.get("organization"));
        this.set("newExam.status", 0);

        if (!Ember.isEmpty(this.get("newExam.notes"))) {
          this.set("newExam.status", 1);

          if (Ember.isEmpty(this.get("newExam.doneDate"))) {
            this.set("newExam.doneDate", (0, _moment.default)());
          }
        }

        if (!Ember.isEmpty(this.get("newExam.type.children"))) {
          this.get("newExam.type.children").forEach(function (itemItem) {
            if (itemItem.get("checked") && !Ember.isEmpty(itemItem.get("notes"))) {
              self.set("newExam.status", 1);

              if (Ember.isEmpty(self.get("newExam.doneDate"))) {
                self.set("newExam.doneDate", (0, _moment.default)());
              }
            }
          });
        }

        var newExam = this.get("store").createRecord("exam", this.get("newExam"));
        newExam.save().then(function (savedExam) {
          if (self.get("model.shoppingCart")) {
            self.get("store").findRecord("shopping-cart", self.get("model.shoppingCart.id"));
          }

          if (!Ember.isEmpty(self.get("newExam.type.children"))) {
            self.get("newExam.type.children").forEach(function (itemItem) {
              if (itemItem.get("checked")) {
                self.get("store").createRecord("exam-line", {
                  exam: savedExam,
                  item: itemItem.get("child"),
                  notes: itemItem.get("notes"),
                  orderNumber: itemItem.get("orderNumber")
                }).save();
              }
            });
          }

          self.get("newExam.type.children").forEach(function (line) {
            line.set("checked", false);
            line.set("notes", null);
          });
          self.createAction(savedExam);
          self.close();
          self.sendAction("reloadModel");
          self.get("flashMessages").success(self.get("intl").t("exams.messages.createdSuccess"));
        }).catch(function (result) {
          self.set("beingSaved", false);
          self.get("flashMessages").danger(result.errors ? result.errors[0] ? Object.keys(result.errors[0])[0] : "Error" : "Error");
        });
      },
      reset: function reset() {
        this.set("newExam", {});
        this.set("selectedExamType", null);
        this.set("examTypeID", null);
        this.set("subExamItemTypesAux", []);
        this.set("laboratorySelected", null);
        this.set("beingSaved", false);
        this.set("laboratoryID", null);
        this.set("selectedOption", null);
        this.set("requestedSeed", (0, _moment.default)());
        this.set("newExam.requestDate", (0, _moment.default)());
      }
    },
    createAction: function createAction(examination) {
      if (!Ember.isEmpty(this.get("model.action"))) {
        this.set("model.action.examination", examination);
        this.get("store").createRecord("action", this.get("model.action")).save();
      }
    }
  });

  _exports.default = _default;
});