define("crm/routes/organizations/organization/workers/worker/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var workerID = this.modelFor("organizations.organization.workers.worker").get('id');
      return this.get('store').findRecord('worker', workerID);
    },
    actions: {
      updated: function updated(id) {
        this.transitionTo('organizations.organization.workers.worker', id);
      },
      canceled: function canceled() {
        this.transitionTo('organizations.organization.workers');
      }
    }
  });

  _exports.default = _default;
});