define("crm/templates/organizations/organization/sheltereds/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vKtZwSES",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,null,{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"openModalCreateSheltered\"]],[7],[6,\"i\"],[9,\"class\",\"fa fa-plus\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"sheltereds.new.title\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openEditShelteredModal\",\"openCreateAppointmentModal\",\"disable\",\"enable\"],[[20,[\"model\"]],[20,[\"columns\"]],\"openEditShelteredModal\",\"openCreateAppointmentModal\",\"disable\",\"enable\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/sheltereds/index.hbs"
    }
  });

  _exports.default = _default;
});