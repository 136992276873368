define("crm/templates/organizations/organization/listings/clinical/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "grvbHc3H",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[6,\"h2\"],[7],[1,[25,\"t\",[\"periodics.title\"],null],false],[8],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-md-6 col-lg-4 col-xl-3\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"organizations.organization.listings.clinical.periodics\"],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"widget bg-clinical text-white p-lg text-center\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"m-b-md\"],[7],[0,\"\\n          \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[25,\"t\",[\"icons.periodics\"],null],\" fa-4x\"]]],[7],[8],[0,\"\\n          \"],[6,\"h1\"],[9,\"class\",\"m-xs\"],[7],[0,\" \"],[8],[0,\"\\n          \"],[6,\"h3\"],[9,\"class\",\"font-bold no-margins\"],[7],[1,[25,\"t\",[\"periodics.title\"],null],false],[8],[0,\"\\n          \"],[6,\"small\"],[7],[0,\" \"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/listings/clinical/index.hbs"
    }
  });

  _exports.default = _default;
});