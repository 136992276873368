define("crm/translations/es-mx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "crud": {
      "save": "Salvar",
      "saved": "Salvado"
    },
    "icons": {
      "currency": "$"
    },
    "system": {
      "alerts": {
        "online": {
          "text": "Puede continuar su trabajo."
        }
      }
    }
  };
  _exports.default = _default;
});