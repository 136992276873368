define("crm/abilities/invoice", ["exports", "crm/abilities/manager"], function (_exports, _manager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _manager.default.extend({
    canCreate: true,
    canRead: true,
    canUpdate: true
  });

  _exports.default = _default;
});