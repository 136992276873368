define("crm/templates/organizations/organization/group/customers/active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LwGZU1UM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\",\"class\"],[[25,\"t\",[\"customers.enabled\"],null],[25,\"t\",[\"icons.document\"],null],\"module-crm\"]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"filters-panel\"],[7],[0,\"\\n        \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"actions.filters\"],null],false],[8],[0,\"\\n      \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",\"1900-01-01\",[20,[\"before\"]],[20,[\"after\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n      \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",[20,[\"after\"]],\"2500-12-31\",[20,[\"before\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[0,\"        \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"filteredModel\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/group/customers/active.hbs"
    }
  });

  _exports.default = _default;
});