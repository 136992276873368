define("crm/components/appointment-detail", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    pdf: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    isNotEnglish: Ember.computed("intl.locale", function () {
      return this.get("intl.locale").get("firstObject") !== "en";
    }),
    canDoAppointmentDetails: Ember.computed("can", function () {
      return this.get("can").can("read appointment");
    }),
    newConsultation: {},
    close: function close() {
      Ember.$("#openAppointmentDetails").modal("hide");
    },
    didRender: function didRender() {
      var self = this;

      if (Ember.isEmpty(this.get("appointmentDetail.customer.phoneContact")) && !Ember.isEmpty(this.get("appointmentDetail.customer.id"))) {
        this.get("store").query("contact", {
          customer: this.get("appointmentDetail.customer.id")
        });
      }

      if (this.get("appointmentDetail.shoppingCart.id")) {
        this.set("shoppingCart", this.get("appointmentDetail.shoppingCart"));
      } else if (this.get("appointmentDetail.consultation.id")) {
        this.get("store").query("shopping-cart", {
          consultation: this.get("appointmentDetail.consultation.id")
        }).then(function (shoppingCarts) {
          if (!Ember.isEmpty(shoppingCarts) && shoppingCarts.get("length") > 0) {
            self.set("shoppingCart", shoppingCarts.objectAt(0));
          } else {
            self.set("shoppingCart", null);
          }
        });
      } else {
        this.set("shoppingCart", null);
      }
    },
    firstZip: Ember.computed("appointmentDetail.customer.firstAddress", function () {
      if (this.get("appointmentDetail.customer.firstAddress")) {
        if (this.get("appointmentDetail.customer.firstAddress.zip")) {
          if (this.get("appointmentDetail.customer.firstAddress.town")) {
            return this.get("appointmentDetail.customer.firstAddress.zip") + this.get("appointmentDetail.customer.firstAddress.town");
          } else {
            return this.get("appointmentDetail.customer.firstAddress.zip");
          }
        } else {
          if (this.get("appointmentDetail.customer.firstAddress.town")) {
            return this.get("appointmentDetail.customer.firstAddress.town");
          } else {
            return "";
          }
        }
      }
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get("appointmentDetail.patient.id") && !this.get("appointmentDetail.patient.currentWeight.id")) {
        this.get("store").findRecord("patient", this.get("appointmentDetail.patient.id"));
        this.get("store").query("weight", {
          patient: this.get("appointmentDetail.patient.id")
        });
      }

      if (this.get("appointmentDetail.id")) {
        this.get("store").findRecord("appointment", this.get("appointmentDetail.id"), {
          reload: true
        });
      }

      if (this.get("appointmentDetail.consultation.id")) {
        this.get("store").findRecord("consultation", this.get("appointmentDetail.consultation.id"), {
          reload: true
        });
      }

      if (this.get("appointmentDetail.customer.id")) {
        this.get("store").query("address", {
          customer: this.get("appointmentDetail.customer.id")
        });
      }
    },
    disableCheckInButton: Ember.computed('appointmentDetail.status', 'appointmentDetail.customer.id', function () {
      if (this.get('appointmentDetail.status') == 1 && this.get('appointmentDetail.customer.id')) {
        return false;
      }

      return true;
    }),
    disableRescheduleConsultationButton: Ember.computed('appointmentDetail.status', function () {
      return ![1, 3].includes(this.get('appointmentDetail.status'));
    }),
    disableEditButton: Ember.computed('appointmentDetail.status', 'canDoAppointmentDetails', function () {
      return ![1, 2, 3].includes(this.get('appointmentDetail.status')) || !this.get("canDoAppointmentDetails");
    }),
    disableGiveUpConsultationButton: Ember.computed('appointmentDetail.status', 'canDoAppointmentDetails', function () {
      return ![2, 3].includes(this.get('appointmentDetail.status')) || !this.get("canDoAppointmentDetails");
    }),
    disableMissConsultationButton: Ember.computed('appointmentDetail.status', 'canDoAppointmentDetails', function () {
      return this.get('appointmentDetail.status') > 1 || !this.get("canDoAppointmentDetails");
    }),
    disableGetOutButton: Ember.computed("appointmentDetail.status", 'canDoAppointmentDetails', function () {
      return this.get("appointmentDetail.status") !== 4 || !this.get("canDoAppointmentDetails");
    }),
    disableCheckoutWithoutPayingButton: Ember.computed("appointmentDetail.status", 'canDoAppointmentDetails', function () {
      return this.get("appointmentDetail.status") !== 5 || !this.get("canDoAppointmentDetails");
    }),
    disableConsultationDetailsButton: Ember.computed('appointmentDetail.status', function () {
      if (this.get('appointmentDetail.status') == 4 || this.get('appointmentDetail.status') == 5) {
        return false;
      }

      return true;
    }),
    disableGoToConsultationButton: Ember.computed('appointmentDetail.status', function () {
      if (this.get('appointmentDetail.status') == 3 || this.get('appointmentDetail.status') == 2 || this.get('appointmentDetail.status') == 4 || this.get('appointmentDetail.status') == 9 || this.get('appointmentDetail.status') == 1) {
        return false;
      }

      return true;
    }),
    disableMarkAsPaidButton: Ember.computed("organization.salesFeature", "appointmentDetail.status", "shoppingCart.id", "shoppingCart.status", function () {
      return this.get("organization.salesFeature") || ![4, 5].includes(this.get("appointmentDetail.status")) || this.get("shoppingCart.status") === 1;
    }),
    disablePayButton: Ember.computed("organization.salesFeature", "appointmentDetail.status", "shoppingCart.id", "shoppingCart.status", function () {
      return !this.get("organization.salesFeature") || ![5].includes(this.get("appointmentDetail.status")) || this.get("shoppingCart.status") === 1;
    }),
    disableDeleteButton: Ember.computed("appointmentDetail.status", "canDoAppointmentDetails", function () {
      return ![1, 2, 3].includes(this.get('appointmentDetail.status')) || !this.get("canDoAppointmentDetails");
    }),
    showPrescription: Ember.computed("appointmentDetail.status", function () {
      return this.get("appointmentDetail.status") === 4 || this.get("appointmentDetail.status") === 5;
    }),
    disableOpenShoppingCartDetailsButton: Ember.computed("appointmentDetail.status", "shoppingCart.id", function () {
      return !this.get("shoppingCart.id") || [7, 8, 10, 11].includes(this.get("appointmentDetail.status")) || this.get("shoppingCart.status") === 1;
    }),
    appointmentStartEndDiff: Ember.computed('appointmentDetail.start', 'appointmentDetail.end', function () {
      return (0, _moment.default)(this.get('appointmentDetail.end'), "DD/MM/YYYY HH:mm:ss").diff((0, _moment.default)(this.get('appointmentDetail.start'), "DD/MM/YYYY HH:mm:ss"), 'minutes');
    }),
    giveUpDate: Ember.computed('appointmentDetail.start', function () {
      return (0, _moment.default)().format('YYYY-MM-DD');
    }),
    giveUpTime: Ember.computed('appointmentDetail.start', function () {
      return (0, _moment.default)().format('HH:mm');
    }),
    missedDate: Ember.computed('appointmentDetail.start', function () {
      return (0, _moment.default)().format('YYYY-MM-DD');
    }),
    missedTime: Ember.computed('appointmentDetail.start', function () {
      return (0, _moment.default)().format('HH:mm');
    }),
    rescheduleAppointmentDay: Ember.computed('appointmentDetail.start', function () {
      return (0, _moment.default)(this.get('appointmentDetail.start')).format('YYYY-MM-DD');
    }),
    rescheduleAppointmentStartTime: Ember.computed('appointmentDetail.start', function () {
      return (0, _moment.default)(this.get('appointmentDetail.start')).format('HH:mm');
    }),
    rescheduleAppointmentEstimateEndTime: Ember.computed('rescheduleAppointmentStartTime', 'rescheduleAppointmentDay', function () {
      if (this.get('rescheduleAppointmentStartTime')) {
        var startDate = (0, _moment.default)(this.get('rescheduleAppointmentDay') + ' ' + this.get('rescheduleAppointmentStartTime'));
        var endDate = startDate.add(0.5, 'hours');
        return endDate.format('HH:mm');
      }
    }),
    rescheduleAppointmentDuration: Ember.computed('rescheduleAppointmentDay', 'rescheduleAppointmentStartTime', 'rescheduleAppointmentEstimateEndTime', function () {
      if (this.get('rescheduleAppointmentDay') && this.get('rescheduleAppointmentEstimateEndTime')) {
        var start = (0, _moment.default)(this.get('rescheduleAppointmentDay') + ' ' + this.get('rescheduleAppointmentStartTime'));
        var end = (0, _moment.default)(this.get('rescheduleAppointmentDay') + ' ' + this.get('rescheduleAppointmentEstimateEndTime'));
        return (0, _moment.default)(end.diff(start)).format('HH:mm');
      }
    }),
    rescheduledAppointment: {},
    isSchedulingFuture: function isSchedulingFuture(time) {
      if ((0, _moment.default)().diff(time) > 0) {
        return false;
      } else {
        return true;
      }
    },
    textDelimiter: function textDelimiter(text, lineBreak) {
      var output = "";

      for (var i = 0; i < text.length; i++) {
        if (i > 0 && i % lineBreak == 0) output += "\n";
        output += text.charAt(i);
      }

      return output;
    },
    addContentToPDFArray: function addContentToPDFArray(contentArr, dataType, param1, param2, data, lineBreak, rotation) {
      if (!String(data).includes("undefined") && dataType == "text") {
        var objectToAdd = _defineProperty({}, dataType, [param1, param2, this.textDelimiter(data, lineBreak)]);

        contentArr.push(objectToAdd);

        if (parseInt(rotation)) {
          objectToAdd[dataType].push(null);
          objectToAdd[dataType].push(90);
        }
      }
    },
    stringToArray: function stringToArray(string) {
      return string.split(' ');
    },
    breakLines: function breakLines(words, fontSize) {
      var result;

      switch (fontSize) {
        case 11:
          result = this.breakLinesAux(90, words, []);
          break;

        case 12:
          result = this.breakLinesAux(83, words, []);
          break;

        case 14:
          result = this.breakLinesAux(50, words, []);
          break;

        case 19:
          result = this.breakLinesAux(50, words, []);
          break;
      }

      return result;
    },
    breakLinesAux: function breakLinesAux(size, words, result) {
      if (words.length === 1) {
        result.push(words[0]);
        return result;
      }

      var phrase = words[0];
      var i = 1;

      for (; i < words.length; i++) {
        if (phrase.length + words[i].length + 1 < size) {
          phrase += " " + words[i];
        } else {
          break;
        }
      }

      result.push(phrase);

      if (i === words.length) {
        return result;
      } else {
        return this.breakLinesAux(size, words.slice(i), result);
      }
    },
    actions: {
      pay: function pay() {
        if (this.get("organization.salesFeature")) {
          var queryParams = {
            customerId: this.get('appointmentDetail.customer.id'),
            patientId: this.get('appointmentDetail.patient.id'),
            shoppingCartId: this.get('shoppingCart.id'),
            appointmentId: this.get('appointmentDetail.id')
          };
          this.sendAction("transitionToRoute", "organizations.organization.pointOfSales", queryParams);
          this.close();
        }
      },
      markAsPaid: function markAsPaid(appointment) {
        if (appointment) {
          if (appointment.get("shoppingCart.id") != null) {
            this.get("store").findRecord("shopping-cart", appointment.get("shoppingCart.id")).then(function (shoppingCart) {
              shoppingCart.set("status", 1);
              shoppingCart.save();
            });
          }

          appointment.set('exitTime', (0, _moment.default)().format());
          appointment.set('status', 6);
          appointment.save().then(function () {
            if (appointment.get("shoppingCart.id") && Ember.isEmpty(appointment.get("shoppingCart.lines"))) {
              appointment.get("shoppingCart").unloadRecord();
            }
          });
          this.get('flashMessages').success(this.get('intl').t('appointments.messages.checkOut'));
          this.close();
        }
      },
      saveReceptionNotes: function saveReceptionNotes() {
        var self = this;
        this.set("disableReceptionNotesButton", true);
        this.get("appointmentDetail").save().then(function () {
          return self.get('flashMessages').success(self.get('intl').t('alerts.changesSaved'));
        }).finally(function () {
          return self.set("disableReceptionNotesButton", false);
        });
      },
      generateDeclarationRGPD: function generateDeclarationRGPD() {
        var self = this;
        this.get("store").findRecord("customer", this.get("appointmentDetail.customer.id")).then(function () {
          self.sendAction("openModal", {
            entity: "pdf2",
            action: "details",
            model: {
              template: "declarationRGPD",
              appointment: self.get("appointmentDetail")
            }
          });
        });
      },
      generatePrescription: function generatePrescription() {
        this.sendAction("openModal", {
          entity: "pdf2",
          action: "details",
          model: {
            template: "prescription",
            consultation: this.get("appointmentDetail.consultation")
          }
        });
      },
      checkout: function checkout(appointment) {
        if (appointment) {
          appointment.set('endConsultation', (0, _moment.default)().format());
          appointment.set('status', 5);
          appointment.save();
          this.close();
        }
      },
      getOut: function getOut(appointment) {
        if (appointment) {
          appointment.set('exitTime', (0, _moment.default)().format());
          appointment.set("status", 6);
          appointment.save().then(function () {
            if (appointment.get("shoppingCart.id") && Ember.isEmpty(appointment.get("shoppingCart.lines"))) {
              appointment.get("shoppingCart").unloadRecord();
            }
          });
          this.get('flashMessages').success(this.get('intl').t('appointments.messages.checkOut'));
          this.close();
        }
      },
      goConsultation: function goConsultation() {
        //TODO remove run later and merge with waiting-rooms index moveToInVisit()
        var self = this;
        var appointment = this.get("appointmentDetail");

        if (appointment.get("status") !== 4) {
          appointment.set('status', 4);
          appointment.save().then(function () {
            self.get("store").query("consultation", {
              organization: self.get("organization.id"),
              appointment: appointment.get("id")
            }).then(function (consultations) {
              self.sendAction("transitionToRouteID", "organizations.organization.consultations.consultation", consultations.get("firstObject.id"));
            });
          });
        } else {
          self.sendAction("transitionToRouteID", "organizations.organization.consultations.consultation", appointment.get('consultation.id'));
        }

        this.close();
      },
      giveUp: function giveUp() {
        Ember.$("#openGiveUpReason").modal();
        this.close();
      },
      modalGiveUpReason: function modalGiveUpReason(appointment) {
        if (appointment) {
          var self = this;
          appointment.set('giveUpDate', (0, _moment.default)(this.get('giveUpDate') + ' ' + this.get('giveUpTime')).format());
          appointment.set('giveUpReason', this.get('giveUpReason'));
          appointment.set("status", 8);
          appointment.save().then(function () {
            if (appointment.get("shoppingCart.id") && Ember.isEmpty(appointment.get("shoppingCart.lines"))) {
              appointment.get("shoppingCart").unloadRecord();
            }

            self.set('giveUpReason', null);
            Ember.$("#openGiveUpReason").modal("hide");
            self.close();
          });
        }
      },
      missedConsultation: function missedConsultation() {
        Ember.$("#openMissedReason").modal();
        this.close();
      },
      modalMissedReason: function modalMissedReason(appointment) {
        if (appointment) {
          var self = this;
          appointment.set('missedDate', (0, _moment.default)(this.get('missedDate') + ' ' + this.get('missedTime')).format());
          appointment.set('missedReason', this.get('missedReason'));
          appointment.set("status", 7);
          appointment.save().then(function () {
            if (appointment.get("shoppingCart.id") && Ember.isEmpty(appointment.get("shoppingCart.lines"))) {
              appointment.get("shoppingCart").unloadRecord();
            }

            self.set('missedReason', null);
            Ember.$("#openMissedReason").modal("hide");
            self.close();
          });
        }
      },
      rescheduleConsultation: function rescheduleConsultation() {
        if (this.get('appointmentDetail')) {
          this.set('appointmentDetail.waitingRoom', false);
          this.get('appointmentDetail').save();
          this.close();

          if (this.get("appointmentDetail.comingFrom") === "week") {
            this.sendAction("transitionToRouteAppointmentsWeek", {
              oldAppointment: this.get('appointmentDetail.id')
            });
          } else {
            this.sendAction("transitionToRouteAppointmentsDay", {
              oldAppointment: this.get('appointmentDetail.id')
            });
          }
        }
      },
      checkIn: function checkIn() {
        var self = this;
        var appointment = this.get('appointmentDetail');

        if (appointment) {
          appointment.set('arrivalTime', (0, _moment.default)().format());
          appointment.set('status', 3);
          appointment.save().then(function () {
            return self.close();
          });
        }
      },
      newScheduling: function newScheduling(appointment) {
        if (appointment) {
          this.sendAction('newSchedulingAppointment', appointment);
        }
      },
      missedReasonCanceled: function missedReasonCanceled() {
        this.set('missedReason', null);
      },
      selectPatientAppointment: function selectPatientAppointment(patient) {
        var appointment = this.get("appointmentDetail");
        appointment.set('patient', patient);
        appointment.save();
        Ember.$('#openAssociatePatientOnAppointment').hide();
      },
      openModalCreateWeight: function openModalCreateWeight(model) {
        this.sendAction("openModal", {
          entity: "weight",
          action: "new",
          model: model
        });
        this.close();
      },
      openWeightsModal: function openWeightsModal(model) {
        this.sendAction("openModal", {
          entity: "weight",
          action: "details",
          model: model
        });
      },
      openModalAssociatePatient: function openModalAssociatePatient() {
        Ember.$('#openAssociatePatientOnAppointment').modal();
      },
      openEditPatientModal: function openEditPatientModal(model) {
        this.sendAction('openEditPatientModal', model);
      },
      delete: function _delete(id) {
        this.sendAction("delete", id);
      },
      setEnabled: function setEnabled(model) {
        this.sendAction("setEnabled", model);
      },
      makeSale: function makeSale(model) {
        this.sendAction("makeSale", model);
      },
      openCreateAppointmentModal: function openCreateAppointmentModal(model) {
        this.close();
        this.sendAction('openCreateAppointmentModal', model);
      },
      openUpdateCustomerModal: function openUpdateCustomerModal(record) {
        this.sendAction('openUpdateCustomerModal', record);
      },
      openConsultationDetails: function openConsultationDetails() {
        this.sendAction("openModal", {
          entity: "consultation",
          action: "details",
          model: this.get("appointmentDetail.consultation")
        });
      },
      callShoppingCartEdit: function callShoppingCartEdit() {
        this.sendAction("openModal", {
          entity: "shopping-cart",
          action: "edit",
          model: {
            shoppingCart: this.get("shoppingCart"),
            hidePayButton: true
          }
        });
      },
      edit: function edit() {
        this.close();
        this.sendAction("openModal", {
          entity: "appointment",
          action: "edit",
          model: this.get("appointmentDetail")
        });
      },
      deleteAppointment: function deleteAppointment() {
        var self = this;

        if (confirm(this.get('intl').t('actions.removeAppointmentConsultation'))) {
          this.get("appointmentDetail").destroyRecord();
          self.close();
        }
      },
      roomSelect: function roomSelect(room) {
        this.set("appointmentDetail.room", room);
        this.get("appointmentDetail").save();
      }
    }
  });

  _exports.default = _default;
});