define("crm/templates/organizations/organization/stats/clinical/totals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aeMD0i36",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"u-second-nav\",null,[[\"title\",\"titleIcon\",\"class\"],[[25,\"t\",[\"consultations.title\"],null],[25,\"t\",[\"icons.consultations\"],null],\"module-clinical\"]]],false],[0,\"\\n\\n\"],[1,[25,\"stats-totals\",null,[[\"model\",\"moduleColor\"],[[20,[\"model\"]],\"#4CAF50\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/stats/clinical/totals.hbs"
    }
  });

  _exports.default = _default;
});