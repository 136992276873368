define("crm/templates/organizations/organization/how-to/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iCt1ZkqT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ibox-panel\",null,[[\"class\",\"titleIcon\",\"title\",\"hideTools\"],[\"module-crm\",[25,\"t\",[\"icons.sms\"],null],[25,\"t\",[\"pages.sms\"],null],true]],{\"statements\":[[0,\"    \"],[6,\"ol\"],[7],[0,\"\\n        \"],[6,\"h2\"],[7],[0,\"\\n            \"],[6,\"li\"],[7],[0,\"\\n                \"],[1,[25,\"t\",[\"howTo.steps.makeAvailable\"],null],false],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\\n        \"],[6,\"hr\"],[7],[8],[0,\"\\n\\n        \"],[6,\"h2\"],[7],[0,\"\\n            \"],[6,\"li\"],[7],[0,\"\\n                \"],[1,[25,\"t\",[\"howTo.steps.goToPeriodics\"],null],false],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\\n        \"],[6,\"hr\"],[7],[8],[0,\"\\n\\n        \"],[6,\"h2\"],[7],[0,\"\\n            \"],[6,\"li\"],[7],[0,\"\\n                \"],[1,[25,\"t\",[\"howTo.steps.createCampaign\"],null],false],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\\n        \"],[6,\"hr\"],[7],[8],[0,\"\\n\\n        \"],[6,\"h2\"],[7],[0,\"\\n            \"],[6,\"li\"],[7],[0,\"\\n                \"],[1,[25,\"t\",[\"howTo.steps.startSendingMessages\"],null],false],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/how-to/index.hbs"
    }
  });

  _exports.default = _default;
});