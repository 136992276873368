define("crm/templates/components/umt-datetime3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s2SlvBJa",
    "block": "{\"symbols\":[],\"statements\":[[6,\"small\"],[7],[1,[18,\"date\"],false],[8],[4,\"if\",[[20,[\"record\",\"time\"]]],null,{\"statements\":[[0,\" \"],[1,[18,\"time\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/umt-datetime3.hbs"
    }
  });

  _exports.default = _default;
});