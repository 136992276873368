define("crm/templates/components/show-task-workers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ECMVy08d",
    "block": "{\"symbols\":[\"worker\"],\"statements\":[[4,\"each\",[[20,[\"record\",\"workers\"]]],null,{\"statements\":[[0,\"    \"],[6,\"p\"],[7],[0,\"\\n        \"],[4,\"link-to\",[\"organizations.organization.workers.worker\",[19,1,[\"id\"]]],null,{\"statements\":[[0,\" \"],[1,[19,1,[\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-task-workers.hbs"
    }
  });

  _exports.default = _default;
});