define("crm/templates/components/item/umt-icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "idb+MwE/",
    "block": "{\"symbols\":[],\"statements\":[[6,\"i\"],[10,\"title\",[25,\"t\",[\"compoundProduct.title\"],null],null],[10,\"class\",[26,[[25,\"if\",[[20,[\"record\",\"displayPrice\"]],\"text-sales\",\"text-muted\"],null],\" fa fa-fw fa-\",[25,\"t\",[\"icons.compoundProduct\"],null]]]],[7],[8],[0,\"\\n\"],[6,\"i\"],[10,\"title\",[25,\"t\",[\"items.form.category.sellable\"],null],null],[10,\"class\",[26,[[25,\"if\",[[20,[\"record\",\"sellable\"]],\"text-sales\",\"text-muted\"],null],\" fa fa-fw fa-\",[25,\"t\",[\"icons.item\"],null]]]],[7],[8],[0,\"\\n\"],[6,\"i\"],[10,\"title\",[25,\"t\",[\"items.form.category.stockable\"],null],null],[10,\"class\",[26,[[25,\"if\",[[20,[\"record\",\"stockable\"]],\"text-sales\",\"text-muted\"],null],\" fa fa-fw fa-\",[25,\"t\",[\"icons.warehouses\"],null]]]],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/item/umt-icons.hbs"
    }
  });

  _exports.default = _default;
});