define("crm/templates/components/item-type-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WLbhe5s4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"hideFooter\",\"class\"],[\"modal-itemType-list\",[25,\"t\",[\"configurations.item.brands.title\"],null],true,\"module-sales\"]],{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"disable\",\"enable\",\"openEdit\"],[[20,[\"itemTypes\"]],[20,[\"columns\"]],\"disable\",\"enable\",\"openEdit\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/item-type-list.hbs"
    }
  });

  _exports.default = _default;
});