define("crm/templates/components/laboratory-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cDRIp5+O",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"class\",\"hideFooter\"],[\"modal-laboratory-create\",[25,\"t\",[\"laboratories.new.title\"],null],\"module-clinical\",true]],{\"statements\":[[4,\"custom-form\",null,[[\"fieldTypes\",\"save\",\"actionText\",\"reset\",\"disabledAction\",\"canceled\"],[[20,[\"fieldTypes\"]],\"create\",\"crud.create\",\"reset\",[20,[\"disabledAction\"]],\"canceled\"]],{\"statements\":[[0,\"        \"],[6,\"fieldset\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n                \"],[6,\"label\"],[9,\"class\",\"col-lg-4 control-label\"],[7],[1,[25,\"t\",[\"laboratories.laboratory.name\"],null],false],[6,\"span\"],[10,\"title\",[26,[[25,\"t\",[\"validations.fieldRequired\"],null]]]],[9,\"class\",\"text-danger\"],[7],[0,\" *\"],[8],[8],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"col-lg-8\"],[7],[0,\"\\n                    \"],[1,[25,\"input\",null,[[\"type\",\"required\",\"value\",\"class\"],[\"text\",true,[20,[\"newLaboratory\",\"name\"]],\"form-control\"]]],false],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/laboratory-new.hbs"
    }
  });

  _exports.default = _default;
});