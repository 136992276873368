define("crm/templates/components/show-patients-by-customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mk9VMaY0",
    "block": "{\"symbols\":[\"patient\",\"index\"],\"statements\":[[4,\"each\",[[20,[\"record\",\"patients\"]]],null,{\"statements\":[[4,\"if\",[[25,\"lt\",[[19,2,[]],3],null]],null,{\"statements\":[[0,\"        \"],[1,[19,1,[\"name\"]],false],[0,\" \"],[6,\"br\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-patients-by-customer.hbs"
    }
  });

  _exports.default = _default;
});