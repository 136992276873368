define("crm/templates/components/show-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LH2gWEZf",
    "block": "{\"symbols\":[],\"statements\":[[6,\"a\"],[3,\"action\",[[19,0,[]],\"toggleShowMoreFields\"]],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"showMoreFields\"]]],null,{\"statements\":[[0,\"        \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[25,\"t\",[\"icons.caretUp\"],null]]]],[7],[8],[0,\" \"],[1,[25,\"t\",[\"intl.showLess\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[25,\"t\",[\"icons.caretDown\"],null]]]],[7],[8],[0,\" \"],[1,[25,\"t\",[\"intl.showMore\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-more.hbs"
    }
  });

  _exports.default = _default;
});