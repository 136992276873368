define("crm/routes/organizations/organization/tasks/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      startedBefore: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return this.get("store").query("task", {
        organization: this.modelFor("organizations.organization").get("id"),
        startedBefore: params.startedBefore,
        status: 1
      }); //.then(hash => hash.filterBy("id", "47523"))
    }
  });

  _exports.default = _default;
});