define("crm/templates/components/tasks-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s0h90Y5S",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"filteredTasks\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"showCheck\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[20,[\"showAll\"]]]]],false],[0,\" \"],[1,[25,\"t\",[\"exams.all\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[25,\"pet-models-table\",null,[[\"class\",\"data\",\"columns\",\"pending\",\"callModal\",\"openDone\",\"updateModel\"],[\"table-fixed\",[20,[\"filteredTasks\"]],[20,[\"columns\"]],[20,[\"model\",\"pending\"]],\"callModal\",\"openDone\",\"updateModel\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n    \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.task\"],null]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/tasks-list.hbs"
    }
  });

  _exports.default = _default;
});