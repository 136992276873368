define("crm/templates/components/contacts-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "awtR+9+r",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"class\",\"callModal\"],[[20,[\"model\"]],[20,[\"columns\"]],\"hide-search table-fixed\",\"callModal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.notes\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/contacts-list.hbs"
    }
  });

  _exports.default = _default;
});