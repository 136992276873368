define("crm/templates/components/exams-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kh3Vfw6u",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[20,[\"hideDates\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",[20,[\"minCreatedAfter\"]],[20,[\"createdBefore\"]],[20,[\"createdAfter\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n  \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",[20,[\"createdAfter\"]],[20,[\"maxCreatedBefore\"]],[20,[\"createdBefore\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n  \"],[6,\"hr\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"model\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"p-absolute\"],[7],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[20,[\"showAll\"]]]]],false],[0,\" \"],[1,[25,\"t\",[\"exams.all\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"showGlobalFilter\",\"showComponentFooter\",\"callModal\",\"document\",\"deleteExam\"],[[20,[\"filteredExams\"]],[20,[\"columns\"]],[25,\"gt\",[[20,[\"filteredExams\",\"length\"]],10],null],[25,\"and\",[[25,\"gt\",[[20,[\"filteredExams\",\"length\"]],10],null],[25,\"and\",[[25,\"not\",[[20,[\"hideComponentFooter\"]]],null],[25,\"not\",[[20,[\"singlePage\"]]],null]],null]],null],\"callModal\",\"document\",\"deleteExam\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.exam\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/exams-list.hbs"
    }
  });

  _exports.default = _default;
});