define("crm/templates/components/patient-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xMf+VrCY",
    "block": "{\"symbols\":[],\"statements\":[[6,\"section\"],[9,\"class\",\"page-body\"],[7],[0,\"\\n    \"],[1,[25,\"u-search-form\",null,[[\"formClass\",\"search\",\"lockButton\",\"searchText\",\"oldString\",\"placeholder\"],[\"module-crm\",[25,\"action\",[[19,0,[]],\"searchPatients\"],null],[20,[\"lockButton\"]],[20,[\"searchText\"]],[20,[\"oldString\"]],[25,\"t\",[\"actions.search\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[20,[\"patients\",\"length\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"displayDataChangedAction\"],[[20,[\"patients\"]],[20,[\"columns\"]],[25,\"action\",[[19,0,[]],\"rowSelected\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/patient-select.hbs"
    }
  });

  _exports.default = _default;
});