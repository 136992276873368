define("crm/templates/components/item-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1nYPVI54",
    "block": "{\"symbols\":[],\"statements\":[[6,\"section\"],[7],[0,\"\\n  \"],[1,[25,\"u-search-form\",null,[[\"formClass\",\"search\",\"lockButton\",\"searchText\",\"oldString\"],[[20,[\"btnClass\"]],[25,\"action\",[[19,0,[]],\"searchItems\"],null],[20,[\"lockButton\"]],[20,[\"searchText\"]],[20,[\"oldString\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[25,\"and\",[[20,[\"items\",\"length\"]],[20,[\"searchText\",\"length\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"displayDataChangedAction\",\"class\"],[[20,[\"items\"]],[20,[\"columns\"]],[25,\"action\",[[19,0,[]],\"rowSelected\"],null],\"hide-search\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[20,[\"hideNoData\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.item\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/item-select.hbs"
    }
  });

  _exports.default = _default;
});