define("crm/templates/components/task-to-do", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zqz+CsWX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"action1\",\"action1Text\"],[\"modal-task-done\",[25,\"t\",[\"tasks.task.title\"],null],[25,\"if\",[[25,\"eq\",[[20,[\"model\",\"status\"]],1],null],\"modalToDoTask\"],null],\"tasks.done\"]],{\"statements\":[[0,\"    \"],[6,\"section\"],[7],[0,\"\\n        \"],[6,\"form\"],[9,\"class\",\"form-horizontal\"],[7],[0,\"\\n            \"],[6,\"fieldset\"],[7],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n                    \"],[6,\"label\"],[9,\"class\",\"col-lg-3 control-label\"],[7],[1,[25,\"t\",[\"enums.chronos.dateUpper\"],null],false],[8],[0,\"\\n                    \"],[6,\"div\"],[9,\"class\",\"col-lg-6\"],[7],[0,\"\\n                        \"],[1,[25,\"input\",null,[[\"type\",\"required\",\"value\",\"disabled\"],[\"date\",true,[20,[\"doTaskDay\"]],true]]],false],[0,\"\\n                        \"],[1,[25,\"input\",null,[[\"type\",\"value\",\"disabled\"],[\"time\",[20,[\"doTaskTime\"]],true]]],false],[0,\"\\n                    \"],[8],[0,\"\\n                \"],[8],[0,\"\\n\\n                \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n                    \"],[6,\"label\"],[9,\"class\",\"col-lg-3 control-label\"],[7],[1,[25,\"t\",[\"tasks.task.comment\"],null],false],[8],[0,\"\\n                    \"],[6,\"div\"],[9,\"class\",\"col-lg-6\"],[7],[0,\"\\n                        \"],[1,[25,\"textarea\",null,[[\"type\",\"class\",\"maxlength\",\"value\"],[\"text\",\"form-control\",\"255\",[20,[\"model\",\"comment\"]]]]],false],[0,\"\\n                        \"],[6,\"p\"],[9,\"class\",\"help-block\"],[7],[1,[25,\"characters-number\",null,[[\"max\",\"text\"],[255,[20,[\"model\",\"comment\"]]]]],false],[8],[0,\"\\n                    \"],[8],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/task-to-do.hbs"
    }
  });

  _exports.default = _default;
});