define("crm/templates/components/show-bundle-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6JucC5W2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"fixedPrice\"]]],null,{\"statements\":[[0,\"    \"],[1,[18,\"fixedPrice\"],false],[0,\" \"],[1,[25,\"t\",[\"icons.currency\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-bundle-price.hbs"
    }
  });

  _exports.default = _default;
});