define("crm/models/document", ["exports", "ember-data", "crm/models/abstract-model"], function (_exports, _emberData, _abstractModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModel.default.extend({
    intl: Ember.inject.service(),
    payableAmount: _emberData.default.attr("number"),
    internalNotes: _emberData.default.attr("string"),
    status: _emberData.default.attr("number"),
    organization: _emberData.default.belongsTo("organization"),
    lastLineGroup: Ember.computed("lines.@each.lineGroup", function () {
      var lineGroup = 0;
      this.get("lines").forEach(function (line) {
        if (!Ember.isEmpty(line.get("lineGroup")) && line.get("lineGroup") > lineGroup) {
          lineGroup = line.get("lineGroup");
        }
      });
      return lineGroup;
    })
  });

  _exports.default = _default;
});