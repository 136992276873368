define("crm/abilities/feature", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    other: 0,
    veterinary: 2,
    nurse: 3,
    assistant: 4,
    receptionist: 5,
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    worker: Ember.computed.readOnly("sessionAccount.currentWorker"),
    settings: Ember.computed.readOnly("sessionAccount.currentWorker.organization.settings"),
    features: Ember.computed.readOnly("sessionAccount.currentWorker.organization.features"),
    featureNames: Ember.computed("features.[]", function () {
      return this.get("features") ? this.get("features").map(function (x) {
        return x.get("name");
      }) : [];
    }),
    seattingCodes: Ember.computed("settings.[]", function () {
      return this.get("settings") ? Object.keys(this.get("settings")) : [];
    }),
    canDo: Ember.computed('manager', function () {
      return true;
    }),
    canCreate: Ember.computed('manager', function () {
      return true;
    }),
    canRead: Ember.computed('manager', function () {
      return true;
    }),
    canUpdate: Ember.computed('manager', function () {
      return true;
    }),
    canDelete: Ember.computed('manager', function () {
      return true;
    }),
    g: Ember.computed("currentUser.god", function () {
      return this.get("currentUser.god");
    }),
    manager: Ember.computed("worker.manager", function () {
      return this.get("g") || this.get("worker.manager");
    }),
    undermanager: Ember.computed("manager", "worker.undermanager", function () {
      return this.get("manager") || this.get("worker.undermanager");
    }),
    is: function is(role) {
      return this.canRoles([role]);
    },
    canRoles: function canRoles(roles) {
      return this.get("g") || roles && roles.includes(this.get("worker.role"));
    },
    hasFeature: function hasFeature(feature) {
      return this.get("featureNames") ? this.get("featureNames").includes(feature) : null;
    },
    hasSetting: function hasSetting(setting) {
      return this.get("seattingCodes") ? this.get("seattingCodes").includes(setting) : null;
    }
  });

  _exports.default = _default;
});