define("crm/templates/organizations/organization/inventory/excess", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NzI9qcLR",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"u-second-nav\",null,[[\"title\",\"titleIcon\",\"class\"],[[25,\"t\",[\"pages.excess\"],null],[25,\"t\",[\"icons.transferStock\"],null],\"module-crm\"]]],false],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"data\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"hideComponentFooter\",\"pageSize\"],[[20,[\"model\",\"data\"]],[20,[\"columns\"]],true,[20,[\"model\",\"data\",\"length\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.items\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/inventory/excess.hbs"
    }
  });

  _exports.default = _default;
});