define("crm/components/purchase-receipt-new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service("session"),
    es: Ember.inject.service(),
    errorReturned: Ember.computed.alias("es.errorReturned"),
    supplier: null,
    selectedFinancialAccount: null,
    paymentMethod: "NUM",
    saving: false,
    didUpdateAttrs: function didUpdateAttrs() {
      // Purchase Invoice
      if (this.get("model.supplier") && this.get("model.lines")) {
        this.set("supplier", this.get("model.supplier"));
      }

      if (this.get("model.supplier") && this.get("model.supplier.enabled")) {
        this.set("supplier", this.get("model.supplier"));
      }

      if (this.get("financialAccounts.length") == 1 && this.get("financialAccounts.firstObject.financialAccountType") == 1) {
        if (!this.get("financialAccounts.firstObject.isManual") || this.get("financialAccounts.firstObject.isManual") && this.get("financialAccounts.firstObject.isOpen")) this.set("model.financialAccount", this.get("financialAccounts.firstObject"));
      }

      this.set("model.date", this.get("todayDate"));
    },
    close: function close() {
      this.set("closed", this.get("closed") ? false : true);
    },
    reset: function reset() {
      this.set("saving", false);
      this.set("supplier", null);
      this.set("selectedFinancialAccount", null);
      this.set("cash", null);
      this.set("checkNumber", null);
      this.set("checkDate", null);
      this.set("internalNotes", null);
      this.set("paymentMethod", "NUM");
      this.set("model", {});
    },
    todayDate: Ember.computed("moment", function () {
      return (0, _moment.default)().format("YYYY-MM-DD");
    }),
    fillOutSettledObserver: Ember.observer("openItems.@each.open", function () {
      var self = this;
      this.get("openItems").forEach(function (openItem) {
        openItem.set("settled", self.money(openItem.get("openAmount")));
        openItem.set("discount", 0);
      });
    }),
    financialAccountsUsable: Ember.computed("financialAccounts.[]", "financialAccounts.@each.financialAccountType", "financialAccounts.@each.isManual", "financialAccounts.@each.isOpen", function () {
      return Ember.isEmpty(this.get("financialAccounts")) ? [] : this.get("financialAccounts").filter(function (fa) {
        return !fa.get("isManual") || fa.get("isManual") && fa.get("isOpen");
      });
    }),
    total: Ember.computed("openItems.@each.selected", "openItems.@each.settled", "openItems.@each.discount", function () {
      var total = 0;
      var self = this;

      if (this.get("openItems")) {
        this.get("openItems").forEach(function (openItem) {
          if (openItem.get("selected")) {
            total += self.money(openItem.get("settled"));
            total -= Ember.isEmpty(Number(openItem.get("discount"))) ? 0 : Number(openItem.get("discount"));
          }
        });
      }

      return total;
    }),
    change: Ember.computed("cash", "total", function () {
      return this.get("cash") ? this.money(this.get("cash") - this.get("total")) : 0;
    }),
    hasErrors: Ember.computed("total", function () {
      return this.get("openItems").filter(function (o) {
        return o.get("selected");
      }).get("length") && this.get("total") <= 0;
    }),
    disableBtn: Ember.computed("total", "filteredOpenItems.@each.selected", "saving", "change", "paymentMethod", "checkNumber", "checkDate", "model.date", function () {
      return this.get("saving") || this.get("total") <= 0 || Ember.isEmpty(this.get("openItems").filterBy("selected")) || Ember.isEmpty(this.get("paymentMethod")) || Ember.isEmpty(this.get("model.date")) || this.get("change") < 0 && this.get("change") !== 0 - this.get("total") || this.get("paymentMethod") === "CHQ" && (Ember.isEmpty(this.get("checkNumber")) || Ember.isEmpty(this.get("checkDate")));
    }),
    noFinancialAccountTypeBankAccount: Ember.computed("financialAccountsUsable.[]", "financialAccountsUsable.@each.financialAccountType", function () {
      return Ember.isEmpty(this.get("financialAccountsUsable")) ? [] : this.get("financialAccountsUsable").filterBy("financialAccountType", 2).get("length") > 0;
    }),
    noFinancialAccountTypeRegister: Ember.computed("financialAccountsUsable.[]", "financialAccountsUsable.@each.financialAccountType", "paymentMethod", function () {
      return Ember.isEmpty(this.get("financialAccountsUsable")) ? [] : this.get("financialAccountsUsable").filterBy("financialAccountType", 1).get("length") > 0 || this.get("paymentMethod") === "DEB";
    }),
    openItems: Ember.computed("supplier.id", function () {
      if (this.get("supplier.id")) {
        return this.get("store").query("purchase-invoice", {
          supplier: this.get("supplier.id"),
          open: "true"
        });
      } else {
        return [];
      }
    }),
    filteredOpenItems: Ember.computed("openItems.[]", function () {
      var self = this;

      if (this.get("model.lines")) {
        var filteredOpenItems = [];
        this.get("openItems").forEach(function (openItem) {
          if (openItem.get("id") === self.get("model.id")) {
            openItem.set("selected", true);
          }

          filteredOpenItems.addObject(openItem);
        });
        return filteredOpenItems;
      } else {
        return this.get("openItems");
      }
    }),
    openItemsSum: Ember.computed("openItems.@each.openAmount", function () {
      var result = 0;

      if (this.get("openItems")) {
        this.get("openItems").forEach(function (openItem) {
          result += openItem.get("openAmount");
        });
      }

      return result;
    }),
    actions: {
      reset: function reset() {
        this.reset();
      },
      canceled: function canceled() {
        this.close();
      },
      setSupplier: function setSupplier(supplier) {
        this.set("supplier", supplier);
      },
      create: function create() {
        this.set("saving", true);
        var self = this;
        var newPurchaseReceipt = {
          supplier: this.get("supplier"),
          financialAccount: this.get("model.financialAccount"),
          paymentMethod: this.get("paymentMethod"),
          checkNumber: this.get("checkNumber") ? this.get("checkNumber") : null,
          checkDate: this.get("checkDate") ? this.get("checkDate") : null,
          organization: this.get("organization"),
          internalNotes: this.get("internalNotes"),
          date: this.get("model.date"),
          name: this.get("model.name")
        };
        this.get("store").createRecord("purchase-receipt", newPurchaseReceipt).save().then(function (savedPurchaseReceipt) {
          self.get("openItems").forEach(function (openItem) {
            if (openItem.get("selected")) {
              var newPurchaseReceiptPurchaseInvoice = {
                purchaseInvoice: openItem,
                purchaseReceipt: savedPurchaseReceipt,
                settled: openItem.get("settled")
              };
              self.get("store").createRecord("purchase-receipt-purchase-invoice", newPurchaseReceiptPurchaseInvoice).save().then(function () {
                self.sendAction("created", savedPurchaseReceipt);
                self.close();
              }).catch(function (e) {
                self.set("saving", false);
                self.get("es").handle(e);
              });
            }
          });
        }).catch(function (e) {
          self.set("saving", false);
          self.get("es").handle(e);
        });
      }
    },
    money: function money(number) {
      return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
    }
  });

  _exports.default = _default;
});