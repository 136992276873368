define("crm/routes/organizations/organization/update-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.modelFor("organizations.organization");
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var organizationController = this.controllerFor("organizations.organization");
      controller.set("itemTypes", organizationController.get("itemTypes"));
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("selectedLeftItemType", null);
        controller.set("selectedRightItemType", null);
        controller.set("leftItems", []);
        controller.set("rightItems", []);
      }
    }
  });

  _exports.default = _default;
});