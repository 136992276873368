define("crm/templates/organizations/organization/listings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y8DQEpXF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"model\",\"organization\",\"statsFeature\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"module-stats\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"filters-panel no-margin\"],[7],[0,\"\\n      \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"actions.filters\"],null],false],[8],[0,\"\\n      \"],[1,[25,\"input-pills\",null,[[\"values\",\"selected\",\"onchange\"],[[20,[\"calendarPeriodTypes\"]],[25,\"readonly\",[[20,[\"filter1\"]]],null],[25,\"action\",[[19,0,[]],\"setFilter1\"],null]]]],false],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"well\"],[7],[1,[25,\"date-filters\",null,[[\"nextDateStart\",\"nextDateEnd\",\"search\",\"class\"],[[25,\"readonly\",[[20,[\"createdAfter\"]]],null],[25,\"readonly\",[[20,[\"createdBefore\"]]],null],\"search\",\"no-margin\"]]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n    \"],[1,[18,\"outlet\"],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"unavailable-feature\",null,[[\"module\",\"feature\"],[\"module-stats\",\"stats\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/listings.hbs"
    }
  });

  _exports.default = _default;
});