define("crm/templates/organizations/organization/equipments/equipment/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XqxfiPlE",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"pages.equipment\"],null],[25,\"t\",[\"icons.manufacturers\"],null]]],{\"statements\":[[0,\"  \"],[6,\"li\"],[7],[4,\"link-to\",[\"organizations.organization.equipments.equipment.edit\",[20,[\"model\",\"id\"]]],[[\"title\"],[[25,\"t\",[\"actions.edit\"],null]]],{\"statements\":[[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.pencilSquare\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"actions.edit\"],null],false]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"hideCollapse\"],[true]],{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-6\"],[7],[0,\"\\n        \"],[6,\"ul\"],[9,\"class\",\"list-group\"],[7],[0,\"\\n          \"],[6,\"li\"],[9,\"class\",\"list-group-item\"],[7],[0,\"\\n            \"],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[1,[20,[\"model\",\"name\"]],false],[8],[0,\"\\n            \"],[6,\"strong\"],[7],[1,[25,\"t\",[\"items.item.name\"],null],false],[8],[0,\"\\n          \"],[8],[0,\"\\n          \"],[6,\"li\"],[9,\"class\",\"list-group-item\"],[7],[0,\"\\n            \"],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[1,[20,[\"model\",\"manufacturer\",\"name\"]],false],[8],[0,\"\\n            \"],[6,\"span\"],[7],[1,[25,\"t\",[\"pages.manufacturer\"],null],false],[8],[0,\"\\n          \"],[8],[0,\"\\n          \"],[6,\"li\"],[9,\"class\",\"list-group-item\"],[7],[0,\"\\n            \"],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[1,[20,[\"model\",\"type\",\"name\"]],false],[8],[0,\"\\n            \"],[6,\"span\"],[7],[1,[25,\"t\",[\"equipments.type\"],null],false],[8],[0,\"\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/equipments/equipment/index.hbs"
    }
  });

  _exports.default = _default;
});