define("crm/templates/organizations/organization/sms/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ULaCqB5x",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"class\",\"title\",\"titleIcon\"],[\"module-crm\",[25,\"t\",[\"sms.sent.title\"],null],[25,\"t\",[\"icons.sms\"],null]]],{\"statements\":[[0,\"  \"],[6,\"li\"],[7],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"sms.new.title\"],null]]]],[3,\"action\",[[19,0,[]],\"sendSMS\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.sms\"],null]]]],[7],[8],[0,\" \"],[1,[25,\"t\",[\"sms.new.title\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"class\"],[\"module-crm\"]],{\"statements\":[[0,\"  \"],[1,[25,\"sms-sent-list\",null,[[\"model\",\"openDetails\",\"openEdit\",\"reloadModel\",\"updateModel\"],[[20,[\"model\"]],\"openDetails\",\"openEdit\",\"reloadModel\",\"updateModel\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/sms/index.hbs"
    }
  });

  _exports.default = _default;
});