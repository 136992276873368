define("crm/templates/components/documents-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0oyzSRys",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"singlePage\",\"hideActions\",\"showPdf\",\"transitionToRoute\",\"updateQuery\",\"addReceipt\",\"disable\",\"enable\",\"reloadModel\",\"handleOrder\",\"openModal\",\"annulDocument\",\"editDocumentModal\"],[[20,[\"model\"]],[20,[\"columns\"]],[20,[\"singlePage\"]],[20,[\"hideActions\"]],\"showPdf\",\"transitionToRoute\",\"updateQuery\",\"addReceipt\",\"disable\",\"enable\",\"reloadModel\",\"handleOrder\",\"openModal\",\"annulDocument\",\"editDocumentModal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.documents\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/documents-table.hbs"
    }
  });

  _exports.default = _default;
});