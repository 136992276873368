define("crm/templates/components/exam-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nL2m8Tlh",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"table-actions\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"hidle text-center\"],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-ellipsis-h disabled\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"actions text-center\"],[7],[0,\"\\n    \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"exams.exam.view\"],null],null],[3,\"action\",[[19,0,[]],\"callModal\",\"details\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.detail\"],null]]]],[7],[8],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"canDoExam\"]]],null,{\"statements\":[[0,\"      \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"exams.results\"],null],null],[3,\"action\",[[19,0,[]],\"callModal\",\"edit\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.pencilSquare\"],null]]]],[7],[8],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"record\",\"doneDate\"]]],null,{\"statements\":[[0,\"        \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"internments.internment.details.reports\"],null],null],[3,\"action\",[[19,0,[]],\"document\"]],[7],[6,\"i\"],[9,\"class\",\"fa fa-fw fa-lg fa-file-pdf-o\"],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"canDelete\"]]],null,{\"statements\":[[0,\"        \"],[6,\"a\"],[10,\"title\",[25,\"t\",[\"exams.delected\"],null],null],[3,\"action\",[[19,0,[]],\"delete\",[20,[\"record\"]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-lg fa-\",[25,\"t\",[\"icons.delete\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/exam-actions.hbs"
    }
  });

  _exports.default = _default;
});