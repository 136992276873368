define("crm/templates/components/umt-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y0QH1ZBH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"number\"]]],null,{\"statements\":[[0,\"    \"],[1,[18,\"unit\"],false],[0,\",\"],[6,\"small\"],[7],[1,[18,\"decimal\"],false],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/umt-number.hbs"
    }
  });

  _exports.default = _default;
});