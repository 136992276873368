define("crm/templates/components/stays-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pCzcAw1H",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",\"1900-01-01\",\"2500-12-31\",[20,[\"date\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n\\n\"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openDetails\",\"editStayModal\"],[[20,[\"model\"]],[20,[\"columns\"]],\"openDetails\",\"editStayModal\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/stays-list.hbs"
    }
  });

  _exports.default = _default;
});