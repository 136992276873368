define("crm/templates/components/supplier-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DtTLPW0y",
    "block": "{\"symbols\":[],\"statements\":[[6,\"section\"],[9,\"class\",\"page-body\"],[7],[0,\"\\n    \"],[1,[25,\"u-search-form\",null,[[\"search\",\"lockButton\",\"searchText\",\"oldString\",\"placeholder\"],[[25,\"action\",[[19,0,[]],\"searchSuppliers\"],null],[20,[\"lockButton\"]],[20,[\"searchText\"]],[20,[\"oldString\"]],[25,\"t\",[\"suppliers.supplier.title\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[20,[\"suppliers\",\"length\"]]],null,{\"statements\":[[0,\"        \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"displayDataChangedAction\"],[[20,[\"suppliers\"]],[20,[\"columns\"]],[25,\"action\",[[19,0,[]],\"rowSelected\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/supplier-select.hbs"
    }
  });

  _exports.default = _default;
});