define("crm/templates/components/documents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ITAvF1yw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"currentAccount\"]]],null,{\"statements\":[[0,\"  \"],[6,\"ul\"],[9,\"class\",\"list-group\"],[7],[0,\"\\n    \"],[6,\"li\"],[10,\"class\",[26,[\"list-group-item \",[18,\"currentAccountColor\"]]]],[7],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[1,[18,\"currentAccount\"],false],[0,\" \"],[1,[25,\"t\",[\"icons.currency\"],null],false],[8],[6,\"strong\"],[7],[1,[25,\"t\",[\"sales.balance\"],null],false],[8],[8],[0,\"\\n    \"],[6,\"li\"],[9,\"class\",\"list-group-item\"],[7],[4,\"link-to\",[\"organizations.organization.customers.customer.documents\"],null,{\"statements\":[[1,[25,\"t\",[\"howTo.title\"],null],false]],\"parameters\":[]},null],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"showPdf\",\"transitionToRoute\",\"updateQuery\",\"addReceipt\",\"addPayment\",\"disable\",\"enable\",\"handleOrder\",\"openModal\",\"annulDocument\",\"editDocumentModal\",\"sendEmail\"],[[20,[\"model\"]],[20,[\"columns\"]],\"showPdf\",\"transitionToRoute\",\"updateQuery\",\"addReceipt\",\"addPayment\",\"disable\",\"enable\",\"handleOrder\",\"openModal\",\"annulDocument\",\"editDocumentModal\",\"sendEmail\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.documents\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/documents-list.hbs"
    }
  });

  _exports.default = _default;
});