define("crm/templates/components/saft-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QK1tYEGk",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"table-actions\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"hidle text-center\"],[7],[0,\"\\n    \"],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-ellipsis-h disabled\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"actions text-center\"],[7],[0,\"\\n    \"],[2,\" details \"],[0,\"\\n    \"],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"actions.details\"],null]]]],[3,\"action\",[[19,0,[]],\"openDetails\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.detail\"],null]]]],[7],[8],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"canDoSaft\"]]],null,{\"statements\":[[0,\"      \"],[2,\" export \"],[0,\"\\n\"],[4,\"if\",[[25,\"eq\",[[20,[\"exporting\"]],false],null]],null,{\"statements\":[[0,\"        \"],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"actions.download\"],null]]]],[3,\"action\",[[19,0,[]],\"export\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.download\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"a\"],[9,\"class\",\"disabled\"],[10,\"title\",[26,[[25,\"t\",[\"actions.download\"],null]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-fw fa-\",[25,\"t\",[\"icons.download\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n      \"],[2,\" delete \"],[0,\"\\n      \"],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"actions.delete\"],null]]]],[3,\"action\",[[19,0,[]],\"delete\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.delete\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/saft-actions.hbs"
    }
  });

  _exports.default = _default;
});