define("crm/templates/organizations/organization/warehouses/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dT7YjPCw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"warehouses.title\"],null],[25,\"t\",[\"icons.warehouses\"],null]]],{\"statements\":[[4,\"if\",[[20,[\"canDoWarehouses\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"openModalCreateWarehouse\"]],[7],[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.warehouse\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"warehouses.warehouse.title\"],null],false],[8],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"exporting\"]]],null,{\"statements\":[[0,\"      \"],[6,\"li\"],[7],[6,\"a\"],[9,\"class\",\"disabled\"],[7],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-file-excel-o \"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"pages.inventory\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"li\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"export\"]],[7],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-file-excel-o \"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"pages.inventory\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[4,\"if\",[[20,[\"model\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openEditWarehouseModal\",\"disable\",\"enable\",\"openDetails\"],[[20,[\"model\"]],[20,[\"columns\"]],\"openEditWarehouseModal\",\"disable\",\"enable\",\"openDetails\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"u-alert-panel\",null,[[\"message\"],[[25,\"concat\",[[25,\"t\",[\"intl.noContent\"],null],[25,\"concat\",[\" \",[25,\"t\",[\"warehouses.title\"],null]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/warehouses/index.hbs"
    }
  });

  _exports.default = _default;
});