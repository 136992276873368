define("crm/templates/components/organization-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p1koKyUX",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ibox-title\"],[7],[0,\"\\n    \"],[6,\"h5\"],[7],[1,[25,\"t\",[[20,[\"title\"]]],null],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ibox-content text-center\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"h1 m-t-xs\"],[7],[0,\"\\n        \"],[6,\"a\"],[9,\"class\",\"btn btn-default\"],[7],[6,\"span\"],[9,\"class\",\"clickable\"],[3,\"action\",[[19,0,[]],\"openEdit\"]],[7],[1,[25,\"t\",[\"actions.edit\"],null],false],[8],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/organization-card.hbs"
    }
  });

  _exports.default = _default;
});