define("crm/components/internment/todo-components", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    todoPeriods: Ember.computed.alias("catalogs.todoPeriods"),
    shouldHideCalculator: true,
    subTasksIds: [],
    isTypeDrug: Ember.computed("model.taskType", function () {
      if (this.get("model.constructor.modelName") === "drug") {
        return true;
      }
    }),
    isTypeEvent: Ember.computed("model.taskType", function () {
      if (this.get("model.constructor.modelName") == "event") {
        return true;
      }
    }),
    isTypeSerum: Ember.computed("model.taskType", function () {
      if (this.get("model.constructor.modelName") === "serum") {
        return true;
      }
    }),
    periodAlias: Ember.computed("model.period", function () {
      var _this = this;

      if (Ember.isEmpty(this.get("model.period")) && this.get("model.started")) {
        return this.get("intl").t("periodics.periods.drugs.noRepeat");
      } else if (this.get("model.period")) {
        return this.get("todoPeriods").find(function (p) {
          return p.id == _this.get("model.period");
        }).name;
      }
    }),
    actions: {
      update: function update() {
        var _this2 = this;

        var self = this;

        if (this.get("endedAux") === "") {
          this.set("model.started", null);
        } else if (this.get("endedAux")) {
          this.set("model.started", (0, _moment.default)(this.get("startedAux")));
        }

        if (this.get("endedAux") === "") {
          this.set("model.ended", null);
        } else if (this.get("endedAux")) {
          this.set("model.ended", (0, _moment.default)(this.get("endedAux")));
        }

        if (this.get("volumeTotalInput")) {
          this.set("model.volumeTotal", this.get("volumeTotalInput"));
        }

        if (this.get("adjustmentInput")) {
          this.set("model.adjustment", this.get("adjustmentInput"));
        }

        if (this.get("amountInput")) {
          this.set("model.amount", this.get("amountInput"));
        }

        if (this.get("model.hasDirtyAttributes")) {
          this.get("model").save().then(function () {
            return self.sendAction("cancel", self.get("model.id"), self.get("model.taskType"));
          }).catch(function (result) {
            return _this2.get("es").errorReturned(result);
          });
        } else {
          this.sendAction("cancel", this.get("model.id"), this.get("model.taskType"));
        }
      },
      setPeriodSelectAnswer: function setPeriodSelectAnswer(value) {
        value = Number(value);
        this.set("endedAux", null);
        this.set("endedDate", null);
        this.set("endedHours", null);
        this.set("endedMinutes", null);

        if (isNaN(value)) {
          // When no repeat is selected since the value is null it returs the no repeat string which is not a number
          this.set("model.period", null);
        } else {
          this.set("model.period", value);

          if (value === 0) {
            var startedAux = this.get("startedAux");
            this.set("endedDate", startedAux.format("YYYY-MM-DD"));
            this.set("endedHours", (startedAux.hours() < 10 ? "0" : "") + startedAux.hours());
            this.set("endedMinutes", (startedAux.minutes() < 10 ? "0" : "") + startedAux.minutes());
          }
        }
      },
      setTaskTypeSelectAnswer: function setTaskTypeSelectAnswer(value) {
        this.set("model.taskType", value);
      },
      setUnitSelectAnswer: function setUnitSelectAnswer(value) {
        this.set("model.unit", Number(value));
      },
      setViaSelectAnswer: function setViaSelectAnswer(value) {
        this.set("model.via", Number(value));
      },
      setMonitoringSelectAnswer: function setMonitoringSelectAnswer(value) {
        this.set("model.name", value);
      },
      setDrugSelectAnswer: function setDrugSelectAnswer(value) {
        this.set("model.name", value);
      },
      setSerumSelectAnswer: function setSerumSelectAnswer(value) {
        this.set("model.name", value);
      },
      setAdjustmentSelectAnswer: function setAdjustmentSelectAnswer(value) {
        this.set("adjustment", this.get("adjustment") === value ? null : value);

        if (this.get("adjustment") !== null) {
          this.set("adjustmentInput", (this.get("model.internment.clinicAnimal.animal.maintenanceFluidRate") * this.get("adjustment")).toFixed(3));
        }
      }
    }
  });

  _exports.default = _default;
});