define("crm/templates/components/stay-ibox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YvPxcd2+",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ibox-content ibox-heading text-center\"],[7],[0,\"\\n    \"],[6,\"h3\"],[7],[1,[20,[\"model\",\"patient\",\"name\"]],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"hasStartAndEnd\"]]],null,{\"statements\":[[0,\"            \"],[6,\"div\"],[9,\"class\",\"col-sm-12 text-center\"],[7],[0,\"\\n                \"],[6,\"strong\"],[7],[1,[25,\"pu-chronos-date\",[[20,[\"model\",\"startDate\"]]],null],false],[0,\" - \"],[1,[25,\"pu-chronos-date\",[[20,[\"model\",\"endDate\"]]],null],false],[8],[0,\" \"],[6,\"small\"],[7],[0,\"(\"],[1,[25,\"pu-chronos-diff-days\",[[20,[\"model\",\"startDate\"]],[20,[\"model\",\"endDate\"]]],null],false],[0,\" \"],[1,[25,\"t\",[\"enums.chronos.days\"],null],false],[0,\")\"],[8],[0,\"\\n            \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/stay-ibox.hbs"
    }
  });

  _exports.default = _default;
});