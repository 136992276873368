define("crm/templates/organizations/organization/inventory/today", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N4xZTEWH",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"jumbotron text-center\"],[7],[0,\"\\n  \"],[6,\"h1\"],[7],[0,\"Janela removida\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Esta janela era um duplicado do armazém.\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Pode consultar o stock no respectivo armazém.\"],[8],[0,\"\\n  \"],[4,\"link-to\",[\"organizations.organization.warehouses\"],[[\"class\"],[\"btn btn-primary btn-lg module-procurement\"]],{\"statements\":[[0,\"Armazéns\"]],\"parameters\":[]},null],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/inventory/today.hbs"
    }
  });

  _exports.default = _default;
});