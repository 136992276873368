define("crm/templates/components/shifts-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TtiWKEnF",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openDetails\",\"openEdit\",\"hideComponentFooter\",\"pageSize\"],[[20,[\"model\"]],[20,[\"columns\"]],\"openDetails\",\"openEdit\",true,[20,[\"model\",\"length\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/shifts-list.hbs"
    }
  });

  _exports.default = _default;
});