define("crm/components/exams-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    newExam: {},
    showAll: true,
    filteredExams: Ember.computed("model.@each.requestDate", "model.@each.doneDate", "showAll", function () {
      if (this.get("model")) {
        return this.get("showAll") ? this.get("model") : this.get("model").filter(function (exam) {
          return (0, _moment.default)().isSame(exam.get("requestDate", "day")) || !exam.get("doneDate") || (0, _moment.default)().isSame(exam.get("doneDate", "day"));
        });
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("createdAfter", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      this.set("createdBefore", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
    },
    filtersObserver: Ember.observer("createdAfter", "createdBefore", function () {
      if (!Ember.isEmpty(this.get("createdAfter")) && !Ember.isEmpty(this.get("createdBefore")) && (0, _moment.default)(this.get("createdAfter")) > (0, _moment.default)("1970-01-01")) {
        this.sendAction("updateModel", this.get("createdAfter"), this.get("createdBefore"));
      }
    }),
    minCreatedAfter: Ember.computed("createdBefore", function () {
      if (!Ember.isEmpty(this.get("createdBefore"))) {
        return (0, _moment.default)(this.get("createdBefore")).subtract(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    maxCreatedBefore: Ember.computed("createdAfter", function () {
      if (!Ember.isEmpty(this.get("createdAfter"))) {
        return (0, _moment.default)(this.get("createdAfter")).add(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    actions: {
      setCheckBox: function setCheckBox(status) {
        if (status.checked) {
          status.set("checked", false);
        } else {
          status.set("checked", true);
        }
      },
      callModal: function callModal(arg) {
        this.get("callModal")(arg);
      },
      deleteExam: function deleteExam(exam) {
        this.get("filteredModels").removeObject(exam);
      },
      document: function document(model) {
        this.sendAction("callLabAnalysisExamReport", model);
      },
      reloadModel: function reloadModel() {
        this.sendAction("reloadModel");
      }
    },
    columns: Ember.computed("model.[]", function () {
      var listCollumns = [{
        "propertyName": "doneDate",
        "component": "umt-date",
        "title": this.get("intl").t("exams.doneDate"),
        "className": "column-date"
      }, {
        "propertyName": "name",
        "title": this.get("intl").t("exams.examTypes.title"),
        "component": "title-models-table-display",
        "className": "text-strong"
      }, {
        "component": "exam-actions",
        "className": "column-actions-4"
      }];

      if (!this.get("hideRequestDate")) {
        listCollumns.splice(2, 0, {
          "propertyName": "requestDate",
          "component": "umt-date",
          "title": this.get("intl").t("exams.requestDate"),
          "sortDirection": "asc",
          "sortPrecedence": 1,
          "className": "column-date"
        });
      }

      if (!this.get("hidePatient")) {
        listCollumns.splice(1, 0, {
          "propertyName": "patient.name",
          "title": this.get("intl").t("patients.patient.title"),
          "component": "title-models-table-display",
          "path": "organizations.organization.patients.patient",
          "routeProperty": "patient.id"
        });
      }

      return listCollumns;
    })
  });

  _exports.default = _default;
});