define("crm/routes/organizations/organization/suppliers-current-accounts", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    // Bearer
    intl: Ember.inject.service(),
    can: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var organization = this.modelFor("organizations.organization");

      if (!organization.get("featureNames").includes("procurement")) {
        return null;
      }

      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('create supplier')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    model: function model() {
      var organization = this.modelFor("organizations.organization");

      if (!organization.get("featureNames").includes("procurement")) {
        return null;
      }

      var organizationID = organization.get("id");
      var self = this;
      return Ember.$.get({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/purchaseInvoices/currentAccount" + "?organization=" + organizationID,
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
        }
      }).then(function (lines) {
        return lines.map(function (i) {
          return {
            id: i[0],
            name: i[1],
            vat: i[2],
            count: i[3]
          };
        });
      });
    },
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: this.get("intl").t("pages.currentAccounts")
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("organization", this.modelFor("organizations.organization"));
    }
  });

  _exports.default = _default;
});