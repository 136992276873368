define("crm/templates/components/file-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uXI5Yhfz",
    "block": "{\"symbols\":[],\"statements\":[[6,\"p\"],[9,\"class\",\"ellipsis m-b-xs\"],[7],[6,\"a\"],[9,\"target\",\"_blank\"],[10,\"href\",[20,[\"model\",\"url\"]],null],[7],[6,\"i\"],[9,\"class\",\"fa fa-external-link\"],[7],[8],[0,\" \"],[6,\"small\"],[7],[1,[20,[\"model\",\"name\"]],false],[8],[8],[8],[0,\"\\n\"],[6,\"p\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"isImage\"]]],null,{\"statements\":[[0,\"        \"],[6,\"img\"],[10,\"src\",[26,[[20,[\"model\",\"url\"]]]]],[9,\"class\",\"img-responsive\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"model\",\"isPdf\"]]],null,{\"statements\":[[0,\"            \"],[6,\"i\"],[9,\"class\",\"fa fa-5x fa-file-pdf-o disabled\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[6,\"i\"],[9,\"class\",\"fa fa-5x fa-file disabled\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[4,\"unless\",[[20,[\"hideDelete\"]]],null,{\"statements\":[[0,\"      \"],[6,\"a\"],[3,\"action\",[[19,0,[]],\"delete\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"text-muted fa fa-fw fa-\",[25,\"t\",[\"icons.delete\"],null]]]],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/file-display.hbs"
    }
  });

  _exports.default = _default;
});