define("crm/templates/organizations/organization/exams/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ud7L7E9S",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"exams.title\"],null],[25,\"t\",[\"icons.exam\"],null]]],{\"statements\":[[4,\"if\",[[20,[\"canDoExam\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"openCreate\",\"laboratory\"]],[7],[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.laboratory\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"laboratories.new.title\"],null],false],[8],[8],[0,\"\\n    \"],[6,\"li\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"openCreate\",\"exam\"]],[7],[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.exam\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"exams.new.title\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[0,\"  \"],[1,[25,\"exams-list\",null,[[\"model\",\"organization\",\"createdAfter\",\"createdBefore\",\"callModal\",\"callLabAnalysisExamReport\",\"updateModel\",\"reloadModel\"],[[20,[\"model\",\"exams\"]],[20,[\"model\"]],[20,[\"model\",\"createdAfter\"]],[20,[\"model\",\"createdBefore\"]],[25,\"action\",[[19,0,[]],\"callModal\"],null],\"callLabAnalysisExamReport\",\"updateModel\",\"reloadModel\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/exams/index.hbs"
    }
  });

  _exports.default = _default;
});