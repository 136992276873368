define("crm/templates/organizations/organization/purchase-invoices/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+0pD61BP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"file-upload\",null,[[\"entity\",\"serviceEndpoint\",\"formData\",\"data\",\"fileName\",\"uploaded\"],[[20,[\"model\"]],\"purchaseInvoices/upload\",[20,[\"model\"]],[20,[\"data\"]],[20,[\"fileName\"]],\"uploaded\"]],{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"form-group hidden\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"input-group\"],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"form-control\"],[7],[0,\"\\n        \"],[6,\"input\"],[9,\"name\",\"organization\"],[10,\"value\",[20,[\"model\",\"organization\",\"id\"]],null],[7],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/purchase-invoices/upload.hbs"
    }
  });

  _exports.default = _default;
});