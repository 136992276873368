define("crm/components/exam-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    canDelete: Ember.computed("currentUser.id", function () {
      return true;
      /*
       * Because for now 'record.creator' is null when is result of a list request
       * return (this.get("currentUser.id") === this.get("record.creator.id"));
       */
    }),
    canDoExam: Ember.computed("can", function () {
      //return this.get("can").can("do clinical"); TODOJD
      return true;
    }),
    actions: {
      callModal: function callModal(action) {
        this.sendAction("callModal", {
          entity: "exam",
          action: action,
          model: this.get("record")
        });
      },
      delete: function _delete(exam) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          exam.destroyRecord();
          this.sendAction("deleteExam", exam);
        }
      },
      document: function document() {
        this.sendAction('document', this.get("record"));
      }
    }
  });

  _exports.default = _default;
});