define("crm/routes/organizations/organization/waiting-rooms/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var organization = this.modelFor("organizations.organization");
      Ember.set(organization, "appointments", this.store.query("appointment", {
        organization: organization.get("id"),
        waitingRoom: true
      }));
      return organization;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var self = this;
      var aux = 30000;
      Ember.run.later(function () {
        return self.refreshAppointments(model, aux);
      }, aux);
    },
    actions: {
      createdConsultation: function createdConsultation(id) {
        this.transitionTo('organizations.organization.consultations.consultation', id);
      },
      checkOutDetails: function checkOutDetails(appointment) {
        this.transitionTo('organizations.organization.consultations.consultation.checkOut', appointment.get('id'));
      },
      openAppointmentDetails: function openAppointmentDetails(appointment) {
        this.send('openModalAppointmentDetails', appointment);
      },
      openCreateAppointment: function openCreateAppointment(date) {
        this.send('openModalCreateAppointment', date);
      }
    },
    refreshAppointments: function refreshAppointments(organization, lastTime) {
      var self = this;
      this.refresh();

      if (Ember.$(document)[0].URL.includes("waitingRoom")) {
        var aux = lastTime * 1.2;
        Ember.run.later(function () {
          return self.refreshAppointments(organization, aux);
        }, aux);
      }
    }
  });

  _exports.default = _default;
});