define("crm/templates/components/visits-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5BsuBSwX",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",\"1900-01-01\",[20,[\"dateEnd\"]],[20,[\"dateStart\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",[20,[\"dateStart\"]],\"2500-12-31\",[20,[\"dateEnd\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n\\n    \"],[6,\"hr\"],[7],[8],[0,\"\\n\\n    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openEditVisitModal\",\"openDetails\",\"disable\",\"enable\",\"delete\"],[[20,[\"filteredModels\"]],[20,[\"columns\"]],\"openEditVisitModal\",\"openDetails\",\"disable\",\"enable\",\"delete\"]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/visits-list.hbs"
    }
  });

  _exports.default = _default;
});