define("crm/services/modules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    crm: Ember.computed("intl", function () {
      return {
        iconName: this.get('intl').t("icons.crm"),
        name: this.get('intl').t("pages.crm"),
        class: "module-crm",
        title: this.get('intl').t("pages.crm"),
        menu: [{
          class: "module-crm",
          link: "organizations.organization.customers",
          iconName: this.get('intl').t("icons.customers"),
          name: this.get('intl').t("customers.title"),
          title: this.get('intl').t("customers.title")
        }, {
          class: "module-crm",
          link: "organizations.organization.patients",
          iconName: this.get('intl').t("icons.patients"),
          name: this.get('intl').t("patients.title"),
          title: this.get('intl').t("patients.title")
        }, {
          link: "organizations.organization.periodics",
          iconName: this.get('intl').t("icons.periodic"),
          name: this.get('intl').t("periodics.title"),
          class: "module-crm",
          title: this.get('intl').t("periodics.title")
        }, {
          class: "module-crm",
          link: "organizations.organization.campaigns",
          iconName: this.get('intl').t("icons.campaigns"),
          name: this.get('intl').t("campaigns.title"),
          title: this.get('intl').t("campaigns.title"),
          feature: "campaigns"
        }, {
          class: "module-crm",
          link: "organizations.organization.sms",
          iconName: this.get('intl').t("icons.sms"),
          name: this.get('intl').t("sms.sent.title"),
          title: this.get('intl').t("sms.sent.title"),
          feature: "campaigns"
        }]
      };
    }),
    calendar: Ember.computed("intl", function () {
      return {
        iconName: this.get('intl').t("icons.calendarMenu"),
        name: this.get('intl').t("pages.reception"),
        class: "module-calendar",
        title: this.get('intl').t("pages.reception"),
        menu: [{
          link: "organizations.organization.appointments.custom",
          iconName: this.get('intl').t("icons.appointments"),
          name: this.get('intl').t("calendar.title"),
          class: "module-calendar",
          title: this.get('intl').t("calendar.title")
        }, {
          link: "organizations.organization.schedule",
          iconName: this.get('intl').t("icons.appointments"),
          name: this.get('intl').t("pages.schedule"),
          class: "module-calendar",
          title: this.get('intl').t("pages.schedule")
        }, {
          link: "organizations.organization.waitingRooms",
          iconName: this.get('intl').t("icons.waitingRoom"),
          name: this.get('intl').t("waitingRooms.title"),
          class: "module-calendar",
          title: this.get('intl').t("waitingRooms.title"),
          skipFeature: "houseCalls"
        }, {
          link: "organizations.organization.tasks",
          iconName: this.get('intl').t("icons.task"),
          name: this.get('intl').t("tasks.title"),
          class: "module-calendar",
          title: this.get('intl').t("tasks.title")
        }]
      };
    }),
    clinical: Ember.computed("intl", function () {
      return {
        iconName: this.get('intl').t("icons.clinicalMenu"),
        name: this.get('intl').t("pages.clinical"),
        class: "module-clinical",
        title: this.get('intl').t("pages.clinical"),
        menu: [{
          link: "organizations.organization.consultations",
          iconName: this.get('intl').t("icons.visit"),
          name: this.get('intl').t("visits.title"),
          class: "module-clinical",
          title: this.get('intl').t("visits.title")
        }, {
          link: "organizations.organization.periodics",
          iconName: this.get('intl').t("icons.periodic"),
          name: this.get('intl').t("periodics.title"),
          class: "module-clinical",
          title: this.get('intl').t("periodics.title")
        }, {
          link: "organizations.organization.exams",
          iconName: this.get('intl').t("icons.exam"),
          name: this.get('intl').t("exams.title"),
          class: "module-clinical",
          title: this.get('intl').t("exams.title")
        }, {
          link: "organizations.organization.clinicalWaitingRooms",
          iconName: this.get('intl').t("icons.waitingRoom"),
          name: this.get('intl').t("waitingRooms.title"),
          class: "module-clinical",
          title: this.get('intl').t("waitingRooms.title"),
          skipFeature: "houseCalls"
        }, {
          link: "organizations.organization.templates",
          iconName: "file-word-o",
          name: this.get('intl').t("templates.title"),
          class: "module-clinical",
          title: this.get('intl').t("templates.title")
        }]
      };
    }),
    internments: Ember.computed("intl", function () {
      return {
        iconName: this.get('intl').t("icons.internments"),
        name: this.get('intl').t("pages.internments"),
        class: "module-internments",
        title: this.get('intl').t("pages.internments"),
        menu: [{
          link: "organizations.organization.internments.overview",
          iconName: this.get('intl').t("icons.internments"),
          name: this.get('intl').t("pages.overview"),
          title: this.get('intl').t("pages.overview"),
          class: "module-internments"
        }, {
          link: "organizations.organization.internments.index",
          iconName: this.get('intl').t("icons.internments"),
          name: this.get('intl').t("pages.internments"),
          title: this.get('intl').t("pages.internments"),
          class: "module-internments"
        }]
      };
    }),
    sales: Ember.computed("intl", function () {
      return {
        iconName: this.get('intl').t("icons.salesMenu"),
        name: this.get('intl').t("pages.sales"),
        class: "module-sales",
        title: this.get('intl').t("pages.sales"),
        menu: [{
          link: "organizations.organization.items",
          iconName: this.get('intl').t("icons.item"),
          name: this.get('intl').t("items.title"),
          class: "module-sales",
          title: this.get('intl').t("items.title"),
          free: true
        }, {
          link: "organizations.organization.pointOfSales",
          iconName: this.get('intl').t("icons.pointOfSales"),
          name: this.get('intl').t("pointOfSales.title"),
          class: "module-sales",
          title: this.get('intl').t("pointOfSales.title")
        }, {
          link: "organizations.organization.documents",
          iconName: this.get('intl').t("icons.documents"),
          name: this.get('intl').t("documents.title"),
          class: "module-sales",
          title: this.get('intl').t("documents.title")
        }, {
          link: "organizations.organization.financialAccounts",
          iconName: this.get('intl').t("icons.financialAccounts"),
          name: this.get('intl').t("financialAccounts.title"),
          class: "module-sales",
          title: this.get('intl').t("financialAccounts.title")
        }, {
          link: "organizations.organization.saft",
          iconName: this.get('intl').t("icons.saft"),
          name: this.get('intl').t("saft.title"),
          class: "module-sales",
          title: this.get('intl').t("saft.title")
        }, {
          link: "organizations.organization.charges",
          iconName: this.get('intl').t("icons.charge"),
          name: this.get('intl').t("charges.title"),
          class: "module-sales",
          title: this.get('intl').t("charges.title")
        }, {
          link: "organizations.organization.quotations",
          iconName: this.get('intl').t("icons.documents"),
          name: this.get('intl').t("quotations.title"),
          class: "module-sales",
          title: this.get('intl').t("quotations.title")
        }, {
          link: "organizations.organization.listings.sales.receivables",
          iconName: this.get('intl').t("icons.currentAccount"),
          name: this.get('intl').t("pages.currentAccounts"),
          class: "module-sales",
          title: this.get('intl').t("pages.currentAccounts")
        }, {
          link: "organizations.organization.shopping-carts",
          iconName: this.get('intl').t("icons.shoppingCart"),
          name: this.get('intl').t("shoppingCarts.title"),
          class: "module-sales",
          title: this.get('intl').t("shoppingCarts.title")
        }]
      };
    }),
    procurement: Ember.computed("intl", function () {
      return {
        iconName: this.get('intl').t("icons.procurement"),
        name: this.get('intl').t("procurement.title"),
        class: "module-procurement",
        title: this.get('intl').t("procurement.title"),
        menu: [{
          link: "organizations.organization.suppliers",
          iconName: this.get('intl').t("icons.suppliers"),
          name: this.get('intl').t("suppliers.title"),
          class: "module-procurement",
          title: this.get('intl').t("suppliers.title")
        }, {
          link: "organizations.organization.purchase-invoices",
          iconName: this.get('intl').t("icons.purchases"),
          name: this.get('intl').t("purchases.title"),
          class: "module-procurement",
          title: this.get('intl').t("pages.purchases")
        }, {
          link: "organizations.organization.purchase-orders",
          iconName: this.get('intl').t("icons.purchaseOrders"),
          name: this.get('intl').t("pages.purchaseOrders"),
          class: "module-procurement",
          title: this.get('intl').t("pages.purchaseOrders")
        }, {
          link: "organizations.organization.purchase-requests",
          iconName: this.get('intl').t("icons.purchaseRequests"),
          name: this.get('intl').t("pages.purchaseRequests"),
          class: "module-procurement",
          title: this.get('intl').t("pages.purchaseRequests")
        }, {
          link: "organizations.organization.warehouses",
          iconName: this.get('intl').t("icons.warehouses"),
          name: this.get('intl').t("warehouses.title"),
          class: "module-procurement",
          title: this.get('intl').t("warehouses.title")
        }, {
          link: "organizations.organization.inventory.shortage",
          iconName: this.get('intl').t("icons.transferStock"),
          name: this.get('intl').t("pages.shortage"),
          class: "module-procurement",
          title: this.get('intl').t("pages.shortage"),
          feature: "sales"
        }, {
          link: "organizations.organization.item-adjustments",
          iconName: this.get("intl").t("icons.transferStock"),
          name: this.get("intl").t("itemAdjustments.title"),
          class: "module-procurement",
          title: this.get("intl").t("itemAdjustments.title"),
          feature: "sales"
        }, {
          link: "organizations.organization.suppliers-current-accounts",
          iconName: this.get('intl').t("icons.currentAccount"),
          name: this.get('intl').t("pages.currentAccounts"),
          class: "module-procurement",
          title: this.get('intl').t("pages.currentAccounts")
        }, {
          link: "organizations.organization.procurement.documents",
          iconName: this.get('intl').t("icons.documents"),
          name: this.get('intl').t("documents.title"),
          class: "module-procurement",
          title: this.get('intl').t("documents.title")
        }]
      };
    }),
    referral: Ember.computed("intl", function () {
      return {
        iconName: this.get('intl').t("icons.referrals"),
        name: this.get('intl').t("referrals.title"),
        class: "module-referral",
        title: this.get('intl').t("referrals.title"),
        menu: [{
          link: "organizations.organization.liaisons",
          iconName: this.get('intl').t("icons.liaisons"),
          name: this.get('intl').t("liaisons.title"),
          class: "module-referral",
          title: this.get('intl').t("liaisons.title")
        }, {
          link: "organizations.organization.events",
          iconName: this.get('intl').t("icons.events"),
          name: this.get('intl').t("events.title"),
          class: "module-referral",
          title: this.get('intl').t("events.title")
        }]
      };
    }),
    hotel: Ember.computed("intl", function () {
      return {
        iconName: this.get('intl').t("icons.hotel"),
        name: this.get('intl').t("pages.hotel"),
        class: "module-hotel",
        title: this.get('intl').t("pages.hotel"),
        menu: [{
          link: "organizations.organization.hotel.boxes",
          iconName: this.get('intl').t("icons.boxes"),
          name: this.get('intl').t("pages.boxes"),
          class: "module-hotel",
          title: this.get('intl').t("pages.boxes")
        }, {
          link: "organizations.organization.hotel.stays",
          iconName: this.get('intl').t("icons.stays"),
          name: this.get('intl').t("pages.stays"),
          class: "module-hotel",
          title: this.get('intl').t("pages.stays")
        }]
      };
    }),
    stats: Ember.computed("intl", function () {
      return {
        link: "organizations.organization.stats",
        iconName: this.get('intl').t("icons.stats"),
        name: this.get('intl').t("pages.stats"),
        class: "module-stats",
        title: this.get('intl').t("pages.stats")
      };
    }),
    listings: Ember.computed("intl", function () {
      return {
        link: "organizations.organization.listings",
        iconName: this.get('intl').t("icons.listings"),
        name: this.get('intl').t("pages.listings"),
        class: "module-stats",
        title: this.get('intl').t("pages.listings"),
        free: true
      };
    }),
    group: Ember.computed("intl", function () {
      return {
        iconName: this.get('intl').t("icons.group"),
        name: this.get('intl').t("pages.group"),
        class: "module-group",
        title: this.get('intl').t("pages.group"),
        menu: [{
          link: "organizations.organization.group.customers",
          iconName: this.get('intl').t("icons.customers"),
          name: this.get('intl').t("pages.customers"),
          class: "module-crm",
          title: this.get('intl').t("pages.customers")
        }, {
          link: "organizations.organization.group.patients",
          iconName: this.get('intl').t("icons.patients"),
          name: this.get('intl').t("pages.patients"),
          class: "module-crm",
          title: this.get('intl').t("pages.patients")
        }, {
          link: "organizations.organization.group.calendar",
          iconName: this.get('intl').t("icons.calendar"),
          name: this.get('intl').t("pages.calendar"),
          class: "module-calendar",
          title: this.get('intl').t("pages.calendar")
        }, {
          link: "organizations.organization.group.waitingRoom",
          iconName: this.get('intl').t("icons.calendarMenu"),
          name: this.get('intl').t("pages.reception"),
          class: "module-calendar",
          title: this.get('intl').t("pages.reception")
        }, {
          link: "organizations.organization.group.consultations",
          iconName: this.get('intl').t("icons.consultations"),
          name: this.get('intl').t("pages.consultations"),
          class: "module-clinical",
          title: this.get('intl').t("pages.consultations")
        }, {
          link: "organizations.organization.group.hr",
          iconName: this.get('intl').t("icons.hr"),
          name: this.get('intl').t("pages.hr"),
          class: "module-sales",
          title: this.get('intl').t("pages.hr")
        }, {
          link: "organizations.organization.group.items",
          iconName: this.get('intl').t("icons.item"),
          name: this.get('intl').t("pages.items"),
          class: "module-sales",
          title: this.get('intl').t("pages.items")
        }, {
          link: "organizations.organization.group.documents",
          iconName: this.get('intl').t("icons.documents"),
          name: this.get('intl').t("documents.title"),
          class: "module-sales",
          title: this.get('intl').t("documents.title")
        }, {
          link: "organizations.organization.group.financialAccounts",
          iconName: this.get('intl').t("icons.financialAccounts"),
          name: this.get('intl').t("pages.financialAccounts"),
          class: "module-sales",
          title: this.get('intl').t("pages.financialAccounts")
        }, {
          link: "organizations.organization.managment",
          iconName: this.get('intl').t("icons.salesMenu"),
          name: this.get('intl').t("pages.sales"),
          class: "module-sales",
          title: this.get('intl').t("pages.sales")
        }, {
          link: "organizations.organization.group.salesTableDays",
          iconName: this.get('intl').t("icons.salesMenu"),
          name: this.get('intl').t("pages.sales"),
          class: "module-sales",
          title: this.get('intl').t("pages.sales")
        }, {
          link: "organizations.organization.group.sales",
          iconName: this.get('intl').t("icons.salesMenu"),
          name: this.get('intl').t("stats.vsLastYear"),
          class: "module-sales",
          title: this.get('intl').t("stats.vsLastYear")
        }, {
          link: "organizations.organization.transfer-stock",
          iconName: this.get("intl").t("icons.transferStock"),
          name: this.get("intl").t("actions.transferStock"),
          class: "module-sales",
          title: this.get("intl").t("actions.transferStock")
        }, {
          link: "organizations.organization.group.suppliers-current-accounts",
          iconName: this.get('intl').t("icons.suppliers"),
          name: this.get('intl').t("pages.suppliers"),
          class: "module-procurement",
          title: this.get('intl').t("pages.suppliers")
        }, {
          link: "organizations.organization.group.purchase-invoices",
          iconName: this.get('intl').t("icons.purchases"),
          name: this.get('intl').t("purchases.title"),
          class: "module-procurement",
          title: this.get('intl').t("pages.purchases")
        }, {
          link: "organizations.organization.group.purchase-orders",
          iconName: this.get('intl').t("icons.purchaseOrders"),
          name: this.get('intl').t("pages.purchaseOrders"),
          class: "module-procurement",
          title: this.get('intl').t("pages.purchaseOrders")
        }, {
          link: "organizations.organization.group.purchase-requests",
          iconName: this.get('intl').t("icons.purchaseRequests"),
          name: this.get('intl').t("pages.purchaseRequests"),
          class: "module-procurement",
          title: this.get('intl').t("pages.purchaseRequests")
        }]
      };
    }),
    workers: Ember.computed("intl", function () {
      return {
        iconName: this.get('intl').t("icons.worker"),
        name: this.get('intl').t("pages.hr"),
        class: "module-hr",
        title: this.get('intl').t("pages.hr"),
        menu: [{
          link: "organizations.organization.workers",
          iconName: this.get('intl').t("icons.workers"),
          name: this.get('intl').t("workers.title"),
          class: "module-hr",
          title: this.get('intl').t("workers.title"),
          free: true
        }, {
          link: "organizations.organization.invites",
          iconName: this.get('intl').t("icons.invites"),
          name: this.get('intl').t("pages.invites"),
          class: "module-hr",
          title: this.get('intl').t("pages.invites"),
          free: true
        }, {
          feature: "shifts",
          link: "organizations.organization.shifts",
          iconName: this.get('intl').t("icons.shifts"),
          name: this.get('intl').t("pages.shifts"),
          class: "module-hr",
          title: this.get('intl').t("pages.shifts")
        }, {
          feature: "shifts",
          link: "organizations.organization.absences",
          iconName: this.get('intl').t("icons.absences"),
          name: this.get('intl').t("pages.absences"),
          class: "module-hr",
          title: this.get('intl').t("pages.absences")
        }]
      };
    }),
    settings: Ember.computed("intl", function () {
      return {
        link: "organizations.organization.configurations",
        iconName: this.get('intl').t("icons.configs"),
        name: this.get('intl').t("configurations.title"),
        class: "module-system",
        title: this.get('intl').t("configurations.title"),
        free: true
      };
    })
  });

  _exports.default = _default;
});