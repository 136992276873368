define("crm/templates/components/box-ibox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BjnVfkxP",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"ibox-content ibox-heading text-center\"],[7],[0,\"\\n    \"],[6,\"h3\"],[7],[1,[20,[\"model\",\"name\"]],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/box-ibox.hbs"
    }
  });

  _exports.default = _default;
});