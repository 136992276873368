define("crm/templates/components/financial-account-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sbDzwuUP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"hideFooter\",\"class\"],[\"modal-financialAccount-list\",[25,\"t\",[\"financialAccounts.title\"],null],true,\"module-sales\"]],{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"editFinancialAccount\"],[[20,[\"model\"]],[20,[\"columns\"]],\"editFinancialAccount\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/financial-account-list.hbs"
    }
  });

  _exports.default = _default;
});