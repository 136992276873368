define("crm/templates/components/umt-number-variation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZBkbmzra",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"text-right\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"valueDiffPercent\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"u-num-round\",[[20,[\"valueDiffPercent\"]],0],null],false],[0,\" % \"],[6,\"i\"],[10,\"class\",[26,[[18,\"textColor\"],\" fa fa-fw fa-\",[18,\"arrow\"]]]],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"     \\n\"]],\"parameters\":[]}],[8],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"u-ibox-tip\"],[7],[0,\"\\n  \"],[1,[25,\"dashboard-box\",null,[[\"model\",\"description\",\"oldValue\",\"title\",\"text\"],[[20,[\"value\"]],[25,\"concat\",[[25,\"u-num-round\",[[20,[\"valueOld\"]],2],null],[25,\"concat\",[\" \",[25,\"t\",[\"icons.currency\"],null]],null]],null],[20,[\"valueOld\"]],[20,[\"record\",\"name\"]],[20,[\"color\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/umt-number-variation.hbs"
    }
  });

  _exports.default = _default;
});