define("crm/templates/organizations/organization/plans", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hkp5tvGN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"plans.entity\"],null],[25,\"t\",[\"u-icons.plans\"],null]]],{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[4,\"link-to\",[\"organizations.organization.plans.new\"],null,{\"statements\":[[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"u-icons.plan\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"plans.plan.entity\"],null],false]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-4 col-lg-3 col-xl-2\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n            \"],[1,[25,\"u-models-table\",null,[[\"data\",\"columns\",\"singlePage\"],[[20,[\"model\"]],[20,[\"columns\"]],true]]],false],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-8 col-lg-9 col-xl-10\"],[7],[0,\"\\n        \"],[1,[18,\"outlet\"],false],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/plans.hbs"
    }
  });

  _exports.default = _default;
});