define("crm/templates/components/worker-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bPAftlgm",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[10,\"title\",[20,[\"record\",\"worker1\",\"name\"]],null],[7],[1,[25,\"u-name-initials\",[[20,[\"record\",\"worker1\",\"name\"]]],null],false],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/worker-display.hbs"
    }
  });

  _exports.default = _default;
});