define("crm/templates/organizations/organization/listings/procurement/items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iyaU+Led",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"items.title\"],null],[25,\"t\",[\"icons.item\"],null]]]],false],[0,\"\\n\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"text-right\"],[7],[0,\"Total: \"],[1,[25,\"price-display\",null,[[\"price\"],[[20,[\"total\"]]]]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"scroll-x\"],[7],[0,\"\\n      \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"singlePage\"],[[20,[\"filteredData\"]],[20,[\"columns\"]],true]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.items\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/listings/procurement/items.hbs"
    }
  });

  _exports.default = _default;
});