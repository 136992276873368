define("crm/templates/organizations/organization/invoices/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uq1tHfhM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"pages.invoices\"],null],[25,\"t\",[\"icons.invoice\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"openEditPatientModal\",\"openDetails\",\"openCreateAppointmentModal\",\"disable\",\"enable\"],[[20,[\"model\"]],[20,[\"columns\"]],\"openEditPatientModal\",\"openDetails\",\"openCreateAppointmentModal\",\"disable\",\"enable\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/invoices/index.hbs"
    }
  });

  _exports.default = _default;
});