define("crm/templates/components/periodics-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L2nsKAZS",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"model\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"singlePage\",\"showGlobalFilter\",\"showComponentFooter\",\"doNextPeriodic\",\"callModal\",\"gotoAppointmentNew\"],[[20,[\"model\"]],[20,[\"columns\"]],[20,[\"singlePage\"]],[25,\"gt\",[[20,[\"model\",\"length\"]],10],null],[25,\"and\",[[25,\"gt\",[[20,[\"model\",\"length\"]],10],null],[25,\"and\",[[25,\"not\",[[20,[\"hideComponentFooter\"]]],null],[25,\"not\",[[20,[\"singlePage\"]]],null]],null]],null],\"doNextPeriodic\",\"callModal\",\"gotoAppointmentNew\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.periodic\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/periodics-list.hbs"
    }
  });

  _exports.default = _default;
});