define("crm/templates/organizations/organization/customers/customer/documents/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UHB8HLzx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"class\",\"title\",\"titleIcon\"],[\"module-crm\",[25,\"t\",[\"pages.documents\"],null],[25,\"t\",[\"icons.documents\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ibox-content card-slim\"],[7],[0,\"\\n  \"],[6,\"h2\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"avatarUrl\"]]],null,{\"statements\":[[0,\"      \"],[6,\"img\"],[9,\"alt\",\"image\"],[9,\"class\",\"img-circle iamge-responsive\"],[10,\"src\",[26,[[20,[\"model\",\"avatarUrl\"]]]]],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-fw fa-\",[25,\"t\",[\"icons.customer\"],null]]]],[7],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[1,[20,[\"model\",\"name\"]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"ibox-content m-t-lg\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"openItems\",\"isPending\"]]],null,{\"statements\":[[0,\"    \"],[1,[18,\"loading-data\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"filteredModels\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/customers/customer/documents/index.hbs"
    }
  });

  _exports.default = _default;
});