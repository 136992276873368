define("crm/templates/organizations/organization/transfer-stock/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UGUjGSwV",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"class\",\"title\",\"titleIcon\"],[\"module-procurement\",[25,\"t\",[\"pages.transferStock\"],null],[25,\"t\",[\"icons.transferStock\"],null]]],{\"statements\":[[4,\"if\",[[20,[\"canDoGroupTransferStock\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[4,\"link-to\",[\"organizations.organization.transfer-stock.new\"],[[\"class\"],[\"module-procurement\"]],{\"statements\":[[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.transferStock\"],null]]]],[7],[8],[0,\" \"],[1,[25,\"t\",[\"transfersStock.new.title\"],null],false]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"ibox-content m-b-lg\"],[7],[0,\"\\n    \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"actions.filters\"],null],false],[8],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",\"2000-01-01\",[20,[\"startedBefore\"]],[20,[\"startedAfter\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"type\",\"min\",\"max\",\"value\",\"required\",\"class\"],[\"date\",[20,[\"startedAfter\"]],\"2100-12-31\",[20,[\"startedBefore\"]],true,\"pet-date-picker\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\\n\"],[4,\"ibox-panel\",null,[[\"class\"],[\"module-procurement\"]],{\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\",\"callModal\",\"showPdf\"],[[20,[\"model\"]],[20,[\"columns\"]],[25,\"action\",[[19,0,[]],\"callModal\"],null],\"showPdf\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.transferStock\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/transfer-stock/index.hbs"
    }
  });

  _exports.default = _default;
});