define("crm/routes/questionnaire-reply", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    catalogs: Ember.inject.service(),
    session: Ember.inject.service(),
    breadCrumb: null,
    beforeModel: function beforeModel(transition) {
      if (this.get("session.isAuthenticated")) {
        this.get("session").invalidate();
        this.transitionTo("questionnaire-reply", transition.params.questionnaire_code);
      }
    },
    model: function model(params) {
      return this.get("store").query("questionnaire-invite", {
        "code": params.questionnaire_code
      }).then(function (i) {
        if (i && i.get("firstObject")) {
          return {
            invite: i.get("firstObject")
          };
        } else {
          return null;
        }
      });
    }
  });

  _exports.default = _default;
});