define("crm/templates/components/show-task-priority", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gxMX8Eij",
    "block": "{\"symbols\":[],\"statements\":[[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[25,\"if\",[[20,[\"priority\"]],[25,\"t\",[\"icons.flag\"],null]],null],\" \",[20,[\"priority\",\"color\"]]]]],[7],[8],[0,\" \"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-task-priority.hbs"
    }
  });

  _exports.default = _default;
});