define("crm/templates/components/note-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kMaByf66",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"class\",\"title\",\"titleIcon\",\"hideFooter\",\"modalSize\"],[\"modal-note-details\",\"module-crm\",[25,\"t\",[\"notes.value\"],null],[25,\"t\",[\"icons.notes\"],null],true,\"lg\"]],{\"statements\":[[0,\"  \"],[1,[25,\"note-ibox\",null,[[\"model\"],[[20,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/note-details.hbs"
    }
  });

  _exports.default = _default;
});