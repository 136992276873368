define("crm/templates/components/specie-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sk7eh6tR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"class\",\"hideFooter\"],[\"modal-specie-create\",[25,\"t\",[\"configurations.species.new\"],null],\"module-crm\",true]],{\"statements\":[[4,\"custom-form\",null,[[\"fieldTypes\",\"save\",\"actionText\",\"reset\",\"disabledAction\",\"canceled\"],[[20,[\"fieldTypes\"]],\"create\",\"crud.create\",\"reset\",[20,[\"disabledAction\"]],\"canceled\"]],{\"statements\":[[0,\"        \"],[6,\"fieldset\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n                \"],[2,\" Name \"],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n                    \"],[6,\"label\"],[9,\"class\",\"col-lg-4 control-label\"],[7],[1,[25,\"t\",[\"configurations.species.name\"],null],false],[0,\" \"],[6,\"span\"],[9,\"class\",\"text-danger\"],[10,\"title\",[25,\"t\",[\"validations.fieldRequired\"],null],null],[7],[0,\" *\"],[8],[8],[0,\"\\n                    \"],[6,\"div\"],[9,\"class\",\"col-lg-8\"],[7],[0,\"\\n                        \"],[1,[25,\"input\",null,[[\"type\",\"required\",\"value\",\"class\",\"autofocus\",\"placeholder\"],[\"text\",true,[20,[\"newSpecie\",\"name\"]],\"form-control\",true,[25,\"t\",[\"configurations.species.name\"],null]]]],false],[0,\"\\n                    \"],[8],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/specie-new.hbs"
    }
  });

  _exports.default = _default;
});