define("crm/templates/components/procurement/show-purchase-request-name-quantity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vZZLZY2h",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"organizations.organization.purchase-requests.purchase-request\",[20,[\"record\",\"purchaseRequestLine\",\"purchaseRequest\",\"id\"]]],[[\"class\"],[\"module-procurement\"]],{\"statements\":[[0,\"    \"],[1,[20,[\"record\",\"purchaseRequestLine\",\"purchaseRequest\",\"name\"]],false],[4,\"if\",[[20,[\"record\",\"purchaseRequestLine\",\"quantity\"]]],null,{\"statements\":[[0,\" (\"],[1,[20,[\"record\",\"purchaseRequestLine\",\"quantity\"]],false],[0,\")\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/procurement/show-purchase-request-name-quantity.hbs"
    }
  });

  _exports.default = _default;
});