define("crm/templates/organizations/organization/campaigns/automatic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GLZMjk8b",
    "block": "{\"symbols\":[\"campaign\",\"campaignName\",\"data\",\"contact\",\"kk\",\"vv\"],\"statements\":[[1,[18,\"u-second-nav\"],false],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"model\"]]],null,{\"statements\":[[4,\"if\",[[19,1,[]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2\"],[7],[0,\"\\n\"],[4,\"each\",[[25,\"-each-in\",[[19,1,[]]],null]],null,{\"statements\":[[4,\"ibox-panel\",null,[[\"title\"],[[19,2,[]]]],{\"statements\":[[4,\"if\",[[19,3,[\"contactList\"]]],null,{\"statements\":[[4,\"each\",[[19,3,[\"contactList\"]]],null,{\"statements\":[[4,\"each\",[[25,\"-each-in\",[[19,4,[]]],null]],null,{\"statements\":[[0,\"                  \"],[6,\"p\"],[7],[6,\"b\"],[7],[1,[19,5,[]],false],[8],[0,\": \"],[1,[19,6,[]],false],[8],[0,\"\\n\"]],\"parameters\":[5,6]},null],[0,\"                \"],[6,\"hr\"],[7],[8],[0,\"\\n\"]],\"parameters\":[4]},null],[0,\"              \"],[6,\"div\"],[9,\"class\",\"well\"],[7],[1,[19,3,[\"replacedText\"]],false],[8],[0,\"\\n              \"],[6,\"div\"],[9,\"class\",\"text-right\"],[7],[1,[19,3,[\"sendDateStr\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"              \"],[6,\"div\"],[9,\"class\",\"alert alert-danger\"],[7],[1,[19,3,[]],false],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[2,3]},null],[0,\"      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/campaigns/automatic.hbs"
    }
  });

  _exports.default = _default;
});