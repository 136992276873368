define("crm/templates/organizations/organization/absences/approval/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nOAE0cGf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"class\",\"title\",\"titleIcon\"],[\"module-hr\",[25,\"t\",[\"pages.approval\"],null],[25,\"t\",[\"icons.check\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n    \"],[1,[25,\"approvals-pending-list\",null,[[\"model\",\"openModal\",\"reloadModel\"],[[20,[\"model\"]],\"openModal\",\"reloadModel\"]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/absences/approval/index.hbs"
    }
  });

  _exports.default = _default;
});