define("crm/templates/components/internment/discharge-text-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EWTAaGXJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"title\",\"class\",\"closed\",\"reset\",\"modalSize\",\"hideFooter\"],[\"modal-internment-discharge-text\",[25,\"t\",[\"internments.internment.dischargeText\"],null],\"module-internments\",[20,[\"closed\"]],\"reset\",\"sm\",true]],{\"statements\":[[4,\"custom-form\",null,[[\"save\",\"actionText\",\"canceled\",\"disabledAction\"],[\"save\",\"crud.save\",\"cancel\",[20,[\"disableButtons\"]]]],{\"statements\":[[0,\"        \"],[6,\"fieldset\"],[7],[0,\"\\n            \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n                \"],[6,\"div\"],[7],[0,\"\\n                    \"],[1,[25,\"textarea\",null,[[\"type\",\"maxlength\",\"value\",\"class\"],[\"text\",\"510\",[20,[\"model\",\"dischargeText\"]],\"form-control\"]]],false],[0,\"\\n                    \"],[6,\"p\"],[9,\"class\",\"help-block\"],[7],[1,[25,\"characters-number\",null,[[\"max\",\"text\"],[510,[20,[\"model\",\"dischargeText\"]]]]],false],[8],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/internment/discharge-text-modal.hbs"
    }
  });

  _exports.default = _default;
});