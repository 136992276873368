define("crm/templates/components/quotations-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t+B2wSeg",
    "block": "{\"symbols\":[\"quotation\"],\"statements\":[[4,\"if\",[[20,[\"listQuotations\"]]],null,{\"statements\":[[4,\"each\",[[20,[\"listQuotations\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"col-lg-6\"],[7],[0,\"\\n            \"],[6,\"a\"],[10,\"class\",[26,[\"label label-\",[25,\"if\",[[25,\"eq\",[[19,1,[\"id\"]],[20,[\"model\",\"order\",\"quotation\",\"id\"]]],null],\"primary\",\"default\"],null]]]],[3,\"action\",[[19,0,[]],\"selectedQuotation\",[19,1,[]]]],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n            \"],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"actions.viewItem\"],null]]]],[3,\"action\",[[19,0,[]],\"showPdf\",[19,1,[]]]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.detail\"],null]]]],[7],[8],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"span\"],[7],[1,[25,\"t\",[\"alerts.missingQuotation\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/quotations-list.hbs"
    }
  });

  _exports.default = _default;
});