define("crm/services/pdf", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.alias("sessionAccount.currentUser"),
    getProperty: function getProperty(property, replacement) {
      if (property) {
        return property;
      } else if (replacement) {
        return replacement;
      } else {
        return "";
      }
    },
    getPdf: function getPdf(name, consultation, organization, fields, patient) {
      switch (name) {
        case "dischargeLetter":
          return this.dischargeLetter(name, consultation, organization, fields);

        case "responsabilityTerm":
          return this.responsabilityTerm(name, consultation, organization, fields, patient);

        case "generalAnesthesia":
          return this.generalAnesthesia(name, consultation, organization, fields);

        case "bathCutRequest":
          return this.bathCutRequest(name, consultation, organization, fields);

        case "bathCutReport":
          return this.bathCutReport(name, consultation, organization, fields);

        case "ecoReport":
          return this.ecoReport(name, consultation, organization, fields);

        case "checkUpSeniorDog":
          return this.checkUpSeniorDog(name, consultation, organization, fields);

        case "checkUpSeniorCat":
          return this.checkUpSeniorCat(name, consultation, organization, fields);

        case "electrocardiogramDog":
          return this.electrocardiogramDog(name, consultation, organization, fields);

        case "electrocardiogramCat":
          return this.electrocardiogramCat(name, consultation, organization, fields);

        case "surgeryReport":
          return this.surgeryReport(name, consultation, organization, fields);

        case "labAnalysisReport":
          return this.labAnalysisReport(name, consultation, organization, fields);

        case "labAnalysisExamReport":
          return this.labAnalysisExamReport(name, consultation, organization, fields);

        case "surgeryDischarge":
          return this.surgeryDischarge(name, consultation, organization, fields);

        case "internmentDischarge":
          return this.internmentDischarge(name, consultation, organization, fields);
      }
    },
    breakLines: function breakLines(words, fontSize) {
      var result;

      switch (fontSize) {
        case 11:
          result = this.breakLinesAux(90, words, []);
          break;

        case 12:
          result = this.breakLinesAux(83, words, []);
          break;

        case 14:
          result = this.breakLinesAux(50, words, []);
          break;

        case 19:
          result = this.breakLinesAux(50, words, []);
          break;
      }

      return result;
    },
    breakLinesAux: function breakLinesAux(size, words, result) {
      if (words.length === 1) {
        result.push(words[0]);
        return result;
      }

      var phrase = words[0];
      var i = 1;

      for (; i < words.length; i++) {
        if (phrase.length + words[i].length + 1 < size) {
          phrase += " " + words[i];
        } else {
          break;
        }
      }

      result.push(phrase);

      if (i === words.length) {
        return result;
      } else {
        return this.breakLinesAux(size, words.slice(i), result);
      }
    },
    textDelimiter: function textDelimiter(text, lineBreak) {
      var output = "";

      for (var i = 0; i < text.length; i++) {
        if (i > 0 && i % lineBreak === 0) {
          output += "\n";
        }

        output += text.charAt(i);
      }

      return output;
    },
    addContentToPDFArray: function addContentToPDFArray(contentArr, dataType, marginLeft, marginTop, data, lineBreak, rotation) {
      if (!String(data).includes("undefined") && dataType == "text") {
        var objectToAdd = _defineProperty({}, dataType, [marginLeft, marginTop, this.textDelimiter(data, lineBreak)]);

        contentArr.push(objectToAdd);

        if (parseInt(rotation)) {
          objectToAdd[dataType].push(null);
          objectToAdd[dataType].push(90);
        }
      }
    },
    electrocardiogramDog: function electrocardiogramDog(name, consultation, organization, fields) {
      var height = 12;
      var self = this;
      var sizePatient;
      var sizeCustomer;

      if (consultation.get("patient.name").length > 30) {
        sizePatient = 85;
      } else {
        sizePatient = 80;
      }

      if (consultation.get("owner.firstAndLastName").length > 30) {
        sizeCustomer = 115;
      } else {
        sizeCustomer = 110;
      }

      var cont = [{
        setFontSize: 11
      }];
      this.addContentToPDFArray(cont, "text", 20, height, "Data: " + (0, _moment.default)().format("YYYY-MM-DD HH:mm"));
      height += 12;
      cont.push({
        setFontSize: 19
      });
      this.addContentToPDFArray(cont, "text", 20, height, organization.get("name"));
      height += 6;
      cont.push({
        setFontSize: 12
      });
      this.addContentToPDFArray(cont, "text", 20, height, organization.get("street"));
      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, organization.get("town") + " " + organization.get("zip"));
      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, "Telefone: " + organization.get("phone"));
      height += 10;
      cont.push({
        setFontSize: 19
      });
      this.addContentToPDFArray(cont, "text", 65, height, "ELECTROCARDIOGRAMA");
      cont.push({
        setFontSize: 11
      });
      height = 70;
      this.addContentToPDFArray(cont, "text", 20, height, "Dados da Visita");
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, "Tipo: " + consultation.get("appointment.display"));
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, (0, _moment.default)().format("L"));
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, "Prescritor: " + consultation.get("worker1.name"));
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, "Motivo: " + consultation.get("appointment.observation"), 30);
      height = 70;
      this.addContentToPDFArray(cont, "text", 120, height, "Dados do paciente");
      height += 5;
      this.addContentToPDFArray(cont, "text", 120, height, "Nome: " + consultation.get("patient.name"), 30);
      height += 5;
      this.addContentToPDFArray(cont, "text", 120, sizePatient, "Espécie: " + consultation.get("patient.species.name"));
      height = 100;
      this.addContentToPDFArray(cont, "text", 120, height, "Tutor(a)");
      height += 5;
      this.addContentToPDFArray(cont, "text", 120, height, "Nome: " + consultation.get("owner.firstAndLastName"), 30);
      this.addContentToPDFArray(cont, "text", 120, sizeCustomer, "Contacto: " + consultation.get("owner.lastContact.contact"), 30);
      height += 10;
      cont.push({
        setFontSize: 11
      });
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Parâmetro");
      this.addContentToPDFArray(cont, "text", 70, height, "Paciente");
      this.addContentToPDFArray(cont, "text", 120, height, "Referência");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Frequência cachorro");

      if (fields[0].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[0].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "<= 220");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Frequência miniatura");

      if (fields[1].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[1].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "<= 180");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Frequência Cardíaca");

      if (fields[2].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[2].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "70-160");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Frequência gigante");

      if (fields[3].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[3].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "60-140");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Duração de P (s)");

      if (fields[4].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[4].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "<= 0,04");
      height += 3;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Amplitude de P (mv)");

      if (fields[5].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[5].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "<= 0,2");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Intervalo P-R (s)");

      if (fields[6].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[6].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "0,06 – 0,13");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Duração de QRS (s)");

      if (fields[7].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[7].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "<= 0,05");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Amplitude de R (mv)");

      if (fields[8].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[8].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "<= 2,5");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Segmento S-T (mv)");

      if (fields[9].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[9].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "Depr. <= 0,2");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Amplitude de T (mv)");

      if (fields[10].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[10].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "Elev. <= 0,15");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Intervalo Q-T (s)");

      if (fields[11].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[11].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "< ¼ R");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Eixo elétrico cardíaco (º)");

      if (fields[12].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[12].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "0,15 – 0,25");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, "Diagnóstico", 40);
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, "Electrocardiográfico: ", 40);

      if (fields[13].value) {
        this.breakLines(fields[13].value.split(" "), 12).forEach(function (l) {
          self.addContentToPDFArray(cont, "text", 55, height, l, 82);
          height += 5;
        });
      }

      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, "Comentários: ", 30);

      if (fields[14].value) {
        this.breakLines(fields[14].value.split(" "), 12).forEach(function (l) {
          self.addContentToPDFArray(cont, "text", 45, height, l, 82);
          height += 5;
        });
      }

      height += 6;
      this.addContentToPDFArray(cont, "text", 60, height, "___________________________________", 67);
      height += 10;
      this.addContentToPDFArray(cont, "text", 90, height, consultation.get("worker1.name") + "," + this.getProperty(consultation.get("worker1.professionalLetterNumber"), ""), 67);
      cont.push({
        setFontSize: 9
      });
      this.addContentToPDFArray(cont, "text", 10, 260, "Processado por computador - Pet Universal, Data: " + (0, _moment.default)().format("L") + " às " + (0, _moment.default)().format("LT"), null, 90);
      return cont;
    },
    electrocardiogramCat: function electrocardiogramCat(name, consultation, organization, fields) {
      var height = 12;
      var self = this;
      var sizePatient;
      var sizeCustomer;

      if (consultation.get("patient.name").length > 30) {
        sizePatient = 85;
      } else {
        sizePatient = 80;
      }

      if (consultation.get("owner.firstAndLastName").length > 30) {
        sizeCustomer = 115;
      } else {
        sizeCustomer = 110;
      }

      var cont = [{
        setFontSize: 11
      }];
      this.addContentToPDFArray(cont, "text", 20, height, "Data: " + (0, _moment.default)().format("YYYY-MM-DD HH:mm"));
      height += 12;
      cont.push({
        setFontSize: 19
      });
      this.addContentToPDFArray(cont, "text", 20, height, organization.get("name"));
      height += 6;
      cont.push({
        setFontSize: 12
      });
      this.addContentToPDFArray(cont, "text", 20, height, organization.get("street"));
      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, organization.get("town") + " " + organization.get("zip"));
      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, "Telefone: " + organization.get("phone"));
      height += 10;
      cont.push({
        setFontSize: 19
      });
      this.addContentToPDFArray(cont, "text", 65, height, "ELECTROCARDIOGRAMA");
      cont.push({
        setFontSize: 11
      });
      height = 70;
      this.addContentToPDFArray(cont, "text", 20, height, "Dados da Visita");
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, "Tipo: " + consultation.get("appointment.display"));
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, (0, _moment.default)().format("L"));
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, "Prescritor: " + consultation.get("worker1.name"));
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, "Motivo: " + consultation.get("appointment.observation"), 30);
      height = 70;
      this.addContentToPDFArray(cont, "text", 120, height, "Dados do paciente");
      height += 5;
      this.addContentToPDFArray(cont, "text", 120, height, "Nome: " + consultation.get("patient.name"), 30);
      height += 5;
      this.addContentToPDFArray(cont, "text", 120, sizePatient, "Espécie: " + consultation.get("patient.species.name"));
      height = 100;
      this.addContentToPDFArray(cont, "text", 120, height, "Tutor(a)");
      height += 5;
      this.addContentToPDFArray(cont, "text", 120, height, "Nome: " + consultation.get("owner.firstAndLastName"), 30);
      this.addContentToPDFArray(cont, "text", 120, sizeCustomer, "Contacto: " + consultation.get("owner.lastContact.contact"), 30);
      height += 10;
      cont.push({
        setFontSize: 11
      });
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      height += 2;
      this.addContentToPDFArray(cont, "text", 20, height, "Parâmetro");
      this.addContentToPDFArray(cont, "text", 70, height, "Paciente");
      this.addContentToPDFArray(cont, "text", 120, height, "Referência");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Frequência Cardíaca");

      if (fields[0].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[0].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "160-240bpm");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Duração de P");

      if (fields[1].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[1].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "<= 0,04s");
      height += 3;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Amplitude de P");

      if (fields[2].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[2].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "<= 0,2mv");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Intervalo P-R");

      if (fields[3].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[3].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "0,05 – 0,09s");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Duração de QRS");

      if (fields[4].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[4].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "<= 0,04s");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Amplitude de R");

      if (fields[5].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[5].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "<= 0,9mv");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Segmento S-T");

      if (fields[6].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[6].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "Depr. <= 0,2mv  Elev. <= 0,15mv");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Amplitude de T");

      if (fields[7].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[7].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "< 0,3mv");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Intervalo Q-T");

      if (fields[8].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[8].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "0,12 – 0,18s");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Eixo elétrico");

      if (fields[9].value) {
        this.addContentToPDFArray(cont, "text", 70, height, fields[9].value);
      }

      this.addContentToPDFArray(cont, "text", 120, height, "0º-160º");
      height += 2;
      this.breakLines("________________________________________________________________________".split(" "), 12).forEach(function (l) {
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
        height += 5;
      });
      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, "Diagnóstico", 40);
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, "Electrocardiográfico: ", 40);

      if (fields[10].value) {
        this.breakLines(fields[10].value.split(" "), 12).forEach(function (l) {
          self.addContentToPDFArray(cont, "text", 55, height, l, 82);
          height += 5;
        });
      }

      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, "Comentários: ", 40);

      if (fields[11].value) {
        this.breakLines(fields[11].value.split(" "), 12).forEach(function (l) {
          self.addContentToPDFArray(cont, "text", 45, height, l, 82);
          height += 5;
        });
      }

      height += 6;
      this.addContentToPDFArray(cont, "text", 60, height, "___________________________________", 67);
      height += 10;
      this.addContentToPDFArray(cont, "text", 90, height, consultation.get("worker1.name") + "," + this.getProperty(consultation.get("worker1.professionalLetterNumber"), ""), 67);
      cont.push({
        setFontSize: 9
      });
      this.addContentToPDFArray(cont, "text", 10, 260, "Processado por computador - Pet Universal, Data: " + (0, _moment.default)().format("L") + " às " + (0, _moment.default)().format("LT"), null, 90);
      return cont;
    },
    labAnalysisReport: function labAnalysisReport(name, consultation, organization, fields) {
      var height = 12;
      var self = this;
      var sizePatient;
      var sizeCustomer;

      if (consultation.get("patient.name").length > 30) {
        sizePatient = 85;
      } else {
        sizePatient = 80;
      }

      if (consultation.get("owner.firstAndLastName").length > 30) {
        sizeCustomer = 115;
      } else {
        sizeCustomer = 110;
      }

      var cont = [{
        setFontSize: 11
      }];
      this.addContentToPDFArray(cont, "text", 20, height, "Data: " + (0, _moment.default)().format("YYYY-MM-DD HH:mm"));
      height += 12;
      cont.push({
        setFontSize: 19
      });
      this.addContentToPDFArray(cont, "text", 20, height, organization.get("name"));
      height += 6;
      cont.push({
        setFontSize: 12
      });
      this.addContentToPDFArray(cont, "text", 20, height, organization.get("street"));
      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, organization.get("town") + " " + organization.get("zip"));
      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, "Telefone: " + organization.get("phone"));
      height += 10;
      cont.push({
        setFontSize: 19
      });
      this.addContentToPDFArray(cont, "text", 40, height, "RELATÓRIO DE ANÁLISES LABORATORIAIS");
      cont.push({
        setFontSize: 12
      });
      height = 80;
      this.addContentToPDFArray(cont, "text", 20, height, "Dados da Visita");
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, "Tipo: " + consultation.get("appointment.display"));
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, (0, _moment.default)().format("L"));
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, "Prescritor: " + consultation.get("worker1.name"));
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, "Motivo: " + consultation.get("appointment.observation"), 30);
      height = 80;
      this.addContentToPDFArray(cont, "text", 120, height, "Dados do paciente");
      height += 5;
      this.addContentToPDFArray(cont, "text", 120, height, "Nome: " + consultation.get("patient.name"), 30);
      height += 5;
      this.addContentToPDFArray(cont, "text", 120, sizePatient, "Espécie: " + consultation.get("patient.species.name"));
      height = 110;
      this.addContentToPDFArray(cont, "text", 120, height, "Tutor(a)");
      height += 5;
      this.addContentToPDFArray(cont, "text", 120, height, "Nome: " + consultation.get("owner.firstAndLastName"), 30);
      this.addContentToPDFArray(cont, "text", 120, sizeCustomer, "Contacto: " + consultation.get("owner.lastContact.contact"), 30);
      height += 10;
      this.addContentToPDFArray(cont, "text", 20, height, "Grupo de parâmetros: ", 40);

      if (fields[0].value) {
        this.breakLines(fields[0].value.split(" "), 12).forEach(function (l) {
          self.addContentToPDFArray(cont, "text", 65, height, l, 82);
          height += 5;
        });
      }

      height += 10;
      this.addContentToPDFArray(cont, "text", 20, height, "Comentários: ", 40);

      if (fields[16].value) {
        this.breakLines(fields[16].value.split(" "), 12).forEach(function (l) {
          self.addContentToPDFArray(cont, "text", 50, height, l, 82);
          height += 5;
        });
      }

      height += 10;
      this.addContentToPDFArray(cont, "text", 20, height, "Data de Conclusão: ", 40);

      if (fields[17].value) {
        this.breakLines(fields[17].value.split(" "), 12).forEach(function (l) {
          self.addContentToPDFArray(cont, "text", 60, height, l, 82);
          height += 5;
        });
      }

      height += 10;
      cont.push({
        line: [40, 230, height, 230]
      });
      this.addContentToPDFArray(cont, "text", 80, 240, "Dr(a). " + consultation.get("worker1.name"));
      cont.push({
        setFontSize: 9
      });
      this.addContentToPDFArray(cont, "text", 10, 260, "Processado por computador - Pet Universal, Data: " + (0, _moment.default)().format("L") + " às " + (0, _moment.default)().format("LT"), null, 90);
      return cont;
    },
    internmentDischarge: function internmentDischarge(name, consultation, organization, fields) {
      var height = 12;
      var self = this;
      var sizePatient;
      var sizeCustomer;

      if (consultation.get("patient.name").length > 30) {
        sizePatient = 85;
      } else {
        sizePatient = 80;
      }

      if (consultation.get("owner.firstAndLastName").length > 30) {
        sizeCustomer = 115;
      } else {
        sizeCustomer = 110;
      }

      var cont = [{
        setFontSize: 11
      }];
      this.addContentToPDFArray(cont, "text", 20, height, "Data: " + (0, _moment.default)().format("YYYY-MM-DD HH:mm"));
      height += 12;
      cont.push({
        setFontSize: 19
      });
      this.addContentToPDFArray(cont, "text", 20, height, organization.get("name"));
      height += 6;
      cont.push({
        setFontSize: 12
      });
      this.addContentToPDFArray(cont, "text", 20, height, organization.get("street"));
      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, organization.get("town") + " " + organization.get("zip"));
      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, "Telefone: " + organization.get("phone"));
      height += 10;
      cont.push({
        setFontSize: 19
      });
      this.addContentToPDFArray(cont, "text", 65, height, "ALTA DE INTERNAMENTO");
      cont.push({
        setFontSize: 12
      });
      height = 80;
      this.addContentToPDFArray(cont, "text", 20, height, "Dados da Visita");
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, "Tipo: " + consultation.get("appointment.display"));
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, (0, _moment.default)().format("L"));
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, "Prescritor: " + consultation.get("worker1.name"));
      height += 5;
      this.addContentToPDFArray(cont, "text", 20, height, "Motivo: " + consultation.get("appointment.observation"), 30);
      height = 80;
      this.addContentToPDFArray(cont, "text", 120, height, "Dados do paciente");
      height += 5;
      this.addContentToPDFArray(cont, "text", 120, height, "Nome: " + consultation.get("patient.name"), 30);
      height += 5;
      this.addContentToPDFArray(cont, "text", 120, sizePatient, "Espécie: " + consultation.get("patient.species.name"));
      height = 110;
      this.addContentToPDFArray(cont, "text", 120, height, "Tutor(a)");
      height += 5;
      this.addContentToPDFArray(cont, "text", 120, height, "Nome: " + consultation.get("owner.firstAndLastName"), 30);
      this.addContentToPDFArray(cont, "text", 120, sizeCustomer, "Contacto: " + consultation.get("owner.lastContact.contact"), 30);
      height += 10;
      this.addContentToPDFArray(cont, "text", 20, height, "Diagnóstico: ", 40);

      if (fields.get("firstObject").value) {
        this.addContentToPDFArray(cont, "text", 45, height, fields.get("firstObject").value);
      }

      height += 10;
      cont.push({
        setFontSize: 11
      });
      this.addContentToPDFArray(cont, "text", 20, height, "Alimentação e água: ", 40);

      if (fields[1].value) {
        this.breakLines(fields[1].value.split(" "), 12).forEach(function (l) {
          self.addContentToPDFArray(cont, "text", 55, height, l, 82);
          height += 5;
        });
      }

      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, "Exercício actividade: ", 40);

      if (fields[2].value) {
        this.breakLines(fields[2].value.split(" "), 12).forEach(function (l) {
          self.addContentToPDFArray(cont, "text", 55, height, l, 82);
          height += 5;
        });
      }

      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, "Eliminação: ", 40);

      if (fields[3].value) {
        this.breakLines(fields[3].value.split(" "), 12).forEach(function (l) {
          self.addContentToPDFArray(cont, "text", 40, height, l, 82);
          height += 5;
        });
      }

      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, "Sutura: ", 40);

      if (fields[4].value) {
        this.breakLines(("Sutura: " + fields[4].value).split(" "), 12).forEach(function (l) {
          self.addContentToPDFArray(cont, "text", 35, height, l, 82);
          height += 5;
        });
      }

      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, "Vigiar: ", 40);

      if (fields[5].value) {
        this.breakLines(fields[5].value.split(" "), 12).forEach(function (l) {
          self.addContentToPDFArray(cont, "text", 35, height, l, 82);
          height += 5;
        });
      }

      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, "Próxima consulta: ", 40);

      if (fields[6].value) {
        this.breakLines(fields[6].value.split(" "), 12).forEach(function (l) {
          self.addContentToPDFArray(cont, "text", 55, height, l, 82);
          height += 5;
        });
      }

      height += 6;
      this.addContentToPDFArray(cont, "text", 20, height, "Próximos exames: ", 40);

      if (fields[7].value) {
        this.breakLines(fields[7].value.split(" "), 12).forEach(function (l) {
          self.addContentToPDFArray(cont, "text", 55, height, l, 82);
          height += 5;
        });
      }

      height += 6;
      cont.push({
        line: [40, height, 170, height]
      });
      height += 10;
      this.addContentToPDFArray(cont, "text", 80, height, "Dr(a). " + consultation.get("worker1.name"));
      cont.push({
        setFontSize: 9
      });
      this.addContentToPDFArray(cont, "text", 10, 260, "Processado por computador - Pet Universal, Data: " + (0, _moment.default)().format("L") + " às " + (0, _moment.default)().format("LT"), null, 90);
      return cont;
    },
    createPDFEcoReportContent: function createPDFEcoReportContent(consultation) {
      var sex;

      if (consultation.get("patient.sex") === 1) {
        sex = this.get('i18n').t('models.sex.m');
      } else if (consultation.get("patient.sex") === 2) {
        sex = this.get('i18n').t('models.sex.f');
      }

      var cont = [{
        setFontSize: 11
      }];
      this.addContentToPDFArray(cont, "text", 20, 12, "Data: " + (0, _moment.default)().format("YYYY-MM-DD HH:mm"));
      cont.push({
        setFontSize: 19
      });
      this.addContentToPDFArray(cont, "text", 70, 24, "Relatório Ecográfico");
      cont.push({
        setFontSize: 12
      });
      this.addContentToPDFArray(cont, "text", 20, 40, "Nome do Proprietário:");
      this.addContentToPDFArray(cont, "text", 80, 40, consultation.get("patient.owner.name"));
      this.addContentToPDFArray(cont, "text", 20, 45, "Nome do Paciente:");
      this.addContentToPDFArray(cont, "text", 80, 45, consultation.get("patient.name"));
      this.addContentToPDFArray(cont, "text", 20, 50, "Raça:");
      this.addContentToPDFArray(cont, "text", 50, 50, consultation.get("patient.breed"));
      this.addContentToPDFArray(cont, "text", 20, 55, "Idade:");
      this.addContentToPDFArray(cont, "text", 50, 55, "" + consultation.get("patient.age"));
      this.addContentToPDFArray(cont, "text", 80, 55, "Sexo:");
      this.addContentToPDFArray(cont, "text", 80, 55, sex);
      this.addContentToPDFArray(cont, "text", 20, 65, "Data da ecografia:");
      this.addContentToPDFArray(cont, "text", 80, 65, "Data do relatório:");
      this.addContentToPDFArray(cont, "text", 20, 80, "Motivo da ecografia:");
      cont.push({
        setFillColor: "#EDEDED"
      });
      cont.push({
        rect: [20, 85, 170, 15, "F"]
      });
      this.addContentToPDFArray(cont, "text", 20, 90, "Orgão |", 67);
      this.addContentToPDFArray(cont, "text", 35, 90, "Normal |", 67);
      this.addContentToPDFArray(cont, "text", 52.5, 90, "Anormal |", 67);
      this.addContentToPDFArray(cont, "text", 71, 90, "Não visualizado |", 67);
      this.addContentToPDFArray(cont, "text", 105, 90, "Orgão |", 67);
      this.addContentToPDFArray(cont, "text", 120, 90, "Normal |", 67);
      this.addContentToPDFArray(cont, "text", 140, 90, "Anormal |", 67);
      this.addContentToPDFArray(cont, "text", 160, 90, "Não visualizado", 67);
      this.addContentToPDFArray(cont, "text", 33, 91, "|", 67);
      this.addContentToPDFArray(cont, "text", 20, 92, "________________________________________________________________________", 80);
      this.addContentToPDFArray(cont, "text", 35, 90, "Normal |", 67);
      this.addContentToPDFArray(cont, "text", 52.5, 90, "Anormal |", 67);
      this.addContentToPDFArray(cont, "text", 71, 90, "Não visualizado |", 67);
      this.addContentToPDFArray(cont, "text", 105, 90, "Orgão |", 67);
      this.addContentToPDFArray(cont, "text", 120, 90, "Normal |", 67);
      this.addContentToPDFArray(cont, "text", 140, 90, "Anormal |", 67);
      this.addContentToPDFArray(cont, "text", 160, 90, "Não visualizado", 67);
      cont.push({
        setFillColor: "#EDEDED"
      });
      cont.push({
        rect: [20, 150, 170, 20, "F"]
      });
      this.addContentToPDFArray(cont, "text", 25, 160, "Este é um exemplo de uma carta de alta", 67);
      cont.push({
        line: [40, 230, 170, 230]
      });
      this.addContentToPDFArray(cont, "text", 80, 240, "Dr(a). " + consultation.get("worker1.name"));
      cont.push({
        setFontSize: 9
      });
      this.addContentToPDFArray(cont, "text", 10, 260, "Processado por computador - Pet Universal, Data: " + (0, _moment.default)().format("L") + " às " + (0, _moment.default)().format("LT"), null, 90);
      return cont;
    },
    createPDFDebtConsentContent: function createPDFDebtConsentContent(consultation, organization) {
      var self = this;
      var cont = [{
        setFontSize: 11
      }];
      this.addContentToPDFArray(cont, "text", 20, 12, "Data: " + (0, _moment.default)().format("YYYY-MM-DD HH:mm"));
      cont.push({
        setFontSize: 19
      });
      cont.push({
        setFont: 'Arial'
      });
      var height = 50;
      this.addContentToPDFArray(cont, "text", 20, 24, "Declaração Compromissória de Reconhecimento de Dívida e Plano de Pagamento", 60);
      cont.push({
        setFontSize: 14
      });
      this.breakLines("PRIMEIRA CLÁUSULA".split(" "), 14).forEach(function (l) {
        height += 5;
        self.addContentToPDFArray(cont, "text", 20, height, l, 50);
      });
      cont.push({
        setFontSize: 12
      });
      var src = "OUTORGANTE: " + consultation.get("patient.owner.name") + " contribuinte fiscal número " + consultation.get("patient.owner.nif") + ", na qualidade de dono e legítimo possuidor do animal de estimação " + consultation.get("patient.name") + ", adiante designado por Outurgante.";
      this.breakLines(src.split(" "), 12).forEach(function (l) {
        height += 5;
        self.addContentToPDFArray(cont, "text", 20, height, l, 82);
      });
      this.addContentToPDFArray(cont, "text", 20, 100, "1. O Outorgante expressamente reconhece e assume de pleno a responsabilidade pelo pagamento integral, reconhecendo-se devedor perante a sociedade " + organization.get("name") + ", pessoa coletiva número" + ", na data da assinatura desta declaração, da quantia de capital de _________€.", 95);
      this.addContentToPDFArray(cont, "text", 20, 125, "2. O Outorgante expressamente reconhece e assume de pleno a responsabilidade pelo pagamento integral, reconhecendo-se devedor perante a sociedade " + organization.get("company.name") + ", pessoa coletiva número" + ", na data da assinatura desta declaração, da quantia de capital de _________€.", 95);
      this.addContentToPDFArray(cont, "text", 20, 150, "3. O Outorgante tomou conhecimento que a" + organization.get("company.name") + "cedeu a totalidade do crédito identificado no número anterior à sociedade " + organization.get("name") + ", pessoa coletiva número.", 95);
      this.addContentToPDFArray(cont, "text", 20, 170, "4. O Outorgante reconhece, asssim, ser devedor perante a sociedade " + organization.get("name") + ", na data de assinatura desta declaração, da quantia total de capital de _________€.", 95);
      cont.push({
        setFontSize: 14
      });
      this.addContentToPDFArray(cont, "text", 80, 190, "SEGUNDA CLÁUSULA", 50);
      cont.push({
        setFontSize: 12
      });
      this.addContentToPDFArray(cont, "text", 20, 200, "As dívidas referidas na clásula anterior dizem respeito ao fornecimento de bens e à prestação de serviços que cada uma as entidades referidas fez ao animal identificado no cqabeçalho, a pedido expresso do Outurgante.", 95);
      cont.push({
        setFontSize: 14
      });
      this.addContentToPDFArray(cont, "text", 80, 230, "TERCEIRA CLÁUSULA", 50);
      cont.push({
        setFontSize: 12
      });
      this.addContentToPDFArray(cont, "text", 20, 240, "1. O Outurgante compromete-se a efetuar o pagamento da quantia total de capital em dívida em ________ prestações mensais e sucessivas, no montante de ________€ cada um, até ao dia _________ de cada mês, com início no mês de _________.", 95);
      cont.push({
        setFontSize: 12
      });
      this.addContentToPDFArray(cont, "text", 20, 260, "1. O Outurgante compromete-se a pagar os juros de mora que à taxa legal em vigor se vencerem, no montante de _________€, juntamente com a última prestação.", 95);
      cont.push({
        setFontSize: 9
      });
      this.addContentToPDFArray(cont, "text", 10, 260, "Processado por computador - Pet Universal, Data: " + (0, _moment.default)().format("L") + " às " + (0, _moment.default)().format("LT"), null, 90);
      return cont;
    }
  });

  _exports.default = _default;
});