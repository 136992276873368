define("crm/templates/organizations/organization/hotel/boxes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lkq3qKVJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"class\",\"title\",\"titleIcon\"],[\"module-hotel\",[25,\"t\",[\"pages.boxes\"],null],[25,\"t\",[\"icons.boxes\"],null]]],{\"statements\":[[4,\"if\",[[20,[\"canDoBoxes\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[6,\"a\"],[10,\"title\",[26,[[25,\"t\",[\"boxes.create\"],null]]]],[3,\"action\",[[19,0,[]],\"openBoxNew\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[25,\"t\",[\"icons.boxes\"],null]]]],[7],[8],[0,\" \"],[1,[25,\"t\",[\"boxes.create\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ibox\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ibox-content\"],[7],[0,\"\\n        \"],[1,[25,\"boxes-list\",null,[[\"model\",\"openModal\",\"reloadModel\"],[[20,[\"model\"]],\"openModal\",\"reloadModel\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/hotel/boxes/index.hbs"
    }
  });

  _exports.default = _default;
});