define("crm/templates/components/order-line-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "on1x0GSw",
    "block": "{\"symbols\":[],\"statements\":[[6,\"a\"],[10,\"class\",[26,[[25,\"unless\",[[20,[\"model\",\"linePrice\"]],\"no-price\"],null]]]],[3,\"action\",[[19,0,[]],\"openDetails\",[20,[\"model\"]]]],[7],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[0,\"\\n    \"],[6,\"small\"],[7],[0,\"(\"],[1,[20,[\"model\",\"quantity\"]],false],[0,\"x \"],[1,[20,[\"model\",\"item\",\"saleUnitSymbol\"]],false],[0,\") \"],[4,\"if\",[[20,[\"model\",\"discount\"]]],null,{\"statements\":[[0,\"(\"],[1,[20,[\"model\",\"discount\"]],false],[0,\"%)\"]],\"parameters\":[]},null],[8],[0,\" \"],[4,\"if\",[[20,[\"model\",\"linePrice\"]]],null,{\"statements\":[[1,[25,\"u-num-round2\",[[20,[\"model\",\"linePrice\"]]],null],false],[0,\" \"],[1,[25,\"t\",[\"icons.currency\"],null],false],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"span\"],[9,\"class\",\"ellipsis item-name\"],[10,\"title\",[20,[\"model\",\"item\",\"name\"]],null],[7],[0,\"\\n    \"],[1,[20,[\"model\",\"item\",\"name\"]],false],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"item\",\"description\"]]],null,{\"statements\":[[0,\"      \"],[6,\"small\"],[9,\"class\",\"text-muted\"],[10,\"title\",[20,[\"model\",\"item\",\"description\"]],null],[7],[6,\"i\"],[9,\"class\",\"fa fa-fw fa-info\"],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"small\"],[7],[6,\"i\"],[9,\"class\",\"fa fa-fw\"],[7],[8],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/order-line-card.hbs"
    }
  });

  _exports.default = _default;
});