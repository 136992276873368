define("crm/templates/components/show-animals-number-by-client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PEdbyTEl",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[6,\"i\"],[9,\"class\",\"fa fa-paw\"],[7],[8],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"label\"],[7],[0,\"\\n    \"],[1,[20,[\"record\",\"patients\",\"length\"]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/show-animals-number-by-client.hbs"
    }
  });

  _exports.default = _default;
});