define("crm/templates/components/consultation-details-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UF2Hfdhq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"class\",\"title\",\"modalSize\",\"noPadding\",\"hideFooter\",\"action2\",\"action2Text\",\"action2Icon\"],[\"modal-consultation-details\",\"module-clinical iboxes\",[20,[\"model\",\"appointment\",\"type\",\"name\"]],\"md\",true,[20,[\"canDoConsultationDetails\"]],\"consultationDetails\",\"actions.details\",[25,\"t\",[\"icons.detail\"],null]]],{\"statements\":[[0,\"  \"],[1,[25,\"consultation-details\",null,[[\"model\",\"openModalMDCDetails\",\"openModalAssociatePatient\",\"openEditPatientModal\",\"delete\",\"setEnabled\",\"makeSale\",\"openCreateAppointmentModal\",\"openUpdateCustomerModal\",\"openDrugDetailsModal\"],[[20,[\"model\"]],\"openModalMDCDetails\",\"openModalAssociatePatient\",\"openEditPatientModal\",\"delete\",\"setEnabled\",\"makeSale\",\"openCreateAppointmentModal\",\"openUpdateCustomerModal\",\"openDrugDetailsModal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/consultation-details-modal.hbs"
    }
  });

  _exports.default = _default;
});