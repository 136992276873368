define("crm/templates/components/pet-new-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vCk8j4QI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"modal-dialog modal-\",[25,\"if\",[[20,[\"modalSize\"]],[20,[\"modalSize\"]],\"lg\"],null]]]],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"modal-content\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"modal-header\"],[7],[0,\"\\n            \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"close\"],[9,\"data-dismiss\",\"modal\"],[9,\"aria-hidden\",\"true\"],[7],[0,\"×\"],[8],[0,\"\\n            \"],[6,\"h4\"],[9,\"class\",\"modal-title\"],[7],[1,[18,\"title\"],false],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"modal-body\"],[7],[0,\"\\n            \"],[6,\"div\"],[7],[0,\"\\n                \"],[11,1],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"modal-footer\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"action1\"]]],null,{\"statements\":[[0,\"                \"],[6,\"button\"],[9,\"type\",\"submit\"],[9,\"class\",\"btn btn-primary\"],[3,\"action\",[[19,0,[]],\"sendAction1\"]],[7],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[18,\"action1Icon\"]]]],[7],[8],[0,\" \"],[1,[25,\"t\",[[20,[\"action1Text\"]]],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/pet-new-modal.hbs"
    }
  });

  _exports.default = _default;
});