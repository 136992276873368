define("crm/templates/organizations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f+T8zUfg",
    "block": "{\"symbols\":[],\"statements\":[[1,[18,\"outlet\"],false],[0,\"\\n\\n\"],[1,[25,\"sweet-alert\",null,[[\"title\",\"titleClass\",\"html\",\"type\",\"show\",\"allowOutsideClick\",\"showConfirmButton\"],[[25,\"t\",[\"system.alerts.offline.title\"],null],\"text-danger\",[25,\"t\",[\"system.alerts.offline.html\"],null],\"error\",[20,[\"showSweetAlertError\"]],false,false]]],false],[0,\"\\n\\n\"],[1,[25,\"sweet-alert\",null,[[\"title\",\"text\",\"type\",\"show\",\"showConfirmButton\",\"timer\"],[[25,\"t\",[\"system.alerts.online.title\"],null],[25,\"t\",[\"system.alerts.online.text\"],null],\"success\",[20,[\"showSweetAlertSuccess\"]],false,2000]]],false],[0,\"\\n\\n\"],[4,\"if\",[[25,\"can\",[\"do god\"],null]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"id\",\"godAlert\"],[9,\"class\",\"alert alert-danger\"],[7],[0,\"GOD MODE!\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations.hbs"
    }
  });

  _exports.default = _default;
});