define("crm/templates/companies/company/employees/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NJqkbdBC",
    "block": "{\"symbols\":[\"employee\"],\"statements\":[[6,\"div\"],[9,\"class\",\"animated fadeInRight\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"model\"]]],null,{\"statements\":[[4,\"link-to\",[\"companies.company.employees.employee\",[19,1,[\"id\"]]],[[\"tagName\",\"class\"],[\"article\",\"clickable col-xs-12 col-sm-6 col-lg-4 col-xl-3\"]],{\"statements\":[[0,\"                \"],[6,\"div\"],[9,\"class\",\"contact-box profile\"],[7],[0,\"\\n                    \"],[6,\"div\"],[9,\"class\",\"col-sm-4\"],[7],[0,\"\\n                        \"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n                            \"],[6,\"img\"],[9,\"alt\",\"image\"],[9,\"class\",\"img-circle m-t-xs img-responsive\"],[9,\"src\",\"/universe-gui/images/avatar.png\"],[7],[8],[0,\"\\n\\n                            \"],[6,\"div\"],[9,\"class\",\"m-t-xs font-bold\"],[7],[4,\"if\",[[25,\"eq\",[1,[19,1,[\"profile\"]]],null]],null,{\"statements\":[[0,\"Admin\"]],\"parameters\":[]},{\"statements\":[[0,\" \"]],\"parameters\":[]}],[8],[0,\"\\n                        \"],[8],[0,\"\\n                    \"],[8],[0,\"\\n                    \"],[6,\"div\"],[9,\"class\",\"col-sm-8\"],[7],[0,\"\\n                        \"],[6,\"h3\"],[9,\"class\",\"ellipsis\"],[7],[6,\"strong\"],[7],[1,[19,1,[\"user\",\"name\"]],false],[8],[8],[0,\"\\n                        \"],[6,\"p\"],[9,\"class\",\"ellipsis\"],[7],[4,\"if\",[[19,1,[\"user\",\"email\"]]],null,{\"statements\":[[6,\"i\"],[9,\"class\",\"fa fa-fw fa-envelope-o\"],[7],[8],[0,\" \"],[1,[19,1,[\"user\",\"email\"]],false]],\"parameters\":[]},{\"statements\":[[0,\" \"]],\"parameters\":[]}],[8],[0,\"\\n\"],[0,\"                    \"],[8],[0,\"\\n                    \"],[6,\"div\"],[9,\"class\",\"clearfix\"],[7],[8],[0,\"\\n                \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/companies/company/employees/index.hbs"
    }
  });

  _exports.default = _default;
});