define("crm/templates/organizations/organization/warehouses/warehouse/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sfPwJvmB",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"pages.warehouse\"],null],[25,\"t\",[\"icons.warehouse\"],null]]]],false],[0,\"\\n\"],[1,[25,\"loading-page\",null,[[\"icon\",\"hideSearch\"],[\"warehouse\",true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/warehouses/warehouse/loading.hbs"
    }
  });

  _exports.default = _default;
});