define("crm/templates/components/temperament-faces", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/J6DzRc4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"showOnly\"]]],null,{\"statements\":[[0,\"    \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-\",[20,[\"selectedTemperament\",\"icon\"]],\" text-\",[20,[\"selectedTemperament\",\"color\"]]]]],[10,\"title\",[26,[[20,[\"selectedTemperament\",\"title\"]]]]],[7],[8],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[2,\" GOOD \"],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"clickable\"],[3,\"action\",[[19,0,[]],\"setTemperament\",1]],[7],[0,\"\\n        \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-smile-o \",[25,\"if\",[[25,\"eq\",[[20,[\"temperament\"]],1],null],\"text-navy\",\"\"],null]]]],[10,\"title\",[25,\"t\",[\"patients.patient.temperament.good\"],null],null],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n    \"],[2,\" Average \"],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"clickable\"],[3,\"action\",[[19,0,[]],\"setTemperament\",5]],[7],[0,\"\\n        \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-meh-o \",[25,\"if\",[[25,\"eq\",[[20,[\"temperament\"]],5],null],\"text-warning\",\"\"],null]]]],[10,\"title\",[25,\"t\",[\"patients.patient.temperament.moderate\"],null],null],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n    \"],[2,\" BAD \"],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"clickable\"],[3,\"action\",[[19,0,[]],\"setTemperament\",9]],[7],[0,\"\\n        \"],[6,\"i\"],[10,\"class\",[26,[\"fa fa-lg fa-frown-o \",[25,\"if\",[[25,\"eq\",[[20,[\"temperament\"]],9],null],\"text-danger\",\"\"],null]]]],[10,\"title\",[25,\"t\",[\"patients.patient.temperament.agressive\"],null],null],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[11,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/temperament-faces.hbs"
    }
  });

  _exports.default = _default;
});