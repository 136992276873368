define("crm/templates/users/user/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sRoiHZFq",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-lg-10 col-lg-offset-1\"],[7],[0,\"\\n        \"],[1,[25,\"pu-person-ibox\",null,[[\"title\",\"model\",\"newRoute\",\"newRouteID\",\"newRouteIcon\",\"hideTools\"],[[25,\"t\",[\"models.user.entity\"],null],[20,[\"model\",\"person\"]],\"users.user.edit\",[20,[\"model\",\"id\"]],\"pencil\",true]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/users/user/index.hbs"
    }
  });

  _exports.default = _default;
});