define("crm/templates/organizations/organization/stats/internments/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SjMEKHZm",
    "block": "{\"symbols\":[],\"statements\":[[6,\"h2\"],[7],[1,[25,\"t\",[\"stats.variations\"],null],false],[8],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"col-xs-12 col-md-6 col-lg-4 col-xl-3\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"organizations.organization.stats.internments.daily\"],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"widget yellow-bg p-lg text-center\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"m-b-md\"],[7],[0,\"\\n          \"],[6,\"i\"],[9,\"class\",\"fa fa-bar-chart fa-4x\"],[7],[8],[0,\"\\n          \"],[6,\"h1\"],[9,\"class\",\"m-xs\"],[7],[1,[25,\"t\",[\"internments.title\"],null],false],[8],[0,\"\\n          \"],[6,\"h3\"],[9,\"class\",\"font-bold no-margins\"],[7],[1,[25,\"t\",[\"internments.title\"],null],false],[8],[0,\"\\n          \"],[6,\"small\"],[7],[0,\"(\"],[1,[25,\"t\",[\"icons.currency\"],null],false],[0,\")\"],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/stats/internments/index.hbs"
    }
  });

  _exports.default = _default;
});