define("crm/templates/organizations/organization/charges/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XwKUrShC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\",\"class\"],[[25,\"t\",[\"charges.title\"],null],[25,\"t\",[\"icons.charge\"],null],\"module-sales\"]],{\"statements\":[[0,\"  \"],[6,\"li\"],[7],[4,\"link-to\",[\"organizations.organization.charges.settings\"],null,{\"statements\":[[6,\"i\"],[10,\"class\",[26,[\"fa fa-\",[25,\"t\",[\"icons.configs\"],null]]]],[7],[8],[0,\" \"],[1,[25,\"t\",[\"configurations.title\"],null],false]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"ibox-panel\",null,[[\"class\"],[\"module-sales\"]],{\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"pet-models-table\",null,[[\"data\",\"columns\"],[[20,[\"model\"]],[20,[\"columns\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[25,\"empty-set\",null,[[\"icon\"],[[25,\"t\",[\"icons.charge\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/charges/index.hbs"
    }
  });

  _exports.default = _default;
});