define("crm/templates/organizations/organization/items/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2VW+V9PR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"pages.items\"],null],[25,\"t\",[\"icons.item\"],null]]],{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"openCompoundProductCreate\"]],[7],[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.compoundProduct\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"compoundProduct.newCompoundProduct\"],null],false],[8],[8],[0,\"\\n    \"],[6,\"li\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"openModalCreateItem\"]],[7],[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.item\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"items.new.title\"],null],false],[8],[8],[0,\"\\n\"],[4,\"if\",[[25,\"eq\",[[20,[\"exporting\"]],false],null]],null,{\"statements\":[[0,\"        \"],[6,\"li\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"export\",[20,[\"model\",\"createdBefore\"]],[20,[\"model\",\"createdAfter\"]]]],[7],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-cloud-download\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"actions.download\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"li\"],[7],[6,\"a\"],[9,\"class\",\"disabled\"],[7],[6,\"i\"],[9,\"class\",\"fa fa-lg fa-cloud-download\"],[7],[8],[0,\" \"],[1,[25,\"t\",[\"actions.download\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[1,[25,\"loading-page\",null,[[\"icon\"],[\"item\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/items/loading.hbs"
    }
  });

  _exports.default = _default;
});