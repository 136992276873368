define("crm/routes/organizations/organization/consultations/consultation/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    afterModel: function afterModel(model) {
      Ember.RSVP.hash({
        patient: this.store.findRecord("patient", model.get("patient.id"), {
          reload: true
        }),
        appointment: this.store.query("appointment", {
          patient: model.get("patient.id")
        }),
        // to display the history
        consultations: this.store.query("consultation", {
          patient: model.get("patient.id")
        }),
        // to display the history
        periodicItems: this.store.query("item", {
          patient: model.get("patient.id"),
          periodic: true
        }),
        periodics: this.store.query("periodic", {
          patient: model.get("patient.id")
        }),
        files: this.store.query("fileConsultation", {
          consultation: model.get("id")
        }),
        fileExams: this.store.query("fileExam", {
          consultation: model.get("id")
        }),
        templates: this.store.query("consultation-template-section-field", {
          consultation: model.get("id")
        }, {
          reload: true
        }),
        exams: this.store.query("exam", {
          consultation: model.get("id")
        }),
        drugs: this.store.query("drug", {
          consultation: model.get("id")
        }),
        shoppingCarts: this.store.query("shopping-cart", {
          consultation: model.get("id")
        }, {
          reload: true
        }),
        reports: this.store.query("report", {
          consultation: model.get("id")
        }, {
          reload: true
        }),
        reportSections: this.store.query("report-section", {
          consultation: model.get("id")
        }, {
          reload: true
        }),
        reportFields: this.store.query("report-field", {
          consultation: model.get("id")
        }, {
          reload: true
        }),
        templateSectionFields: this.store.query("template-section-field", {
          consultation: model.get("id")
        }, {
          reload: true
        }),
        physicalExam: model.get("physicalExam.id") ? this.store.findRecord("physicalExam", model.get("physicalExam.id")) : null
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (model.get("organization.company.id")) {
        controller.set("templates", this.store.query("template", {
          company: model.get("organization.company.id")
        }));
      }
    }
  });

  _exports.default = _default;
});