define("crm/templates/organizations/campanhas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kEpo9tUi",
    "block": "{\"symbols\":[\"org\",\"campaign\",\"key\",\"value\",\"contact\",\"kk\",\"vv\"],\"statements\":[[4,\"each\",[[20,[\"model\"]]],null,{\"statements\":[[4,\"ibox-panel\",null,[[\"title\"],[[19,1,[\"organization\"]]]],{\"statements\":[[4,\"each\",[[19,1,[\"campaigns\"]]],null,{\"statements\":[[4,\"each\",[[25,\"-each-in\",[[19,2,[]]],null]],null,{\"statements\":[[0,\"        \"],[6,\"b\"],[7],[1,[19,3,[]],false],[8],[0,\"\\n\"],[4,\"each\",[[19,4,[\"contactList\"]]],null,{\"statements\":[[4,\"each\",[[25,\"-each-in\",[[19,5,[]]],null]],null,{\"statements\":[[0,\"            \"],[6,\"p\"],[7],[6,\"b\"],[7],[1,[19,6,[]],false],[8],[0,\": \"],[1,[19,7,[]],false],[8],[0,\"\\n\"]],\"parameters\":[6,7]},null]],\"parameters\":[5]},null]],\"parameters\":[3,4]},null]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/campanhas.hbs"
    }
  });

  _exports.default = _default;
});