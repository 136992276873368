define("crm/templates/organizations/organization/internments/internment/todo-monitorings/todo-monitoring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qQgwyAkM",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"internment/todo-details\",null,[[\"model\",\"callModal\",\"backToInternment\",\"closeTask\",\"type\",\"transitionToTask\",\"currentVet\",\"serumTypes\",\"supplementationTypes\"],[[20,[\"model\"]],[25,\"action\",[[19,0,[]],\"callModal\"],null],[25,\"action\",[[19,0,[]],\"backToInternment\"],null],\"closeTask\",[20,[\"type\"]],\"transitionToTask\",[20,[\"model\",\"task\",\"currentVet\"]],[20,[\"model\",\"serumTypes\"]],[20,[\"model\",\"supplementationTypes\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/internments/internment/todo-monitorings/todo-monitoring.hbs"
    }
  });

  _exports.default = _default;
});