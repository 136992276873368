define("crm/templates/components/warehouse-details-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hJv1hPSO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-modal\",null,[[\"id\",\"class\",\"title\",\"modalSize\"],[\"modal-warehouse-details\",\"module-sales\",[25,\"t\",[\"warehouses.warehouse.title\"],null],\"md\"]],{\"statements\":[[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"col-lg-4 col-lg-offset-4\"],[7],[0,\"\\n        \"],[6,\"h1\"],[9,\"class\",\"text-center\"],[7],[1,[20,[\"model\",\"name\"]],false],[8],[0,\"\\n        \"],[4,\"if\",[[20,[\"model\",\"description\"]]],null,{\"statements\":[[6,\"p\"],[9,\"class\",\"well\"],[7],[1,[20,[\"model\",\"description\"]],false],[8]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/components/warehouse-details-modal.hbs"
    }
  });

  _exports.default = _default;
});